import Divider from "@yoopta/divider";

export const myDivider = Divider.extend({
  elementProps: {
    divider: props => {
      return {...props,
        color: '#0060DEFF'
      }
    }
  }
})
