import {
  AgilesDeskSettingsAccessState,
  AgilesDeskSettingsAccessUser,
  AgilesDeskSettingsAccessUserRoles
} from "./agiles-desk-settings-access-state";
import {
  AgilesDeskSettingsAccessAction,
  AgilesDeskSettingsAccessActionTypes
} from "./agiles-desk-settings-access-action";
import {
  AgilesDeskSettingsAccessResultAction,
  AgilesDeskSettingsAccessResultActionTypes
} from "./agiles-desk-settings-access-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { parseToHighlightedParts } from "../../../../../../../../../core/utils/parse-to-highlighted-parts";
import {
  ShortEmployee
} from "../../../../../../../../../core/components/select-user-modal/select-user-modal.component";

@Injectable()
export class AgilesDeskSettingsAccessExecutor extends Executor<
  AgilesDeskSettingsAccessState,
  AgilesDeskSettingsAccessAction,
  AgilesDeskSettingsAccessResultAction
> {

  constructor(

  ) {
    super();
  }

  override init(
    reducer: Reducer<AgilesDeskSettingsAccessState, AgilesDeskSettingsAccessResultAction>,
    getState: () => AgilesDeskSettingsAccessState,
    onReduced: (state: AgilesDeskSettingsAccessState) => void,
  ) {
    super.init(reducer, getState, onReduced);
  }

  execute(action: AgilesDeskSettingsAccessAction) {
    switch (action.type) {
      case AgilesDeskSettingsAccessActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY:
        this.reduce({
          type: AgilesDeskSettingsAccessResultActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY,
          value: action.value
        })
        break;
      case AgilesDeskSettingsAccessActionTypes.ADD_USERS:
        this.handleAddUsers(action.users)
        break;
      case AgilesDeskSettingsAccessActionTypes.CHANGE_USER_ROLE:
        this.handleChangeUserRole(action.user, action.roleId)
        break
      case AgilesDeskSettingsAccessActionTypes.DELETE_USER:
        this.reduce({
          type: AgilesDeskSettingsAccessResultActionTypes.DELETE_USER,
          user: action.user
        })
        break
      case AgilesDeskSettingsAccessActionTypes.CLOSE_DELETE_MODAL:
        this.reduce({
          type: AgilesDeskSettingsAccessResultActionTypes.CHANGE_DELETE_USER_MODAL_VISIBILITY,
          value: false
        })
        break
      case AgilesDeskSettingsAccessActionTypes.CHANGE_SEARCH_FIELD:
        this.handleFilterUsers(action.value)
        break
      case AgilesDeskSettingsAccessActionTypes.DELETE_CONFIRM:
        this.handleDeleteConfirm()
        break;
    }
  }

  private handleDeleteConfirm(){
    const state = this.getState()
    this.reduce({
      type: AgilesDeskSettingsAccessResultActionTypes.CHANGE_USERS,
      users: state.users.slice().filter(user => user.user.id !== state.userToDelete?.user.id)
    })
    this.handleFilterUsers(this.getState().filterString)
    this.reduce({
      type: AgilesDeskSettingsAccessResultActionTypes.CHANGE_DELETE_USER_MODAL_VISIBILITY,
      value: false
    })
  }

  private handleAddUsers(users: ShortEmployee[]){
    this.reduce({
      type: AgilesDeskSettingsAccessResultActionTypes.CHANGE_USERS,
      users: this.getState().users.slice()
        .concat(users
          .map((user)=> {
            return {
              user: user,
              role: AgilesDeskSettingsAccessUserRoles.PARTICIPANT
            }
          })
        )
    })
    this.handleFilterUsers(this.getState().filterString)
  }

  private handleFilterUsers(filterString: string) {
    this.reduce({
      type: AgilesDeskSettingsAccessResultActionTypes.FILTER_USERS,
      filterString: filterString,
      filteredUsers: this.getState().users.filter(user => {
        const fullName = user.user.surname + ' ' + user.user.name + (user.user.patronymic ? ' ' + user.user.patronymic : '')
        if(fullName.toLowerCase().includes(filterString.toLowerCase())
          || user.user.email.toLowerCase().includes(filterString.toLowerCase())
        ){
          user.user.nameParts = parseToHighlightedParts(fullName, filterString)
          user.user.emailParts = parseToHighlightedParts(user.user.email, filterString)
          return true
        }
        return false
      })
    })
  }

  private handleChangeUserRole(
    user: AgilesDeskSettingsAccessUser,
    roleId: string
  ) {
    let userId = AgilesDeskSettingsAccessUserRoles.PARTICIPANT

    switch (roleId) {
      case AgilesDeskSettingsAccessUserRoles.PROHIBITED:
        userId = AgilesDeskSettingsAccessUserRoles.PROHIBITED
        break
      case AgilesDeskSettingsAccessUserRoles.ADMIN:
        userId = AgilesDeskSettingsAccessUserRoles.ADMIN
        break
    }

    this.reduce({
      type: AgilesDeskSettingsAccessResultActionTypes.CHANGE_USERS,
      users: this.getState().users.slice().map(mapUser => {
        return {...mapUser,
          role: user.user.id === mapUser.user.id ? userId : mapUser.role
        }
      })
    })
    this.handleFilterUsers(this.getState().filterString)
  }
}
