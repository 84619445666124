import {
  FormField
} from "../../../../../../../employees/modules/employees-list/presentation/components/create-employee-modal/state/create-employee-modal-state";
import { PaymentType } from "../../../../domain/payment-type";
import { ToggleItem } from "../../toggler/toggler.component";

export type PaymentResultAction =
  | ChangeOrganisationNameResultAction
  | ChangeLegalAddressResultAction
  | ChangeINNResultAction
  | ChangeKPPResultAction
  | ChangePhoneNumberResultAction
  | ToggleSoleProprietorResultAction
  | InitOnChangesResultAction
  | OpenInvoicePaymentModalResultAction
  | ValidateFieldsResultAction
  | TogglePaymentTypeResultAction
  | ChangeEmailResultAction
  | ChangeReceiptPriceResultAction
  | LocaliseResultAction

export enum PaymentResultActionTypes {
  CHANGE_ORGANISATION_NAME,
  CHANGE_LEGAL_ADDRESS,
  TOGGLE_I_SOLE_PROPRIETOR,
  CHANGE_INN,
  CHANGE_KPP,
  CHANGE_PHONE_NUMBER,
  INIT_ON_CHANGES,
  OPEN_INVOICE_PAYMENT_MODAL,
  VALIDATE_FIELDS,
  TOGGLE_PAYMENT_TYPE,
  CHANGE_EMAIL,
  CHANGE_RECEIPT_PRICE,
  LOCALISE
}

export interface ChangeReceiptPriceResultAction {
  readonly type: PaymentResultActionTypes.CHANGE_RECEIPT_PRICE
  readonly value: number
}

export interface LocaliseResultAction {
  readonly type: PaymentResultActionTypes.LOCALISE
  readonly togglerItems: ToggleItem[]
}

export interface TogglePaymentTypeResultAction{
  readonly type: PaymentResultActionTypes.TOGGLE_PAYMENT_TYPE,
  readonly value: PaymentType
}

export interface OpenInvoicePaymentModalResultAction{
  readonly type: PaymentResultActionTypes.OPEN_INVOICE_PAYMENT_MODAL,
}

export interface InitOnChangesResultAction{
  readonly type: PaymentResultActionTypes.INIT_ON_CHANGES,
  readonly countUsers: number
  readonly periodType: 'month' | 'year'
}

export interface ChangeOrganisationNameResultAction {
  readonly type: PaymentResultActionTypes.CHANGE_ORGANISATION_NAME,
  readonly value: FormField
}

export interface ChangeEmailResultAction {
  readonly type: PaymentResultActionTypes.CHANGE_EMAIL,
  readonly value: FormField
}

export interface ChangeLegalAddressResultAction {
  readonly type: PaymentResultActionTypes.CHANGE_LEGAL_ADDRESS;
  readonly value: FormField
}

export interface ChangeINNResultAction {
  readonly type: PaymentResultActionTypes.CHANGE_INN;
  readonly value: FormField
}

export interface ChangeKPPResultAction {
  readonly type: PaymentResultActionTypes.CHANGE_KPP;
  readonly value: FormField
}

export interface ChangePhoneNumberResultAction {
  readonly type: PaymentResultActionTypes.CHANGE_PHONE_NUMBER;
  readonly value: FormField
}

export interface ToggleSoleProprietorResultAction {
  readonly type: PaymentResultActionTypes.TOGGLE_I_SOLE_PROPRIETOR;
  readonly value: boolean
}

export interface ValidateFieldsResultAction {
  readonly type: PaymentResultActionTypes.VALIDATE_FIELDS;
  readonly organisationName: FormField;
  readonly legalAddress: FormField;
  readonly INN: FormField;
  readonly KPP: FormField;
  readonly phoneNumber: FormField;
}
