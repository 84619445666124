import {
  provideTransloco,
  TranslocoModule
} from '@jsverse/transloco';
import { isDevMode, NgModule } from "@angular/core";
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTranslocoMessageformat } from "@jsverse/transloco-messageformat";

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['en', 'ru'],
        defaultLang: 'ru',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader
    }),
    provideTranslocoMessageformat({
      locales: ['en', 'ru'],
    })
  ],
})
export class TranslocoRootModule {}
