import { PasswordResetStageType } from "./password-reset-state";

export type PasswordResetResultAction =
  | ChangeCurrentPasswordResultAction
  | ChangeNewPasswordResultAction
  | ChangeNewConfirmPasswordResultAction
  | ChangePasswordResetStageResultAction
  | ChangeIsLoadingResultAction
  | SaveRecoverySaltResultAction

export enum PasswordResetResultActionTypes {
  CHANGE_CURRENT_PASSWORD,
  CHANGE_NEW_PASSWORD,
  CHANGE_NEW_CONFIRM_PASSWORD,
  CHANGE_STAGE,
  CHANGE_IS_LOADING,
  RECOVERY_INIT,
}

export interface ChangeCurrentPasswordResultAction {
  readonly type: PasswordResetResultActionTypes.CHANGE_CURRENT_PASSWORD
  readonly value: string
  readonly error: string | null
}

export interface ChangeIsLoadingResultAction {
  readonly type: PasswordResetResultActionTypes.CHANGE_IS_LOADING
  readonly value: boolean
}

export interface SaveRecoverySaltResultAction {
  readonly type: PasswordResetResultActionTypes.RECOVERY_INIT
  readonly token: string
  readonly salt?: string
}

export interface ChangePasswordResetStageResultAction {
  readonly type: PasswordResetResultActionTypes.CHANGE_STAGE
  readonly newStage: PasswordResetStageType
}

export interface ChangeNewPasswordResultAction {
  readonly type: PasswordResetResultActionTypes.CHANGE_NEW_PASSWORD
  readonly value: string
  readonly error: string | null
}

export interface ChangeNewConfirmPasswordResultAction {
  readonly type: PasswordResetResultActionTypes.CHANGE_NEW_CONFIRM_PASSWORD
  readonly value: string
  readonly error: string | null
}
