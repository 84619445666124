import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleComponent } from "./presentation/role.component";
import { RoleMainComponent } from './presentation/components/role-main/role-main.component';
import { RolePermissionsComponent } from './presentation/components/role-permissions/role-permissions.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SpaceSettingsHome, SvgPerson, SvgTrash } from "../../../../core/components/svg-components/svg.components";
import { EmployeesRolesModule } from "../employees-roles/employees-roles.module";
import { Validator } from "../../../../core/validators/validator";
import { SettingsModule } from "../../../../core/components/settings/settings.module";
import { PageHeaderComponent } from "../../../../core/components/page-header/page-header.component";
import { AlertModalComponent } from "../../../../core/components/alert-modal/alert-modal.component";
import { LoadingShadingComponent } from "../../../../core/components/loading-shading/loading-shading.component";
import { ButtonComponent } from "../../../../core/components/buttons/button/button.component";
import { FieldsModule } from "../../../../core/components/fields/fields.module";
import { RoleUsersComponent } from './presentation/components/role-users/role-users.component';
import { SelectUserModalComponent } from "../../../../core/components/select-user-modal/select-user-modal.component";
import { UserSummeryComponent } from "../../../../core/components/user-summery/user-summery.component";
import {
  SelectUsersModalServiceImpl
} from "../../../agiles/agile-desk/modules/agiles-desk-settings/presentation/components/agiles-desk-settings-access/data/select-users-modal-service-impl";
import { SelectUserModalService } from "../../../../core/services/select-user-modal-service/select-user-modal.service";
import { SvgRoleAccesses } from "./icons/role-icons";
import { RouterLink } from "@angular/router";
import { provideTranslocoScope, TranslocoDirective } from "@jsverse/transloco";
import { RoleConstants } from "./common/role-constants";
import { TextParserComponent } from "../../../../core/components/text-parser/text-parser.component";
import {
  PermissionsFormComponent
} from "../../../../core/components/permissions-form/permissions-form.component";

@NgModule({
  declarations: [
    RoleComponent,
    RoleMainComponent,
    RolePermissionsComponent,
    RoleUsersComponent,
    SvgRoleAccesses
  ],
  imports: [
    CommonModule,
    SpaceSettingsHome,
    SvgPerson,
    EmployeesRolesModule,
    SvgTrash,
    MatProgressSpinnerModule,
    SettingsModule,
    PageHeaderComponent,
    AlertModalComponent,
    LoadingShadingComponent,
    ButtonComponent,
    FieldsModule,
    SelectUserModalComponent,
    UserSummeryComponent,
    RouterLink,
    TranslocoDirective,
    TextParserComponent,
    PermissionsFormComponent
  ],
  providers: [
    {
      provide: SelectUserModalService,
      useClass: SelectUsersModalServiceImpl
    },
    {
      provide: 'NewRoleNameValidator',
      useExisting: Validator,
      useFactory: EmployeesRolesModule.roleNameValidatorFactory,
    },
    {
      provide: 'NewRoleDescriptionValidator',
      useExisting: Validator,
      useFactory: EmployeesRolesModule.roleDescriptionValidatorFactory,
    },
  ]
})
export class RoleModule { }
