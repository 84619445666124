import { Component, Input } from "@angular/core";
import { SubscriptionEntity, TariffType } from "../../../domain/subscription-entity";
import { dateToDMonthYString } from "../../../../../../../core/utils/dateToDMonthYString";
import { DatePipe } from "@angular/common";
import { PaymentAndTariffConstants } from "../../../common/payment-and-tariff-constants";

@Component({
  selector: 'app-active-subscription',
  templateUrl: './active-subscription.component.html',
  styleUrls: [
    './active-subscription.component.scss',
    '../../../../../common/settings.scss'
  ]
})
export class ActiveSubscriptionComponent {
  @Input() subscription?: SubscriptionEntity;

  isOpen: boolean = false;

  constructor(private datePipe: DatePipe) {
  }

  getLocalDate(date: Date, shortMonth: boolean): string{
    return dateToDMonthYString(date, this.datePipe, shortMonth)
  }

  getPeriodString(first: Date, second: Date): string {
    let format = 'd MMM'
    let suffix = ''
    if(first.getFullYear() !== second.getFullYear()){
      format += ' YYYY'
    } else {
      suffix = ', ' + first.getFullYear()
    }
    let result = this.datePipe.transform(first, format, undefined, 'ru-RU') ?? ''
    result += ' - ' + this.datePipe.transform(second, format, undefined, 'ru-RU') ?? ''
    result += suffix
    return result
  }

  protected readonly PaymentAndTariffConstants = PaymentAndTariffConstants;
}
