import { Injectable } from '@angular/core';
import {
  AdditionalTariff, SubscriptionInfo, TariffInfo
} from "../../../../../../data/dto/get-update-invoice-data-dto";
import { DropdownItem } from "../../../../../../../../core/components/fields/components/dropdown-field/dropdown-field.component";
import { PurchasePeriod } from "../../../../../../data/dto/all-tariffs-dto";

@Injectable()
export class TariffFormState {
  readonly isLoading: boolean = false;

  readonly isExpansionOpen: boolean = true
  readonly isDurationOpen: boolean = true

  readonly usersCount: number = 0
  readonly memoryCount: number = 0

  readonly currentDuration: DropdownItem | null = null
  readonly durationDropDownItems: DropdownItem[] = []

  readonly subscriptionInfo: SubscriptionInfo | null = null
  readonly currentTariff: TariffInfo | null = null
  readonly purchasePeriods: PurchasePeriod[] = []
  readonly additionalTariff: AdditionalTariff | null = null
}
