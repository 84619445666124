import { AgilesDeskSettingsAccessUser } from "./agiles-desk-settings-access-state";

export type AgilesDeskSettingsAccessResultAction =
  | ChangeUsersModalVisibilityResultAction
  | ChangeUsersVisibilityResultAction
  | FilterUsersResultAction
  | DeleteUserResultAction
  | ChangeDeleteUserModalVisibilityResultAction

export enum AgilesDeskSettingsAccessResultActionTypes {
  CHANGE_ADD_USERS_MODAL_VISIBILITY,
  CHANGE_DELETE_USER_MODAL_VISIBILITY,
  CHANGE_USERS,
  FILTER_USERS,
  DELETE_USER,
}

export interface ChangeUsersVisibilityResultAction {
  readonly type: AgilesDeskSettingsAccessResultActionTypes.CHANGE_USERS
  readonly users: AgilesDeskSettingsAccessUser[]
}

export interface DeleteUserResultAction {
  readonly type: AgilesDeskSettingsAccessResultActionTypes.DELETE_USER
  readonly user: AgilesDeskSettingsAccessUser
}

export interface FilterUsersResultAction {
  readonly type: AgilesDeskSettingsAccessResultActionTypes.FILTER_USERS
  readonly filterString: string
  readonly filteredUsers: AgilesDeskSettingsAccessUser[]
}

export interface ChangeUsersModalVisibilityResultAction {
  readonly type: AgilesDeskSettingsAccessResultActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY
  readonly value: boolean
}

export interface ChangeDeleteUserModalVisibilityResultAction {
  readonly type: AgilesDeskSettingsAccessResultActionTypes.CHANGE_DELETE_USER_MODAL_VISIBILITY
  readonly value: boolean
}
