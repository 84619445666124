import { ToggleItem } from "../presentation/components/toggler/toggler.component";

export class PaymentAndTariffConstants{
  public static readonly TRANSLOCO_READ = 'paymentAndTariff'
  public static PAYMENT_TYPES: ToggleItem[] = [
    {
      id: 'card',
      text: 'payment-by-card'
    },
    {
      id: 'invoice',
      text: 'payment-by-invoice'
    },
  ]
}
