<div
  class="p-[8px] overflow-hidden min-w-[550px] max-w-[550px] min-h-[220px] pb-[32px] card-box-shadow border-[1px] border-tt-border-default bg-white rounded-[16px] relative"
>
  <app-core-loading-shading
    *ngIf="isLoading"
  />
  <div
    *ngIf="!isLoading"
  >
    <div class="pt-[8px] px-[8px] w-full h-[127px] absolute left-0 top-0">
      <app-str-to-colored-bg
        [str]="employee.email"
      />
    </div>
    <div class="relative z-10">
      <div class="pt-[8px] px-[8px] gap-[8px] flex justify-end min-h-[40px]">
        <div
          *ngIf="employee.canEdit"
        >
          <button class="w-[32px] h-[32px] bg-white rounded-[16px] flex justify-center items-center"
                  (click)="isPopupVisible = !isPopupVisible"
          >
            <svg-triple-dot class="text-tt-icon-default"/>
          </button>

          <app-core-action-menu
            *ngIf="isPopupVisible"
            [sections]="sections"
            (elementClicked)="onElementClick($event)"
            appPopup
            [parentOffset]="{x: 0, y: 8}"
            (popupClose)="isPopupVisible = false"
          />
        </div>

      </div>

      <div class="mt-[20px] px-[16px] flex gap-[16px]">
        <app-core-avatar
          *ngIf="!employee.icon"
          size="5xl"
          variant="primary"
          placeholder="{{avatarPlaceHolder}}"
          bgRGB="{{avatarBgRGB}}"
          [borderSize]="4"
        />
        <div class="text-tt-text-secondary text-[14px] leading-[17px] flex flex-col gap-[12px]">
          <div class="font-semibold text-[22px] leading-[27px] text-tt-text-main">
            <p *ngIf="nameType === 'SurnameNamePatronymic' && employee.patronymic != ''">{{employee.surname}}<br/>{{employee.name}} {{employee.patronymic}}</p>
            <p *ngIf="nameType === 'SurnameName' || employee.patronymic == ''"><br/>{{employee.surname}} {{employee.name}}</p>
            <p *ngIf="nameType === 'SurnameNP'"><br/>{{employee.surname}} {{employee.name.at(0)}}.
              <span *ngIf="employee.patronymic">{{employee.patronymic.length > 0 ? employee.patronymic.at(0) + '.' : ''}}</span></p>
            <p *ngIf="nameType === 'NameSurname'"><br/>{{employee.name}} {{employee.surname}}</p>
          </div>

          <p>{{employee.email}}</p>

          <p>{{phoneNumber}}</p>
        </div>

      </div>
    </div>
  </div>


</div>
