import { Executor, Reducer } from "src/app/core/mvi/store";
import { PaymentResultAction, PaymentResultActionTypes } from "./payment-result-action";
import { Inject, Injectable } from "@angular/core";
import { PaymentState } from "./payment-state";
import { PaymentAction, PaymentActionTypes } from "./payment-action";
import { Validator } from "../../../../../../../../core/validators/validator";
import { translate, TranslocoService } from "@jsverse/transloco";
import { PaymentAndTariffConstants } from "../../../../common/payment-and-tariff-constants";
import { PaymentConstants } from "../common/payment-constants";

@Injectable()
export class PaymentExecutor extends Executor<
  PaymentState,
  PaymentAction,
  PaymentResultAction
> {
  constructor(
    @Inject('InvoicingModalOrganisationNameValidator')
    private organisationNameValidator: Validator,
    @Inject('InvoicingModalLegalAddressValidator')
    private legalAddressValidator: Validator,
    @Inject('InvoicingModalKPPValidator')
    private KPPValidator: Validator,
    @Inject('InvoicingModalINNValidator')
    private INNValidator: Validator,
    @Inject('InvoicingModalSoloProprietorINNValidator')
    private soloProprietorINNValidator: Validator,
    @Inject('RegistrationPhoneNumberValidator')
    private phoneNumberValidator: Validator,
    @Inject('RegistrationEmailValidator')
    private emailValidator: Validator,
    private translocoService: TranslocoService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<PaymentState,PaymentResultAction>,
    getState: () => PaymentState,
    onReduced: (state: PaymentState) => void)
  {
    super.init(reducer, getState, onReduced);
    this.validateFields()
    this.translocoService.langChanges$.subscribe(() => this.localise())
  }

  execute(action: PaymentAction) {
    switch (action.type) {
      case PaymentActionTypes.CHANGE_RECEIPT_PRICE:
        this.reduce({
          type: PaymentResultActionTypes.CHANGE_RECEIPT_PRICE,
          value: action.value
        })
        break
      case PaymentActionTypes.CHANGE_INN:
        this.reduce({
          type: PaymentResultActionTypes.CHANGE_INN,
          value: {
            value: action.value,
            error: this.getState().isISoloProprietor ? this.soloProprietorINNValidator.validate(action.value) : this.INNValidator.validate(action.value)
          }
        })
        break;
      case PaymentActionTypes.CHANGE_KPP:
        this.reduce({
          type: PaymentResultActionTypes.CHANGE_KPP,
          value: {
            value: action.value,
            error: this.KPPValidator.validate(action.value)
          }
        })
        break;
      case PaymentActionTypes.CHANGE_EMAIL:
        this.reduce({
          type: PaymentResultActionTypes.CHANGE_EMAIL,
          value: {
            value: action.value,
            error: this.emailValidator.validate(action.value)
          }
        })
        break;
      case PaymentActionTypes.TOGGLE_PAYMENT_TYPE:
        switch(action.id){
          case 'card':
            this.reduce({
              type: PaymentResultActionTypes.TOGGLE_PAYMENT_TYPE,
              value: 'card'
            })
            break
          case 'invoice':
            this.reduce({
              type: PaymentResultActionTypes.TOGGLE_PAYMENT_TYPE,
              value: 'invoice'
            })
            break
        }
        break
      case PaymentActionTypes.CHANGE_LEGAL_ADDRESS:
        this.reduce({
          type: PaymentResultActionTypes.CHANGE_LEGAL_ADDRESS,
          value: {
            value: action.value,
            error: this.legalAddressValidator.validate(action.value)
          }
        })
        break;
      case PaymentActionTypes.CHANGE_ORGANISATION_NAME:
        this.reduce({
          type: PaymentResultActionTypes.CHANGE_ORGANISATION_NAME,
          value: {
            value: action.value,
            error: this.organisationNameValidator.validate(action.value)
          }
        })
        break;
      case PaymentActionTypes.CHANGE_PHONE_NUMBER:
        this.reduce({
          type: PaymentResultActionTypes.CHANGE_PHONE_NUMBER,
          value: {
            value: action.value,
            error: this.phoneNumberValidator.validate(action.value)
          }
        })
        break;
      case PaymentActionTypes.TOGGLE_I_SOLE_PROPRIETOR:
        this.reduce({
          type: PaymentResultActionTypes.TOGGLE_I_SOLE_PROPRIETOR,
          value: !this.getState().isISoloProprietor
        })
        break;
      case PaymentActionTypes.INIT_ON_CHANGES:
        this.reduce({
          type: PaymentResultActionTypes.INIT_ON_CHANGES,
          countUsers: action.countUsers,
          periodType: action.periodType,
        })
        break
      case PaymentActionTypes.OPEN_INVOICE_PAYMENT_MODAL:
        this.reduce({
          type: PaymentResultActionTypes.OPEN_INVOICE_PAYMENT_MODAL
        })
        break
    }
  }

  private localise(){
    this.reduce({
      type: PaymentResultActionTypes.LOCALISE,
      togglerItems: PaymentAndTariffConstants.PAYMENT_TYPES.map((item)=>{
        return {...item,
          text: translate(PaymentConstants.TRANSLOCO_READ + '.' + item.text)
        }
      })
    })
  }

  private validateFields(){
    const state = this.getState()
    this.reduce({
      type: PaymentResultActionTypes.VALIDATE_FIELDS,
      organisationName: { ...state.organisationName, error: this.organisationNameValidator.validate(state.organisationName.value) },
      legalAddress: { ...state.legalAddress, error: this.legalAddressValidator.validate(state.legalAddress.value) },
      INN: { ...state.INN, error: state.isISoloProprietor ? this.soloProprietorINNValidator.validate(state.INN.value) : this.INNValidator.validate(state.INN.value) },
      KPP: { ...state.KPP, error: this.KPPValidator.validate(state.KPP.value) },
      phoneNumber: { ...state.phoneNumber, error: this.phoneNumberValidator.validate(state.phoneNumber.value) },
    })
  }
}
