import { Component, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { HashMap, TranslocoDirective } from "@jsverse/transloco";
import { YooptaEditorConstants } from "../../../common/yoopta-editor-constants";

@Component({
  selector: 'app-localised-editor-text',
  templateUrl: './localised-editor-text.component.html',
  styleUrls: ['./localised-editor-text.component.scss']
})
export class LocalisedEditorTextComponent {
  @Input({required: true}) data!: LocaliseData
  protected readonly YooptaEditorConstants = YooptaEditorConstants;
}

export type LocaliseData = {
  key: string
  props: HashMap[]
}
