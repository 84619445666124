<button
  (click)="isUserCardOpened = !isUserCardOpened"
  class="flex items-center justify-center"
>
  <ng-content></ng-content>
</button>

<app-user-card
  *ngIf="isUserCardOpened"
  appPopup
  [parentOffset]="parentOffset"
  [popupOffsetXSide]="popupOffsetXSide"
  [popupOffsetYSide]="popupOffsetYSide"
  (popupClose)="isUserCardOpened = false"

  [userId]="userId"
  [nameType]="nameType"
  (onClose)="isUserCardOpened = false"
  (editClicked)="editClicked.emit()"
  (deleteClicked)="deleteClicked.emit()"
  (linkClicked)="linkClicked.emit()"
  (messageClicked)="messageClicked.emit()"
/>
