import { RegistrationStatus } from './registration-state';
import { DefaultCompanyInfoEntity } from '../../domain/model/default-company-info-entity';
import { DropdownItem } from "../../../../core/components/fields/components/dropdown-field/dropdown-field.component";

export type RegistrationResultAction =
  | ChangeEmailResultAction
  | ChangePhoneNumberResultAction
  | ChangeNameResultAction
  | ChangeStatusStateResultAction
  | ValidationResultAction
  | CreateResultAction
  | ChangeSurnameResultAction

export enum RegistrationResultActionTypes {
  CHANGE_NAME,
  CHANGE_SURNAME,
  CHANGE_EMAIL,
  CHANGE_PHONE_NUMBER,
  VALIDATION_ERROR,
  CHANGE_STATUS_STATE,
  CREATE
}

export interface ChangeSurnameResultAction {
  readonly type: RegistrationResultActionTypes.CHANGE_SURNAME;
  readonly surname: string;
  readonly error: string;
}

export interface ChangeNameResultAction {
  readonly type: RegistrationResultActionTypes.CHANGE_NAME;
  readonly name: string;
  readonly error: string;
}

export interface ChangeEmailResultAction {
  readonly type: RegistrationResultActionTypes.CHANGE_EMAIL;
  readonly email: string;
  readonly error: string;
}

export interface ChangePhoneNumberResultAction {
  readonly type: RegistrationResultActionTypes.CHANGE_PHONE_NUMBER;
  readonly phoneNumber: string;
  readonly error: string;
}

export interface ValidationResultAction {
  readonly type: RegistrationResultActionTypes.VALIDATION_ERROR;
  readonly surnameError: string;
  readonly nameError: string;
  readonly emailError: string;
  readonly phoneNumberError: string;
}

export interface CreateResultAction {
  readonly type: RegistrationResultActionTypes.CREATE;
}

export interface ChangeStatusStateResultAction {
  readonly type: RegistrationResultActionTypes.CHANGE_STATUS_STATE;
  readonly status: RegistrationStatus;
}
