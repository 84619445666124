import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { ToastsService } from "../../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { Validator } from "../../../../../../../../core/validators/validator";
import { AgilesListConstants } from "../../../common/agiles-list-constants";

@Component({
  selector: 'app-create-desk-modal',
  templateUrl: './create-desk-modal.component.html',
  styleUrls: ['./create-desk-modal.component.scss']
})
export class CreateDeskModalComponent {
  @Output() onCreate = new EventEmitter<string>();
  @Output() onClose = new EventEmitter();

  deskName: string = ''
  deskNameError: string | null = null
  isCreating: boolean = false;

  constructor(
    private toastsService: ToastsService,
    @Inject('AgilesDeskNameValidator')
    private deskNameValidator: Validator
  ){}

  spaceNameChange(str: string){
    this.deskNameError = this.deskNameValidator.validate(str)
    this.deskName = str
  }

  create(){
    this.deskNameError = this.deskNameValidator.validate(this.deskName)
    if(!this.deskNameError){
      this.onCreate.emit(this.deskName)
    }
  }

  protected readonly AgilesListConstants = AgilesListConstants;
}
