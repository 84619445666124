import { Component } from '@angular/core';
import { Store } from 'src/app/core/mvi/store';
import { PasswordRecoveryState } from '../state/password-recovery-state';
import { PasswordRecoveryExecutor } from '../state/password-recovery-executor';
import {
  PasswordRecoveryAction,
  PasswordRecoveryActionTypes,
} from '../state/password-recovery-action';
import { PasswordRecoveryResultAction } from '../state/password-recovery-result-action';
import { PasswordRecoveryReducer } from '../state/password-recovery-reducer.service';
import { AppPaths } from "../../../../app-routing.module";
import { PasswordRecoveryConstants } from "../../common/password-recovery-constants";
import { provideTranslocoScope } from "@jsverse/transloco";

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['../../../authorization/presentation/view/authorization.component.scss'],
  providers: [
    provideTranslocoScope({ scope: "auth/password-recovery", alias: PasswordRecoveryConstants.TRANSLOCO_READ }),
    PasswordRecoveryState,
    PasswordRecoveryExecutor,
    PasswordRecoveryReducer
  ]
})
export class PasswordRecoveryComponent extends Store<
  PasswordRecoveryState,
  PasswordRecoveryExecutor,
  PasswordRecoveryAction,
  PasswordRecoveryResultAction
> {
  constructor(
    state: PasswordRecoveryState,
    executor: PasswordRecoveryExecutor,
    reducer: PasswordRecoveryReducer,
  ) {
    super(state, executor, reducer);
  }

  protected readonly PasswordRecoveryActionTypes = PasswordRecoveryActionTypes;
  protected readonly AppPaths = AppPaths;
  protected readonly PasswordRecoveryConstants = PasswordRecoveryConstants;
}
