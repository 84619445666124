import { cloneElement, isValidElement } from 'react';
import { ACTION_MENU_ICONS_MAP } from '../icons/icons';
import { ActionMenuRenderProps } from "@yoopta/action-menu-list";
import { translate } from "@jsverse/transloco";
import { YooptaEditorConstants } from "../../../../common/yoopta-editor-constants";

const MyActionMenuRender = (
  {
    actions,
    editor,
    empty,
    getItemProps,
    getRootProps,
    view = 'default',
  }: ActionMenuRenderProps) => {
  const isViewSmall = view === 'small';

  const iconWrapStyles = {
    minWidth: isViewSmall ? '32px' : '40px',
    width: isViewSmall ? '32px' : '40px',
    height: isViewSmall ? '32px' : '40px',
  };

  const renderIcon = (Icon: any) => {
    if (!Icon) return null;
    if (typeof Icon === 'string') return <img src={Icon} alt="icon" />;
    if (isValidElement(Icon)) return cloneElement<any>(Icon);
    return <Icon/>;
  };

  return (
    <div
      className="yoopta-action-menu-list-content rounded-[8px] flex flex-col gap-[4px]"
      style={{
        maxWidth: "270px",
        boxShadow: '0px 1px 4px 0px #00000040',
      }}
    >
      <div className="yoo-action-menu-max-h-[300px] yoo-action-menu-overflow-y-auto yoo-action-menu-overflow-x-hidden">
        <div
          {...getRootProps()}
          className="yoo-action-menu-overflow-hidden yoo-action-menu-p-0 yoo-action-menu-text-foreground flex flex-col gap-[4px]"
        >
          {empty && (
            <div className="yoo-action-menu-text-left yoo-action-menu-text-muted-foreground yoo-action-menu-text-xs yoo-action-menu-px-1 yoo-action-menu-py-1">
              No actions available
            </div>
          )}
          {actions.map((action, i) => {
            const block = editor.blocks[action.type];

            if (!block) return null;

            const title = block.options?.["display"]?.title || block.type;
            const description = block.options?.["display"]?.description || '';
            const Icon = action.icon || ACTION_MENU_ICONS_MAP[action.type];

            return (
              <div
                key={i}
              >
                {action.type == 'Image' &&
                  <div className='w-full p-[4px]'>
                    <div className='w-full h-[1px] bg-tt-border-default'></div>
                  </div>
                }
                <button
                  type="button"
                  key={action.type}
                  {...getItemProps(action.type)}
                  className="
                  yoopta-button p-[4px] w-full cursor-pointer rounded-[8px]
                  flex items-center text-left gap-[10px]
                  hover:bg-tt-icon-button-background-hover aria-selected:bg-tt-icon-button-background-hover"
                >
                  <div
                    style={iconWrapStyles}
                    className="yoo-action-menu-flex yoo-action-menu-h-[40px] yoo-action-menu-w-[40px] yoo-action-menu-items-center yoo-action-menu-justify-center yoo-action-menu-rounded-md border border-tt-border-default yoo-action-menu-bg-[#FFFFFF]"
                  >
                    {renderIcon(Icon)}
                  </div>
                  <div>
                    <div className="text-tt-font-size_l text-tt-text-main">{translate(YooptaEditorConstants.PLUGINS_TRANSLOCO_READ + '.' + title)}</div>
                    {!isViewSmall && (
                      <div
                        className="text-tt-font-size_s text-tt-text-secondary truncate"
                        style={{
                          maxWidth: 190 + 'px'
                        }}
                      >
                        {translate(YooptaEditorConstants.PLUGINS_TRANSLOCO_READ + '.' + title + '-description')}
                      </div>
                    )}
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { MyActionMenuRender };
