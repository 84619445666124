<app-core-page-shading
  [appearanceType]="'animate'"
>
  <div
    class="w-full h-full flex justify-center items-center p-[44px]"
    cdkTrapFocus
  >
    <div class="
      p-[24px] rounded-[16px] max-w-[512px] w-full bg-tt-modal-background
      border border-tt-border-default animate-alert_appearance max-h-full
      relative flex flex-col overflow-hidden transition-[height] duration-1000"
      [ngClass]="className"
    >
      <app-core-loading-shading
        *ngIf="isLoading"
      />

      <div class="w-full shrink-0 flex justify-between items-center"
      >
        <p class="font-normal text-tt-font-size_3xl text-tt-informer-title">{{title}}</p>
        <button
          class="size-[40px] flex justify-center items-center text-tt-informer-close-icon rounded-[20px] hover:bg-tt-icon-button-background-hover outline-tt-informer-info-icon"
          (click)="onClose($event)"
          #closeButton
        >
          <svg-x-mark class="size-[24px]"/>
        </button>
      </div>

      <div class="shrink-0">
        <ng-content select="app-core-alert-modal-unscrollable-box"/>
      </div>

      <div
        class="mt-[8px] flex flex-col gap-[16px] flex-grow "
        [ngClass]="{
          'overflow-y-auto tiny-scrollbar': scrollable
        }"
      >
        <app-core-informer-text
          class="shrink-0"
          *ngIf="description != ''"
          [type]="type"
          [informerText]="description"
        />

        <div
          class="flex-grow text-tt-font-size_l pb-[24px] text-tt-informer-value"
        >
          <ng-content/>
        </div>
      </div>

      <div
        class="relative flex shrink-0 justify-end gap-[16px]"
        [ngClass]="{
          'pt-[16px]': withButtonSeparator
        }"
      >
        <div
          *ngIf="withButtonSeparator"
          class="absolute top-0 w-full h-[1px] bg-tt-border-default"
        ></div>

        <app-core-button
          *ngIf="cancelButtonText != ''"
          variant="soft"
          (onClick)="onCancelClicked.emit()"
        >
          {{cancelButtonText}}
        </app-core-button>

        <app-core-button
          *ngIf="confirmButtonText != ''"
          [type]="type == 'info' ? 'basic' : 'danger'"
          (onClick)="onConfirmClicked.emit()"
          [disabled]="confirmDisabled"
          [loading]="isConfirmLoading"
        >
          {{confirmButtonText}}
        </app-core-button>
      </div>

    </div>

  </div>
</app-core-page-shading>
