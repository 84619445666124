import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Params, QueryParamsHandling, RouterLink, RouterLinkActive } from "@angular/router";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { TranslocoDirective } from "@jsverse/transloco";
@Component({
  selector: "app-nav-tabs",
  templateUrl: "./nav-tabs.component.html",
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgClass,
    RouterLinkActive,
    NgForOf,
    TranslocoDirective
  ],
  styleUrls: ["./nav-tabs.component.scss"]
})
export class NavTabsComponent {
  @Input() tabs: NavTab[] = []
  @Input() selectedId: string = ''
  @Input() queryParam: Params = {}
  @Input() navType: NavTabType = 'click'
  @Input() borderBottom: boolean = true
  @Input() borderType: 'header' | 'base' = 'base'
  @Input() startFromLine: boolean = false
  @Input() queryParamsHandling?: QueryParamsHandling
  @Input() translocoRead: string = ''

  @Output() tabClicked = new EventEmitter<string>()
}
export type NavTabType = 'click' | 'link' | 'query'

export type NavTab = {
  id: string
  url: string,
  title: string,
  query?: Params
}
