import { Routes } from "@angular/router";
import { NewsListComponent } from "../features/news/modules/news-list/presentation/news-list.component";
import { NewsEditorComponent } from "../features/news/modules/news-editor/presentation/news-editor.component";

export enum NewsRoutesPath { LIST = '', EDIT = 'edit', CREATE = 'create' }

export const NEWS_ROUTES: Routes = [
  {
    path: ':newsId/' + NewsRoutesPath.EDIT,
    component: NewsEditorComponent,
  },
  {
    path: ':newsId/' + NewsRoutesPath.CREATE,
    component: NewsEditorComponent,
  },
  {
    path: NewsRoutesPath.LIST,
    component: NewsListComponent,
  },
];
