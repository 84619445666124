<ng-container
  *transloco="let t; read: PaymentAndTariffConstants.TRANSLOCO_READ"
>
  <p
    class="text-tt-text-main text-tt-font-size_l"
    *ngIf="!subscription"
  >
    {{ t('no-active-tariff') }}
  </p>

  <div
    *ngIf="subscription"
    class="dropdown-block"
  >
    <div class="flex gap-x-[16px] items-center">
      <div
        class="w-full"
      >
        <p class="subscription-part-title">
          {{ t('your-plan') }}
        </p>
        <p class="subscription-part-value">
          {{ subscription.tariff.name }}
        </p>
      </div>
      <div
        class="w-full"
      >
        <p class="subscription-part-title">
          {{ t('valid-until') }}
        </p>
        <p class="subscription-part-value">
          {{ getLocalDate(subscription.expirationDate, false)  }}
        </p>
      </div>
      <div
        class="w-full"
      >
        <p class="subscription-part-title">
          {{ t('billing-period') }}
        </p>
        <p class="subscription-part-value">
          {{ getPeriodString(subscription.billingPeriodStart, subscription.billingPeriodEnd) }}
        </p>
      </div>
      <div
        class="w-full"
      >
        <p class="subscription-part-title">
          {{ t('active-users') }}
        </p>
        <p class="subscription-part-value">
          {{ subscription.activeUsersCount }} / {{ subscription.activeUsersMax }}
        </p>
      </div>

      <button
        class="flex-grow-0 size-[36px] rounded-[8px] shrink-0 bg-[#F0F5FC] flex justify-center items-center"
        (click)="isOpen = !isOpen"
      >
        <svg-arrow
          class="size-[24px] block text-tt-text-secondary transition-transform"
          [class.rotate-90]="!isOpen"
          [class.rotate-[270deg]]="isOpen"
        />
      </button>
    </div>

    <div
      *ngIf="isOpen"
    >
      <p class="subscription-part-title">
        {{ t('disk-space') }}
      </p>
      <p class="subscription-part-value">
        {{ subscription.occupiedStorageSpace }} / {{ subscription.maxStorageSpace }} {{ t('gigabytes') }}
      </p>
    </div>
  </div>

</ng-container>
