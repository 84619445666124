import { Component } from '@angular/core';
import {
  SelectUserModalService
} from "../../../../../../../../core/services/select-user-modal-service/select-user-modal.service";
import { SelectUsersModalServiceImpl } from "./data/select-users-modal-service-impl";
import { AgilesDeskSettingsAccessState, AgilesDeskSettingsAccessUser } from "./state/agiles-desk-settings-access-state";
import { AgilesDeskSettingsAccessExecutor } from "./state/agiles-desk-settings-access-executor";
import { AgilesDeskSettingsAccessReducer } from "./state/agiles-desk-settings-access-reducer";
import { Store } from "../../../../../../../../core/mvi/store";
import { AgilesDeskSettingsAccessAction, AgilesDeskSettingsAccessActionTypes } from "./state/agiles-desk-settings-access-action";
import { AgilesDeskSettingsAccessResultAction } from "./state/agiles-desk-settings-access-result-action";
import { AgileDeskSettingsConstants } from "../../../common/agile-desk-settings-constants";

@Component({
  selector: 'app-agiles-desk-settings-access',
  templateUrl: './agiles-desk-settings-access.component.html',
  styleUrls: ['./agiles-desk-settings-access.component.scss'],
  providers: [
    {
      provide: SelectUserModalService,
      useClass: SelectUsersModalServiceImpl,
    },
    AgilesDeskSettingsAccessState,
    AgilesDeskSettingsAccessExecutor,
    AgilesDeskSettingsAccessReducer,
  ],
})
export class AgilesDeskSettingsAccessComponent
  extends Store<AgilesDeskSettingsAccessState,
    AgilesDeskSettingsAccessExecutor,
    AgilesDeskSettingsAccessAction,
    AgilesDeskSettingsAccessResultAction>
{
  constructor(
    state: AgilesDeskSettingsAccessState,
    executor: AgilesDeskSettingsAccessExecutor,
    reducer: AgilesDeskSettingsAccessReducer
  ) {
    super(state, executor, reducer);
  }

  protected readonly AgilesDeskSettingsAccessActionTypes = AgilesDeskSettingsAccessActionTypes

  mapUsersToIds(users: AgilesDeskSettingsAccessUser[]): number[]{
    return users.map(user => {
      return user.user.id
    })
  }

  protected readonly AgileDeskSettingsConstants = AgileDeskSettingsConstants;
}
