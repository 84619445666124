import { AgilesDeskSettingsNavItems } from "./agiles-desk-settings-state";

export type AgilesDeskSettingsResultAction =
  | ChangeNavItemResultAction

export enum AgilesDeskSettingsResultActionTypes {
  CHANGE_NAV_ITEM,
}

export interface ChangeNavItemResultAction {
  readonly type: AgilesDeskSettingsResultActionTypes.CHANGE_NAV_ITEM
  readonly navItem: AgilesDeskSettingsNavItems
}
