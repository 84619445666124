<ng-container
  *transloco="let t; read: AgileDeskSettingsConstants.TRANSLOCO_READ"
>
  <div
    class="drag-list"
    [ngClass]="{
    'pb-[1px]': executorList.length > 0
  }"
    cdkDropList
    [cdkDropListData]="executorList"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      *ngFor="let executor of executorList; let i = index"
      class="drag-box group"
      cdkDrag
      [cdkDragData]="executor"
    >
      <div *cdkDragPlaceholder class="drag-placeholder"></div>
      <div class="flex justify-between gap-[16px] items-center h-full">
        <div class="flex items-center">
          <svg-status-drag-handle
            cdkDragHandle
            class="cursor-move"
          />
          <p class="ml-[16px] text-tt-font-size_l text-tt-text-main line-clamp-1">
            {{executor.surname + ' ' + executor.name}}
          </p>
        </div>

        <app-core-button-icon
          class="invisible group-hover:visible"
          size="m"
          (onClick)="onXMarkClicked(executor)"
        >
          <svg-x-mark/>
        </app-core-button-icon>
      </div>
    </div>
  </div>

  <div
    class="w-full h-[48px] px-[8px] flex items-center"
  >
    <app-core-button-text
      (onClick)="selectUserModalOpen = true"
    >
      {{ t('add-executor') }}
    </app-core-button-text>
  </div>

  <app-core-switcher
    class="mt-[16px]"
    [value]="showUnassignedTasks"
    [label]="t('without-executor-row-show')"
    (onSwitch)="showUnassignedTasksChanged.emit(!showUnassignedTasks)"
  />

  <app-select-user-modal
    *ngIf="selectUserModalOpen"
    (onClose)="selectUserModalOpen = false"
    (onAddUsers)="addUsers($event)"
  />

  <app-core-alert-modal
    *ngIf="deleteModalOpen && executorToDelete"
    type="warning"
    [title]="t('delete-executor')"
    [confirmButtonText]="t('delete')"
    [cancelButtonText]="t('cancel')"
    [confirmDisabled]="!selectedAutocompleteItem"
    (onCancelClicked)="closeDeleteModal()"
    (onConfirmClicked)="deleteExecutor()"
  >
    <p
      [textParse]="t('delete-executor-description', { executorName: executorToDelete.surname + ' ' + executorToDelete.name })"
      [parseElements]="[{
        text: executorToDelete.surname + ' ' + executorToDelete.name,
        type: 'text',
        className: 'font-medium'
      }]">
    </p>

    <app-core-autocomplete-field
      class="mt-[16px] block"
      variant="standard"
      [label]="t('select-new-executor-label')"
      [placeholder]="t('select-new-executor-placeholder')"
      [items]="executorAutocompleteItems"
      [selectedItem]="selectedAutocompleteItem"
      (onSelect)="onSelectAutocomplete($event)"
    />

  </app-core-alert-modal>
</ng-container>
