import { AgilesListState } from "./agiles-list-state.service";
import { AgilesListAction, AgilesListActionTypes } from "./agiles-list-action";
import { AgilesListResultAction, AgilesListResultActionTypes } from "./agiles-list-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { parseToHighlightedParts } from "../../../../../../../core/utils/parse-to-highlighted-parts";

@Injectable()
export class AgilesListExecutor extends Executor<
  AgilesListState,
  AgilesListAction,
  AgilesListResultAction
> {

  constructor(

  ) {
    super();
  }

  override init(
    reducer: Reducer<AgilesListState, AgilesListResultAction>,
    getState: () => AgilesListState,
    onReduced: (state: AgilesListState) => void,
  ) {
    super.init(reducer, getState, onReduced);
  }

  execute(action: AgilesListAction) {
    switch (action.type) {
      case AgilesListActionTypes.CHANGE_IS_OPEN_CREATE_MODAL:
        this.reduce({
          type: AgilesListResultActionTypes.CHANGE_IS_OPEN_CREATE_MODAL,
          value: action.value
        })
        break;
      case AgilesListActionTypes.CREATE_DESK:
        const newList = this.getState().agileList.slice()
        newList.push({
          id: 'new_id',
          name: action.name,
          tag: action.name.toLocaleUpperCase(),
          nameParts: parseToHighlightedParts(action.name, ''),
          tagParts: parseToHighlightedParts(action.name.toLocaleUpperCase(), ''),
          admin: {
            id: 123,
            surname: 'admin',
            name: 'admin',
            patronymic: 'admin',
            email:  'admin@qwe.com',
          }
        })
        this.reduce({
          type: AgilesListResultActionTypes.CHANGE_DESK_LIST,
          agileList: newList
        })
        this.reduce({
          type: AgilesListResultActionTypes.CHANGE_IS_OPEN_CREATE_MODAL,
          value: false
        })
        break
    }
  }
}
