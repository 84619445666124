import { dateToDMonthYString } from "./dateToDMonthYString";
import { DatePipe } from "@angular/common";

export const dateToLastTimeString = (date: Date, datePipe: DatePipe, yearPostfix: string = ''): string => {
  const nowDay = new Date().setHours(0, 0, 0, 0);
  const dateDay = new Date(date.getTime()).setHours(0, 0, 0, 0)
  const daysBetweenNow = (nowDay - dateDay) / (1000 * 60 * 60 * 24);
  const userLocalTime = new Date(date.getTime());

  switch (daysBetweenNow){
    case 0:
      return `сегодня ${userLocalTime.getHours()}:${(userLocalTime.getMinutes().toString().length == 1 ? "0" : '') + userLocalTime.getMinutes()}`;
    case 1:
      return `вчера ${userLocalTime.getHours()}:${(userLocalTime.getMinutes().toString().length == 1 ? "0" : '') + userLocalTime.getMinutes()}`
    default:
      return dateToDMonthYString(date, datePipe, undefined, yearPostfix)
  }
};
