import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from "@angular/core";
import { TaskEntity, TaskExecutor } from "../task-item/task-item.component";
import { SelectedTaskEntity } from "../tasks-table/tasks-table.component";
import { AgileDeskConstants } from "../../../common/agile-desk-constants";

@Component({
  selector: "[agiles-task-list]",
  templateUrl: "./task-list.component.html",
  styleUrls: [
    "./task-list.component.scss",
    "../../common/agiles-desk.component.scss"
  ]
})
export class TaskListComponent implements OnChanges, AfterViewInit {
  @Input() tasks: TaskEntity[] = [];
  @Input() selectedTasks: SelectedTaskEntity[] = [];
  @Input() executor: TaskExecutor | null = null;
  @Input() showAddButton: boolean = false;
  @Input() isCompleted: boolean = false;
  @Input() draggingTask: TaskEntity | null = null;
  @Input() isVisible: boolean = false;
  @Input() isDragCancelled: boolean = false

  @Output() onMultipleSelectTask = new EventEmitter<TaskEntity>();
  @Output() onSingleSelectTask = new EventEmitter<TaskEntity>();
  @Output() onAdd = new EventEmitter<TaskEntity[]>();
  @Output() onDragStart = new EventEmitter<TaskEntity>();

  previewWidth: number = 0;
  isDraggingSelect: boolean = false
  dragFreePosition = {x: 0, y: 0}

  private resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(entry => {
      this.previewWidth = entry.contentRect.width - 16//padding-x=8px
    })
  })

  constructor(
    private el: ElementRef,
  ) {
  }

  ngAfterViewInit() {
    this.resizeObserver.observe(this.el.nativeElement)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedTasks']){
      this.setTaskSelectType()
      this.isDraggingSelectCheck()
    }
    if(changes['draggingTask']){
      this.isDraggingSelectCheck()
    }
  }

  dragStart(event: any) {
    this.onDragStart.emit(event.source.data)
  }

  private isDraggingSelectCheck(){
    if(this.draggingTask != null){
      const findTask = this.selectedTasks
        .find(selected => selected.task.number === this.draggingTask!.number)
      this.isDraggingSelect = !!findTask
    } else {
      this.isDraggingSelect = false
    }
  }

  private setTaskSelectType() {
    this.tasks.forEach((task) => {
      task.selectType = this.selectedTasks.find(selected => selected.task == task) ?
        this.selectedTasks.length > 1 ? 'multiple' : 'single' : 'unselect';
    })
  }

  protected readonly AgileDeskConstants = AgileDeskConstants;
}

export interface Point {
  x: number,
  y: number,
}
