<button class="pt-[4px]"
  (click)="openPopup()"
>
  <p class="text-tt-primary text-[15px] leading-[18px]">Добавить роль</p>
</button>

<div
  class="max-w-[370px] py-[12px] w-full flex bg-white justify-center rounded-lg border-[1px] border-tt-border-default roles-popup-shadow"
  *ngIf="popupVisible && !isRolesLoaded"
  appPopup
  popupOffsetYSide="top"
  popupOffsetXSide="left"
  [parentOffset]="{x: 0, y: 6}"
  (popupClose)="closePopup()"
>
  <mat-spinner class="size-[32px]"/>
</div>

<div
  class="
    max-w-[370px] pb-[8px] w-full bg-white rounded-lg border-[1px] border-tt-border-default roles-popup-shadow
    flex flex-col"
  *ngIf="popupVisible && isRolesLoaded"
  appPopup
  popupOffsetYSide="top"
  popupOffsetXSide="left"
  overflowSide="base"
  [parentOffset]="{x: 0, y: 6}"
  [isScrollClose]="false"
  (popupClose)="closePopup()"
>
  <div class="rounded-t-lg w-full px-[16px] py-[8px] bg-tt-light-blue">
    <app-core-search-field
      class="w-full"
      placeholder="Поиск по названию роли"
      (onChange)="filterRoles($event)"
    />
  </div>

  <div
    class="w-full max-h-[380px] flex-grow overflow-y-scroll tiny-scrollbar"
    #rolesList
  >
    <div
      *ngIf="filteredRoles.length == 0"
      class="w-full h-full flex justify-center items-center"
    >
      <app-core-image-stub
        [title]="'Не нашли совпадений'"
        [description]="'Попробуйте изменить параметры поиска'"
      />
    </div>
    <button
      class="w-full h-[38px] pl-[20px] pr-[12px] flex items-center justify-between gap-[20px]"
      *ngFor="let role of filteredRoles"
      (click)="roleClick(role)"
    >
      <p
        class="truncate flex-grow text-left text-tt-font-size_l"
        [ngClass]="{
          'text-tt-text-main': !activeRoleIds.includes(role.id),
          'text-tt-menu-navigation-item-icon-border': activeRoleIds.includes(role.id)
        }"
        app-core-with-highlighting
        [text]="role.nameParts"
      ></p>

      <div
        class="min-w-[20px] min-h-[20px]"
        *ngIf="role.description != ''"
        [matTooltip]="role.description"
        [matTooltipPosition]="'above'"
      >
        <svg-question-mark-circle class="min-h-[20px] min-w-[20px] text-tt-icon-secondary"/>
      </div>

    </button>
  </div>

</div>
