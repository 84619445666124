import { Injectable } from "@angular/core";
import { RoleMainEntity } from "../components/role-main/role-main.component";
import { PermissionsDto } from "../../../../../../core/services/role-service/dto/create-role-dto";

@Injectable()
export class RoleState {
  readonly currentNavItem: RoleNavItems = RoleNavItems.MAIN
  readonly id: string = ''
  readonly isCustom: boolean = false
  readonly isDeleteAlertVisible: boolean = false
  readonly isLoading: boolean = false
  readonly isPageLoading: boolean = false
  readonly isDeleting: boolean = false
  readonly canEdit: boolean = false
  readonly canDelete: boolean = false

  readonly mainData: RoleMainEntity ={
    name: 'Роль',
    description: 'Описание',
  }
  readonly permissions: PermissionsDto = {
    knowledgeBase: {
      viewKnowledgeBase: true,
      createKnowledgeBase: false,
      editKnowledgeBase: false,
      deleteKnowledgeBase: false
    },
    company: {
      viewEmployee: true,
      createEmployee: false,
      editEmployee: false,
      deleteEmployee: false
    },
    settings: {
      billing: false,
      main: false,
    },
    role: {
      viewRole: true,
      createRole: false,
      editRole: false,
      deleteRole: false
    }
  }
}

export enum RoleNavItems {MAIN = 'main', PERMISSIONS = 'permissions', USERS = 'users'}
