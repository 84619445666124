import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './presentation/settings.component';
import { SettingsMainModule } from './modules/main/settings-main.module';
import { RouterOutlet } from '@angular/router';
import { Validator } from "../../core/validators/validator";
import { EmptyRule, MaxLengthRule } from "../../core/validators/rule";
import { PaymentAndTariffModule } from "./modules/payment-and-tariff/payment-and-tariff.module";
import { OrdersModule } from "./modules/orders/orders.module";
import { SettingsNavigator } from "./navigator/settings-navigator";
import { PageHeaderComponent } from "../../core/components/page-header/page-header.component";
import { TranslocoDirective } from "@jsverse/transloco";
import { SettingsConstants } from "./common/settings-constants";

@NgModule({
  declarations: [
    SettingsComponent
  ],
  imports: [
    CommonModule,
    SettingsMainModule,
    OrdersModule,
    PaymentAndTariffModule,
    RouterOutlet,
    PageHeaderComponent,
    TranslocoDirective
  ],
  providers: [
    SettingsNavigator,
    {
      provide: "CompanyTitleValidator",
      useExisting: Validator,
      useFactory: SettingsModule.companyTitleValidatorFactory
    },
  ]
})
export class SettingsModule {
  public static companyTitleValidatorFactory = () =>
    new Validator([
      new EmptyRule('company-name-error-empty'),
      new MaxLengthRule('company-name-error-max-length', SettingsConstants.COMPANY_NAME_MAX_LENGTH),
    ]);
}
