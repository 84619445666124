<div
  class="h-[100dvh] min-w-[100dvw] relative flex items-center flex-col px-2 overflow-y-auto tiny-scrollbar"
  [ngClass]="{
    'justify-center': topOffset === 'center',
    'bg-tt-bg-page': bgType === 'page',
    'bg-white': bgType === 'white',
  }"
>
  <div
    *ngIf="isHaveBackButton"
    class="block absolute left-[24px] top-[24px]"
  >
    <div>
      <ng-container *ngIf="backButtonType === 'login'; else button">
        <a
          class="flex gap-[8px] items-center h-[44px] px-[16px] text-tt-text-secondary hover:text-tt-text-main"
          [routerLink]="'/' + AppPaths.LOGIN"
        >
          <svg-back-arrow
            class="size-[20px] block"
          />
          <p
            class="text-tt-font-size_l"
          >
            {{ backButtonText }}
          </p>
        </a>
      </ng-container>

      <ng-template #button>
        <button
          class="flex gap-[8px] items-center h-[44px] px-[16px] text-tt-text-secondary hover:text-tt-text-main"
          (click)="onBackClicked()"
        >
          <svg-back-arrow
            class="size-[20px] block"
          />
          <p
            class="text-tt-font-size_l"
          >
            {{ backButtonText }}
          </p>
        </button>
      </ng-template>
    </div>
  </div>

  <app-core-loading-shading
    *ngIf="isLoading"
  />
  <ng-content />
</div>
