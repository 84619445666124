import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionMenu, ActionSection } from "../action-menu/action-menu.component";
import {
  PaymentAndTariffModule
} from "../../../features/settings/modules/payment-and-tariff/payment-and-tariff.module";
import { SvgTripleDot } from "../svg-components/svg.components";
import { PopupDirective } from "../../directives/popup.directive";
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: "app-core-triple-dot-action-menu",
  templateUrl: "./triple-dot-action-menu.component.html",
  styleUrls: ["./triple-dot-action-menu.component.scss"],
  imports: [
    ActionMenu,
    PaymentAndTariffModule,
    SvgTripleDot,
    PopupDirective,
    NgIf,
    NgClass
  ],
  standalone: true
})
export class TripleDotActionMenuComponent {
  @Input() sections: ActionSection[] = []
  @Input() translocoRead: string = ''
  @Output() onElementClick = new EventEmitter<string>();

  isPopupVisible: boolean = false;

  elementClicked(id: string){
    this.onElementClick.emit(id)
    this.isPopupVisible = false
  }
}
