import { AfterViewInit, Component, HostListener } from "@angular/core";
import { EmployeesListAction, EmployeesListActionTypes } from "./state/employee-list-action";
import { EmployeesListExecutor } from "./state/employees-list-executor";
import { EmployeesListReducer } from "./state/employees-list-reducer";
import { Store } from 'src/app/core/mvi/store';
import { EmployeesListResultAction } from './state/employees-list-result-action';
import { EmployeesListState } from './state/employees-list-state';
import { EmployeeListConstants } from "../common/employees-list-constants";
import { provideTranslocoScope } from "@jsverse/transloco";

@Component({
  selector: 'app-employees-list',
  templateUrl: './employees-list.component.html',
  styleUrls: [
    '../../../../main/presentation/main.component.scss',
    '../../../common/employees.scss'
  ],
  providers:[
    EmployeesListState,
    EmployeesListExecutor,
    EmployeesListReducer,
    provideTranslocoScope({ scope: "employees/employees-list", alias: EmployeeListConstants.TRANSLOCO_READ, }),
  ],
})

export class EmployeesListComponent extends Store<
  EmployeesListState,
  EmployeesListExecutor,
  EmployeesListAction,
  EmployeesListResultAction
> implements AfterViewInit
{
  windowWidth: number = 0

  constructor(
    state: EmployeesListState,
    executor: EmployeesListExecutor,
    reducer: EmployeesListReducer,
  ) {
    super(state, executor, reducer);
    this.performAction({
      type: EmployeesListActionTypes.UPDATE_EMPLOYEES_LIST
    })
  }

  ngAfterViewInit(): void {
    this.windowWidth = window.innerWidth
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any){
    this.windowWidth = window.innerWidth
  }

  protected readonly EmployeesActionTypes = EmployeesListActionTypes;
  protected readonly EmployeesListActionTypes = EmployeesListActionTypes;
  protected readonly window = window;
  protected readonly EmployeeListConstants = EmployeeListConstants;
}

