import { Injectable, QueryList } from "@angular/core";
import { NewsEditorInfoDto } from "../../../../data/dto/news-editor-info-dto";
import { ListType } from "../../../news-list/domain/list-type";
import { NewsSummaryEntity } from "../../../news-list/domain/news-summary-entity";
import { YooptaEditorComponent } from "../../../../../../core/components/yoopta-editor/presentation/yoopta-editor.component";
import { InputFieldComponent } from "../../../../../../core/components/fields/components/input-field/input-field.component";
import {
  TextareaFieldComponent
} from "../../../../../../core/components/fields/components/textarea-field/textarea-field.component";

@Injectable()
export class NewsEditorState {
  readonly newsId: string = ''
  readonly sessionId: string = ''
  readonly pingIntervalId: number = -1
  readonly editorComponent?: YooptaEditorComponent
  readonly inputs?: QueryList<InputFieldComponent | TextareaFieldComponent>
  readonly isLoading: boolean = false

  readonly editorType: EditorType = 'create';
  readonly loadedNews: NewsEditorInfoDto = {
    id: '',
    title: '',
    description: '',
    longreadID: '',
  }

  readonly currentNews: NewsSummaryEntity = {
    id: '',
    title: '',
    description: '',
    longreadId: '',
    dateStr: ''
  }

  readonly afkModalVisibility: boolean = false
  readonly isLoadingAfkModal: boolean = false
  readonly alreadyEditModalVisibility: boolean = false

  readonly isPageLoading: boolean = false
  readonly isEditorLoading: boolean = false

  readonly titleError: string | null = null
  readonly descriptionError: string | null = null

  readonly isPublishSideBarOpen: boolean = false;
  readonly previewType: ListType = 'grid-cell';
}

export type EditorType = 'create' | 'edit'
