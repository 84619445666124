<section
  class="max-w-[400px] w-full text-center"
>
  <div class="flex justify-center">
    <ng-content select="app-core-auth-container-icon"/>
  </div>

  <h2
    class="mt-[16px] mb-[8px] text-tt-font-size_5xl text-tt-text-main"
  >{{ title }}</h2>

  <div
    class="text-tt-font-size_l text-tt-text-secondary"
  >
    <ng-content/>
  </div>

  <a
    *ngIf="withBackButton"
    class="mt-[24px] block"
    [routerLink]="'/' + AppPaths.LOGIN"
  >
    <app-core-button
      className="w-full "
      size="l"
    >
      {{backButtonText}}
    </app-core-button>
  </a>
</section>

<ng-content
  select="app-core-auth-container-footer"
/>
