import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import {
  TasksStatusEntity,
} from "../../../../../../agiles-desk-main/presentation/components/tasks-table/tasks-table.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  ActionElement,
  ActionElementStyle,
  ActionSection
} from "../../../../../../../../../../core/components/action-menu/action-menu.component";
import {
  AutocompleteItem
} from "../../../../../../../../../../core/components/fields/components/autocomplete-field/autocomplete-field.component";
import { Validator } from "../../../../../../../../../../core/validators/validator";
import { AgileDeskSettingsConstants } from "../../../../../common/agile-desk-settings-constants";


const deleteActionMenuElement: ActionElement =
  {
    id: 'delete',
    name: 'delete',
    type: ActionElementStyle.DANGER
  }

const actionMenu: ActionSection[] = [
  {
    elements: [
      {
        id: "edit",
        name: "edit"
      },
    ]
  }
]

@Component({
  selector: 'app-status-list',
  templateUrl: './status-list.component.html',
  styleUrls: [
    './status-list.component.scss',
    '../../agiles-desk-settings-statuses-and-executors.component.scss'
  ]
})
export class StatusListComponent {
  @Input() statusList: TasksStatusEntity[] = []
  @Output() onChangeList = new EventEmitter<TasksStatusEntity[]>()

  isOpenAddModal: boolean = false
  isCompleted: boolean = false
  statusTitle: string = ''
  statusTitleError: string | null = this.statusTitleValidator.validate(this.statusTitle)

  isOpenEditModal: boolean = false
  statusToEdit: TasksStatusEntity | null = null

  isOpenDeleteModal: boolean = false
  statusToDelete: TasksStatusEntity | null = null
  statusAutocompleteItems: AutocompleteItem[] = []
  selectedAutocompleteItem: AutocompleteItem | null = null

  constructor(
    @Inject('TaskStatusTitleValidator')
    private statusTitleValidator: Validator) {
  }

  drop(event: CdkDragDrop<TasksStatusEntity[]>) {
    moveItemInArray(this.statusList, event.previousIndex, event.currentIndex);
    this.onChangeList.emit(this.statusList)
  }

  changeStatusTitle(value: string) {
    if(this.statusToEdit)
    {
      this.statusToEdit.name = value
    }
    else {
      this.statusTitle = value
    }
    this.statusTitleError = this.statusTitleValidator.validate(value)
  }

  addStatus(){
    this.statusTitleError = this.statusTitleValidator.validate(this.statusTitle)
    if(!this.statusTitleError)
    {
      this.statusList.push({
        id: new Date().getTime().toString(),
        name: this.statusTitle,
        isCompleted: this.isCompleted,
        isDeletable: true,
        isOpen: true
      })
      this.onChangeList.emit(this.statusList)
      this.closeModal()
    }
  }

  changeStatus(){
    const editStatus = this.statusToEdit
    if(editStatus)
    {
      this.statusTitleError = this.statusTitleValidator.validate(editStatus.name)
      if(!this.statusTitleError)
      {
        this.statusList = this.statusList.map(status => {
          if(status.id == editStatus.id){
            return editStatus
          }
          return status
        })
        this.onChangeList.emit(this.statusList)
        this.isOpenEditModal = false
      }
    }
  }

  closeModal(): void {
    this.isOpenAddModal = false
    this.isOpenEditModal = false
    this.isCompleted = false
    this.statusTitle = ''
    this.statusTitleError = this.statusTitleValidator.validate(this.statusTitle)
    this.statusToEdit = null
    this.statusToDelete = null
    this.isOpenDeleteModal = false
    this.selectedAutocompleteItem = null
  }

  deleteStatus(status: TasksStatusEntity){
    this.isOpenDeleteModal = true
    this.statusToDelete = status
    this.selectedAutocompleteItem = null
    this.statusAutocompleteItems = this.statusList
      .filter(item => item.id !== status.id)
      .map(item => {
        return {
          id: item.id,
          name: item.name,
        }
      })
  }

  onDelete(){
    if(this.statusToDelete != null && this.selectedAutocompleteItem != null)
    {
      this.statusList.splice(this.statusList.findIndex(item => item.id === this.statusToDelete!.id), 1)
      this.onChangeList.emit(this.statusList)
      this.closeModal()
    }
  }

  onActionMenuClicked(id: string, status: TasksStatusEntity){
    switch (id){
      case "edit":
        this.isOpenEditModal = true
        this.statusToEdit = { ...status }
        this.statusTitleError = this.statusTitleValidator.validate(this.statusToEdit.name)
        break
      case "delete":
        this.deleteStatus(status)
        break
    }
  }

  getActionMenu(canDelete: boolean): ActionSection[]{
    const result: ActionSection[] = [{
      elements: actionMenu[0].elements.slice()
    }];

    if(canDelete){
      result[0].elements.push(deleteActionMenuElement)
    }
    return result
  }

  onSelectAutocomplete(id: string){
    const find = this.statusAutocompleteItems.find(item => item.id === id)
    this.selectedAutocompleteItem = find ? find : null
  }

  protected readonly AgileDeskSettingsConstants = AgileDeskSettingsConstants;
}
