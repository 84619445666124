<tr
  *ngIf="row.executor || showUnassigned"
  class="align-top"
>
  <td
    *ngFor="let taskCell of row.tasksCells; let i = index"
    [ngClass]="{
      'not-assigned-row-bg': row.executor === null,
      'pt-[40]': i !== 0
    }"
    [class]="'min-h-[40px] headerDropBox w-full max-w-[calc(100%_/_' + row.tasksCells.length +')]'"
    cdkDropList
    [cdkDropListEnterPredicate]="dropPredicate"
    [cdkDropListData]="parseToDropListData(taskCell)"
    (cdkDropListDropped)="onDrop.emit({column: taskCell, event:$event})"
  >
    <div
      class="relative h-[38px]"
      *transloco="let t; read: AgileDeskConstants.TRANSLOCO_READ"
    >
      <div
        *ngIf="i === 0"
        class="flex justify-between items-center h-[38px] pr-[8px]"
        [ngClass]="{
          'not-assigned-row-bg': row.executor === null
        }"
        #rowHeader
      >
        <button
          class="flex items-center"
          (click)="row.isOpen = !row.isOpen"
        >
          <div
            class="arrow-button"
          >
            <svg-arrow
              class="arrow"
              [class.rotate-90]="row.isOpen"
            />
          </div>
          <span class="text-tt-font-size_l font-semibold text-tt-text-default">
            {{row.executor ? row.executor.surname + ' ' + row.executor.name : t(AgilesConstants.CARDS_WITHOUT_EXECUTOR) }}
          </span>
        </button>

        <p class="text-tt-font-size_s text-tt-text-secondary">
          {{ t('cards', { count: row.countTasks, countType: ruNumWord(row.countTasks) })}}
        </p>
      </div>
    </div>
  </td>
</tr>

<tr
  class="align-top"
  *ngIf="row.isOpen"
>
  <td
    *ngFor="let taskCell of row.tasksCells; let colIndex = index;"
    class="table-fixed drag-list group pb-[32px]"
    [ngClass]="{
      'border-r border-tt-border-default': colIndex != row.tasksCells.length - 1,
      'bg-tt-nav-active': taskCell.isDragOver,
    }"
    #taskCellsEl

    cdkDropList
    [cdkDropListData]="parseToDropListData(taskCell)"
    (cdkDropListDropped)="onDrop.emit({column: taskCell, event:$event})"
    (cdkDropListEntered)="taskCell.isDragOver = true"
    (cdkDropListExited)="taskCell.isDragOver = false"

    agiles-task-list
    [tasks]="taskCell.tasks"
    [draggingTask]="draggingTask"
    [showAddButton]="!taskCell.isDragOver"
    [isCompleted]="taskCell.isCompleted"
    [selectedTasks]="selectedTaskEntities"
    [executor]="row.executor"
    [isDragCancelled]="isDragCancelled"
    [isVisible]="isDragOverHeader || row.isOpen && (!!row.executor || showUnassigned)"
    (onAdd)="onAdd.emit($event)"
    (onDragStart)="onDragStart.emit({taskEntity: $event, taskCell: taskCell})"
    (onSingleSelectTask)="onSelectTask.emit({task: $event, column: taskCell.tasks, singleSelect: true})"
    (onMultipleSelectTask)="onSelectTask.emit({task: $event, column: taskCell.tasks, singleSelect: false})"
  >
  </td>
</tr>
