import { YooptaEditorComponent } from "../../../../../core/components/yoopta-editor/presentation/yoopta-editor.component";

export type DocumentResultAction =
  | ChangeTitleResultAction
  | InitDocumentResultAction
  | InitChildrenResultAction
  | ChangeGoToSpaceModalVisibilityResultAction
  | ChangeIsLoadingResultAction
  | ChangeBackSpaceModalVisibilityResultAction
  | ChangeAfkModalVisibilityResultAction
  | SaveSessionResultAction
  | ChangeAlreadyEditModalVisibilityResultAction

export enum DocumentResultActionTypes {
  CHANGE_TITLE,
  INIT_DOCUMENT,
  INIT_CHILDREN,
  CHANGE_SAVE_SPACE_MODAL_VISIBILITY,
  CHANGE_IS_LOADING,
  CHANGE_BACK_SPACE_MODAL_VISIBILITY,
  CHANGE_AFK_MODAL_VISIBILITY,
  SAVE_SESSION,
  CHANGE_ALREADY_EDIT_MODAL_VISIBILITY,
}

export interface ChangeAlreadyEditModalVisibilityResultAction {
  readonly type: DocumentResultActionTypes.CHANGE_ALREADY_EDIT_MODAL_VISIBILITY;
  readonly visibility: boolean
  readonly knowledgeBaseId: string
  readonly documentId: string
}

export interface SaveSessionResultAction {
  readonly type: DocumentResultActionTypes.SAVE_SESSION;
  readonly sessionId: string
  readonly pingIntervalId: number
}

export interface ChangeAfkModalVisibilityResultAction {
  readonly type: DocumentResultActionTypes.CHANGE_AFK_MODAL_VISIBILITY;
  readonly visibility: boolean
}

export interface ChangeBackSpaceModalVisibilityResultAction {
  readonly type: DocumentResultActionTypes.CHANGE_BACK_SPACE_MODAL_VISIBILITY;
  readonly visibility: boolean
  readonly isLoading?: boolean
}

export interface ChangeIsLoadingResultAction{
  readonly type: DocumentResultActionTypes.CHANGE_IS_LOADING;
  readonly value: boolean
}

export interface InitChildrenResultAction{
  readonly type: DocumentResultActionTypes.INIT_CHILDREN;
  readonly editor?: YooptaEditorComponent
}

export interface InitDocumentResultAction {
  readonly type: DocumentResultActionTypes.INIT_DOCUMENT;
  readonly id: string
  readonly name: string
  readonly longreadId: string
  readonly knowledgeBaseID: string
}

export interface ChangeTitleResultAction {
  readonly type: DocumentResultActionTypes.CHANGE_TITLE;
  readonly title: string
}

export interface ChangeGoToSpaceModalVisibilityResultAction {
  readonly type: DocumentResultActionTypes.CHANGE_SAVE_SPACE_MODAL_VISIBILITY;
  readonly visibilityValue: boolean
  readonly isLoadingSpaceModal: boolean
}
