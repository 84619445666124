import { Injectable } from "@angular/core";
import { OrderEntity } from "../../domain/order-entity";
import { InvoiceDto } from "../../../../data/dto/invoices-list-dto";

@Injectable()
export class OrdersState {
  readonly isLoading: boolean = false;
  readonly isLoadingError: boolean = false;
  readonly cancelModalIsLoading: boolean = false;

  readonly activeOrderDueDate: string = '';
  readonly activeOrder?: OrderEntity
  readonly storyOrders: OrderEntity[] = []
  readonly activeOrderDto?: InvoiceDto
  readonly storyOrdersDto: InvoiceDto[] = []

  readonly cancelModalVisibility: boolean = false;
}
