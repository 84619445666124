import {
  ResetPasswordStatus,
} from './password-recovery-state';

export type PasswordRecoveryResultAction =
  | ChangeEmailResultAction
  | ValidationResultAction
  | ChangeLoadingStateAction
  | ChangeStatusStateAction

export enum PasswordRecoveryResultActionTypes {
  CHANGE_EMAIL,
  VALIDATION_ERROR,
  CHANGE_LOADING_STATE,
  CHANGE_STATUS_STATE,
}

export interface ChangeEmailResultAction {
  readonly type: PasswordRecoveryResultActionTypes.CHANGE_EMAIL;
  readonly email: string;
  readonly emailError: string;
}

export interface ValidationResultAction {
  readonly type: PasswordRecoveryResultActionTypes.VALIDATION_ERROR;
  readonly emailError: string;
}

export interface ChangeLoadingStateAction {
  readonly type: PasswordRecoveryResultActionTypes.CHANGE_LOADING_STATE;
}

export interface ChangeStatusStateAction {
  readonly type: PasswordRecoveryResultActionTypes.CHANGE_STATUS_STATE;
  readonly status: ResetPasswordStatus;
}
