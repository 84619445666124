import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentAndTariffComponent } from './presentation/payment-and-tariff.component';
import { ActiveSubscriptionComponent } from './presentation/components/active-subscription/active-subscription.component';
import {
  SvgArrow,
  SvgTeamTellsLogo,
  SvgXMark
} from "../../../../core/components/svg-components/svg.components";
import { TariffListComponent } from "./presentation/components/tariff-list/tariff-list.component";
import { TogglerComponent } from "./presentation/components/toggler/toggler.component";
import { RouterLink } from "@angular/router";
import { SubscriptionFormComponent } from './presentation/components/subscription-form/subscription-form.component';
import { ReceiptComponent } from './presentation/components/receipt/receipt.component';
import { PaymentComponent } from './presentation/components/payment/payment.component';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TariffFormComponent } from './presentation/components/tariff-form/tariff-form.component';
import { SubscriptionService } from "../../data/subscription.service";
import { FormModalComponent } from './presentation/components/form-modal/form-modal.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { A11yModule } from "@angular/cdk/a11y";
import { Validator } from "../../../../core/validators/validator";
import { EmptyRule, MaxLengthRule, MinLengthRule } from "../../../../core/validators/rule";
import { ImageStubComponent } from "../../../../core/components/image-stub/image-stub.component";
import { ButtonComponent } from "../../../../core/components/buttons/button/button.component";
import { SwitcherComponent } from "../../../../core/components/switcher/switcher.component";
import { ButtonIconComponent } from "../../../../core/components/buttons/button-icon/button-icon.component";
import { FieldsModule } from "../../../../core/components/fields/fields.module";
import { SvgFeatureCheckmark } from "./presentation/icons/payment-and-tariff-icons";
import { provideTranslocoScope, TranslocoDirective } from "@jsverse/transloco";
import { PaymentAndTariffConstants } from "./common/payment-and-tariff-constants";
import { TextParserComponent } from "../../../../core/components/text-parser/text-parser.component";

@NgModule({
  declarations: [
    PaymentAndTariffComponent,
    ActiveSubscriptionComponent,
    TariffListComponent,
    TogglerComponent,
    SubscriptionFormComponent,
    ReceiptComponent,
    PaymentComponent,
    TariffFormComponent,
    FormModalComponent,
    ButtonIconComponent,
    SvgFeatureCheckmark
  ],
  exports: [
    ReceiptComponent,
    ButtonIconComponent
  ],
  imports: [
    CommonModule,
    SvgArrow,
    RouterLink,
    SvgXMark,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    SvgTeamTellsLogo,
    A11yModule,
    ImageStubComponent,
    ButtonComponent,
    SwitcherComponent,
    FieldsModule,
    TranslocoDirective,
    TextParserComponent
  ],
  providers: [
    SubscriptionService,
    {
      provide: "InvoicingModalOrganisationNameValidator",
      useExisting: Validator,
      useFactory: PaymentAndTariffModule.organisationNameValidatorFactory
    },
    {
      provide: "InvoicingModalLegalAddressValidator",
      useExisting: Validator,
      useFactory: PaymentAndTariffModule.legalAddressValidatorFactory
    },
    {
      provide: "InvoicingModalKPPValidator",
      useExisting: Validator,
      useFactory: PaymentAndTariffModule.KPPValidatorFactory
    },
    {
      provide: "InvoicingModalSoloProprietorINNValidator",
      useExisting: Validator,
      useFactory: PaymentAndTariffModule.SoloProprietorINNValidatorFactory
    },
    {
      provide: "InvoicingModalINNValidator",
      useExisting: Validator,
      useFactory: PaymentAndTariffModule.INNValidatorFactory
    }
  ]
})
export class PaymentAndTariffModule {
  public static organisationNameValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите название организации'),
    ]);

  public static legalAddressValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите юридический адрес'),
    ]);

  public static KPPValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите КПП'),
      new MaxLengthRule(
        'КПП должен содержать 9 символов',
        9,
      ),
      new MinLengthRule(
        'КПП должен содержать 9 символов',
        9,
      )
    ]);

  public static INNValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите ИНН'),
      new MaxLengthRule(
        'ИНН должен содержать 10 символов',
        10,
      ),
      new MinLengthRule(
        'ИНН должен содержать 10 символов',
        10,
      )
    ]);

  public static SoloProprietorINNValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите ИНН'),
      new MaxLengthRule(
        'ИНН должен содержать 12 символов',
        12,
      ),
      new MinLengthRule(
        'ИНН должен содержать 12 символов',
        12,
      )
    ]);
}
