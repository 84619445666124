import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from "@angular/core";
import {
  createYooptaEditor,
  YooptaBlockData,
  YooptaContentValue
} from "@yoopta/editor";
import {YooptaEditorConstants} from "../common/yoopta-editor-constants";
import {Editor2ServiceImpl} from "../data/editor-service-impl";
import {DocumentFileDto} from "../data/dto/documentDto";
import {SpaceConstants} from "../../../../features/knowledge-base/modules/space/common/space-constants";
import {Observable} from "rxjs";
import { EDITOR_TOOLS } from "./tools/editor-tools";
import { EDITOR_PLUGINS } from "./plugins/editor-plugins";
import { EDITOR_MARKS } from "./marks/editor-marks";
import ReactYooptaEditor from "./components/react-yoopta-editor/react-yoopta-editor";
import { ToastsService } from "../../toast-alert/services/toast-alert.service";
import { ToastState } from "../../toast-alert/toast-alert.component";
import { provideTranslocoScope, translate, TranslocoService } from "@jsverse/transloco";

@Component({
  selector: "app-yoopta-editor",
  templateUrl: "./yoopta-editor.component.html",
  providers: [
    Editor2ServiceImpl,
    provideTranslocoScope({ scope: "core/editor", alias: YooptaEditorConstants.TRANSLOCO_READ })
  ]
})
export class YooptaEditorComponent implements OnChanges, AfterViewInit {
  @Input() isReadOnly: boolean = false;
  @Input() longreadId: string = '';
  @Input() title: string = "";
  @Input() isHaveTitle: boolean = true;

  @Output() titleChange = new EventEmitter<string>();
  @Output() saveClicked = new EventEmitter();
  @Output() startLoading = new EventEmitter();
  @Output() endLoading = new EventEmitter<number>();

  editor = createYooptaEditor();

  editorBox: HTMLElement | undefined = undefined;
  isEditorLoaded: boolean = false;
  documentFiles: DocumentFileDto[] = []

  props = {
    placeholder: '',
    editor: this.editor,
    value: YooptaEditorConstants.INIT_EDITOR_VALUE,
    plugins: EDITOR_PLUGINS,
    tools: EDITOR_TOOLS,
    marks: EDITOR_MARKS,
    autoFocus: false,
    readOnly: false,
    selectionBoxRoot: this.editorBox,
    className: "yoopta-editor w-full pb-[30%]"
  };

  protected readonly YooptaEditor = ReactYooptaEditor;
  protected readonly SpaceConstants = SpaceConstants;

  constructor(
    private editorService: Editor2ServiceImpl,
    private toastsService: ToastsService,
    private translocoService: TranslocoService,
  ) {}

  ngAfterViewInit(): void {
    this.translocoService.langChanges$.subscribe(() => {
      this.props = {...this.props,
        placeholder: translate(YooptaEditorConstants.TRANSLOCO_READ + ".editor-placeholder")
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['longreadId'] && this.longreadId != '') {
      if (this.longreadId != '-1') {
        sessionStorage.setItem('longreadId', this.longreadId);
        this.startLoading.emit()
        this.isEditorLoaded = false
        this.editorService.getDocumentBy(this.longreadId)
          .subscribe({
            next: (documentDto) => {
              if (JSON.stringify(documentDto.data) != "{}") {
                this.props.value = documentDto.data as YooptaContentValue;
              } else {
                this.props.value = YooptaEditorConstants.INIT_EDITOR_VALUE;
              }
              this.isEditorLoaded = true;
              this.endLoading.emit(documentDto.updatedAt);
              this.documentFiles = documentDto.files;
              sessionStorage.setItem("usedFiles", "");
            },
            error: () => {
              this.isEditorLoaded = true;
              this.toastsService.createToast({
                title: 'Не удалось загрузить лонгрид',
                description: 'Попробуйте позднее',
                state: ToastState.ERROR
              })
            }
          });
      } else {
        this.isEditorLoaded = true
      }
    }
    if (changes['isReadOnly']) {
      this.props.readOnly = this.isReadOnly;
    }
  }

  saveDocument(): Observable<null> {

    const editorValue = this.editor.getEditorValue() as YooptaContentValue;
    const usedFiles = this.getUsedFiles(editorValue)

    return this.editorService.saveDocument(
      this.longreadId,
      editorValue,
      usedFiles)
  }

  getUsedFiles(editorValue: YooptaContentValue): string[] {
    let result: string[] = []
    for (let prop in editorValue) {
      const block = editorValue[prop] as YooptaBlockData

      switch (block.type){
        case 'Image':
          block.value.forEach((children) => {
            if (
              typeof children === 'object'
              && 'type' in children
              && children.type === 'image'
              && 'src' in children.props
              && children.props.src
            ) {
              if(result.findIndex((value) => value === children.props.src) === -1)
              {
                result.push(children.props.src)
              }
            }
          })
          break

        case 'File':
          block.value.forEach((children) => {
            if (
              typeof children === 'object'
              && 'type' in children
              && children.type === 'file'
              && 'src' in children.props
              && children.props.src
            ) {
              if(result.findIndex((value) => value === children.props.src) === -1)
              {
                result.push(children.props.src)
              }
            }
          })
          break
      }
    }
    return result
  }

  onChangeTitle(event: any) {
    if (this.titleChange) {
      this.title = event.target.value;
      this.titleChange.emit(this.title);
    }
  }

  onEnterClickTitle(event: any) {
    event.preventDefault();
    console.log(this.editor.getEditorValue())
    this.editor.focus()
  }
}
