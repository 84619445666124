import { HighlightedPartsStr } from "../components/text-with-highlighting/text-with-highlighting.component";

export function parseToHighlightedParts(parseStr: string, highlightedPart: string = ''): HighlightedPartsStr[]{
  let result: HighlightedPartsStr[] = []
  if(highlightedPart != ''){
    let position: number = parseStr.toLocaleLowerCase().indexOf(highlightedPart.toLocaleLowerCase())
    let lastString = parseStr
    while(position != -1){
      result.push(
        {
          stringPart: lastString.substring(0, position),
          isHighlighted: false
        },
        {
          stringPart: lastString.substring(position, position + highlightedPart.length),
          isHighlighted: true
        },
      )

      lastString = lastString.substring(position + highlightedPart.length)
      position = lastString.toLocaleLowerCase().indexOf(highlightedPart.toLocaleLowerCase())
    }
    result.push({
      stringPart: lastString,
      isHighlighted: false
    })
  }
  else{
    result = [{stringPart: parseStr, isHighlighted: false}]
  }
  return result
}
