import { Component, EventEmitter, Output } from "@angular/core";
import { SpaceConstants } from "../../../common/space-constants";
@Component({
  selector: 'space-document-header-triple-dot',
  templateUrl: './document-header-triple-dot.component.html',
  styleUrls: ['../../../common/space.component.scss']
})
export class DocumentHeaderTripleDotComponent{

  isPopupOpen: boolean = false
  protected readonly SpaceConstants = SpaceConstants

  @Output() editClicked = new EventEmitter()
  @Output() deleteClicked = new EventEmitter()
  @Output() renameClicked = new EventEmitter()

  popupElementClicked(id: string){
    switch(id){
      case 'rename':
        this.renameClicked.emit()
        break
      case 'edit':
        this.editClicked.emit()
        break
      case 'delete':
        this.deleteClicked.emit()
        break
    }
    this.isPopupOpen = false
  }
}
