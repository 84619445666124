import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  SubscriptionFormResultAction,
  SubscriptionFormResultActionTypes
} from "./subscription-form-result-action";
import { SubscriptionFormState } from './subscription-form-state';

@Injectable()
export class SubscriptionFormReducer
  implements Reducer<SubscriptionFormState, SubscriptionFormResultAction>
{
  reduce(state: SubscriptionFormState, action: SubscriptionFormResultAction): SubscriptionFormState {
    switch (action.type) {
      case SubscriptionFormResultActionTypes.INIT:
        return {...state,
          durationDropdownItems: action.durationDropdownItems,
          currentDuration: action.currentDuration,
          durations: action.durations,
          tariffDropdownItems: action.tariffDropdownItems,
          currentTariff: action.currentTariff,
          allTariffs: action.allTariffs,
          additionalTariff: action.additionalTariff,
        }
      case SubscriptionFormResultActionTypes.CHANGE_DURATION:
        return {...state, currentDuration: action.duration}
      case SubscriptionFormResultActionTypes.CHANGE_TARIFF:
        return {...state, currentTariff: action.tariff}
      case SubscriptionFormResultActionTypes.CHANGE_USERS_COUNT:
        return {...state, usersCount: action.value}
      case SubscriptionFormResultActionTypes.CHANGE_ADDITIONAL_MEMORY:
        return {...state, memoryCount: action.value}
      case SubscriptionFormResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value}
      case SubscriptionFormResultActionTypes.CHANGE_RECEIPT_BUTTON_IS_LOADING:
        return {...state, receiptButtonIsLoading: action.value}
      case SubscriptionFormResultActionTypes.CHANGE_LANG:
        return {...state,
          durationDropdownItems: action.durationDropDownItems,
          currentDuration: action.currentDuration,
        }
    }
  }
}
