<div
  [class]="'py-[8px] relative flex gap-[8px] justify-between items-center group/user-summery ' + className"
  [ngClass]="{
    'hover:bg-tt-icon-button-background-hover': actionType === 'delete'
  }"
>
  <div
    class="flex gap-[16px] items-center"
  >
    <app-user-card-open-container
      [userId]="user.id"
    >
      <app-core-avatar
        size="l"
        variant="primary"
        placeholder="{{(user.surname[0] ? user.surname[0] : '') + (user.name[0] ? user.name[0] : '') }}"
        bgRGB="{{stringToRgb(user.email)}}"
      />
    </app-user-card-open-container>

    <div>
      <p
        class="text-tt-font-size_l text-tt-text-main line-clamp-1"
        app-core-with-highlighting
        [text]="user.nameParts"
      ></p>

      <p
        class="text-tt-text-secondary text-tt-font-size_m line-clamp-1"
        app-core-with-highlighting
        [text]="user.emailParts"
      ></p>
    </div>

  </div>
  <div class="flex gap-[24px] items-center">

    <ng-content/>

    <app-core-checkbox-field
      *ngIf="actionType === 'select'"
      [class.invisible]=""
      class="size-[40px]"
      roundType='circle'
      [value]="user.isSelected"
      (onToggle)="onSelect.emit()"
    />

    <app-core-button-icon
      *ngIf="actionType === 'delete'"
      class="invisible "
      [ngClass]="{
        'group-hover/user-summery:visible': isDeletable
      }"
      size="m"
      (onClick)="onDelete.emit()"
    >
      <svg-x-mark
        class="size-[24px] block"
      />
    </app-core-button-icon>
  </div>


  <div
    *ngIf="borderBottom"
    class="absolute bottom-0 h-[1px] w-full pl-[56px]">
    <div class="w-full h-[1px] bg-tt-border-default"></div>
  </div>

</div>
