import { Component, Input } from "@angular/core";
import { MainConstants } from "../../common/main-constants";

@Component({
  selector: 'main-navigation-element',
  templateUrl: './navigation-element.component.html',
  styleUrls: [
    './navigation-element.component.scss',
    '../navigation-section/navigation-section.component.scss'
  ]
})
export class NavigationElementComponent {
  @Input() navElement: NavigationElement = {
    id: '',
    name: '',
    url: '',
    icon: null
  }
  @Input() type: 'hide' | 'show'  = 'show';
  protected readonly MainConstants = MainConstants;
}

export type NavigationElement = {
  id: string
  name: string
  icon: any
  url: string
  tabIds?: string[]
}
