import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from "@angular/core";
import { TaskExecutor } from "../task-item/task-item.component";
import { stringToRgb } from "../../../../../../../../core/utils/string-to-rgb";

@Component({
  selector: "app-task-avatar",
  templateUrl: "./task-avatar.component.html",
  styleUrls: ["./task-avatar.component.scss"],
  host: {
    class: "size-[20px] p-[2px] rounded-[2px]"
  }
})
export class TaskAvatarComponent implements OnChanges {
  @Input({ required: true }) executor!: TaskExecutor;

  initials: string = "";

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initials = ((this.executor.name ? this.executor.name[0] : '') + (this.executor.surname ? this.executor.surname[0] : '')).toUpperCase();
    this.renderer.setStyle(this.el.nativeElement, 'background-color', `rgb(${stringToRgb(this.executor.email)})`)
  }
}
