export type AgilesListAction =
  | ChangeIsOpenCreateModalAction
  | CreateDeskModalAction

export enum AgilesListActionTypes {
  CHANGE_IS_OPEN_CREATE_MODAL,
  CREATE_DESK,
}

export interface ChangeIsOpenCreateModalAction {
  readonly type: AgilesListActionTypes.CHANGE_IS_OPEN_CREATE_MODAL
  readonly value: boolean
}

export interface CreateDeskModalAction {
  readonly type: AgilesListActionTypes.CREATE_DESK
  readonly name: string
}
