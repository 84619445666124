import { SpaceSettingsState } from "./space-settings-state";
import { SpaceSettingsAction, SpaceSettingsActionTypes } from "./space-settings-action";
import { SpaceSettingsResultAction, SpaceSettingsResultActionTypes } from "./space-settings-result-action";
import { Inject, Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { KnowledgeBaseService } from "src/app/features/knowledge-base/data/knowledge-base-service";
import { SpaceExecutor } from "../../../state/space-executor";
import { SpaceActionTypes } from "../../../state/space-action";
import { Validator } from "../../../../../../../../core/validators/validator";
import { Router } from "@angular/router";
import { MainRoutesPaths } from "../../../../../../../../routes/main-routes";
import { ToastsService } from "../../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../../../core/components/toast-alert/toast-alert.component";
import { translate, TranslocoService } from "@jsverse/transloco";
import { SpacesConstants } from "../../../../../spaces/common/spaces-constants";
import { SpaceConstants } from "../../../../common/space-constants";
import { KnowledgeBaseConstants } from "../../../../../../common/knowledge-base-constants";

@Injectable()
export class SpaceSettingsExecutor extends Executor<
  SpaceSettingsState,
  SpaceSettingsAction,
  SpaceSettingsResultAction
> {
  constructor(
    private knowledgeBaseService: KnowledgeBaseService,
    private spaceExecutor: SpaceExecutor,
    @Inject('SpaceTitleValidator')
    private spaceTitleValidator: Validator,
    private router: Router,
    private toastsService: ToastsService,
    private translocoService: TranslocoService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<SpaceSettingsState, SpaceSettingsResultAction>,
    getState: () => SpaceSettingsState,
    onReduced: (state: SpaceSettingsState) => void
  ) {
    super.init(reducer, getState, onReduced);
    this.translocoService.langChanges$.subscribe(()=>{
      if(this.getState().spaceTitleError != null){
        this.handleChangeSpaceTitle(this.getState().newSpaceTitle)
      }
    })
  }

  execute(action: SpaceSettingsAction) {
    switch (action.type) {
      case SpaceSettingsActionTypes.INIT:
        this.reduce({
          type: SpaceSettingsResultActionTypes.INIT,
          spaceId: action.input.spaceId,
          spaceTitle: action.input.spaceTitle
        })
        break
      case SpaceSettingsActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        this.reduce({
          type: SpaceSettingsResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
          value: action.value
        })
        break
      case SpaceSettingsActionTypes.DELETE:
        this.reduce({
          type: SpaceSettingsResultActionTypes.CHANGE_ALERT_IS_LOADING,
          value: true
        })
        this.knowledgeBaseService.deleteSpace(this.getState().spaceId)
          .subscribe({
            next: ()=>{
              this.router.navigateByUrl(`/${MainRoutesPaths.KNOWLEDGE_BASE}`)
              this.reduce({
                type: SpaceSettingsResultActionTypes.CHANGE_ALERT_IS_LOADING,
                value: false
              })
            },
            error: () =>{
              this.toastsService.createToast({
                title: 'Произошла ошибка при удалении пространства',
                description: 'Попробуйте позднее',
                state: ToastState.ERROR
              })
              this.reduce({
                type: SpaceSettingsResultActionTypes.CHANGE_ALERT_IS_LOADING,
                value: false
              })
            }
          })
        break
      case SpaceSettingsActionTypes.CHANGE_SPACE_TITLE:
        this.handleChangeSpaceTitle(action.value)

        break;
      case SpaceSettingsActionTypes.CANCEL_TITLE:
        this.reduce({
          type: SpaceSettingsResultActionTypes.CANCEL_TITLE
        })
        break
      case SpaceSettingsActionTypes.SAVE_TITLE:
        if(!this.getState().spaceTitleError)
        {
          this.reduce({
            type: SpaceSettingsResultActionTypes.CHANGE_IS_PAGE_LOADING,
            value: true
          })
          this.knowledgeBaseService.updateSpace(this.getState().spaceId, this.getState().newSpaceTitle)
          .subscribe({
            next: () => {
              this.spaceExecutor.execute({
                type: SpaceActionTypes.UPDATE_SPACE_TITLE,
                title: this.getState().newSpaceTitle
              })
              this.reduce({
                type: SpaceSettingsResultActionTypes.CHANGE_IS_PAGE_LOADING,
                value: false
              })
            },
            error: err => {
              this.toastsService.createToast({
                title: 'Произошла ошибка при сохранении пространства',
                description: 'Попробуйте позднее',
                state: ToastState.ERROR
              })
              this.reduce({
                type: SpaceSettingsResultActionTypes.CHANGE_IS_PAGE_LOADING,
                value: false
              })
            },
          })
        }

        break
      case SpaceSettingsActionTypes.CHANGE_NAV_ITEM:
        this.reduce({
          type: SpaceSettingsResultActionTypes.INIT,
          spaceId: this.getState().spaceId,
          spaceTitle: this.getState().spaceTitle
        })
        this.reduce({
          type: SpaceSettingsResultActionTypes.CHANGE_NAV_ITEM,
          navItem: action.navItem
        })
        break;
    }
  }

  private handleChangeSpaceTitle(value: string){
    const error = this.spaceTitleValidator.validate(value)
    this.reduce({
      type: SpaceSettingsResultActionTypes.CHANGE_SPACE_TITLE,
      newSpaceTitle: value,
      spaceTitleError: error ?
        translate(
          SpaceConstants.SETTINGS_TRANSLOCO_READ + '.' + error,
          {maxLength: KnowledgeBaseConstants.SPACE_TITLE_MAX_LENGTH}
        ) : null
    })
  }
}
