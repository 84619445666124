import { Component } from "@angular/core";

@Component({
  selector: 'svg-agiles-card',
  templateUrl: './svg/agiles-card.svg',
})
export class SvgAgilesCard {}

@Component({
  selector: 'svg-agiles-statuses',
  templateUrl: './svg/agiles-statuses.svg',
})
export class SvgAgilesStatuses {}

@Component({
  selector: 'svg-status-drag-handle',
  templateUrl: './svg/status-drag-handle.svg',
})
export class SvgStatusDragHandle {}
