import { Component } from "@angular/core";

@Component({
  selector: 'svg-grid2x2',
  templateUrl: './svg/grid2x2.svg',
})
export class SvgGrid2x2{}

@Component({
  selector: 'svg-checklist',
  templateUrl: './svg/checklist.svg',
})
export class SvgChecklist{}
