import { Routes } from "@angular/router";
import {
  AgilesListComponent
} from "../features/agiles/agiles-main/modules/agiles-list/presentation/agiles-list.component";

export enum AgileRoutesPath { LIST = ''}

export const AGILES_ROUTES: Routes = [
  {
    path: AgileRoutesPath.LIST,
    component: AgilesListComponent
  }
];
