<app-auth-layout
  topOffset="constant"
  bgType="white"
  [isHaveBackButton]="state.status === 'idle' || state.status === 'create_loading'"
  [isLoading]="state.status === 'create_loading'"
  [backButtonText]="'registration.back-to-login' | transloco"
>
  <div class="mt-[120px] w-full flex justify-center">
    <app-registration-form
      *ngIf="state.status === 'idle' || state.status === 'create_loading'"
      [surnameError]="state.surnameError"
      [nameError]="state.nameError"
      [phoneNumberError]="state.phoneNumberError"
      [emailError]="state.emailError"
      (onFirstFocus)="performAction({
        type: RegistrationActionTypes.VALIDATE
      })"
      (onSurnameChange)="performAction({
        type: RegistrationActionTypes.CHANGE_SURNAME,
        surname: $event
      })"
      (onNameChange)="performAction({
        type: RegistrationActionTypes.CHANGE_NAME,
        name: $event
      })"
      (onEmailChange)="performAction({
        type: RegistrationActionTypes.CHANGE_EMAIL,
        email: $event
      })"
      (onPhoneNumberChange)="performAction({
        type: RegistrationActionTypes.CHANGE_PHONE_NUMBER,
        phoneNumber: $event
      })"
      (onSubmit)="performAction({ type: RegistrationActionTypes.CREATE })"
    />

    <app-registration-complete
      *ngIf="state.status === 'success'"
      [email]="state.email"
      class="w-full"
    />

    <app-registration-error
      *ngIf="state.status === 'already_exist_error' || state.status === 'create_error'"
      [email]="state.email"
      class="w-full"
      [type]="state.status === 'already_exist_error' ? 'email-exist' : 'unknown'"
    />

  </div>
</app-auth-layout>
