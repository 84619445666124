import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SpaceSettingsExecutor } from "./state/space-settings-executor";
import { SpaceSettingsNavItems, SpaceSettingsState } from "./state/space-settings-state";
import { SpaceSettingsReducer } from "./state/space-settings-reducer";
import { SpaceSettingsAction, SpaceSettingsActionTypes } from "./state/space-settings-action";
import { Store } from "src/app/core/mvi/store";
import { SpaceSettingsResultAction } from "./state/space-settings-result-action";
import { SpaceConstants } from "../../../common/space-constants";

@Component({
  selector: 'space-settings',
  templateUrl: './space-settings.component.html',
  styleUrls: ['../../../common/space.component.scss'],
  providers: [
    SpaceSettingsExecutor,
    SpaceSettingsState,
    SpaceSettingsReducer
  ]
})
export class SpaceSettingsComponent extends Store<
  SpaceSettingsState,
  SpaceSettingsExecutor,
  SpaceSettingsAction,
  SpaceSettingsResultAction
> implements OnChanges
{
  @Input() spaceSettingsInput!: SpaceSettingsInput
  protected readonly SpaceSettingsNavItems = SpaceSettingsNavItems
  protected readonly SpaceSettingsActionTypes = SpaceSettingsActionTypes

  constructor(
    state: SpaceSettingsState,
    executor: SpaceSettingsExecutor,
    reducer: SpaceSettingsReducer,
  ) {
    super(state, executor, reducer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['spaceSettingsInput']){
      this.performAction({
        type: SpaceSettingsActionTypes.INIT,
        input: this.spaceSettingsInput
      })
    }
  }

  protected readonly SpaceConstants = SpaceConstants;
}

export interface SpaceSettingsInput{
  spaceId: string,
  spaceTitle: string
}
