import { generateId, SlateElement, YooptaPlugin } from '@yoopta/editor';
import { ImageCommands, ImageElementProps } from "@yoopta/image";
import { ImageElementMap, ImagePluginOptions } from "./types/image-plugin-options";
import { MyImageRender } from "./render/my-image-render";
import { environment } from "../../../../../../../environments/environment";

const ALIGNS_TO_JUSTIFY = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const MyImage = new YooptaPlugin<ImageElementMap, ImagePluginOptions>({
  type: 'Image',
  elements: {
    image: {
      render: MyImageRender,
      props: {
        src: null,
        alt: null,
        srcSet: null,
        bgColor: null,
        fit: 'contain',
        sizes: { width: 650, height: 500 },
        nodeType: 'void',
      },
    },
  },
  commands: ImageCommands,
  options: {
    display: {
      title: 'Image',
    },
    accept: 'image/png, image/jpeg, image/gif, image/webp',
    maxSizes: { maxWidth: 720, maxHeight: 550 },
  },
  parsers: {
    html: {
      deserialize: {
        nodeNames: ['IMG'],
        parse: (el) => {
          if (el.nodeName === 'IMG') {
            const src = el.getAttribute('src') || ''
            const url = src.startsWith(`${environment.apiUrl}/editor/file/`) ? src.substring(`${environment.apiUrl}/editor/file/`.length) : src
            const props: SlateElement<'image', ImageElementProps>['props'] = {
              nodeType: 'void',
              src: url,
              alt: el.getAttribute('alt') || '',
              srcSet: el.getAttribute('srcset') || '',
              fit: (el.getAttribute('objectFit') || 'contain') as ImageElementProps['fit'],
              sizes: {
                width: el.getAttribute('width') ? parseInt(el.getAttribute('width') || '650', 10) : 650,
                height: el.getAttribute('height') ? parseInt(el.getAttribute('height') || '500', 10) : 500,
              },
            };

            const node: SlateElement = {
              id: generateId(),
              type: 'image',
              children: [{ text: '' }],
              props,
            };

            return node;
          }
          return
        },
      },
      serialize: (element, text, blockMeta) => {
        const { align = 'center', depth = 0 } = blockMeta || {};
        const justify = ALIGNS_TO_JUSTIFY[align] || 'center';

        return `
          <div style="margin-left: ${depth}px; display: flex; width: 100%; justify-content: "${justify}"">
            <img
              data-meta-align="${align}"
              data-meta-depth="${depth}"
              src="${environment.apiUrl}/editor/file/${element.props.src}"
              alt="${element.props.alt}"
              width="${element.props.sizes.width}"
              height="${element.props.sizes.height}"
              objectFit="${element.props.fit}"
            />
          </div>`;
      },
    },
    markdown: {
      serialize: (element, text) => {
        return `![${element.props.alt}](${environment.apiUrl}/editor/file/${element.props.src})\n`;
      },
    },
  },
});

export { MyImage };
