<a class="
  flex flex-col justify-end gap-[10px]
  space-item-container px-[20px] py-[12px] hover:bg-tt-light-blue"
  [routerLink]="KnowledgeBasePaths.SPACE + '/' + space.id"
>
  <p class="text-tt-text-main text-tt-font-size_2xl font-normal line-clamp-6"
    app-core-with-highlighting
    [text]="space.nameParts"
  ></p>
  <p
    class="text-tt-text-secondary text-[14px] leading-[18px] font-normal"
    *transloco="let t; read: SpacesConstants.TRANSLOCO_READ"
  >
    {{ t('documents', { count: space.documentsCount, countType: ruNumWordType(space.documentsCount)}) }}
  </p>
</a>
