<ng-container
  *transloco="let t; read: PaymentAndTariffConstants.TRANSLOCO_READ"
>
  <div
    class="mt-[44px] w-full flex justify-center"
    *ngIf="state.isLoading"
  >
    <mat-spinner class="size-[48px]"/>
  </div>

  <app-core-image-stub
    class="mt-[20px]"
    *ngIf="!state.isLoading && state.isLoadingError"
    [imageSrc]="''"
    [title]="t('loading-error-title')"
    [description]="t('loading-error-description')"
  />

  <div
    class="py-[44px] max-w-[1256px] w-full mx-auto"
    *ngIf="!state.isLoading && !state.isLoadingError"
  >
    <div class="flex items-center w-full justify-between gap-[16px]">
      <p class="block-title">
        {{ t('active-subscription') }}
      </p>
      <a
        [routerLink]="'./'"
        [queryParams]="{'open':'tariff'}"
        *ngIf="state.subscription && state.isEditable"
        class="text-tt-font-size_l text-tt-primary transition-opacity hover:opacity-80"
      >
        {{ t('extend-and-expand') }}
      </a>
    </div>

    <div
      class="mt-4"
    >
      <app-active-subscription
        [subscription]="state.subscription"
      />
    </div>

    <p class="mt-[24px] mb-[16px] block-title">
      {{ t('tariffs') }}
    </p>

    <app-tariff-list
      [selectedTariff]="state.currentTariff"
      [tariffs]="state.tariffs"
      [durationItems]="state.durationItems"
      [currentDuration]="state.currentDuration"
    />
  </div>

  <app-active-subscription-form
    *ngIf="state.isSubscriptionFormOpen"
  />

  <app-tariff-form
    *ngIf="state.isTariffFormOpen && state.subscription"
  />

</ng-container>
