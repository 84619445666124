<div
  class="child-routes-size px-[24px] tiny-scrollbar"
  *transloco="let t; read: EmployeesConstants.TRANSLOCO_READ"
  (scroll)="onScroll()"
  #scrollable
>
  <app-core-page-header
    [title]="t('my-team')"
    [navTabs]="navTabs"
    [tabsTransclocoRead]="EmployeesConstants.TRANSLOCO_READ"
    navTabType="link"
  />

  <router-outlet></router-outlet>

</div>
