import { NavTab } from "src/app/core/components/nav-tabs/nav-tabs.component";
import { SpaceNavItems } from "../domain/nav-items";
import { ActionElementStyle, ActionSection } from "src/app/core/components/action-menu/action-menu.component";
import { DocumentSummaryEntity } from "../domain/document-summery-entity";

export class SpaceConstants {
  public static readonly EMPTY_DOCUMENT_NAME = "Без названия";
  public static readonly MAIN_TRANSLOCO_READ = 'spaceMain'
  public static readonly SETTINGS_TRANSLOCO_READ = 'spaceSettings'

  public static readonly SELECTED_DOCUMENT_INIT: DocumentSummaryEntity = {
    id: '',
    name: '',
    longreadId: '',
    isOpen: false,
    countSearchMatches: 0,
    documents: [],
  }

  public static readonly MAIN_NAV_TAB: NavTab =
  {
    id: '',
    url: SpaceNavItems.MAIN,
    title: 'main',
    query: { nav: SpaceNavItems.MAIN }
  }

  public static readonly SETTINGS_NAV_TAB: NavTab =
  {
    id: '',
    url: SpaceNavItems.SETTINGS,
    title: 'settings',
    query: { nav: SpaceNavItems.SETTINGS }
  }

  public static readonly TREE_POPUP_MENU_SECTIONS: ActionSection[] = [{
    elements: [
      {
        id: 'edit',
        name: 'edit'
      },
      {
        id: 'rename',
        name: 'rename'
      },
      {
        id: 'delete',
        name: 'delete',
        type: ActionElementStyle.DANGER
      },
    ]
  }]

  public static readonly DOCUMENT_HEADER_POPUP_MENU_SECTIONS: ActionSection[] = [{
    elements: [
      {
        id: 'edit',
        name: 'edit'
      },
      {
        id: 'delete',
        name: 'delete',
        type: ActionElementStyle.DANGER
      },
    ]
  }]

  public static CONTENT_TOP_OFFSET = 92

  public static BASE_DOCUMENT_LEFT_OFFSET: number = 24
  public static BASE_DOCUMENT_RIGHT_OFFSET: number = 8
  public static BASE_DOCUMENT_DEPTH_STEP: number = 12
}
