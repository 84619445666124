import { SubscriptionFormState } from "./subscription-form-state";
import { SubscriptionFormAction, SubscriptionFormActionTypes } from "./subscription-form-action";
import { SubscriptionFormResultAction, SubscriptionFormResultActionTypes } from "./subscription-form-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { DropdownItem } from "../../../../../../../../core/components/fields/components/dropdown-field/dropdown-field.component";
import { ReceiptService } from "../../../../data/receipt-service";
import { convertDurationToDropDownItem, getDurationString } from "../../../utils/convert-tariff-duration";
import { ReceiptField } from "../../../../domain/receipt-secton";
import { ToastState } from "../../../../../../../../core/components/toast-alert/toast-alert.component";
import { ToastsService } from "../../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { numToWordType } from "../../../../../../../../core/utils/num-to-word-type";
import { Location } from "@angular/common";
import { SubscriptionService } from "../../../../../../data/subscription.service";
import { SettingsNavigator } from "../../../../../../navigator/settings-navigator";
import { translate, TranslocoService } from "@jsverse/transloco";
import { SubscriptionFormConstants } from "../common/subscription-form-constants";
import { TariffFormResultActionTypes } from "../../tariff-form/state/tariff-form-result-action";

@Injectable()
export class SubscriptionFormExecutor extends Executor<
  SubscriptionFormState,
  SubscriptionFormAction,
  SubscriptionFormResultAction
> {

  constructor(
    private receiptService: ReceiptService,
    private subscriptionService: SubscriptionService,
    private toastsService: ToastsService,
    private location: Location,
    private settingsNavigator: SettingsNavigator,
    private translocoService: TranslocoService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<SubscriptionFormState, SubscriptionFormResultAction>,
    getState: () => SubscriptionFormState,
    onReduced: (state: SubscriptionFormState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.handleInit()
    this.translocoService.langChanges$.subscribe(()=>this.handleLangChanges())
  }

  execute(action: SubscriptionFormAction) {
    switch (action.type) {
      case SubscriptionFormActionTypes.CREATE_SUBSCRIPTION_ORDER:
        this.handleCreateSubscriptionOrder()
        break
      case SubscriptionFormActionTypes.CHANGE_TARIFF:
        const findTariff = this.getState().tariffDropdownItems.find(tariff => tariff.id === action.tariffId)
        if(findTariff) {
          this.reduce({
            type: SubscriptionFormResultActionTypes.CHANGE_TARIFF,
            tariff: findTariff
          })
        }
        break
      case SubscriptionFormActionTypes.CHANGE_DURATION:
        const findDuration = this.getState().durationDropdownItems.find(duration => duration.id === action.durationId)
        if(findDuration) {
          this.reduce({
            type: SubscriptionFormResultActionTypes.CHANGE_DURATION,
            duration: findDuration
          })
        }
        break
      case SubscriptionFormActionTypes.CHANGE_ADDITIONAL_MEMORY:
        this.reduce({
          type: SubscriptionFormResultActionTypes.CHANGE_ADDITIONAL_MEMORY,
          value: action.value
        })
        break
      case SubscriptionFormActionTypes.CHANGE_USERS_COUNT:
        this.reduce({
          type: SubscriptionFormResultActionTypes.CHANGE_USERS_COUNT,
          value: action.value
        })
        break
    }
    this.updateReceipt()
  }

  private handleLangChanges(){
    this.updateReceipt()
    let currentDuration: DropdownItem | null = null;
    const durations = this.getState().durations.map<DropdownItem>(
      (duration, i) => {
        const result = convertDurationToDropDownItem(duration)
        if (duration.monthsCount === parseInt(this.getState().currentDuration!.id)){
          currentDuration = result
        }
        return result
      }
    )
    this.reduce({
      type: SubscriptionFormResultActionTypes.CHANGE_LANG,
      durationDropDownItems: durations,
      currentDuration: currentDuration
    })
  }

  private handleCreateSubscriptionOrder(){
    const state = this.getState()
    if(state.currentTariff && state.currentDuration)
    {
      this.reduce({
        type: SubscriptionFormResultActionTypes.CHANGE_IS_LOADING,
        value: true
      })
      this.subscriptionService.createInvoice({
        tariffID: state.currentTariff.id,
        monthsCount: parseInt(state.currentDuration.id),
        usersCount: state.usersCount,
        additionalFields: {
          gigabytesCount: state.memoryCount
        }
      }).subscribe({
        next: value => {
          this.settingsNavigator.navigateToOrders()
        },
        error: err => {
          this.toastsService.createToast({
            title: 'Не удалось создать заказ',
            description: 'Попробуйте позднее',
            state: ToastState.ERROR
          })
          this.handleInit()
          this.reduce({
            type: SubscriptionFormResultActionTypes.CHANGE_IS_LOADING,
            value: false
          })
        }
      })
    }
  }

  private updateReceipt(){
    const state = this.getState()
    const currentDuration = state.currentDuration
    const additionalTariff = state.additionalTariff
    if(currentDuration && additionalTariff)
    {
      const findDuration = state.durations.find(dur => dur.monthsCount === parseInt(currentDuration.id))
      const findTariff = state.allTariffs.find((tariff)=> tariff.id == state.currentTariff?.id)

      if(findDuration && findTariff){
        let fields: ReceiptField[] = []
        const durationString = getDurationString(findDuration.monthsCount)
        if(state.usersCount > 0)
        {
          fields.push({
            text: translate(
              SubscriptionFormConstants.TRANSLOCO_READ+'.receipt-tariff',
              {
                tariffName: findTariff.name,
                usersCount: state.usersCount,
                usersCountType: numToWordType(state.usersCount),
                durationString: durationString
              }
            ),
            price: findTariff.userCost * state.usersCount * findDuration.monthsCount
          })
          if(findDuration.discount){
            fields.push({
              text: translate(
                SubscriptionFormConstants.TRANSLOCO_READ + '.receipt-tariff-discount',
                {
                  discount: findDuration.discount,
                  durationString: durationString,
                }
              ),
              price: -Math.round(findTariff.userCost * state.usersCount * findDuration.monthsCount * findDuration.discount / 100)
            })
          }
        }
        if(state.memoryCount > 0){
          const memoryCost = additionalTariff.storageTariffs.find(
            (cost) => cost.minCount <= state.memoryCount &&  state.memoryCount <= cost.maxCount
          )
          if(memoryCost){
            fields.push({
              text: translate(
                SubscriptionFormConstants.TRANSLOCO_READ+'.receipt-storage',
                {
                  gigabytesCount: state.memoryCount,
                }
              ),
              price: memoryCost.cost * findDuration.monthsCount * state.memoryCount
            })
          }
        }

        this.receiptService.updateSections([{fields: fields}])
      }
    }
  }

  private handleInit(){
    this.reduce({
      type: SubscriptionFormResultActionTypes.CHANGE_IS_LOADING,
      value: true
    })

    this.subscriptionService.getCreateInvoiceData().subscribe({
      next: (dto) => {
        let currentDuration: DropdownItem | null = null;
        const durations = dto.purchasePeriods.map<DropdownItem>(
          (duration, i) => {
            const result = convertDurationToDropDownItem(duration)
            if (duration.monthsCount === dto.defaultMonthsCount){
              currentDuration = result
            }
            return result
          }
        )

        let currentTariff: DropdownItem | null = null;
        const tariffs = dto.tariffs.map<DropdownItem>(
          (tariff, i) => {
            const result: DropdownItem = {
              id: tariff.id,
              name: `«${tariff.name}» ${tariff.userCost} ₽`
            }
            if (!currentTariff){
              currentTariff = result
            }
            return result
          }
        )

        this.reduce({
          type: SubscriptionFormResultActionTypes.INIT,
          durationDropdownItems: durations,
          currentDuration: currentDuration,
          durations: dto.purchasePeriods,
          tariffDropdownItems: tariffs,
          currentTariff: currentTariff,
          allTariffs: dto.tariffs,
          additionalTariff: dto.additionalTariff,
        })

        setTimeout(() => this.updateReceipt())
        this.reduce({
          type: SubscriptionFormResultActionTypes.CHANGE_IS_LOADING,
          value: false
        })
      },
      error: err => {
        this.toastsService.createToast({
          title: 'Произошла ошибка при загрузке данных для продления подписки',
          description: 'Попробуйте позднее',
          state: ToastState.ERROR
        })
        this.reduce({
          type: SubscriptionFormResultActionTypes.CHANGE_IS_LOADING,
          value: false
        })
        this.location.back()
      }
    })
  }
}
