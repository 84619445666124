<app-core-settings-nav-container
  *transloco="let t; read: AgileDeskSettingsConstants.TRANSLOCO_READ"
  [title]="t('nav-title')"
>
  <app-core-settings-nav-item
    [isSelected]="selectedNav == AgilesDeskSettingsNavItems.MAIN"
    (onClick)="onClickNav.emit(AgilesDeskSettingsNavItems.MAIN)"
  >
    <svg-settings-home/>
    <p>{{ t('main') }}</p>
  </app-core-settings-nav-item>

  <app-core-settings-nav-item
    [isSelected]="selectedNav == AgilesDeskSettingsNavItems.STATUSES_AND_EXECUTORS"
    (onClick)="onClickNav.emit(AgilesDeskSettingsNavItems.STATUSES_AND_EXECUTORS)"
  >
    <svg-agiles-statuses/>
    <p>{{ t('statuses-and-executors') }}</p>
  </app-core-settings-nav-item>

  <app-core-settings-nav-item
    [isSelected]="selectedNav == AgilesDeskSettingsNavItems.ACCESS"
    (onClick)="onClickNav.emit(AgilesDeskSettingsNavItems.ACCESS)"
  >
    <svg-person/>
    <p>{{ t('access-rights') }}</p>
  </app-core-settings-nav-item>

</app-core-settings-nav-container>
