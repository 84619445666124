import { EditorType, NewsEditorState } from "./news-editor-state";
import { NewsEditorAction, NewsEditorActionTypes } from "./news-editor-action";
import { NewsEditorResultAction, NewsListResultActionTypes } from "./news-editor-result-action";
import { Inject, Injectable } from "@angular/core";
import { Executor } from "src/app/core/mvi/store";
import { NewsService } from "../../../../data/news-service";
import { NewsNavigator } from "../../../../navigator/news-navigator.service";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ActivatedRoute } from "@angular/router";
import { SessionService } from "../../../../../../core/services/session-service/session-service";
import { HttpErrorResponse } from "@angular/common/http";
import { EditorChangesService } from "../../../../../../core/components/yoopta-editor/data/editor-changes-service";
import { environment } from "../../../../../../../environments/environment";
import { AuthService } from "../../../../../authorization/domain/auth.service";
import { Validator } from "../../../../../../core/validators/validator";
import { InputFieldComponent } from "../../../../../../core/components/fields/components/input-field/input-field.component";

@Injectable()
export class NewsEditorExecutor extends Executor<
  NewsEditorState,
  NewsEditorAction,
  NewsEditorResultAction
> {

  constructor(
    private newsService: NewsService,
    private newsNavigator: NewsNavigator,
    private toastsService: ToastsService,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private editorChangesService: EditorChangesService,
    private authService: AuthService,
    @Inject('NewsTitleValidator') private titleValidator: Validator,
    @Inject('NewsDescriptionValidator') private descriptionValidator: Validator,
  ) {
    super();
    this.handleInit()
  }

  execute(action: NewsEditorAction) {
    switch (action.type) {
      case NewsEditorActionTypes.SAVE_AND_MOVE:
        this.handleSave()
        break
      case NewsEditorActionTypes.CLOSE_SESSION:
        this.handleSave(false)
        this.sessionService.closeSession(this.getState().sessionId).subscribe()
        if(this.getState().pingIntervalId != -1){
          clearInterval(this.getState().pingIntervalId)
        }
        break
      case NewsEditorActionTypes.PAGE_UNLOAD:
        this.handlePageUnload()
        break
      case NewsEditorActionTypes.BACK_CLICKED:
        this.navigateBack()
        break
      case NewsEditorActionTypes.CLOSE_AFK_MODAL:
        this.editorChangesService.onChanges(this.editorChangesService.editorChanged)
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_AFK_MODAL_VISIBILITY,
          value: false,
        })
        break
      case NewsEditorActionTypes.INIT_CHILDREN:
        this.reduce({
          type: NewsListResultActionTypes.INIT_CHILDREN,
          editor: action.editor,
          inputEls: action.inputEls,
        })
        break
      case NewsEditorActionTypes.BACK:
        this.navigateBack()
        break;
      case NewsEditorActionTypes.CHANGE_TITLE:
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_TITLE,
          value: action.value,
          error: this.titleValidator.validate(action.value)
        })
        break
      case NewsEditorActionTypes.CHANGE_DESCRIPTION:
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_DESCRIPTION,
          value: action.value,
          error: this.descriptionValidator.validate(action.value)
        })
        break
      case NewsEditorActionTypes.CHANGE_IMAGE_ID:
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_IMAGE_ID,
          value: action.value,
        })
        break
      case NewsEditorActionTypes.PUBLISH:
        this.handlePublish()
        break
      case NewsEditorActionTypes.CHANGE_PUBLISH_SIDEBAR_VISIBLE:
        this.getState().editorComponent?.editor.blur()
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_PUBLISH_SIDEBAR_VISIBLE,
          value: action.value
        })
        break
      case NewsEditorActionTypes.CHANGE_PREVIEW_TYPE:
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_PREVIEW_TYPE,
          value: action.value
        })
        break
      case NewsEditorActionTypes.CHANGE_IS_EDITOR_LOADING:
        this.reduce({
          type: NewsListResultActionTypes.CHANGE_IS_EDITOR_LOADING,
          value: action.value
        })
        break
    }
  }
  private handlePublish(){
    this.validateFields()
    if(!this.getState().titleError && !this.getState().descriptionError)
    {
      this.reduce({
        type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING,
        value: true,
      })
      this.handleSave(false)
      this.newsService.publishNews(this.getState().loadedNews.id).subscribe({
        next: ()=>{
          this.toastsService.createToast({
            title: 'Новость опубликована',
            description: '',
            state: ToastState.SUCCESS
          });
          this.reduce({
            type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING,
            value: false,
          })
          this.navigateBack()
        },
        error: () => {
          this.toastsService.createToast({
            title: 'Не удалось опубликовать новость',
            description: '',
            state: ToastState.ERROR
          });
          this.reduce({
            type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING,
            value: false,
          })
        },
      })
    }
    else{
      this.toastsService.createToast({
        title: 'Публикация невозможна',
        description: 'Проверьте правильно ли заполнены поля для публикации',
        state: ToastState.ERROR,
      })
    }
  }

  private handlePageUnload(){
    const account = this.authService.getAccount()
    if(account)
    {
      fetch(`${environment.apiUrl}/session/close/${this.getState().sessionId}`, {
        keepalive: true,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${account.jwtToken}`
        },
      })
      const currentNews = this.getState().currentNews
      const editorComponent = this.getState().editorComponent
      if(editorComponent) {
        const editorData = editorComponent.editor.getEditorValue()
        const usedFiles = editorComponent.getUsedFiles(editorData)

        fetch(`${environment.apiUrl}/news/${currentNews.id}/save`, {
          keepalive: true,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${account.jwtToken}`
          },
          body: JSON.stringify({
            title: currentNews.title,
            description: currentNews.description,
            imageID: currentNews.imageId,
            data: editorData,
            usedFiles: usedFiles,
          })
        })
      }
    }
  }

  private handleSave(withMove: boolean = true){
    const currentNews = this.getState().currentNews
    const editorComponent = this.getState().editorComponent
    if(editorComponent)
    {
      const editorData = editorComponent.editor.getEditorValue()
      const usedFiles = editorComponent.getUsedFiles(editorData)

      this.reduce({
        type: NewsListResultActionTypes.CHANGE_IS_LOADING_AFK_MODAL,
        value: true
      })
      this.newsService.saveNews(currentNews.id,
        {
          title: currentNews.title,
          description: currentNews.description,
          imageID: currentNews.imageId,
          data: editorData,
          usedFiles: usedFiles,
        }
      ).subscribe({
        next: () => {
          if(withMove)
          {
            this.navigateBack()
          }
          this.reduce({
            type: NewsListResultActionTypes.CHANGE_IS_LOADING_AFK_MODAL,
            value: false
          })
        },
        error: () => {
          this.toastsService.createToast({
            title: 'Произошла ошибка при сохранении изменений',
            description: 'Попробуйте позднее',
            state: ToastState.ERROR
          })
          this.reduce({
            type: NewsListResultActionTypes.CHANGE_IS_LOADING_AFK_MODAL,
            value: false
          })
        }
      })
    }
  }

  private handleInit(){
    const snapshot = this.route.snapshot
    const id = snapshot.params['newsId']
    let editorType: EditorType = 'create'
    switch (snapshot.url[2].path){
      case 'edit':
        editorType = 'edit'
        break
      case 'create':
        editorType = 'create'
        break
    }
    this.newsService.getNewsEditorInfo(id).subscribe({
      next: editorInfo => {
        this.sessionService.startSession(editorInfo.longreadID).subscribe({
          next: (sessionDto) => {
            this.reduce({
              type: NewsListResultActionTypes.SAVE_SESSION,
              sessionId: sessionDto.sessionID,
              pingIntervalId: setInterval(() => this.sessionService.ping(sessionDto.sessionID).subscribe(), 300000)
            })
            this.editorChangesService.setTimer(() =>{
                this.getState().editorComponent?.editor.blur()
                this.reduce({
                  type: NewsListResultActionTypes.CHANGE_AFK_MODAL_VISIBILITY,
                  value: true,
                })
              },
              () => {
                this.editorChangesService.init()
                this.execute({
                  type: NewsEditorActionTypes.SAVE_AND_MOVE
                });
              }
            )

            this.reduce({
              type: NewsListResultActionTypes.INIT,
              newsId: id,
              loadedNews: editorInfo,
              editorType: editorType
            })
          },
          error: err =>{
            if(err instanceof HttpErrorResponse){
              if(err.status === 409){
                this.reduce({
                  type: NewsListResultActionTypes.CHANGE_ALREADY_EDIT_MODAL_VISIBILITY,
                  value: true,
                })
              } else {
                this.toastsService.createToast({
                  title: 'Не удалось начать сессию',
                  description: 'Попробуйте позднее',
                  state: ToastState.ERROR
                })
                this.newsNavigator.navigateToList()
              }
            }
          }
        })
      },
      error: err => {
        this.toastsService.createToast({
          title: 'Произошла ошибка при загрузке новости',
          description: 'Попробуйте позднее',
          state: ToastState.ERROR
        })
        this.newsNavigator.navigateToList()
      }
    })
  }

  private validateFields(){
    const currentNews = this.getState().currentNews
    this.reduce({
      type: NewsListResultActionTypes.CHANGE_TITLE,
      value: currentNews.title,
      error: this.titleValidator.validate(currentNews.title)
    })
    this.reduce({
      type: NewsListResultActionTypes.CHANGE_DESCRIPTION,
      value: currentNews.description,
      error: this.descriptionValidator.validate(currentNews.description)
    })
    this.getState().inputs?.toArray().forEach((input)=>{
      input.focused = false
    })
  }

  private navigateBack(){
    clearInterval(this.getState().pingIntervalId)
    if(this.getState().sessionId != ''){
      this.sessionService.closeSession(this.getState().sessionId).subscribe()
    }
    this.newsNavigator.navigateToList()
  }

  private isNewsUnChanged(): boolean{
    const initNews = this.getState().loadedNews
    const currentNews = this.getState().currentNews
    return !this.editorChangesService.editorChanged
      && initNews.title == currentNews.title
      && initNews.description == currentNews.description
      && initNews.imageID == currentNews.imageId
  }
}
