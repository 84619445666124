<div class="child-routes-size tiny-scrollbar relative"
  (scroll)="onScroll()"
  #scrollable
  *transloco="let t; read: AgilesMainConstants.TRANSLOCO_READ"
>
  <div
    class="px-[24px]"
  >
    <app-core-page-header
      [title]="t('agile-desks')"
      [navTabs]="navTabs"
      [tabsTransclocoRead]="AgilesMainConstants.TRANSLOCO_READ"
      navTabType="link"
      textSize="6xl"
    />
  </div>

  <div class="w-full">
    <router-outlet/>
  </div>

</div>
