<app-core-auth-container
  *transloco="let t; read: PasswordRecoveryConstants.TRANSLOCO_READ"
  [withBackButton]="false"
  [title]="t('title')"
>
  <app-core-auth-container-icon>
    <svg-reset-password-key/>
  </app-core-auth-container-icon>

  <div
    class="flex flex-col gap-[24px] mt-[8px]"
  >
    <p>{{ t('description') }}</p>

    <app-core-input-field
      inputVariant="standard"
      [label]="t('email-label')"
      [placeholder]="t('email-placeholder')"
      type="text"
      size="xl"
      [error]="emailError"
      [errorShowType]="'onUnFocused'"
      (onFocused)="focus()"
      (onChange)="onEmailChange.emit($event)"
    />

    <app-core-button
      size="l"
      className="w-full"
      [loading]="isLoading"
      (click)="onSubmit.emit()"
    >
      {{ t('submit-button') }}
    </app-core-button>
  </div>
</app-core-auth-container>

