import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-core-clue',
  templateUrl: './clue.component.html',
})
export class ClueComponent {
  @Input() className: string | string[] = [];
  @Input() direction: 'vertical' | 'horizontal' = 'vertical';
  @Input() title: string = '';
  @Input() step?: {
    current: number;
    total: number;
  };
  @Input() image: { url: string; alt: string } | null = {
    url: '/assets/modal-window/woman-with-coffee.png',
    alt: 'Woman with coffee',
  };
}
