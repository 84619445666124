import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  TaskExecutor
} from "../../../../../../agile-desk/modules/agiles-desk-main/presentation/components/task-item/task-item.component";
import {
  HighlightedPartsStr
} from "../../../../../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { stringToRgb } from "../../../../../../../../core/utils/string-to-rgb";
import { ActionSection } from "../../../../../../../../core/components/action-menu/action-menu.component";
import { AgilesListConstants } from "../../../common/agiles-list-constants";

@Component({
  selector: '[app-agiles-list-item]',
  templateUrl: './agiles-list-item.component.html',
  styleUrls: ['./agiles-list-item.component.scss']
})
export class AgilesListItemComponent {
  @Input({required: true}) agile!: AgilesListItemType
  @Input() showTag: boolean = true
  @Input() showAdmin: boolean = true

  @Output() onNameClick = new EventEmitter();
  @Output() onDelete = new EventEmitter()
  @Output() onEdit = new EventEmitter()

  protected readonly stringToRgb = stringToRgb;

  readonly actionMenuSections: ActionSection[] = [
    {
      elements: [
        {
          id: 'edit',
          name: 'edit'
        },
        {
          id: 'delete',
          name: 'delete'
        }
      ]
    }
  ]
  protected readonly AgilesListConstants = AgilesListConstants;
}

export type AgilesListItemType = {
  id: string
  name: string,
  nameParts: HighlightedPartsStr[]
  tag: string,
  tagParts: HighlightedPartsStr[]
  admin: TaskExecutor,
}
