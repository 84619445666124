export type SubscriptionFormAction =
  | ChangeUsersCountAction
  | ChangeAdditionalMemoryAction
  | ChangeTariffAction
  | ChangeDurationAction
  | CreateSubscriptionOrderAction

export enum SubscriptionFormActionTypes {
  CHANGE_TARIFF,
  CHANGE_DURATION,
  CHANGE_USERS_COUNT,
  CHANGE_ADDITIONAL_MEMORY,
  CREATE_SUBSCRIPTION_ORDER
}

export interface CreateSubscriptionOrderAction {
  readonly type: SubscriptionFormActionTypes.CREATE_SUBSCRIPTION_ORDER
}

export interface ChangeAdditionalMemoryAction {
  readonly type: SubscriptionFormActionTypes.CHANGE_ADDITIONAL_MEMORY
  readonly value: number
}

export interface ChangeUsersCountAction {
  readonly type: SubscriptionFormActionTypes.CHANGE_USERS_COUNT
  readonly value: number
}

export interface ChangeTariffAction {
  readonly type: SubscriptionFormActionTypes.CHANGE_TARIFF
  readonly tariffId: string
}

export interface ChangeDurationAction {
  readonly type: SubscriptionFormActionTypes.CHANGE_DURATION
  readonly durationId: string
}
