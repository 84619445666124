import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ToggleItem } from "../toggler/toggler.component";
import { TariffEntity } from "../../../domain/tariff-entity";
import { TariffDuration } from "../../../domain/tariff-duration";
import { getDiscountTranslate, getForDurationString } from "../../utils/convert-tariff-duration";
import { translate, TranslocoService } from "@jsverse/transloco";
import { PaymentAndTariffConstants } from "../../../common/payment-and-tariff-constants";

@Component({
  selector: "app-tariff-list",
  templateUrl: "./tariff-list.component.html",
  styleUrls: ["./tariff-list.component.scss"]
})
export class TariffListComponent implements OnChanges {
  @Input() selectedTariff?: TariffEntity;
  @Input() tariffs: TariffEntity[] = []
  @Input() durationItems: TariffDuration[] = []
  @Input() currentDuration: TariffDuration | null = null

  durationToggleItems: ToggleItem[] = []

  constructor(
    private translocoService: TranslocoService,
  ) {
    translocoService.langChanges$.subscribe(()=> this.convertTariffDurationToToggleItems())
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['durationItems']) {
      this.convertTariffDurationToToggleItems();
    }
  }

  convertTariffDurationToToggleItems()
  {
    this.durationToggleItems = [{
      id: '1',
      text: translate(PaymentAndTariffConstants.TRANSLOCO_READ + ".monthly-payment")
    }];

    if(this.durationItems.find((item, index) => item.countMonth < 12 && item.discountPercent > 0))
    {
      this.durationToggleItems.concat(this.durationItems
        .filter(item => item.countMonth < 12 && item.discountPercent > 0)
        .map(item => {
          return {
            id: item.countMonth.toString(),
            text: getForDurationString(item.countMonth),
            suffix: item.discountPercent ?
              getDiscountTranslate(item.discountPercent)
              : undefined,
          }
        })
      )
    }

    let countYears = 0
    let lastYearDiscount = 0
    this.durationItems.forEach(item => {
      if(item.countMonth >= 12){
        countYears++
        lastYearDiscount = item.discountPercent
      }
    })

    if(countYears > 1){
      this.durationToggleItems.concat(
        this.durationItems.filter(item => item.countMonth >= 12)
          .map(item => {
            return {
              id: item.countMonth.toString(),
              text: getForDurationString(item.countMonth),
              suffix: item.discountPercent ?
                getDiscountTranslate(item.discountPercent)
                : undefined,
            }
          })
      )
    } else if (countYears > 0) {
      this.durationToggleItems.push({
        id: '12',
        text: translate(PaymentAndTariffConstants.TRANSLOCO_READ + ".annual-payment"),
        suffix: lastYearDiscount ?
          getDiscountTranslate(lastYearDiscount)
          : undefined,
      })
    }
  }

  toggleTariffType(id: string){
    const findDuration = this.durationItems.find((item) => item.countMonth === parseInt(id))
    this.currentDuration = findDuration ? findDuration : this.currentDuration
  }

  protected readonly Math = Math;
}

