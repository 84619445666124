import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnDestroy,
  OnInit,
  Output,
  Renderer2
} from "@angular/core";
import { fromEvent, Subscription } from "rxjs";

@Directive({
  standalone: true,
  selector: "[appPopup]"
})
export class PopupDirective implements AfterViewInit, OnInit, OnDestroy{
  @Input() parentOffset: Offsets = {x: 0, y: 0}
  @Input() popupOffsetXSide: PopupOffsetXSide = 'right'
  @Input() popupOffsetYSide: PopupOffsetYSide = 'bottom'
  @Input() overflowSide: 'base' | 'bigger' = 'bigger'
  @Input() animateAppearance: boolean = true
  @Input() useParentWidth: boolean = false
  @Input() isScrollClose: boolean = true;
  @Input() canResizeWidth: boolean = false;
  @Output() popupClose = new EventEmitter()

  private parentElement: HTMLElement | null = null
  private thisElement: HTMLElement | null = null

  private initHeight: number = 0
  private initWidth: number = 0
  private currentOffsetXSide: 'left' | 'right' | 'center' = 'right'
  private currentOffsetYSide: 'top' | 'bottom' = 'bottom'

  private resizeObs?: Subscription

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.resizeObs = fromEvent(window, 'resize').subscribe(() => {
      this.onResize();
    });
  }

  ngOnDestroy() {
    if(this.resizeObs){
      this.resizeObs.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.thisElement = this.elementRef?.nativeElement as HTMLElement;
    this.parentElement = this.thisElement.parentElement
    this.initHeight = this.thisElement.offsetHeight
    this.initWidth = this.thisElement.offsetWidth

    this.renderer.setStyle(this.thisElement, 'position', 'fixed')
    if(this.animateAppearance)
    {
      this.renderer.addClass(this.thisElement, 'animate-popup_appearance')
    }
    this.onResize()

    this.renderer.setStyle(this.thisElement, 'transform-origin', this.currentOffsetXSide + ' ' + (this.currentOffsetYSide === 'top' ? 'bottom' : 'top'))
  }

  setPosition(){
    if(this.parentElement && this.thisElement)
    {
      const parentRect = this.parentElement.getBoundingClientRect()
      let offsets: Offsets = {x: 0, y: 0}
      offsets.x = this.getXOffset(parentRect)
      offsets.y = this.getYOffset(parentRect)

      this.renderer.setStyle(this.thisElement, 'z-index', 50)
      this.renderer.setStyle(this.thisElement, 'top', offsets.y + 'px')
      this.renderer.setStyle(this.thisElement, 'left', offsets.x + 'px')
    }
  }

  @HostListener("document:wheel", [])
  onScroll(){
    if(this.isScrollClose)
    {
      this.popupClose.emit()
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent){
    const element = event.target as HTMLElement
    if (this.parentElement != null && !this.parentElement.contains(element)) {
      this.popupClose.emit();
    }
  }

  private onResize() {
    if(this.useParentWidth && this.parentElement)
    {
      this.renderer.setStyle(this.thisElement, 'width', this.parentElement.offsetWidth + 'px')
    }
    this.setPosition()
  }

  private getXOffset(parentRect: DOMRect): number{
    switch (this.popupOffsetXSide){
      case 'left':
        return this.getXOffsetSideLeft(parentRect)
      case 'right':
        return this.getXOffsetSideRight(parentRect)
      case "center":
        this.currentOffsetXSide = 'center'
        return parentRect.left + parentRect.width / 2 - this.thisElement!.offsetWidth / 2 + this.parentOffset.x
    }
  }

  private getXOffsetSideLeft(parentRect: DOMRect){
    let result = parentRect.left + this.parentOffset.x
    const leftSpace = window.innerWidth - result
    const checkWidth = this.canResizeWidth ? this.initWidth : this.thisElement!.offsetWidth
    this.currentOffsetXSide = 'left'
    if (leftSpace < checkWidth ) {
      switch (this.overflowSide){
        case 'base':
          this.setNewWidth(leftSpace)
          break;
        case 'bigger':
          const rightSpace = parentRect.left + parentRect.width - this.parentOffset.x
          const rightOffset = rightSpace - checkWidth
          if(leftSpace > rightSpace){
            this.setNewWidth(leftSpace)
          }
          else{
            if(rightOffset >= checkWidth)
            {
              this.setInitWidth()
            }
            else {
              this.setNewWidth(rightOffset)
            }
            result = rightOffset
            this.currentOffsetXSide = 'right'
          }
          break;
      }
    } else if(checkWidth != this.thisElement!.offsetWidth){
      this.setInitWidth()
    }
    return result
  }

  private getXOffsetSideRight(parentRect: DOMRect){
    const rightSpace = parentRect.left + parentRect.width - this.parentOffset.x
    const checkWidth = this.canResizeWidth ? this.initWidth : this.thisElement!.offsetWidth
    let result = rightSpace - checkWidth
    this.currentOffsetXSide = 'right'
    if (result < 0) {
      switch (this.overflowSide){
        case 'base':
          if(result >= checkWidth)
          {
            this.setInitWidth()
          }
          else {
            this.setNewWidth(result)
          }
          break;
        case 'bigger':
          const leftOffset = parentRect.left + this.parentOffset.x
          const leftSpace = window.innerWidth - result
          if(leftSpace > rightSpace){
            this.setNewWidth(leftSpace)
            result = leftOffset
            this.currentOffsetXSide = 'left'
          }
          else{
            if(result >= checkWidth)
            {
              this.setInitWidth()
            }
            else {
              this.setNewWidth(result)
            }
          }
          break;
      }
    } else if(checkWidth != this.thisElement!.offsetWidth){
      this.setInitWidth()
    }
    return result
  }

  private setNewWidth(width: number){
    if(this.thisElement && !this.useParentWidth && this.canResizeWidth)
    {
      this.renderer.setStyle(this.thisElement, 'width', width + 'px', 1)
    }
  }

  private setInitWidth(){
    if(this.thisElement && !this.useParentWidth && this.canResizeWidth)
    {
      this.renderer.setStyle(this.thisElement, 'width', this.initWidth + 'px', 1)
    }
  }

  private getYOffset(parentRect: DOMRect): number {
    switch (this.popupOffsetYSide) {
      case 'top':
        return this.getYOffsetSideTop(parentRect)
      case "bottom":
        return this.getYOffsetSideBottom(parentRect)
    }
  }

  private getYOffsetSideTop(parentRect: DOMRect): number
  {
    if(this.thisElement)
    {
      let result = parentRect.top - this.parentOffset.y - this.initHeight
      this.currentOffsetYSide = 'top'
      if (result < 0) {
        switch (this.overflowSide){
          case 'base':
            this.setNewHeight(result)
            result = 0
            break;
          case 'bigger':
            const bottomOffset = parentRect.top + parentRect.height + this.parentOffset.y
            const bottomHeightDelta = window.innerHeight - bottomOffset - this.initHeight
            if(result > bottomHeightDelta){
              this.setNewHeight(result)
              result = 0
            }
            else{
              if(bottomHeightDelta < 0){
                this.setNewHeight(bottomHeightDelta)
              }
              else {
                this.setInitHeight()
              }
              this.currentOffsetYSide = 'bottom'
              result = bottomOffset
            }
            break;
        }
      }
      else if(this.initHeight != this.thisElement.offsetHeight){
        this.setInitHeight()
        this.currentOffsetYSide = 'bottom'
      }
      return result
    }
    return 0
  }

  private getYOffsetSideBottom(parentRect: DOMRect): number
  {
    if(this.thisElement)
    {
      let result = parentRect.top + parentRect.height + this.parentOffset.y
      let deltaHeight = window.innerHeight - result - this.initHeight
      this.currentOffsetYSide = 'bottom'

      if (deltaHeight < 0) {
        switch (this.overflowSide){
          case 'base':
            this.setNewHeight(deltaHeight)
            break;
          case 'bigger':
            const topHeightDelta = parentRect.top - this.parentOffset.y - this.initHeight
            if(deltaHeight > topHeightDelta){
              this.setNewHeight(deltaHeight)
            }
            else{
              this.currentOffsetYSide = 'top'
              if(topHeightDelta < 0)
              {
                this.setNewHeight(topHeightDelta)
                result = 0
              }
              else{
                if(this.initHeight != this.thisElement.offsetHeight){
                  this.setInitHeight()
                }
                result = topHeightDelta
              }
            }
            break;
        }
      }
      return result
    }
    return 0
  }

  private setNewHeight(deltaHeight: number){
    if(this.thisElement)
    {
      const newHeight = this.initHeight + deltaHeight
      this.renderer.setStyle(this.thisElement, 'height', newHeight + 'px')
    }
  }

  private setInitHeight(){
    if(this.thisElement)
    {
      this.renderer.setStyle(this.thisElement, 'height', this.initHeight + 'px')
    }
  }
}

export interface Offsets{
  x: number,
  y: number
}

export type PopupOffsetYSide = 'top' | 'bottom'
export type PopupOffsetXSide = 'left' | 'right' | 'center'
