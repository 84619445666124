import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { OrderEntity } from "../../../domain/order-entity";
import { getFormatNumberString } from "../../../../../../../core/utils/get-format-number-string";
import { InvoiceStatus, PaymentType } from "../../../../../data/dto/invoices-list-dto";
import { dateToDMonthYString } from "../../../../../../../core/utils/dateToDMonthYString";
import { DatePipe } from "@angular/common";
import { OrdersConstants } from "../../../common/orders-constants";
import { translate } from "@jsverse/transloco";
import { PaymentAndTariffConstants } from "../../../../payment-and-tariff/common/payment-and-tariff-constants";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: [
    "./order.component.scss",
    "../../../../../common/settings.scss"
  ]
})
export class OrderComponent {
  @Input({required: true}) order!: OrderEntity
  @Input() isOpen: boolean = false

  @Output() onPay = new EventEmitter()
  @Output() onCancel = new EventEmitter()

  constructor(private datePipe: DatePipe) {
  }

  getCreatedAtString(): string{
    return dateToDMonthYString(this.order.createdAt, this.datePipe)
  }

  translateAmount(amount: string){
    return translate(OrdersConstants.RECEIPT_TRANSLOCO_READ + '.total-to-pay', {price:amount})
  }

  protected readonly getFormatNumberString = getFormatNumberString;
  protected readonly InvoiceStatus = InvoiceStatus;
  protected readonly PaymentType = PaymentType;
  protected readonly dateToDMonthYString = dateToDMonthYString;
  protected readonly OrdersConstants = OrdersConstants;
}
