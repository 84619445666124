<ng-container
  *transloco="let t; read: AgileDeskSettingsConstants.TRANSLOCO_READ"
>
  <div
    class="drag-list"
    [ngClass]="{
    'pb-[1px]': statusList.length > 0
  }"
    cdkDropList
    [cdkDropListData]="statusList"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      *ngFor="let status of statusList; let i = index"
      class="drag-box group/triple-dot"
      cdkDrag
      [cdkDragData]="status"
    >
      <div *cdkDragPlaceholder class="drag-placeholder"></div>
      <div class="flex justify-between gap-[16px] items-center h-full">
        <div class="flex items-center">
          <svg-status-drag-handle
            cdkDragHandle
            class="cursor-move"
          />
          <p
            class="text-tt-font-size_l text-tt-text-main size-[24px] flex justify-center items-center"
          >{{i + 1}}</p>
          <p class="ml-[16px] text-tt-font-size_l text-tt-text-main line-clamp-1">{{status.name}}</p>
          <app-core-tag class="ml-[8px]" *ngIf="status.isCompleted">
            {{ t('completed') }}
          </app-core-tag>
          <app-core-tag class="ml-[8px]" *ngIf="!status.isCompleted && !status.isDeletable">
            {{ t('initial-status') }}
          </app-core-tag>
        </div>

        <app-core-triple-dot-action-menu
          [translocoRead]="AgileDeskSettingsConstants.TRANSLOCO_READ"
          [sections]="getActionMenu(status.isDeletable)"
          (onElementClick)="onActionMenuClicked($event, status)"
        >
        </app-core-triple-dot-action-menu>

      </div>
    </div>
  </div>
  <div
    class="w-full h-[48px] px-[8px] flex items-center"
  >
    <app-core-button-text
      (onClick)="isOpenAddModal = true"
    >
      {{ t('add-status') }}
    </app-core-button-text>
  </div>


  <app-core-alert-modal
    *ngIf="isOpenAddModal"
    [title]="t('create-status')"
    [confirmButtonText]="t('create')"
    [confirmDisabled]="statusTitleError !== null"
    [cancelButtonText]="t('cancel')"
    (onCancelClicked)="closeModal()"
    (onConfirmClicked)="addStatus()"
  >
    <div
      class="px-[2px]"
    >
      <app-core-input-field
        inputVariant="standard"
        [label]="t('status-name-label')"
        [charsCounterMax]="40"
        [error]="statusTitleError"
        errorShowType="onUnFocused"
        (onChange)="changeStatusTitle($event)"
      />
    </div>

    <div
      class="flex w-full justify-between mt-[16px]"
    >
      <p class="text-tt-text-main text-tt-font-size_l">
        {{ t('complete-task-status') }}
      </p>
      <app-core-switcher
        [value]="isCompleted"
        (onSwitch)="isCompleted = !isCompleted"
      />
    </div>
  </app-core-alert-modal>

  <app-core-alert-modal
    *ngIf="isOpenEditModal && statusToEdit"
    [title]="t('edit-status')"
    [confirmButtonText]="t('save')"
    [cancelButtonText]="t('cancel')"
    (onCancelClicked)="closeModal()"
    (onConfirmClicked)="changeStatus()"
  >
    <div
      class="px-[2px]"
    >
      <app-core-input-field
        inputVariant="standard"
        [label]="t('status-name-label')"
        [charsCounterMax]="40"
        [value]="statusToEdit.name"
        [defaultValue]="statusToEdit.name"
        [error]="statusTitleError"
        errorShowType="onUnFocused"
        (onChange)="changeStatusTitle($event)"
      />
    </div>

    <div
      class="flex w-full justify-between mt-[16px]"
    >
      <p class="text-tt-text-main text-tt-font-size_l">
        {{ t('complete-task-status') }}
      </p>
      <app-core-switcher
        [isDisabled]="!statusToEdit.isDeletable"
        [value]="statusToEdit.isCompleted"
        (onSwitch)="statusToEdit.isCompleted = !statusToEdit.isCompleted"
      />
    </div>
  </app-core-alert-modal>

  <app-core-alert-modal
    *ngIf="isOpenDeleteModal && statusToDelete"
    type="warning"
    [title]="t('delete-status')"
    [confirmButtonText]="t('delete')"
    [cancelButtonText]="t('cancel')"
    [confirmDisabled]="!selectedAutocompleteItem"
    (onCancelClicked)="closeModal()"
    (onConfirmClicked)="onDelete()"
  >
    <p
      [textParse]="t('delete-status-description', { statusName: statusToDelete.name })"
      [parseElements]="[{
        text: statusToDelete.name,
        type: 'text',
        className: 'font-medium'
      }]"
    ></p>

    <app-core-autocomplete-field
      class="mt-[16px] block"
      variant="standard"
      [label]="t('select-new-status-label')"
      [placeholder]="t('select-new-status-placeholder')"
      [items]="statusAutocompleteItems"
      [selectedItem]="selectedAutocompleteItem"
      (onSelect)="onSelectAutocomplete($event)"
    />

  </app-core-alert-modal>

</ng-container>
