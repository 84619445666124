import {
  Elements,
  YooEditor,
  YooptaBlockData
} from "@yoopta/editor";
import React, { useEffect, useRef } from "react";
import { EmojiCalloutType } from "../types/emoji-callout-type";
import calloutTypeImage from "../utils/callout-type-image";
import { useReadOnly } from "slate-react";

type Props = {
  editor: YooEditor;
  block: YooptaBlockData;
  props: EmojiCalloutType;
};

function EmojiCalloutSelectType ({ editor, block, props }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const componentRef: any = useRef(null);
  const isReadOnly = useReadOnly()

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [componentRef]);

  const onChangeType = (type: EmojiCalloutType) => {
    Elements.updateElement(editor, block.id, { type: 'emojiCallout', props: { type: type } });
  };

  const buttonsClassName = "w-[36px] h-[36px] flex items-center justify-center rounded-[8px] hover:bg-tt-button-hover";

  return (
    <div
      className={'absolute left-[16px] user-select-none'}
      ref={componentRef}
      contentEditable={false}
    >
      <button
        onClick={()=> {
          if(!isReadOnly){
            editor.blur()
            setIsOpen(!isOpen)
          }
        }}
        style={{
          cursor: isReadOnly ? "default" : "pointer"
        }}
      >
        {calloutTypeImage(props)}
      </button>

      {
        isOpen &&
        <div
          className={"absolute rounded-[12px] border border-tt-border-default px-[8px] py-[6px] bg-white flex gap-[4px]"}
          style={{
            top: -76 + "px",
            transform: "translateX(50%)",
            boxShadow: '0px 1px 4px 0px #00000040',
          }}
        >
          <button
            className={buttonsClassName}
            contentEditable={false}
            onClick={() => {
              onChangeType("info")
            }}
          >
            <img src={'assets/editor/callout/info.svg'} alt={"info"} width={24} height={24} />
          </button>

          <button
            className={buttonsClassName}
            contentEditable={false}
            onClick={() => {
              onChangeType("question")
            }}
          >
            <img src={'assets/editor/callout/question-mark.svg'} alt={"question-mark"} width={24} height={24} />
          </button>

          <button
            className={buttonsClassName}
            contentEditable={false}
            onClick={() => {
              onChangeType("warning")
            }}
          >
            <img src={'assets/editor/callout/warning.svg'} alt={"warning"} width={24} height={24} />
          </button>

          <button
            className={buttonsClassName}
            contentEditable={false}
            onClick={() => {
              onChangeType("success")
            }}
          >
            <img src={'assets/editor/callout/success.svg'} alt={"success"} width={24} height={24} />
          </button>

          <button
            className={buttonsClassName}
            contentEditable={false}
            onClick={() => {
              onChangeType("error")
            }}
          >
            <img src={'assets/editor/callout/error.svg'} alt={"error"} width={24} height={24} />
          </button>

          <button
            className={buttonsClassName}
            contentEditable={false}
            onClick={() => {
              onChangeType("plus")
            }}
          >
            <img src={'assets/editor/callout/plus.svg'} alt={"plus"} width={24} height={24} />
          </button>

          <button
            className={buttonsClassName}
            contentEditable={false}
            onClick={() => {
              onChangeType("minus")
            }}
          >
            <img src={'assets/editor/callout/minus.svg'} alt={"minus"} width={24} height={24} />
          </button>

          <button
            className={buttonsClassName}
            contentEditable={false}
            onClick={() => {
              onChangeType("none")
            }}
          >
            <img src={'assets/editor/callout/none.svg'} alt={"none"} width={24} height={24} />
          </button>
        </div>
      }

    </div>

  );
}

export default EmojiCalloutSelectType;
