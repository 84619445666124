import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationNavigator {
  constructor(private router: Router) {}

  openMainPage(skipLocationChange: boolean = false) {
    window.location.replace('/knowledge-base');
  }

  openLogin(withoutStoreUrl: boolean = false) {
    if(withoutStoreUrl){
      window.location.replace('/login');
    }
    else{
      this.router.navigate(['/login'], );
    }
  }
}
