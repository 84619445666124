import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { MainComponent } from './presentation/main.component';
import { NavigationSectionComponent } from './presentation/components/navigation-section/navigation-section.component';
import {
  SvgArrow,
  SvgBreadCrumbsArrow,
  SvgHeaderArrow,
  SvgTeamTellsLogo, SvgTripleDot,
  SvgXMark
} from "../../core/components/svg-components/svg.components";
import { NavigationElementComponent } from './presentation/components/navigation-element/navigation-element.component';
import { MainHeaderComponent } from './presentation/components/main-header/main-header.component';
import { EmployeeService } from '../employees/data/employee-service';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RemainingSubscriptionModalComponent } from './presentation/components/remaining-subscription-modal/remaining-subscription-modal.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { HeaderActionButtonComponent } from './presentation/components/header-action-button/header-action-button.component';
import { PageShadingComponent } from "../../core/components/page-shading/page-shading.component";
import { AvatarComponent } from "../../core/components/avatar/avatar.component";
import { PopupDirective } from "../../core/directives/popup.directive";
import {
  SvgChangePassword,
  SvgExit,
  SvgNavKnowledgeBase,
  SvgNavMyTeam,
  SvgNavNews, SvgNavSettings
} from "./presentation/icons/main-icons";
import { TranslocoDirective, TranslocoPipe } from "@jsverse/transloco";
import { TextParserComponent } from "../../core/components/text-parser/text-parser.component";

@NgModule({
  declarations: [
    MainComponent,
    NavigationSectionComponent,
    NavigationElementComponent,
    MainHeaderComponent,
    RemainingSubscriptionModalComponent,
    HeaderActionButtonComponent,
    SvgChangePassword,
    SvgExit,
    SvgNavKnowledgeBase,
    SvgNavMyTeam,
    SvgNavNews,
    SvgNavSettings,
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    SvgArrow,
    SvgHeaderArrow,
    SvgBreadCrumbsArrow,
    SvgXMark,
    SvgTripleDot,
    MatProgressSpinnerModule,
    MatTooltipModule,
    SvgTeamTellsLogo,
    PageShadingComponent,
    AvatarComponent,
    PopupDirective,
    TranslocoPipe,
    TranslocoDirective,
    TextParserComponent
  ],
  providers: [
    EmployeeService,
  ]
})
export class MainModule {}
