<app-form-modal
  [isLoading]="state.isLoading"
  (onPayClicked)="performAction({
    type: TariffFormActionTypes.CREATE_ORDER
  })"
  *transloco="let t; read: TariffFormConstants.TRANSLOCO_READ"
>
  <div
    class="flex flex-col gap-[16px]"
  >
    <div
      class="form-block"
    >
      <div class="flex justify-between items-center gap-[10px]">
        <p class="form-block-title">{{ t('tariff-extension') }}</p>
        <app-core-switcher
          [value]="state.isDurationOpen"
          (onSwitch)="performAction({
            type: TariffFormActionTypes.CHANGE_IS_DURATION_OPEN,
            value: !state.isDurationOpen
          })"
        />
      </div>

      <app-core-dropdown-field
        *ngIf="state.isDurationOpen"
        [label]="t('subscription-duration-label')"
        variant="standard"
        [selectedItem]="state.currentDuration"
        [items]="state.durationDropDownItems"
        (onSelect)="performAction({
          type: TariffFormActionTypes.CHANGE_DURATION,
          durationId: $event
        })"
      />
    </div>

    <div
      class="form-block"
    >
      <div
        class="flex justify-between items-center gap-[10px]"
      >
        <p class="form-block-title">{{ t('tariff-expansion') }}</p>
        <app-core-switcher
          [value]="state.isExpansionOpen"
          (onSwitch)="performAction({
            type: TariffFormActionTypes.CHANGE_IS_EXTENSIONS_OPEN,
            value: !state.isExpansionOpen
          })"
        />
      </div>

      <div
        class="flex justify-between items-center"
        *ngIf="state.isExpansionOpen"
      >
        <p class="text-tt-text-main text-tt-font-size_l">{{ t('additional-users') }}</p>
        <app-input-number
          [value]="state.usersCount"
          (onChange)="performAction({
            type: TariffFormActionTypes.CHANGE_USERS_COUNT,
            value: $event
          })"
        />
      </div>

      <div
        class="flex justify-between items-center"
        *ngIf="state.isExpansionOpen"
      >
        <p class="text-tt-text-main text-tt-font-size_l">{{ t('disk-space') }}</p>
        <app-input-number
          [value]="state.memoryCount"
          (onChange)="performAction({
            type: TariffFormActionTypes.CHANGE_ADDITIONAL_MEMORY,
            value: $event
          })"
        />
      </div>
    </div>
  </div>
</app-form-modal>
