import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  PasswordResetResultAction,
  PasswordResetResultActionTypes
} from "./password-reset-result-action";
import { PasswordResetState } from './password-reset-state';

@Injectable()
export class PasswordResetReducer
  implements Reducer<PasswordResetState, PasswordResetResultAction>
{
  reduce(state: PasswordResetState, action: PasswordResetResultAction): PasswordResetState {
    switch (action.type) {
      case PasswordResetResultActionTypes.CHANGE_CURRENT_PASSWORD:
        return {...state,
          currentPassword: action.value,
          currentPasswordError: action.error,
        }
      case PasswordResetResultActionTypes.CHANGE_NEW_CONFIRM_PASSWORD:
        return {...state,
          newConfirmPassword: action.value,
          newConfirmPasswordError: action.error,
        }
      case PasswordResetResultActionTypes.CHANGE_NEW_PASSWORD:
        return {...state,
          newPassword: action.value,
          newPasswordError: action.error,
        }
      case PasswordResetResultActionTypes.CHANGE_STAGE:
        return {...state, currentStage: action.newStage}
      case PasswordResetResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value}
      case PasswordResetResultActionTypes.RECOVERY_INIT:
        return {...state,
          recoverySalt: action.salt,
          recoveryToken: action.token
        }
    }
  }
}
