import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  SvgResetPasswordError, SvgResetPasswordSuccess
} from "src/app/core/components/svg-components/svg.components";
import { EmptyRule, MaxLengthRule } from 'src/app/core/validators/rule';
import { Validator } from 'src/app/core/validators/validator';
import {
  emailValidatorFactory,
  phoneNumberValidatorFactory,
} from 'src/app/core/validators/validators';
import { RegistrationComponent } from './presentation/view/registration.component';
import { RegistrationServiceImpl } from './data/registration-service-impl';
import { RegistrationService } from './domain/registration-service';
import { RegistrationFormComponent } from "./presentation/components/registration-form/registration-form.component";
import { RegistrationCompleteComponent } from './presentation/components/registration-complete/registration-complete.component';
import { RouterLink } from "@angular/router";
import { RegistrationErrorComponent } from './presentation/components/registration-error/registration-error.component';
import { AuthLayoutComponent } from "../../core/components/auth/auth-layout/auth-layout.component";
import { ButtonComponent } from "../../core/components/buttons/button/button.component";
import {
  AuthContainerIconComponent
} from "../../core/components/auth/auth-container/auth-container-icon/auth-container-icon.component";
import { AuthContainerComponent } from "../../core/components/auth/auth-container/auth-container.component";
import { FieldsModule } from "../../core/components/fields/fields.module";
import { SvgRegistrationIcon } from "./presentation/icons/registration-icons";
import { provideTranslocoScope, TranslocoModule } from "@jsverse/transloco";
import { RegistrationConstants } from "./common/registration-constants";

@NgModule({
  declarations: [
    RegistrationComponent,
    RegistrationFormComponent,
    RegistrationFormComponent,
    RegistrationCompleteComponent,
    RegistrationErrorComponent,
    SvgRegistrationIcon
  ],
  imports: [
    CommonModule,
    SvgResetPasswordSuccess,
    RouterLink,
    SvgResetPasswordError,
    AuthLayoutComponent,
    ButtonComponent,
    AuthContainerIconComponent,
    AuthContainerComponent,
    FieldsModule,
    TranslocoModule
  ],
  providers: [
    {
      provide: "RegistrationEmailValidator",
      useExisting: Validator,
      useFactory: emailValidatorFactory
    },
    {
      provide: "RegistrationNameValidator",
      useExisting: Validator,
      useFactory: RegistrationModule.nameValidatorFactory
    },
    {
      provide: "RegistrationSurnameValidator",
      useExisting: Validator,
      useFactory: RegistrationModule.surnameValidatorFactory
    },
    {
      provide: "RegistrationPatronymicValidator",
      useExisting: Validator,
      useFactory: RegistrationModule.patronymicValidatorFactory
    },
    {
      provide: "RegistrationPhoneNumberValidator",
      useExisting: Validator,
      useFactory: phoneNumberValidatorFactory
    },
    {
      provide: RegistrationService,
      useClass: RegistrationServiceImpl
    }
  ],
  exports: [
    RegistrationErrorComponent
  ]
})
export class RegistrationModule {
  public static nameValidatorFactory = () =>
    new Validator([
      new EmptyRule('name-error-empty'),
      new MaxLengthRule('name-error-max-length', RegistrationConstants.INPUTS_MAX_LENGTH),
    ]);

  public static surnameValidatorFactory = () =>
    new Validator([
      new EmptyRule('surname-error-empty'),
      new MaxLengthRule('surname-error-max-length', RegistrationConstants.INPUTS_MAX_LENGTH),
    ]);

  public static patronymicValidatorFactory = () =>
    new Validator([
      new MaxLengthRule('Отчество не может быть больше 50 символов', RegistrationConstants.INPUTS_MAX_LENGTH),
    ]);
}
