<app-core-modal
  [open]="isVisible"
  [isLoading]="state.isCreating"
  className="max-h-[700px] h-full w-full max-w-[940px] flex flex-row relative"
  (onClose)="close()"
  *transloco="let t; read: CreateRoleModalConstants.TRANSLOCO_READ"
>
  <app-core-clue
    [step]="{
      current: state.stepViewState.stepNumber,
      total: 2
    }"
    [title]="t(state.stepViewState.clueTitle)"
    class="flex-shrink-0"
  >
    {{ t(state.stepViewState.clueText) }}
  </app-core-clue>

  <app-core-modal-content
    class="flex-grow"
    [footer]="true"
    [parentPaddings]="true"
    [withNavTabs]="true"
    [tabsTranslocoRead]="CreateRoleModalConstants.TRANSLOCO_READ"
    [tabs]="NAV_ITEMS"
    [selectedTabId]="state.stepViewState.selectedNavItem"
    (tabClicked)="performAction({
      type: CreateRoleModalActionTypes.CHANGE_NAV_ITEM,
      id: $event,
      isCheckCurrent: false
    })"
  >
    <div class="px-[2px] h-full">
      <div class="flex justify-center items-center p-[24px]"
           *ngIf="state.isLoading"
      >
        <mat-spinner class="w-[48px] h-[48px]"></mat-spinner>
      </div>
      <div
        class="mt-[16px] h-full"
        *ngIf="!state.isLoading"
      >
        <div
          *ngIf="state.stepViewState.stepNumber == 1"
          class="flex h-full flex-col gap-[16px] pb-[16px]"
        >
          <app-core-input-field
            [label]="t('name-label')"
            inputVariant="standard"
            placeholder=""
            error="{{ state.roleNameError }}"
            [defaultValue]="state.roleName"
            [value]="state.roleName"
            [autoFocus]="true"
            [required]="true"
            [charsCounterMax]="128"
            (onChange)="performAction({
              type: CreateRoleModalActionTypes.CHANGE_ROLE_NAME,
              value: $event
            })"
          />

          <app-core-textarea-field
            [label]="t('description-label')"
            variant="standard"
            placeholder=""
            error="{{ state.roleDescriptionError }}"
            [defaultValue]="state.roleDescription"
            [value]="state.roleDescription"
            [required]="true"
            [charsCounterMax]="180"
            [height]="92"
            [isResizable]="false"
            (onChange)="performAction({
              type: CreateRoleModalActionTypes.CHANGE_ROLE_DESCRIPTION,
              value: $event
            })"
          />
        </div>

        <app-core-permissions-form
          *ngIf="state.stepViewState.stepNumber == 2"
          [permissions]="state.permissions"
        />

      </div>
    </div>

    <app-core-modal-content-footer>
      <app-core-button
        (onClick)="close()"
        variant="soft"
        *ngIf="state.stepViewState.stepNumber == 1"
      >
        {{ t('cancel') }}
      </app-core-button>

      <app-core-button
        (onClick)="performAction({
          type: CreateRoleModalActionTypes.CHANGE_NAV_ITEM,
          id: getNavItemFromStep(state.stepViewState.stepNumber - 1),
          isCheckCurrent: false
        })"
        variant="soft"
        *ngIf="state.stepViewState.stepNumber != 1"
      >
        {{ t('back') }}
      </app-core-button>

      <app-core-button
        (onClick)="performAction({
          type: CreateRoleModalActionTypes.CHANGE_NAV_ITEM,
          id: getNavItemFromStep(state.stepViewState.stepNumber + 1),
          isCheckCurrent: true
        })"
        *ngIf="state.stepViewState.stepNumber != 2"
      >
        {{ t('continue') }}
      </app-core-button>

      <app-core-button
        *ngIf="state.stepViewState.stepNumber == 2"
        (onClick)="performAction({
          type: CreateRoleModalActionTypes.SAVE_ROLE
        })"
        [disabled]="!state.isValid"
      >
        {{ t('save') }}
      </app-core-button>
    </app-core-modal-content-footer>
  </app-core-modal-content>
</app-core-modal>
