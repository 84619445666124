import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthorizationModule } from './features/authorization/authorization.module';
import { AuthServiceImpl } from './features/authorization/data/auth-service-impl.service';
import { AuthInterceptor } from './features/authorization/data/interceptors/auth-interceptor';
import { AuthService } from './features/authorization/domain/auth.service';
import { EmployeesModule } from './features/employees/employees.module';
import { MainModule } from './features/main/main.module';
import { RegistrationModule } from './features/registration/registration.module';
import { DocumentModule } from "./features/document/impl/document.module";
import { KnowledgeBaseModule } from './features/knowledge-base/knowledge-base.module';
import { SettingsModule } from './features/settings/settings.module';
import { RefreshTokenInterceptor } from './features/authorization/data/interceptors/refresh-token-interceptor';
import { NewsModule } from "./features/news/news.module";
import { CookieService } from "ngx-cookie-service";
import { AgileDeskModule } from "./features/agiles/agile-desk/agile-desk.module";
import { AgilesMainModule } from "./features/agiles/agiles-main/agiles-main.module";
import { PasswordRecoveryModule } from "./features/password-recovery/password-recovery.module";
import { PasswordResetModule } from "./features/password-reset/password-reset.module";
import { DatePipe } from "@angular/common";

import { registerLocaleData } from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import { ToastComponent } from "./core/components/toast-alert/toast-alert.component";
import { TranslocoRootModule } from './transloco-root.module';
import { ButtonComponent } from "./core/components/buttons/button/button.component";
import { UserCardService } from "./core/components/user-card/service/user-card-service";
import { UserCardServiceImpl } from "./core/components/user-card/service/user-card-service-impl";

registerLocaleData(localeRu);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthorizationModule,
    EmployeesModule,
    MainModule,
    RegistrationModule,
    PasswordRecoveryModule,
    DocumentModule,
    KnowledgeBaseModule,
    SettingsModule,
    NewsModule,
    AgileDeskModule,
    AgilesMainModule,
    PasswordResetModule,
    ToastComponent,
    HttpClientModule,
    TranslocoRootModule,
    ButtonComponent
  ],
  providers: [
    {
      provide: AuthService,
      useClass: AuthServiceImpl
    },
    {
      provide: UserCardService,
      useClass: UserCardServiceImpl
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    CookieService,
    DatePipe,
    {provide: LOCALE_ID, useValue: 'ru-RU' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
