import { Elements, useYooptaEditor } from '@yoopta/editor';
import { ChangeEvent, useState } from 'react';
import { ImageElementProps } from "@yoopta/image";
import { onImageUrlUpload } from "../utils/on-image-url-upload";
import { useInjected } from "@bubblydoo/angular-react";
import { Editor2ServiceImpl } from "../../../../data/editor-service-impl";
import { YooptaEditorConstants } from "../../../../common/yoopta-editor-constants";
import { translate } from "@jsverse/transloco";

const EmbedUploader = ({ blockId, onClose, onSetLoading }: any) => {
  const editor = useYooptaEditor();
  const [value, setValue] = useState('');
  const editorService = useInjected(Editor2ServiceImpl)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  const embed = async () => {
    if (value.length === 0) return;
    onSetLoading(true)
    onClose();

    try {
      const data = await onImageUrlUpload(value, editorService);
      const defaultImageProps = editor.plugins["Image"].elements["image"].props as ImageElementProps;

      Elements.updateElement<'image', ImageElementProps>(editor, blockId, {
        type: 'image',
        props: {
          src: data.src,
          alt: data.alt,
          sizes: data.sizes || defaultImageProps.sizes,
          bgColor: data.bgColor || defaultImageProps.bgColor,
          fit: data.fit || defaultImageProps.fit || 'fill',
        },
      });
    } catch (error) {
    } finally {
      onSetLoading(false);
    }

  };

  const isEmpty = value.length === 0;

  return (
    <div className="yoopta-uploader-embed-box user-select-none">
      <input
        type="text"
        placeholder={translate(YooptaEditorConstants.TRANSLOCO_READ + ".image-link-placeholder")}
        value={value}
        className="yoopta-uploader-embed-input"
        onChange={onChange}
      />
      <button
        type="button"
        className="yoopta-button user-select-none yoopta-uploader-embed-button"
        disabled={isEmpty}
        onClick={embed}
      >
        {translate(YooptaEditorConstants.TRANSLOCO_READ + '.add')}
      </button>
    </div>
  );
};

export { EmbedUploader };
