import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'svg-arrow',
  templateUrl: './svg/arrow.svg',
})
export class SvgArrow {}

@Component({
  standalone: true,
  selector: 'svg-plus',
  templateUrl: './svg/plus.svg',
})
export class SvgPlus {}

@Component({
  standalone: true,
  selector: 'svg-triple-dot',
  templateUrl: './svg/triple-dot.svg',
})
export class SvgTripleDot {}

@Component({
  standalone: true,
  selector: 'svg-x-mark',
  templateUrl: './svg/xmark.svg',
})
export class SvgXMark {}

@Component({
  standalone: true,
  selector: 'svg-link',
  templateUrl: './svg/link.svg',
})
export class SvgLink {}

@Component({
  standalone: true,
  selector: 'svg-header-arrow',
  templateUrl: './svg/header-arrow.svg'
})
export class SvgHeaderArrow {}

@Component({
  standalone: true,
  selector: 'svg-breadcrumbs-arrow',
  templateUrl: './svg/breadcrumbs-arrow.svg'
})
export class SvgBreadCrumbsArrow {}

@Component({
  standalone: true,
  selector: 'svg-settings-home',
  templateUrl: './svg/settings-home.svg'
})
export class SpaceSettingsHome {}

@Component({
  standalone: true,
  selector: 'svg-person',
  templateUrl: './svg/settings-person.svg'
})
export class SvgPerson {}

@Component({
  standalone: true,
  selector: 'svg-back-arrow',
  templateUrl: './svg/back-arrow.svg',
})
export class SvgArrowBack {}

@Component({
  standalone: true,
  selector: 'svg-trash',
  templateUrl: './svg/trash.svg',
})
export class SvgTrash {}

@Component({
  standalone: true,
  selector: 'svg-minus',
  templateUrl: './svg/minus.svg',
})
export class SvgMinus {}

@Component({
  standalone: true,
  selector: 'svg-team-tells-logo',
  templateUrl: './svg/team-tells-logo.svg',
})
export class SvgTeamTellsLogo {}

@Component({
  standalone: true,
  selector: 'svg-reset-password-key',
  templateUrl: './svg/reset-password-key.svg',
})
export class SvgResetPasswordKey {}

@Component({
  standalone: true,
  selector: 'svg-reset-password-success',
  templateUrl: './svg/reset-password-success-icon.svg',
})
export class SvgResetPasswordSuccess {}

@Component({
  standalone: true,
  selector: 'svg-reset-password-error',
  templateUrl: './svg/reset-password-error.svg',
})
export class SvgResetPasswordError {}
