<div
  class="flex flex-col gap-[9px]"
  *transloco="let t; read: TRANSLOCO_READ"
>
  <div class="permission-block">
    <div class="permission-title-block">
      <p class="permission-title-text">{{ t('employees-title') }}</p>
    </div>
    <div class="pl-[16px] permission-block">
      <div class="permission-title-block">
        <p class="permission-text">{{ t('employees-read') }}</p>
        <app-core-checkbox-field
          [value]="permissions.company.viewEmployee"
          [inactive]='true'
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">{{ t('employees-create-edit') }}</p>
        <app-core-checkbox-field
          [value]="permissions.company.createEmployee"
          (onToggle)="createEditEmployeeToggle()"
          [inactive]="isReadOnly"
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">{{ t('employees-delete') }}</p>
        <app-core-checkbox-field
          [value]="permissions.company.deleteEmployee"
          (onToggle)="permissions.company.deleteEmployee = !permissions.company.deleteEmployee"
          [inactive]="isReadOnly"
        />
      </div>
    </div>
  </div>

  <div class="permission-block">
    <div class="permission-title-block">
      <p class="permission-title-text">{{ t('roles-title') }}</p>
    </div>
    <div class="pl-[16px] permission-block">
      <div class="permission-title-block">
        <p class="permission-text">{{ t('roles-read') }}</p>
        <app-core-checkbox-field
          [value]="permissions.role.viewRole"
          [inactive]='true'
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">{{ t('roles-create-edit') }}</p>
        <app-core-checkbox-field
          [value]="permissions.role.createRole"
          (onToggle)="createEditRoleToggle()"
          [inactive]="isReadOnly"
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">{{ t('roles-delete') }}</p>
        <app-core-checkbox-field
          [value]="permissions.role.deleteRole"
          (onToggle)="permissions.role.deleteRole = !permissions.role.deleteRole"
          [inactive]="isReadOnly"
        />
      </div>
    </div>
  </div>

  <div class="permission-block">
    <div class="permission-title-block">
      <p class="permission-title-text">{{ t('knowledge-base-title') }}</p>
    </div>
    <div class="pl-[16px] permission-block">
      <div class="permission-title-block">
        <p class="permission-text">{{ t('knowledge-base-read') }}</p>
        <app-core-checkbox-field
          [value]="permissions.knowledgeBase.viewKnowledgeBase"
          [inactive]='true'
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">{{ t('knowledge-base-create') }}</p>
        <app-core-checkbox-field
          [value]="permissions.knowledgeBase.createKnowledgeBase"
          (onToggle)="permissions.knowledgeBase.createKnowledgeBase = !permissions.knowledgeBase.createKnowledgeBase "
          [inactive]="isReadOnly"
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">{{ t('knowledge-base-edit') }}</p>
        <app-core-checkbox-field
          [value]="permissions.knowledgeBase.editKnowledgeBase"
          (onToggle)="permissions.knowledgeBase.editKnowledgeBase = !permissions.knowledgeBase.editKnowledgeBase"
          [inactive]="isReadOnly"
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">{{ t('knowledge-base-delete') }}</p>
        <app-core-checkbox-field
          [value]="permissions.knowledgeBase.deleteKnowledgeBase"
          (onToggle)="permissions.knowledgeBase.deleteKnowledgeBase = !permissions.knowledgeBase.deleteKnowledgeBase"
          [inactive]="isReadOnly"
        />
      </div>
    </div>
  </div>

  <div class="permission-block pb-[9px]">
    <div class="permission-title-block">
      <p class="permission-title-text">{{ t('settings-title') }}</p>
    </div>
    <div class="pl-[16px] permission-block">
      <div class="permission-title-block">
        <p class="permission-text">{{ t('settings-platform') }}</p>
        <app-core-checkbox-field
          [value]="permissions.settings.main"
          (onToggle)="permissions.settings.main = !permissions.settings.main"
          [inactive]="isReadOnly"
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">{{ t('settings-license') }}</p>
        <app-core-checkbox-field
          [value]="permissions.settings.billing"
          (onToggle)="permissions.settings.billing = !permissions.settings.billing"
          [inactive]="isReadOnly"
        />
      </div>
    </div>
  </div>
</div>
