<button
  type="button"
  class="h-full w-full justify-center flex items-center group"
  [disabled]="inactive"
  (click)="onToggleValue()"
>
  <div
    class="flex items-center justify-center h-full aspect-square rounded-full"
    [ngClass]="{
      'text-tt-icon-secondary': inactive && inactiveStyle === 'gray',
      'text-tt-primary': !inactive || inactiveStyle === 'base',
      'group-hover:bg-tt-button-hover': !inactive && hoverType === 'base',
      'group-hover:bg-[#EEF2FC]': !inactive && hoverType === 'blue',
    }"
  >
    <svg-checkmark-fill
      class="size-[24px] shrink-0 flex items-center justify-center"
      *ngIf="value && roundType === 'base'"
    />

    <svg-checkbox-empty
      *ngIf="!value && roundType === 'base'"
      class="size-[24px] shrink-0 text-tt-icon-secondary flex items-center justify-center"
    />

    <svg-checkbox-circle-fill
      class="size-[24px] shrink-0 flex items-center justify-center"
      *ngIf="value && roundType === 'circle'"
    />

    <svg-checkbox-circle-empty
      class="size-[24px] shrink-0 flex items-center justify-center"
      *ngIf="!value && roundType === 'circle'"
    />


  </div>
  <p
    *ngIf="label != ''"
    class="ms-2 text-tt-font-size_l text-tt-text-main"
  >
    {{ label }}
  </p>
</button>
