import { SpaceSummeryEntity } from "../components/space-item/space-item.component";

export type SpacesResultAction =
  | ChangeVisibleCreateSpaceModalResultAction
  | UpdateSpacesResultAction
  | ChangeIsLoadingResultAction
  | FilterSpacesResultAction
  | ChangeIsCreatingResultAction

export enum SpacesResultActionTypes {
  CHANGE_VISIBLE_CREATE_SPACE_MODAL,
  UPDATE_SPACES,
  CHANGE_IS_LOADING,
  FILTER_SPACES,
  CHANGE_IS_CREATING
}

export interface ChangeIsCreatingResultAction{
  readonly type: SpacesResultActionTypes.CHANGE_IS_CREATING;
  readonly value: boolean
}

export interface FilterSpacesResultAction {
  readonly type: SpacesResultActionTypes.FILTER_SPACES
  readonly filteredSpaces: SpaceSummeryEntity[],
  readonly filterStr: string
}

export interface ChangeIsLoadingResultAction{
  readonly type: SpacesResultActionTypes.CHANGE_IS_LOADING;
  readonly value: boolean
  readonly isLoadingError: boolean
}

export interface ChangeVisibleCreateSpaceModalResultAction{
  readonly type: SpacesResultActionTypes.CHANGE_VISIBLE_CREATE_SPACE_MODAL;
  readonly value: boolean
}

export interface UpdateSpacesResultAction{
  readonly type: SpacesResultActionTypes.UPDATE_SPACES
  readonly canCreate: boolean
  readonly recentSpaces: SpaceSummeryEntity[]
  readonly spaces: SpaceSummeryEntity[]
}
