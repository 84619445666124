import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  SvgArrowBack, SvgResetPasswordKey, SvgResetPasswordSuccess
} from "src/app/core/components/svg-components/svg.components";
import { Validator } from 'src/app/core/validators/validator';
import { emailValidatorFactory } from 'src/app/core/validators/validators';
import { PasswordRecoveryComponent } from './presentation/view/password-recovery.component';
import { RouterLink } from "@angular/router";
import { PasswordRecoveryFormComponent } from './presentation/components/password-recovery-form/password-recovery-form.component';
import { PasswordRecoveryCompleteComponent } from './presentation/components/password-recovery-complete/password-recovery-complete.component';
import { RegistrationModule } from "../registration/registration.module";
import { AuthLayoutComponent } from "../../core/components/auth/auth-layout/auth-layout.component";
import { ButtonComponent } from "../../core/components/buttons/button/button.component";
import {
  AuthContainerIconComponent
} from "../../core/components/auth/auth-container/auth-container-icon/auth-container-icon.component";
import { AuthContainerComponent } from "../../core/components/auth/auth-container/auth-container.component";
import { FieldsModule } from "../../core/components/fields/fields.module";
import { provideTranslocoScope, TranslocoModule } from "@jsverse/transloco";
import { PasswordRecoveryConstants } from "./common/password-recovery-constants";

@NgModule({
  declarations: [
    PasswordRecoveryComponent,
    PasswordRecoveryFormComponent,
    PasswordRecoveryCompleteComponent
  ],
  imports: [
    CommonModule,
    RouterLink,
    SvgResetPasswordKey,
    SvgResetPasswordSuccess,
    RegistrationModule,
    SvgArrowBack,
    AuthLayoutComponent,
    ButtonComponent,
    AuthContainerIconComponent,
    AuthContainerComponent,
    FieldsModule,
    TranslocoModule
  ],
  providers: [
    {
      provide: "ResetPasswordEmailValidator",
      useExisting: Validator,
      useFactory: emailValidatorFactory
    }
  ]
})
export class PasswordRecoveryModule {}
