import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EmployeeAllDto } from './dto/employee-all-dto';
import { EmployeeCreateDto } from './dto/employee-create-dto';
import { GetEmployeeDto } from "./dto/get-employee-dto";
import { GetEmployeeByRoleDto } from "./dto/get-employee-by-role-dto";

@Injectable()
export class EmployeeService {
  private readonly apiUrl: string = `${environment.apiUrl}/employee`;

  constructor(private httpClient: HttpClient) {}

  public getEmployees(): Observable<EmployeeAllDto> {
    return this.httpClient.get<EmployeeAllDto>(`${this.apiUrl}/all`);
  }

  public getEmployee(id: number): Observable<GetEmployeeDto> {
    return this.httpClient.get<GetEmployeeDto>(`${this.apiUrl}/${id}`);
  }

  public getEmployeesByRole(id: string):Observable<GetEmployeeByRoleDto>{
    return this.httpClient.get<GetEmployeeByRoleDto>(`${this.apiUrl}/all/${id}`);
  }

  public createEmployee(employee: EmployeeCreateDto): Observable<null> {
    const body = {...employee,
      name: employee.name.trim().split(' ').filter((str) => str.length > 0).join(' '),
      surname: employee.surname.trim().split(' ').filter((str) => str.length > 0).join(' '),
      patronymic: employee.patronymic.trim().split(' ').filter((str) => str.length > 0).join(' ')
    };
    return this.httpClient.post<null>(`${this.apiUrl}/create`, body);
  }

  public editEmployee(id: number, employee: EmployeeCreateDto): Observable<null> {
    const body = {...employee,
      name: employee.name.trim().split(' ').filter((str) => str.length > 0).join(' '),
      surname: employee.surname.trim().split(' ').filter((str) => str.length > 0).join(' '),
      patronymic: employee.patronymic.trim().split(' ').filter((str) => str.length > 0).join(' ')
    };
    return this.httpClient.put<null>(`${this.apiUrl}/${id}`, body);
  }

  public deleteEmployee(id: number): Observable<null> {
    return this.httpClient.delete<null>(`${this.apiUrl}/${id}`);
  }

  public addEmployeesRole(roleID: string, userIds: number[]): Observable<null>{
    const body = {
      roleID: roleID,
      userIDs: userIds
    }
    return this.httpClient.post<null>(`${this.apiUrl}/add-role`, body)
  }

  public removeEmployeesRole(roleID: string, userIds: number[]){
    const body = {
      roleID: roleID,
      userIDs: userIds
    }
    return this.httpClient.post<null>(`${this.apiUrl}/remove-role`, body)
  }
}
