import { Reducer } from 'src/app/core/mvi/store';
import { PasswordRecoveryState } from './password-recovery-state';
import {
  PasswordRecoveryResultAction,
  PasswordRecoveryResultActionTypes,
} from './password-recovery-result-action';
import { Injectable } from '@angular/core';
import { clone } from 'cloneable-ts';

@Injectable()
export class PasswordRecoveryReducer
  implements Reducer<PasswordRecoveryState, PasswordRecoveryResultAction>
{
  reduce(
    state: PasswordRecoveryState,
    action: PasswordRecoveryResultAction,
  ): PasswordRecoveryState {
    switch (action.type) {
      case PasswordRecoveryResultActionTypes.CHANGE_EMAIL:
        return clone(state, { email: action.email, emailError: action.emailError });

      case PasswordRecoveryResultActionTypes.VALIDATION_ERROR:
        return clone(state, { emailError: action.emailError });

      case PasswordRecoveryResultActionTypes.CHANGE_LOADING_STATE:
        return clone(state, { isLoading: !state.isLoading });

      case PasswordRecoveryResultActionTypes.CHANGE_STATUS_STATE:
        return clone(state, { status: action.status });
    }
  }
}
