<app-form-modal
  [isLoading]="state.isLoading"
  (onPayClicked)="performAction({
     type: SubscriptionFormActionTypes.CREATE_SUBSCRIPTION_ORDER
  })"
  *transloco="let t; read: SubscriptionFormConstants.TRANSLOCO_READ"
>
  <div
    class="flex flex-col gap-[16px]"
  >
    <div
      class="form-block"
    >
      <p class="form-block-title">{{ t('title') }}</p>

      <div class="flex flex-col gap-[20px]">
        <app-core-dropdown-field
          [label]="t('select-tariff-label')"
          variant="standard"
          [selectedItem]="state.currentTariff"
          [items]="state.tariffDropdownItems"
          (onSelect)="performAction({
          type: SubscriptionFormActionTypes.CHANGE_TARIFF,
          tariffId: $event
        })"
        />

        <app-core-dropdown-field
          [label]="t('select-duration-label')"
          variant="standard"
          [selectedItem]="state.currentDuration"
          [items]="state.durationDropdownItems"
          (onSelect)="performAction({
          type: SubscriptionFormActionTypes.CHANGE_DURATION,
          durationId: $event
        })"
        />

        <div class="flex justify-between items-center">
          <p class="text-tt-text-main text-tt-font-size_l">
            {{ t('count-user-label') }}
          </p>
          <app-input-number
            [value]="state.usersCount"
            (onChange)="performAction({
            type: SubscriptionFormActionTypes.CHANGE_USERS_COUNT,
            value: $event
          })"
          />
        </div>
      </div>
    </div>

    <div
      class="form-block"
    >
      <p class="form-block-title">{{ t('additionally') }}</p>

      <div class="flex justify-between items-center">
        <p class="text-tt-text-main text-tt-font-size_l">{{ t('additional-gigabytes-label') }}</p>
        <app-input-number
          [value]="state.memoryCount"
          (onChange)="performAction({
            type: SubscriptionFormActionTypes.CHANGE_ADDITIONAL_MEMORY,
            value: $event
          })"
        />
      </div>
    </div>

  </div>

</app-form-modal>
