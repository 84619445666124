import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges, OnInit,
  Output,
  SimpleChanges, ViewChild
} from "@angular/core";

export type AutocompleteItem = {
  id: string;
  name: string;
  description?: string;
  image?: {
    url: string | null;
    placeholder: string;
  };
};

@Component({
  selector: "app-core-autocomplete-field",
  templateUrl: "./autocomplete-field.component.html",
})
export class AutocompleteFieldComponent implements OnChanges, OnInit {
  constructor(private el: ElementRef) {}

  @Input() className: string | string[] = [];
  @Input() items: AutocompleteItem[] = [];
  @Input() error?: string | null;
  @Input() placeholder: string = 'Выберите элемент';
  @Input() label?: string;
  @Input() selectedItem?: AutocompleteItem | null;
  @Input() required = false;
  @Input() staticItems = true;
  @Input() variant: 'standard' = 'standard';

  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() onUnselect: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('autocompleteInput') autocompleteInputEl!: ElementRef;
  @ViewChild('autocompleteDropdown') autocompleteDropdownEl?: ElementRef;

  opened = false;
  focused = false;
  searchString = '';
  localItems: AutocompleteItem[] = [];

  readonly ID_PREFIX = 'autocomplete-field-item-'

  trackItemByFn(_: number, item: any) {
    return item.id;
  }

  onFocus() {
    this.focused = true;
    if (!this.onSelect.observed) return
    this.opened = true
    setTimeout(()=>{
      if(this.selectedItem)
      {
        const selectedElement = document.getElementById(this.ID_PREFIX + this.selectedItem.id);
        if(selectedElement){
          selectedElement.scrollIntoView({block: "center"})
        }
      }
    })

  }

  onUnFocus() {
    this.focused = false;
  }

  onChangeValue(event: any) {
    const value = event.target.value;
    this.searchString = value;
    if (this.staticItems) {
      this.filterLocalItems();
    }
    if (this.onChange) {
      this.onChange.emit(value);
    }
  }

  filterLocalItems() {
    this.localItems = this.items.filter((item) =>
      item.name
        .toLowerCase()
        .trim()
        .includes(this.searchString.toLowerCase().trim()),
    );
  }

  onSelectItem(item: AutocompleteItem) {
    if (this.onSelect) {
      this.onSelect.emit(item.id);
    }
    this.opened = false;
    this.searchString = item.name;
    this.placeholder = item.name;
  }

  onUnselectItem(event: MouseEvent, id: string) {
    event.stopPropagation();
    if (this.onUnselect) {
      this.onUnselect.emit(id);
    }
    this.searchString = '';
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (this.opened && !this.el.nativeElement.contains(event.target)) {
      this.opened = false;
      if (this.selectedItem) {
        this.placeholder = this.selectedItem.name;
      } else {
        this.searchString = '';
      }
    }
  }

  ngOnInit() {
    if (this.selectedItem) {
      this.placeholder = this.selectedItem.name;
    }

    if (this.staticItems) {
      this.filterLocalItems();
    } else {
      this.localItems = this.items;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items'] && !changes['items'].firstChange) {
      if (this.staticItems) {
        this.filterLocalItems();
      } else {
        this.localItems = this.items;
      }
    }
  }
}
