<div class="child-routes-width h-[48px] pl-[24px] pr-[20px] flex items-center justify-between gap-[24px] min-w-[0]"
  [ngClass]="header.styles"
  *transloco="let t; read: MainConstants.TRANSLOCO_READ"
>
  <div
    class="py-[14px] gap-[24px]
      flex flex-[1] items-center font-normal
      text-tt-header-bread-crumbs text-tt-font-size_l truncate">
    <p class="shrink-0">{{header.organisationName}}</p>

    <div class="flex flex-grow">
      <div class="flex items-center hover:text-tt-text-secondary"
        *ngFor="let breadCrumb of header.breadCrumbs; let i = index"
      >
        <p class="overflow-ellipsis" *ngIf="breadCrumb.href == 'none'">
          {{ breadCrumb.notLocalise ? breadCrumb.name : t('features.' + breadCrumb.name)}}
        </p>
        <a
          class="overflow-ellipsis"
          [routerLink]="breadCrumb.href"
          *ngIf="breadCrumb.href != 'none'"
        >
          {{ breadCrumb.notLocalise ? breadCrumb.name : t('features.' + breadCrumb.name)}}
        </a>
        <svg-breadcrumbs-arrow
          class="text-tt-icon-default"
          *ngIf="i != header.breadCrumbs.length - 1"
        />
      </div>
    </div>
  </div>

  <div>
    <button class="header-button-size flex justify-center items-center size-[40px]"
      (click)="openPopup()"
    >
      <app-core-avatar
        size="ml"
        variant="primary"
        [url]="employee.avatarUrl"
        [placeholder]="employee.userAvatarPlaceholder"
        [bgRGB]="employee.userAvatarColor"
      />
    </button>

    <div
      class="max-w-[280px] w-full rounded-[8px]
        bg-white shadow-tt-shadow-header-popup
        border-[1px] border-tt-border-default"
      *ngIf="isPopupVisible"
      appPopup
      [popupOffsetXSide]="'right'"
      [parentOffset]="{x: 0, y: 4}"
      (popupClose)="isPopupVisible = false"
    >
      <div class="py-[16px] px-[12px] w-full
        flex flex-col gap-[8px] items-center"
      >
        <app-core-avatar
          size="xl"
          variant="primary"
          [url]="employee.avatarUrl"
          [placeholder]="employee.userAvatarPlaceholder"
          [bgRGB]="employee.userAvatarColor"
        />
        <div class="w-full">
          <p class="text-tt-font-size_l text-tt-text-main text-center">
            <span>{{employee.surname}}</span>
            <span> {{employee.name}}</span>
          </p>

          <p class="text-tt-font-size_m text-tt-text-secondary text-center">{{employee.email}}</p>
        </div>
      </div>

      <div class="h-[1px] w-full bg-tt-border-default"></div>

      <div class="p-[8px] w-full flex flex-col items-center">
        <app-header-action-button
          (click)="navigateToResetPassword()"
        >
          <svg-change-password/>
          <p>{{ t('change-password') }}</p>
        </app-header-action-button>
        <app-header-action-button
          variant="danger"
          (onClick)="logoutClicked.emit()"
        >
          <svg-exit/>
          <p>{{ t('logout') }}</p>
        </app-header-action-button>
      </div>
    </div>

  </div>

</div>

