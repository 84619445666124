import { ShortEmployee } from "../../../../../../../../core/components/select-user-modal/select-user-modal.component";

export type RoleUsersResultAction =
  | ChangeUsersModalVisibilityResultAction
  | ChangeUsersVisibilityResultAction
  | FilterUsersResultAction
  | DeleteUserResultAction
  | ChangeDeleteUserModalVisibilityResultAction
  | ChangeIsLoadingResultAction
  | InitResultAction
  | ChangeIsLoadingDeleteModalResultAction
  | ChangeIsLoadingSelectUsersModalResultAction

export enum RoleUsersResultActionTypes {
  CHANGE_ADD_USERS_MODAL_VISIBILITY,
  CHANGE_DELETE_USER_MODAL_VISIBILITY,
  CHANGE_IS_LOADING,
  CHANGE_IS_LOADING_DELETE_MODAL,
  CHANGE_IS_LOADING_SELECT_USERS_MODAL,
  CHANGE_USERS,
  FILTER_USERS,
  DELETE_USER,
  INIT
}

export interface ChangeUsersVisibilityResultAction {
  readonly type: RoleUsersResultActionTypes.CHANGE_USERS
  readonly users: ShortEmployee[]
}

export interface InitResultAction {
  readonly type: RoleUsersResultActionTypes.INIT
  readonly roleId: string
}

export interface ChangeIsLoadingDeleteModalResultAction {
  readonly type: RoleUsersResultActionTypes.CHANGE_IS_LOADING_DELETE_MODAL
  readonly value: boolean
}

export interface ChangeIsLoadingSelectUsersModalResultAction {
  readonly type: RoleUsersResultActionTypes.CHANGE_IS_LOADING_SELECT_USERS_MODAL
  readonly value: boolean
}

export interface DeleteUserResultAction {
  readonly type: RoleUsersResultActionTypes.DELETE_USER
  readonly user: ShortEmployee
}

export interface ChangeIsLoadingResultAction {
  readonly type: RoleUsersResultActionTypes.CHANGE_IS_LOADING
  readonly value: boolean
}

export interface FilterUsersResultAction {
  readonly type: RoleUsersResultActionTypes.FILTER_USERS
  readonly filterString: string
  readonly filteredUsers: ShortEmployee[]
}

export interface ChangeUsersModalVisibilityResultAction {
  readonly type: RoleUsersResultActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY
  readonly value: boolean
}

export interface ChangeDeleteUserModalVisibilityResultAction {
  readonly type: RoleUsersResultActionTypes.CHANGE_DELETE_USER_MODAL_VISIBILITY
  readonly value: boolean
}
