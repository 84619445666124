import { Injectable } from "@angular/core";
import {
  FormField
} from "../../../../../../../employees/modules/employees-list/presentation/components/create-employee-modal/state/create-employee-modal-state";
import { ToggleItem } from "../../toggler/toggler.component";
import { PaymentType } from "../../../../domain/payment-type";

@Injectable()
export class PaymentState {
  readonly receiptPrice: number = 0;
  readonly organisationName: FormField = {value: ''};
  readonly legalAddress: FormField = {value: ''};
  readonly INN: FormField = {value: ''};
  readonly isISoloProprietor: boolean = false;
  readonly KPP: FormField = {value: ''};
  readonly phoneNumber: FormField = {value: ''};
  readonly email: FormField = {value: ''};
  readonly countUsers: number = 0
  readonly periodType: 'month' | 'year' = 'month'
  readonly isInvoicePaymentModalVisible: boolean = false;

  readonly togglerItems: ToggleItem[] = []

  readonly selectedPaymentType: PaymentType = 'card'
}
