import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsMainComponent } from './presentation/settings-main.component';
import {
  SpaceSettingsHome,
  SvgPerson,
  SvgTrash
} from "../../../../core/components/svg-components/svg.components";
import { SpaceModule } from "../../../knowledge-base/modules/space/space.module";
import { LoadingShadingComponent } from "../../../../core/components/loading-shading/loading-shading.component";
import { AlertModalComponent } from "../../../../core/components/alert-modal/alert-modal.component";
import { SettingsModule } from "../../../../core/components/settings/settings.module";
import { ButtonComponent } from "../../../../core/components/buttons/button/button.component";
import { FieldsModule } from "../../../../core/components/fields/fields.module";
import { TranslocoDirective } from "@jsverse/transloco";

@NgModule({
  declarations: [SettingsMainComponent],
  imports: [
    CommonModule,
    SpaceModule,
    SpaceSettingsHome,
    SvgPerson,
    SvgTrash,
    LoadingShadingComponent,
    AlertModalComponent,
    SettingsModule,
    ButtonComponent,
    FieldsModule,
    TranslocoDirective
  ],
})
export class SettingsMainModule {}
