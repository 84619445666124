import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { AgilesScrollService } from "../data/agiles-scroll.service";
import { NavTab } from "../../../../core/components/nav-tabs/nav-tabs.component";
import { Tabs } from "../../../main/presentation/state/main-state";
import { AgileDeskRoutesPath } from "../../../../routes/agile-desk-routes";
import { HeaderService } from "../../../main/data/header-service";
import { MainRoutesPaths } from "../../../../routes/main-routes";
import { AgileDeskConstants } from "../common/agile-desk-constants";
import { provideTranslocoScope } from "@jsverse/transloco";

@Component({
  selector: 'app-agiles-desk',
  templateUrl: './agiles-desk.component.html',
  styleUrls: [
    '../../../main/presentation/main.component.scss',
  ],
  providers: [
    AgilesScrollService,
    provideTranslocoScope({ scope: 'agiles/agile-desk', alias: AgileDeskConstants.TRANSLOCO_READ })
  ]
})
export class AgilesDeskComponent implements AfterViewInit{
  navTabs: NavTab[]= [
    {
      id: Tabs.EMPLOYEES,
      url: './',
      title: 'main',
    },
    {
      id: Tabs.ROLE,
      url: AgileDeskRoutesPath.DESK_SETTINGS,
      title: 'settings',
    },
  ]

  @ViewChild('scrollable') scrollableEl!: ElementRef

  constructor(
    private headerService: HeaderService,
    private agilesScrollService: AgilesScrollService,
  ){
    headerService.ChangeStyle('bg-tt-bg-page')
    headerService.ChangeBreadCrumbs([{
      name: 'agile-desks',
      href: '/' + MainRoutesPaths.AGILES
    },])
  }

  ngAfterViewInit(): void {
    this.agilesScrollService.setScrollableElement(this.scrollableEl.nativeElement)
  }

  onScroll(){
    if(this.scrollableEl){
      if(this.scrollableEl.nativeElement.scrollTop > 0){
        this.headerService.ChangeStyle('bg-tt-bg-page border-b-[1px] border-tt-border-default')
      }
      else{
        this.headerService.ChangeStyle('bg-tt-bg-page')
      }
      if(this.scrollableEl.nativeElement.scrollTop > 50){
        this.headerService.ChangeBreadCrumbs([
          {
            name: 'agile-desks',
            href: '/' + MainRoutesPaths.AGILES
          },
          {
            name: '@project_name',
            notLocalise: true,
            href: '/' + MainRoutesPaths.AGILE_DESK.replace(':id', '1')
          }
        ])
      }
      else{
        this.headerService.ChangeBreadCrumbs([{
          name: 'agile-desks',
          href: '/' + MainRoutesPaths.AGILES
        },])
      }
    }
  }

  protected readonly AgileDeskConstants = AgileDeskConstants;
}
