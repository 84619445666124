<div
  class="flex flex-col justify-between w-full h-full"
  *transloco="let t; read: RoleConstants.TRANSLOCO_READ"
>
  <div>
    <app-core-settings-content-element
      [title]="t('permissions')"
    >
      <p
        [textParse]="t('permissions-description')"
        [parseElements]="[{
          text: t('permissions-description-link'),
          className: 'text-tt-primary cursor-pointer',
          type: 'text'
        }]"
      ></p>
    </app-core-settings-content-element>

    <div class="h-[1px] w-full bg-tt-border-default"></div>

    <app-core-settings-content-element>
      <app-core-permissions-form
        [permissions]="permissions"
        [isReadOnly]="isReadOnly"
      />

    </app-core-settings-content-element>
  </div>

  <app-core-settings-content-element
    *ngIf="!isInitEqual()"
  >
    <div class="flex w-full justify-end gap-[16px]">
      <app-core-button
        variant="soft"
        (onClick)="decline()"
      >
        {{ t('cancel') }}
      </app-core-button>
      <app-core-button
        (onClick)="save()"
      >
        {{ t('save') }}
      </app-core-button>
    </div>

  </app-core-settings-content-element>
</div>
