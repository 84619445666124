<div class="relative h-screen w-full child-routes-size overflow-y-auto tiny-scrollbar"
  (scroll)="onScroll()"
  #scrollable
  *transloco="let t; read: SpacesConstants.TRANSLOCO_READ"
>
  <div class="header-bg-gradient top-0 left-0 w-full absolute z-0 h-[450px]"></div>

  <div class="
    px-[16px] h-full w-full z-10
    flex flex-col items-center"
  >
    <div class="flex flex-col gap-[24px] items-center w-full max-w-[940px]"
    >
      <p class="font-medium ml-[6px] text-[32px] leading-[48px] z-10 text-tt-text-main"
        id="welcome"
      >
        {{ t('title') }}
      </p>

      <app-core-search-field
        class="max-w-[760px] w-full"
        size="xl"
        className="h-[48]"
        [placeholder]="t('search-placeholder')"
        (onChange)="performAction({
          type: SpacesActionTypes.FILTER_SPACES,
          filterStr: $event
        })"
      />
    </div>

    <div class="mt-[60px]"
      *ngIf="state.isLoading"
    >
      <mat-spinner class="w-[48px] h-[48px]"></mat-spinner>
    </div>

    <app-core-image-stub
      *ngIf="state.isLoadingError"
      class="relative"
      [imageSrc]="''"
      [title]="t('load-error-title')"
      [description]="t('load-error-description')"
    />

    <div
      *ngIf="!state.isLoadingError"
      class="flex flex-col items-center relative w-full max-w-[940px]"
      [ngClass]='{
        "invisible h-[1px] overflow-hidden" : state.isLoading
      }'
      #spaces
    >
      <div
        [style.width] = "spacesWidth"
      >
        <div class="mt-[48px]"
          *ngIf="state.recentSpaces.length > 0 && state.filterString == ''"
        >
          <p class="leading-[30px] font-medium text-[20px] text-tt-text-main">
            {{ t('recent-spaces') }}
          </p>

          <div class="mt-[16px] flex gap-[24px] flex-wrap"
            [style.gap]="SpacesConstants.SPACES_GAP + 'px'"
          >
            <space-item
              *ngFor="let space of state.recentSpaces"
              [space]="space"
              [style.max-width]="SpacesConstants.SPACES_MAX_W"
            />
          </div>
        </div>

        <div class="mt-[24px]"
          [ngClass]="{
            'mt-[48px]': state.recentSpaces.length == 0
          }"
        >
          <p
            class="leading-[30px] font-medium text-[20px] text-tt-text-main"
            *ngIf="!(state.filterString != '' && state.filteredSpaces.length == 0)"
          >
            {{ t('all-spaces') }}
          </p>
          <div class="mt-[24px] flex flex-wrap pb-[24px]"
            [style.gap]="SpacesConstants.SPACES_GAP + 'px'"
          >
            <div class="space-item-container text-tt-primary  font-normal hover:bg-tt-light-blue"
              *ngIf="state.canCreate && state.filterString == ''"
              [style.max-width]="SpacesConstants.SPACES_MAX_W"
            >
              <button class="
                flex flex-col justify-center items-center gap-[16px]
                h-full w-full"
                (click)="performAction({
                  type: SpacesActionTypes.CHANGE_VISIBLE_CREATE_SPACE_MODAL,
                  value: true
                })"
              >
                <svg-plus class="w-[49px] h-[49px]"/>
                <p class="text-tt-font-size_2xl leading-[20px]">
                  {{ t('new-space') }}
                </p>
              </button>

            </div>

            <space-item
              *ngFor="let space of state.filterString == '' ? state.spaces : state.filteredSpaces"
              [space]="space"
              [style.max-width]="SpacesConstants.SPACES_MAX_W"
            />

            <app-core-image-stub
              *ngIf="!state.isLoading && state.filteredSpaces.length == 0 && state.filterString != ''"
              [imageSrc]="''"
              [title]="t('not-found-spaces-title')"
              [description]="t('not-found-spaces-description')"
            />
          </div>
        </div>
      </div>

    </div>

  </div>

</div>

<create-space-modal
  *ngIf="state.isCreateSpaceModalVisible"
  [isCreating]="state.isCreating"
  (createClicked)="performAction({
    type: SpacesActionTypes.CREATE_SPACE,
    spaceName: $event
  })"
  (closeClicked)="performAction({
    type: SpacesActionTypes.CHANGE_VISIBLE_CREATE_SPACE_MODAL,
    value: false
  })"
/>
