import { Component } from '@angular/core';
import { Store } from "../../../../../../../../core/mvi/store";
import { AgilesDeskSettingsStatusesAndExecutorsExecutor } from "./state/agiles-desk-settings-statuses-and-executors-executor";
import { AgilesDeskSettingsStatusesAndExecutorsState } from "./state/agiles-desk-settings-statuses-and-executors-state";
import {
  AgilesDeskSettingsStatusesAndExecutorsAction,
  AgilesDeskSettingsStatusesAndExecutorsActionTypes
} from "./state/agiles-desk-settings-statuses-and-executors-action";
import { AgilesDeskSettingsStatusesAndExecutorsResultAction } from "./state/agiles-desk-settings-statuses-and-executors-result-action";
import { AgilesDeskSettingsStatusesAndExecutorsReducer } from "./state/agiles-desk-settings-statuses-and-executors-reducer";
import {
  SelectUserModalService
} from "../../../../../../../../core/services/select-user-modal-service/select-user-modal.service";
import { SelectUsersModalServiceImpl } from "./data/select-users-modal-service-impl";
import { AgileDeskSettingsConstants } from "../../../common/agile-desk-settings-constants";

@Component({
  selector: 'app-agiles-desk-settings-statuses-and-executors',
  templateUrl: './agiles-desk-settings-statuses-and-executors.component.html',
  styleUrls: ['./agiles-desk-settings-statuses-and-executors.component.scss'],
  providers: [
    AgilesDeskSettingsStatusesAndExecutorsState,
    AgilesDeskSettingsStatusesAndExecutorsExecutor,
    AgilesDeskSettingsStatusesAndExecutorsReducer,
    {
      provide: SelectUserModalService,
      useClass: SelectUsersModalServiceImpl,
    },
  ]
})
export class AgilesDeskSettingsStatusesAndExecutorsComponent
  extends Store<
    AgilesDeskSettingsStatusesAndExecutorsState,
    AgilesDeskSettingsStatusesAndExecutorsExecutor,
    AgilesDeskSettingsStatusesAndExecutorsAction,
    AgilesDeskSettingsStatusesAndExecutorsResultAction
  >
{
  constructor(
    state: AgilesDeskSettingsStatusesAndExecutorsState,
    executor: AgilesDeskSettingsStatusesAndExecutorsExecutor,
    reducer: AgilesDeskSettingsStatusesAndExecutorsReducer
  ) {
    super(state, executor, reducer);
  }

  protected readonly AgilesDeskSettingsStatusesAndExecutorsActionTypes = AgilesDeskSettingsStatusesAndExecutorsActionTypes;
  protected readonly AgileDeskSettingsConstants = AgileDeskSettingsConstants;
}
