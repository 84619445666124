<ng-container
  *transloco="let t; read: OrdersConstants.TRANSLOCO_READ"
>
  <div
    class="mt-[44px] w-full flex justify-center"
    *ngIf="state.isLoading"
  >
    <mat-spinner class="size-[48px]"/>
  </div>

  <app-core-image-stub
    class="mt-[20px]"
    *ngIf="!state.isLoading && state.isLoadingError"
    [imageSrc]="''"
    [title]="t('download-error-title')"
    [description]="t('download-error-description')"
  />

  <div
    class="py-[44px] max-w-[1256px] w-full mx-auto flex flex-col gap-[24px]"
    *ngIf="!state.isLoading && !state.isLoadingError"
  >
    <div class="flex flex-col gap-[16px]" *ngIf="state.activeOrder">
      <p class="block-title">
        {{ t('active-order') }}
      </p>
      <app-core-informer-text
        [informerText]="t('price-order-inform', { date: state.activeOrderDueDate }) "
      />

      <app-order
        [order]="state.activeOrder"
        [isOpen]="true"
        (onPay)="performAction({
          type: OrdersActionTypes.PAY_ORDER,
        })"
        (onCancel)="performAction({
          type: OrdersActionTypes.CHANGE_CANCEL_MODAL_VISIBLE,
          value: true
        })"
      />
    </div>

    <div class="flex flex-col gap-[16px]">
      <p class="block-title">{{ t('purchase-history') }}</p>

      <p
        class="text-tt-text-main text-tt-font-size_l"
        *ngIf="state.storyOrders.length === 0"
      >
        {{ t('empty-orders') }}
      </p>

      <app-order
        *ngFor="let order of state.storyOrders"
        [order]="order"
      />
    </div>
  </div>

  <app-core-alert-modal
    *ngIf="state.cancelModalVisibility"
    [title]="t('cancel-order')"
    type="warning"
    [cancelButtonText]="t('back-to-orders')"
    [confirmButtonText]="t('cancel-order')"
    [isLoading]="state.cancelModalIsLoading"
    (onConfirmClicked)="performAction({
    type: OrdersActionTypes.CANCEL_ORDER,
  })"
    (onCancelClicked)="performAction({
    type: OrdersActionTypes.CHANGE_CANCEL_MODAL_VISIBLE,
    value: false
  })"
  >
    {{ t('cancel-order-description') }}
  </app-core-alert-modal>
</ng-container>
