import { Component, Input } from "@angular/core";
import { Location, NgClass, NgIf } from "@angular/common";
import { AppPaths } from "../../../../app-routing.module";
import { LoadingShadingComponent } from "../../loading-shading/loading-shading.component";
import { SvgArrowBack } from "../../svg-components/svg.components";
import { RouterLink } from "@angular/router";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  imports: [
    NgIf,
    LoadingShadingComponent,
    SvgArrowBack,
    NgClass,
    RouterLink
  ],
  standalone: true
})
export class AuthLayoutComponent {
  @Input() topOffset: 'center' | 'constant' = 'center'
  @Input() bgType: 'page' | 'white' = 'page'
  @Input() isHaveBackButton = false
  @Input() backButtonType: 'login' | 'back' = 'login'
  @Input() backButtonText: string = ''
  @Input() isLoading: boolean = false;

  constructor(private location: Location) {
  }

  onBackClicked(){
    this.location.back()
  }

  protected readonly AppPaths = AppPaths;
}
