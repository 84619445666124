import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { LinkToolRenderProps } from "../types/link-tool-render-props";
import { isValidURL } from "../../../../../../utils/is-valid-url"
import { useYooptaEditor } from "@yoopta/editor";
import { AngularWrapper } from "@bubblydoo/angular-react";
import { ButtonComponent } from "../../../../../buttons/button/button.component";
import { LocalisedEditorTextComponent } from "../../../components/localised-text/localised-editor-text.component";
import { InputFieldComponent } from "../../../../../fields/components/input-field/input-field.component";
import { translate } from "@jsverse/transloco";
import { YooptaEditorConstants } from "../../../../common/yoopta-editor-constants";

const MyLinkToolRender = (props: LinkToolRenderProps) => {
  const { withLink = true, withTitle = true } = props;
  const editor = useYooptaEditor();
  const defaultLinkProps = editor.plugins?.["LinkPlugin"]?.elements?.["link"]?.props;

  const [link, setLink] = useState(props?.link || defaultLinkProps);

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setLink((p) => ({ ...p, [target.name]: target.value }));
  };

  useEffect(() => {
    const hasUrl = !!props.link.url;
    if (hasUrl) setLink(props.link);
    if (!hasUrl && defaultLinkProps) {
      setLink({
        ...props.link,
        rel: defaultLinkProps.rel || props.link.rel || '',
        target: defaultLinkProps.target || props.link.target || '_self',
      });
    }
  }, [props.link]);

  const onSave = () => {
    if (!link.url) return;
    let result = link.url
    if(!(result.startsWith('http://') || result.startsWith('https://'))) {
      result = 'https://' + result
    }
    if(isValidURL(result)){
      props.onSave({...link, url: result});
    }
  };

  const onDelete = () => {
    props.onDelete();
  };

  return (
    <div
      className="yoopta-link-tool flex flex-col gap-[8px]"
      style={{
        boxShadow: '0px 1px 4px 0px #00000040'
      }}
    >
      {withTitle && (
        <div
          className='flex flex-col gap-[8px]'
        >
          <label htmlFor="title" className="yoopta-link-tool-label">
            {translate(YooptaEditorConstants.TRANSLOCO_READ + '.link-text-label')}
          </label>
          <input
            id="title"
            type="text"
            className="yoopta-link-tool-input"
            name="title"
            value={link.title ?? ''}
            onChange={onChange}
            placeholder={translate(YooptaEditorConstants.TRANSLOCO_READ + '.link-text-placeholder')}
            autoComplete="off"
          />
        </div>
      )}
      {withLink && (
        <div
          className='flex flex-col gap-[8px]'
        >
          <label htmlFor="url" className="yoopta-link-tool-label">
            {translate(YooptaEditorConstants.TRANSLOCO_READ + '.link-url-label')}
          </label>
          <input
            id="url"
            type="text"
            className="yoopta-link-tool-input"
            name="url"
            value={link.url ?? ''}
            onChange={onChange}
            placeholder={translate(YooptaEditorConstants.TRANSLOCO_READ + '.link-url-placeholder')}
            autoComplete="off"
          />
        </div>
      )}
      <div
        className="flex justify-between"
      >
        <AngularWrapper
          component={ButtonComponent}
          inputs={{
            disabled: !link.url,
          }}
          outputs={{
            "onClick": onSave,
          }}
        >
          { props.link.url ? translate(YooptaEditorConstants.TRANSLOCO_READ + '.update') : YooptaEditorConstants.TRANSLOCO_READ + '.add'}
        </AngularWrapper>
        <AngularWrapper
          component={ButtonComponent}
          inputs={{
            variant: "soft",
            type: "danger",
          }}
          outputs={{
            "onClick": onDelete,
          }}
        >
          {translate(YooptaEditorConstants.TRANSLOCO_READ + '.delete')}
        </AngularWrapper>
      </div>
    </div>
  );
};

export { MyLinkToolRender };
