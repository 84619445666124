import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TaskExecutor } from "../../../../../../agiles-desk-main/presentation/components/task-item/task-item.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  ShortEmployee
} from "../../../../../../../../../../core/components/select-user-modal/select-user-modal.component";
import {
  AutocompleteItem
} from "../../../../../../../../../../core/components/fields/components/autocomplete-field/autocomplete-field.component";
import { AgileDeskSettingsConstants } from "../../../../../common/agile-desk-settings-constants";

@Component({
  selector: 'app-executor-list',
  templateUrl: './executor-list.component.html',
  styleUrls: [
    './executor-list.component.scss',
    '../../agiles-desk-settings-statuses-and-executors.component.scss'
  ]
})
export class ExecutorListComponent {
  @Input() executorList: TaskExecutor[] = []
  @Input() showUnassignedTasks: boolean = false
  @Output() onChangeList = new EventEmitter<TaskExecutor[]>()
  @Output() showUnassignedTasksChanged = new EventEmitter()

  selectUserModalOpen: boolean = false

  deleteModalOpen: boolean = false
  executorToDelete: TaskExecutor | null = null
  executorAutocompleteItems: AutocompleteItem[] = []
  selectedAutocompleteItem: AutocompleteItem | null = null

  drop(event: CdkDragDrop<TaskExecutor[]>) {
    moveItemInArray(this.executorList, event.previousIndex, event.currentIndex);
    this.onChangeList.emit(this.executorList)
  }

  addUsers(employee: ShortEmployee[]){
    this.onChangeList.emit(this.executorList.concat(employee))
    this.selectUserModalOpen = false
  }

  onXMarkClicked(executor: TaskExecutor){
    this.deleteModalOpen = true
    this.executorToDelete = executor
    const withoutExecutor = {
      id: 'none',
      name: 'Без исполнителя',
    }
    this.executorAutocompleteItems = [withoutExecutor].concat(this.executorList
      .filter(filterExecutor=> filterExecutor.id != executor.id)
      .map((executor)=>{
        return{
          id: executor.id.toString(),
          name: executor.surname + ' ' + executor.name
        }
      })
    )
  }

  deleteExecutor(){
    if(this.executorToDelete && this.selectedAutocompleteItem){
      this.executorList.splice(this.executorList.indexOf(this.executorToDelete), 1)
      this.onChangeList.emit(this.executorList)
      this.closeDeleteModal()
    }
  }

  closeDeleteModal(){
    this.deleteModalOpen = false
    this.executorToDelete = null
    this.executorAutocompleteItems = []
    this.selectedAutocompleteItem = null
  }

  onSelectAutocomplete(id: string){
    const find = this.executorAutocompleteItems.find(item => item.id === id)
    this.selectedAutocompleteItem = find ? find : null
  }

  protected readonly AgileDeskSettingsConstants = AgileDeskSettingsConstants;
}
