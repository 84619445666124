import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterOutlet } from "@angular/router";
import { EmployeesRolesComponent } from './presentation/employees-roles.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RoleItemComponent } from './presentation/components/roles-item/roles-item.component';
import { SvgTripleDot } from "../../../../core/components/svg-components/svg.components";
import { CreateRoleModalComponent } from './presentation/components/create-role-modal/create-role-modal.component';
import { Validator } from 'src/app/core/validators/validator';
import { EmptyRule, MaxLengthRule } from 'src/app/core/validators/rule';
import { AlertModalComponent } from "../../../../core/components/alert-modal/alert-modal.component";
import { ImageStubComponent } from "../../../../core/components/image-stub/image-stub.component";
import { ButtonComponent } from "../../../../core/components/buttons/button/button.component";
import { ActionMenu } from "../../../../core/components/action-menu/action-menu.component";
import { PopupDirective } from "../../../../core/directives/popup.directive";
import {
  TextWithHighlightingComponent
} from "../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { ModalWindowModule } from "../../../../core/components/modal-window/modal-window.module";
import { FieldsModule } from "../../../../core/components/fields/fields.module";
import { SvgLock } from "./presentation/icons/employees-roles-icons";
import { TranslocoDirective } from "@jsverse/transloco";
import { TextParserComponent } from "../../../../core/components/text-parser/text-parser.component";
import {
  PermissionsFormComponent
} from "../../../../core/components/permissions-form/permissions-form.component";
import { EmployeesRolesConstants } from "./common/employees-roles-constants";

@NgModule({
  declarations: [
    EmployeesRolesComponent,
    RoleItemComponent,
    CreateRoleModalComponent,
    SvgLock
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    MatProgressSpinnerModule,
    SvgTripleDot,
    RouterLink,
    AlertModalComponent,
    ImageStubComponent,
    ButtonComponent,
    ActionMenu,
    PopupDirective,
    TextWithHighlightingComponent,
    ModalWindowModule,
    FieldsModule,
    TranslocoDirective,
    TextParserComponent,
    PermissionsFormComponent
  ],
  providers: [
    {
      provide: "NewRoleNameValidator",
      useExisting: Validator,
      useFactory: EmployeesRolesModule.roleNameValidatorFactory
    },
    {
      provide: "NewRoleDescriptionValidator",
      useExisting: Validator,
      useFactory: EmployeesRolesModule.roleDescriptionValidatorFactory
    }
  ]
})
export class EmployeesRolesModule {
  public static roleNameValidatorFactory = () =>
    new Validator([
      new EmptyRule('name-error-empty'),
      new MaxLengthRule('name-error-max-length', EmployeesRolesConstants.ROLE_NAME_MAX_LENGTH),
    ]);
  public static roleDescriptionValidatorFactory = () =>
    new Validator([
      new EmptyRule('description-error-empty'),
      new MaxLengthRule('description-error-max-length', EmployeesRolesConstants.ROLE_DESCRIPTION_MAX_LENGTH),
    ]);
}
