import { Component, Input } from "@angular/core";
import {
  HighlightedPartsStr
} from "../../../../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { KnowledgeBasePaths } from "../../../../../../../routes/knowledge-base-routes";
import { numToWordType } from "../../../../../../../core/utils/num-to-word-type";
import { SpacesConstants } from "../../../common/spaces-constants";

@Component({
  selector: 'space-item',
  templateUrl: './space-item.component.html',
  styleUrls: ['../../../common/spaces.component.scss']
})
export class SpaceItemComponent{
  @Input() space: SpaceSummeryEntity = {
    id: '-1',
    name: '',
    sectionsCount: 10,
    documentsCount: 10,
    nameParts: []
  }

  protected readonly KnowledgeBasePaths = KnowledgeBasePaths;
  protected readonly ruNumWordType = numToWordType;
  protected readonly SpacesConstants = SpacesConstants;
}

export interface SpaceSummeryEntity{
  id: string,
  name: string,
  sectionsCount: number,
  documentsCount: number,
  nameParts: HighlightedPartsStr[]
}
