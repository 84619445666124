<app-core-page-shading
  *ngIf="isVisible"
  [appearanceType]="'animate'"
>
  <div
    class="m-auto w-[376px] rounded-[16px] order-bg-gradient relative animate-alert_appearance"
    *transloco="let t; read: MainConstants.TRANSLOCO_READ + '.subscription-modal'"
  >
    <img ngSrc="/assets/modal-window/saly.png" class="w-full" height="208" width="376" draggable="false" priority="priority" alt=""/>

    <div class="p-[24px]">
      <p class="font-semibold text-[22px] leading-[27px] text-tt-text-main">
        <span
          *ngIf="daysLeft && daysLeft > 0"
          [textParse]="t('days-left-text', { count: daysLeft, countType: numWordType(daysLeft) })"
          [parseElements]="[{
            text: t('days-left-text-highlighted', { count: daysLeft, countType: numWordType(daysLeft) }),
            className: 'days-left-color',
            type: 'text'
          }]"
        ></span>
        <span *ngIf="!daysLeft || daysLeft <= 0">{{ t('license-over') }}</span>
      </p>
      <p class="text-[14px] leading-[17px] mt-[8px] text-tt-text-secondary">
        {{ t('license-renewal') }}
      </p>

      <button
        class="slider-bg w-full mt-[48px] rounded-[20px] py-[11px] flex justify-center"
        (click)="goToPayment()"
      >
        <p class="text-[16px] leading-[18px] text-tt-text-contrast">{{t('proceed-to-payment')}}</p>
      </button>

      <button
        class="w-full mt-[12px]"
        (click)="close()"
        *ngIf="daysLeft && daysLeft > 0"
      >
        <p class="text-[14px] leading-[17px] text-tt-text-secondary">{{ t('remind-later') }}</p>
      </button>
    </div>

    <button
      class="absolute top-[4px] cursor-pointer right-[-40px] w-[32px] h-[32px] flex justify-center items-center bg-tt-bg-page rounded-[24px]"
      (click)="close()"
    >
      <svg-x-mark class="min-h-[24px] min-w-[24px] text-tt-icon-default"/>
    </button>
  </div>
</app-core-page-shading>
