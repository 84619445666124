import { Component } from '@angular/core';

@Component({
  selector: "app-core-alert-modal-unscrollable-box",
  templateUrl: "./alert-modal-unscrollable-box.component.html",
  standalone: true,
  styleUrls: ["./alert-modal-unscrollable-box.component.scss"]
})
export class AlertModalUnscrollableBoxComponent {

}
