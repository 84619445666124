import { AgilesListItemType } from "../components/agiles-list-item/agiles-list-item.component";

export type AgilesListResultAction =
  | ChangeIsOpenCreateModalResultAction
  | ChangeDesksListResultAction

export enum AgilesListResultActionTypes {
  CHANGE_IS_OPEN_CREATE_MODAL,
  CHANGE_DESK_LIST
}

export interface ChangeIsOpenCreateModalResultAction {
  readonly type: AgilesListResultActionTypes.CHANGE_IS_OPEN_CREATE_MODAL
  readonly value: boolean
}

export interface ChangeDesksListResultAction {
  readonly type: AgilesListResultActionTypes.CHANGE_DESK_LIST
  readonly agileList: AgilesListItemType[]
}
