import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RegistrationActionTypes } from "../../state/registration-action";
import { AppPaths } from "../../../../../app-routing.module";
import { RegistrationConstants } from "../../../common/registration-constants";

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent {
  @Input() surnameError: string = ''
  @Input() nameError: string = ''
  @Input() emailError: string = ''
  @Input() phoneNumberError: string = ''

  @Output() onSurnameChange = new EventEmitter<string>();
  @Output() onNameChange = new EventEmitter<string>();
  @Output() onEmailChange = new EventEmitter<string>();
  @Output() onPhoneNumberChange = new EventEmitter<string>();
  @Output() onSubmit = new EventEmitter();
  @Output() onFirstFocus = new EventEmitter();

  private isWasFocused = false

  focus(){
    if(!this.isWasFocused){
      this.isWasFocused = true
      this.onFirstFocus.emit()
    }
  }

  protected readonly RegistrationActionTypes = RegistrationActionTypes;
  protected readonly AppPaths = AppPaths;
  protected readonly RegistrationConstants = RegistrationConstants;
}
