import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  RoleUsersResultAction,
  RoleUsersResultActionTypes
} from "./role-users-result-action";
import { RoleUsersState } from './role-users-state.service';

@Injectable()
export class RoleUsersReducer
  implements Reducer<RoleUsersState, RoleUsersResultAction>
{
  reduce(state: RoleUsersState, action: RoleUsersResultAction): RoleUsersState {
    switch (action.type) {
      case RoleUsersResultActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY:
        return {...state, isOpenSelectUsersModal: action.value}
      case RoleUsersResultActionTypes.CHANGE_USERS:
        return {...state, users: action.users, isOpenSelectUsersModal: false}
      case RoleUsersResultActionTypes.FILTER_USERS:
        return {...state, filteredUsers: action.filteredUsers, filterString:action.filterString }
      case RoleUsersResultActionTypes.DELETE_USER:
        return {...state, userToDelete: action.user, isOpenDeleteUserModal: true}
      case RoleUsersResultActionTypes.CHANGE_DELETE_USER_MODAL_VISIBILITY:
        return {...state, isOpenDeleteUserModal: action.value}
      case RoleUsersResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value}
      case RoleUsersResultActionTypes.INIT:
        return {...state, roleId: action.roleId}
      case RoleUsersResultActionTypes.CHANGE_IS_LOADING_DELETE_MODAL:
        return {...state, isLoadingDeleteUserModal: action.value}
      case RoleUsersResultActionTypes.CHANGE_IS_LOADING_SELECT_USERS_MODAL:
        return {...state, isLoadingSelectUsersModal: action.value}
    }
  }
}
