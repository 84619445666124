import { Injectable } from "@angular/core";
import { ReceiptSection } from "../domain/receipt-secton";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class ReceiptService{
  private mutableSection: BehaviorSubject<ReceiptSection[]> =
    new BehaviorSubject<ReceiptSection[]>([]);

  receiptSections: Observable<ReceiptSection[]> = this.mutableSection.asObservable()

  updateSections(receiptSections: ReceiptSection[]) {
    this.mutableSection.next(receiptSections)
  }
}
