<a
  class="rounded-[16px] border-[1px] block overflow-hidden bg-white border-tt-border-default"
  [ngClass]="{
    'h-[376px] w-[402px]': type == 'grid-cell',
    'h-[222px] w-full': type == 'list-element',
  }"
  [routerLink]="'./'"
  [queryParams]="{'newsId': news.id}"
  [queryParamsHandling]="'merge'"
>
  <div class="w-full h-[144px] bg-center bg-cover"
    [style.background-image]="'url(' + imageUrl + ')'"
    *ngIf="type == 'grid-cell'"
    [ngClass]="{
      'bg-[#C4C4C4]': imageUrl == ''
    }"
  ></div>

  <div class="p-[24px] w-full flex overflow-hidden"
    [ngClass]="{
      'h-[232px]': type == 'grid-cell',
      'h-[222px]': type == 'list-element',
    }"
  >

    <div class="flex-grow-1 basis-full h-full flex flex-col overflow-hidden gap-[16px] text-left"
      [ngClass]="{
        'h-[184px] w-[354]': type == 'grid-cell',
        'h-[174px]': type == 'list-element',
      }"
      #text
    >
      <p class="text-[14px] text-tt-text-secondary leading-[18px]">{{news.dateStr}}</p>
      <p class="text-[18px] font-medium text-tt-text-main leading-[29px] line-clamp-2 overflow-hidden text-ellipsis">{{news.title}}</p>
      <p class="text-[15px] text-tt-text-secondary leading-[18px] line-clamp-1"
        #description
      >{{news.description}}</p>
    </div>

    <div class="float-right ml-[24px] shrink-0 max-w-[309px] w-full h-full rounded-[16px] bg-center bg-cover"
       *ngIf="type == 'list-element'"
       [style.background-image]="'url(' + imageUrl + ')'"
       [ngClass]="{
         'bg-[#C4C4C4]': imageUrl == ''
       }"
    ></div>

  </div>
</a>
