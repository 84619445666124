import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgilesDeskMainComponent } from "./presentation/agiles-desk-main.component";
import { TaskItemComponent } from "./presentation/components/task-item/task-item.component";
import { TaskAvatarComponent } from "./presentation/components/task-avatar/task-avatar.component";
import { TasksTableComponent } from "./presentation/components/tasks-table/tasks-table.component";
import { TaskListComponent } from "./presentation/components/task-list/task-list.component";
import { TasksToolbarComponent } from "./presentation/components/tasks-toolbar/tasks-toolbar.component";
import { CdkDrag, CdkDragPlaceholder, CdkDragPreview, CdkDropList, CdkDropListGroup } from "@angular/cdk/drag-drop";
import { SvgArrow, SvgPlus, SvgTrash } from "../../../../../core/components/svg-components/svg.components";
import { MousePressedScrollDirective } from "../../../../../core/directives/mouse-pressed-scroll.directive";
import { TableRowComponent } from "./presentation/components/table-row/table-row.component";
import { ButtonComponent } from "../../../../../core/components/buttons/button/button.component";
import { TranslocoDirective } from "@jsverse/transloco";
import { ClickOutsideDirective } from "../../../../../core/directives/click-outside.directive";

@NgModule({
  declarations: [
    AgilesDeskMainComponent,
    TaskItemComponent,
    TaskAvatarComponent,
    TasksTableComponent,
    TaskListComponent,
    TasksToolbarComponent,
    TableRowComponent
  ],
  imports: [
    CommonModule,
    SvgTrash,
    CdkDropList,
    SvgArrow,
    CdkDrag,
    SvgPlus,
    CdkDragPlaceholder,
    CdkDropListGroup,
    MousePressedScrollDirective,
    CdkDragPreview,
    ButtonComponent,
    TranslocoDirective,
    ClickOutsideDirective
  ]
})
export class AgilesDeskMainModule { }
