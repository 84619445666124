import { Injectable } from '@angular/core';
import { ListType } from "../../domain/list-type";
import { NewsSummaryEntity } from "../../domain/news-summary-entity";
import { DraftDto } from "../../../../data/dto/draft-dto";

@Injectable()
export class NewsListState {
  readonly listType: ListType = 'grid-cell';
  readonly news: NewsSummaryEntity[] = []
  readonly canCreate: boolean = false;
  readonly canEdit: boolean = false;

  readonly isPageLoading: boolean = false;

  readonly openDraftModalVisibility: boolean = false;
  readonly openDraftModalIsLoading: boolean = false;

  readonly draft?: DraftDto

  readonly isFullNewsOpen: boolean = false;
  readonly fullNews?: NewsSummaryEntity
}
