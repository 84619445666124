import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { stringToRgb } from "../../utils/string-to-rgb";
import { ActionMenu, ActionSection } from "../action-menu/action-menu.component";
import { UserCardService } from "./service/user-card-service";
import { ToastsService } from "../toast-alert/services/toast-alert.service";
import { ToastState } from "../toast-alert/toast-alert.component";
import { AvatarComponent } from "../avatar/avatar.component";
import { SvgTripleDot } from "../svg-components/svg.components";
import { StrToColoredBgComponent } from "../str-to-colored-bg/str-to-colored-bg.component";
import { LoadingShadingComponent } from "../loading-shading/loading-shading.component";
import { PopupDirective } from "../../directives/popup.directive";
import { NgIf } from "@angular/common";

@Component({
  selector: "app-user-card",
  templateUrl: "./user-card.component.html",
  standalone: true,
  imports: [
    AvatarComponent,
    ActionMenu,
    SvgTripleDot,
    StrToColoredBgComponent,
    LoadingShadingComponent,
    PopupDirective,
    NgIf
  ],
  styleUrls: ["./user-card.component.scss"]
})
export class UserCardComponent implements OnChanges{
  @Input({required: true}) userId!: number
  @Input() nameType: 'SurnameNamePatronymic' | 'SurnameName' | 'SurnameNP' | 'NameSurname' = 'SurnameNamePatronymic'

  @Output() messageClicked = new EventEmitter()
  @Output() linkClicked = new EventEmitter()
  @Output() editClicked = new EventEmitter()
  @Output() deleteClicked = new EventEmitter()
  @Output() onClose = new EventEmitter()

  @ViewChild('topBg') topBg?: ElementRef;

  employee!: UserCardEntity
  phoneNumber: string = ''
  avatarPlaceHolder: string = ''
  avatarBgRGB: string = ''

  isPopupVisible: boolean = false
  isLoading: boolean = false

  readonly sections: ActionSection[] = [
    {
      elements: [{
        id: 'edit',
        name: 'Редактировать'
      },]
    }
  ]

  constructor(
    private renderer: Renderer2,
    private userCardService: UserCardService,
    private toastsService: ToastsService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['userId']){
      this.isLoading = true
      this.userCardService.getUser(this.userId).subscribe({
        next: user=>
        {
          this.isLoading = false;
          this.employee = user;
          this.phoneNumber = this.employee.phoneNumber.replace(/\D/g, '')
          this.phoneNumber = '+' + this.phoneNumber.slice(0, this.phoneNumber.length - 10) +
            ' (' + this.phoneNumber.slice(this.phoneNumber.length - 10, this.phoneNumber.length - 7) +
            ') ' + this.phoneNumber.slice(this.phoneNumber.length -7, this.phoneNumber.length -4) +
            '-' + this.phoneNumber.slice(this.phoneNumber.length -4, this.phoneNumber.length -2) +
            '-' + this.phoneNumber.slice(this.phoneNumber.length -2);
          this.avatarBgRGB = stringToRgb(this.employee.email)
          this.avatarPlaceHolder = ((this.employee.surname[0] ?? '') + (this.employee.name[0] ?? '')).toUpperCase()
          if(this.topBg?.nativeElement != undefined)
          {
            this.renderer.setStyle(this.topBg?.nativeElement, 'background', `linear-gradient(87.91deg, rgba(${this.avatarBgRGB}, 0.25) 0%, rgba(${this.avatarBgRGB}, 0.15) 100%)`, 1)
          }
        },
        error: err => {
          this.isLoading = false
          this.toastsService.createToast({
            title: 'Не удалось загрузить карточку сотрудника',
            description: 'Попробуйте позднее',
            state: ToastState.ERROR
          })
          this.onClose.emit()
        }
      })
    }
  }

  onElementClick(id: string){
    switch(id){
      case 'edit':
        this.editClicked.emit()
        break;
      case 'delete':
        this.deleteClicked.emit()
        break;
    }
    this.isPopupVisible = false
  }
}

export interface UserCardEntity{
  id: number,
  name: string,
  surname: string,
  patronymic?: string
  icon: string,
  email: string,
  phoneNumber: string,
  canEdit: boolean,
  canDelete: boolean
}
