import { Reducer } from 'src/app/core/mvi/store';
import {
  RoleResultAction,
  RoleResultActionTypes,
} from './role-result-action';
import { Injectable } from '@angular/core';
import { RoleState } from './role-state';

@Injectable()
export class RoleReducer
  implements Reducer<RoleState, RoleResultAction>
{
  reduce(
    state: RoleState,
    action: RoleResultAction,
  ): RoleState {
    switch (action.type) {
      case RoleResultActionTypes.UPDATE_ROLE:
        return {...state,
          id: action.id,
          mainData: action.mainData,
          permissions: action.permissions,
          isCustom: action.isCustom,
          canEdit: action.canEdit,
          canDelete: action.canDelete,
        }
      case RoleResultActionTypes.CHANGE_NAV_ITEM:
        return {...state, currentNavItem: action.navItem}
      case RoleResultActionTypes.SAVE_MAIN_DATA:
        return {...state, mainData: action.mainData}
      case RoleResultActionTypes.SAVE_PERMISSIONS:
        return {...state, permissions: { ...action.permissions }}
      case RoleResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        return {...state, isDeleteAlertVisible: action.value}
      case RoleResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value}
      case RoleResultActionTypes.CHANGE_IS_PAGE_LOADING:
        return {...state, isPageLoading: action.value}
      case RoleResultActionTypes.CHANGE_IS_DELETING:
        return {...state, isDeleting: action.value}
    }
  }
}
