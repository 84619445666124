import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  AgilesDeskSettingsResultAction,
  AgilesDeskSettingsResultActionTypes
} from "./agiles-desk-settings-result-action";
import { AgilesDeskSettingsState } from './agiles-desk-settings-state';

@Injectable()
export class AgilesDeskSettingsReducer
  implements Reducer<AgilesDeskSettingsState, AgilesDeskSettingsResultAction>
{
  reduce(state: AgilesDeskSettingsState, action: AgilesDeskSettingsResultAction): AgilesDeskSettingsState {
    switch (action.type) {
      case AgilesDeskSettingsResultActionTypes.CHANGE_NAV_ITEM:
        return {...state, currentNavItem: action.navItem}
    }
  }
}
