import { generateId, YooptaPlugin } from '@yoopta/editor';
import { MyLinkRender } from "./render/my-link-render";
import { LinkCommands, LinkElementProps } from "@yoopta/link";
import { LinkElementMap } from "./types/link-element";

const MyLink = new YooptaPlugin<LinkElementMap>({
  type: 'LinkPlugin',
  elements: {
    link: {
      render: MyLinkRender,
      props: {
        url: null,
        target: '_blank',
        rel: 'noopener noreferrer',
        nodeType: 'inline',
        title: null,
      },
    },
  },
  options: {
    display: {
      title: 'Link',
    },
  },
  commands: LinkCommands,
  parsers: {
    html: {
      serialize: (element, text) => {
        const { url, target, rel, title } = element.props;
        return `<a href="${url}" target="${target}" rel="${rel}">${title || text}</a>`;
      },
      deserialize: {
        nodeNames: ['A'],
        parse: (el, editor) => {
          if (el.nodeName === 'A') {
            const href = el.getAttribute('href') || '';

            const defaultLinkProps = editor.plugins["LinkPlugin"].elements["link"].props as LinkElementProps;

            const target = el.getAttribute('target') || defaultLinkProps.target;
            const rel = el.getAttribute('rel') || defaultLinkProps.rel;
            const title = el.textContent || '';
            const props: LinkElementProps = {
              url: href,
              target,
              rel,
              title,
              nodeType: 'inline',
            };

            return {
              id: generateId(),
              type: 'link',
              props,
              children: [{ text: title }],
            };
          }
          return
        },
      },
    },
  },
});

export { MyLink };
