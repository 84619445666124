import { Component, Input } from '@angular/core';
import { NgClass, NgForOf } from "@angular/common";

@Component({
  selector: "[app-core-with-highlighting]",
  templateUrl: "./text-with-highlighting.component.html",
  imports: [
    NgForOf,
    NgClass
  ],
  standalone: true
})
export class TextWithHighlightingComponent {
  @Input() text: HighlightedPartsStr[] = [];
}

export interface HighlightedPartsStr{
  stringPart: string,
  isHighlighted: boolean
}
