import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
  selector: 'app-core-button-icon',
  templateUrl: './button-icon.component.html',
})
export class ButtonIconComponent implements AfterViewInit{
  @Input() className: string | string[] = [];
  @Input() disabled = false;
  @Input() size: 'm' | 'l' | 'xl' = 'l'
  @Input() autofocus: boolean = false;
  @Input() firstFocusHide: boolean = false;

  @Output() onClick = new EventEmitter<MouseEvent>();

  @ViewChild('button') buttonEl!: ElementRef;

  isWasFocused: boolean = false;

  onClickValue(event: MouseEvent) {
    this.onClick.emit(event);
  }

  ngAfterViewInit(): void {
    if(this.autofocus){
      this.buttonEl.nativeElement.focus()
    }
  }

  onFocus(){
    if(this.firstFocusHide && !this.isWasFocused){
      this.isWasFocused = true
      this.buttonEl.nativeElement.blur()
    }
  }
}
