<div class="h-[40px] bg-white flex text-tt-text-secondary">
  <button class="flex h-full w-[60px] justify-center items-center rounded-l-[20px] border-[1px] border-tt-border-default"
    [ngClass]="{
      'bg-tt-field-background-color': currentType == 'grid-cell'
    }"
    (click)="toggleGrid.emit()"
  >
    <svg-grid2x2/>
  </button>

  <button class="flex h-full w-[60px] justify-center items-center rounded-r-[20px] border-[1px] border-tt-border-default"
    [ngClass]="{
      'bg-tt-field-background-color': currentType == 'list-element'
    }"
    (click)="toggleList.emit()"
  >
    <svg-checklist/>
  </button>
</div>
