import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-input-number",
  templateUrl: "./input-number.component.html",
  styleUrls: ["./input-number.component.scss"]
})
export class InputNumberComponent implements OnChanges{
  @Input() value: number = 0;
  @Output() onChange = new EventEmitter();

  localValue: number = 0;
  numberControl = new FormControl(0);

  constructor() {

    this.numberControl.valueChanges.subscribe(value => {
      if(value){
        this.numberControl.setValue(value, { emitEvent: false });
      }
      else {
        this.numberControl.setValue(0, { emitEvent: false });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes["value"]){
      this.localValue = this.value
      this.numberControl.setValue(this.localValue, { emitEvent: false });
    }
  }

  onChangeValue(event: any){
    const number = event.target.value.replace(/[^0-9]/g, '').replace(/^0+/, '')

    if(number.length > 0){
      this.localValue = parseInt(number);
    }
    else{
      this.localValue = 0
    }
    this.onChange.emit(this.localValue);
  }

  incrementValue(){
    if(this.localValue <= 99998){
      this.localValue++
      this.numberControl.setValue(this.localValue)
      this.onChange.emit(this.localValue);
    }
  }

  decrementValue(){
    if(this.localValue > 0)
    {
      this.localValue--
      this.numberControl.setValue(this.localValue)
      this.onChange.emit(this.localValue);
    }
  }
}
