import {
  TasksStatusEntity
} from "../../../../../agiles-desk-main/presentation/components/tasks-table/tasks-table.component";
import { TaskExecutor } from "../../../../../agiles-desk-main/presentation/components/task-item/task-item.component";

export type AgilesDeskSettingsStatusesAndExecutorsAction =
  | ChangeStatusListAction
  | ChangeExecutorListAction
  | ChangeUnassignedShowAction

export enum AgilesDeskSettingsStatusesAndExecutorsActionTypes {
  CHANGE_STATUS_LIST,
  CHANGE_EXECUTOR_LIST,
  CHANGE_UNASSIGNED_SHOW,
}

export interface ChangeUnassignedShowAction {
  readonly type: AgilesDeskSettingsStatusesAndExecutorsActionTypes.CHANGE_UNASSIGNED_SHOW
  readonly value: boolean
}

export interface ChangeStatusListAction {
  readonly type: AgilesDeskSettingsStatusesAndExecutorsActionTypes.CHANGE_STATUS_LIST
  readonly list: TasksStatusEntity[]
}

export interface ChangeExecutorListAction {
  readonly type: AgilesDeskSettingsStatusesAndExecutorsActionTypes.CHANGE_EXECUTOR_LIST
  readonly list: TaskExecutor[]
}
