import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";

@Injectable()
export class HeaderService{
  private mutableHeader: BehaviorSubject<HeaderEntity> =
    new BehaviorSubject<HeaderEntity>({
      organisationName: 'teamtells',
      breadCrumbs: []
    });

  public header: Observable<HeaderEntity> = this.mutableHeader.asObservable()

  public ChangeBreadCrumbs(breadCrumbs: HeaderBreadCrumb[]){
    this.mutableHeader.next(
    { ...this.mutableHeader.value,
      breadCrumbs: breadCrumbs,
    })
  }

  public ChangeStyle(styles: string | string[]){
    this.mutableHeader.next(
    { ...this.mutableHeader.value,
      styles: styles,
    })
  }

  public changeOrganisationName(name: string){
    this.mutableHeader.next(
    { ...this.mutableHeader.value,
      organisationName: name,
    })
  }
}

export interface HeaderEntity{
  organisationName: string,
  breadCrumbs: HeaderBreadCrumb[]
  styles?: string | string[]
}

export interface HeaderBreadCrumb{
  name: string,
  href: string
  notLocalise?: boolean
}
