import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  Output,
  QueryList, Renderer2,
  SimpleChanges,
  ViewChildren
} from "@angular/core";
import { SelectedTaskEntity, TasksCell, TasksTableRowEntity } from "../tasks-table/tasks-table.component";
import { numToWordType } from "../../../../../../../../core/utils/num-to-word-type";
import { AgilesConstants } from "../../../../../../common/agiles-constants";
import { TaskEntity } from "../task-item/task-item.component";
import { CdkDrag, CdkDragDrop, CdkDropList } from "@angular/cdk/drag-drop";
import { AgileDeskConstants } from "../../../common/agile-desk-constants";

@Component({
  selector: "[app-task-table-row]",
  templateUrl: "./table-row.component.html",
  styleUrls: [
    "./table-row.component.scss",
    "../tasks-table/tasks-table.component.scss",
    "../../common/agiles-desk.component.scss"
  ]
})
export class TableRowComponent implements AfterViewInit, OnChanges {
  @Input({ required: true }) row!: TasksTableRowEntity;
  @Input() showUnassigned: boolean = false;
  @Input() selectedTaskEntities: SelectedTaskEntity[] = [];
  @Input() draggingTask: TaskEntity | null = null;
  @Input() isDragCancelled: boolean = false

  @Output() onAdd = new EventEmitter<TaskEntity[]>();
  @Output() onDragStart = new EventEmitter<TaskDragStartData>();
  @Output() onDrop = new EventEmitter<DropTaskData>();
  @Output() onSelectTask = new EventEmitter<SelectTaskData>();

  @ViewChildren("rowHeader") rowHeaders!: QueryList<ElementRef>;
  @ViewChildren("taskCellsEl", {read: CdkDropList}) taskCellsEl!: QueryList<CdkDropList>;
  @ViewChildren("headerDropList", {read: CdkDropList}) headerDropListsEl!: QueryList<CdkDropList>;

  isDragOverHeader: boolean = false;

  protected readonly ruNumWord = numToWordType;
  protected readonly AgilesConstants = AgilesConstants;

  private resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(entry => {
      this.resizeRow(entry.contentRect.width);
    });
  });

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['draggingTask'] && this.draggingTask === null)
    {
      this.isDragOverHeader = false
    }
    if(changes['row']){
      let countTasks = 0
      this.row.tasksCells.forEach((tasksCell) => {
        countTasks +=tasksCell.tasks.length
      })
      this.row.countTasks = countTasks;
    }
  }

  ngAfterViewInit() {
    this.resizeObserver.observe(this.el.nativeElement)
    const htmlElement = this.el.nativeElement as HTMLElement
    htmlElement.addEventListener('mouseenter', () => this.headerMouseEnter())
    htmlElement.addEventListener('mouseleave', () => this.headerMouseLeave())
  }

  resizeRow(width: number){
    this.rowHeaders.forEach((rowHeader) => {
      this.renderer.setStyle(rowHeader.nativeElement, 'width', `${width - 1}px`);
    })
  }

  headerMouseEnter(){
    if(this.draggingTask !== null)
    {
      this.isDragOverHeader = true
    }
  }

  headerMouseLeave(){
    if(this.draggingTask !== null)
    {
      this.isDragOverHeader = false
    }
  }

  dropPredicate(drag: CdkDrag, drop: CdkDropList): boolean{
    return !drop.data.isRowOpened
  }

  parseToDropListData(cell: TasksCell): TaskDropListData{
    return {
      cell: cell,
      isRowOpened: this.row.isOpen
    }
  }

  protected readonly AgileDeskConstants = AgileDeskConstants;
}

export type TaskDropListData = {
  cell: TasksCell,
  isRowOpened: boolean
}

export type TaskDragStartData = {
  taskEntity: TaskEntity
  taskCell: TasksCell
}

export type SelectTaskData = {
  task: TaskEntity,
  column: TaskEntity[],
  singleSelect: boolean
}

export type DropTaskData = {
  column: TasksCell,
  event: CdkDragDrop<TaskDropListData>
}
