import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  alreadyLoginGuardFunction,
} from "./features/authorization/presentation/guard/auth-guard";
import { AuthorizationComponent } from './features/authorization/presentation/view/authorization.component';
import { MainComponent } from './features/main/presentation/main.component';
import { RegistrationComponent } from './features/registration/presentation/view/registration.component';
import { DocumentComponent } from "./features/document/impl/presentation/view/document.component";
import { MAIN_ROUTES, MainRoutesPaths } from "./routes/main-routes";
import { NewsEditorComponent } from "./features/news/modules/news-editor/presentation/news-editor.component";
import { PasswordRecoveryComponent } from "./features/password-recovery/presentation/view/password-recovery.component";
import { PasswordResetComponent } from "./features/password-reset/presentation/password-reset.component";

export enum AppPaths{
  DOCUMENT = 'document',
  LOGIN = 'login',
  SIGNUP = 'signup',
  PASSWORD_RECOVERY = 'password-recovery',
  PASSWORD_RESET = 'password-reset',
}

const appRoutes: Routes = [
  {
    path: AppPaths.LOGIN,
    component: AuthorizationComponent,
    canActivate: [alreadyLoginGuardFunction],
  },
  {
    path: AppPaths.SIGNUP,
    component: RegistrationComponent,
    canActivate: [alreadyLoginGuardFunction],
  },
  {
    path: AppPaths.PASSWORD_RECOVERY,
    component: PasswordRecoveryComponent,
    canActivate: [alreadyLoginGuardFunction],
  },
  {
    path: AppPaths.PASSWORD_RESET,
    component: PasswordResetComponent,
  },
  {
    path: AppPaths.DOCUMENT + '/:documentId',
    component: DocumentComponent,
  },
  {
    path: MainRoutesPaths.NEWS + '/:newsId' + '/create',
    component: NewsEditorComponent,
  },
  {
    path: MainRoutesPaths.NEWS + '/:newsId' + '/edit',
    component: NewsEditorComponent,
  },
  {
    path: '',
    component: MainComponent,
    children: MAIN_ROUTES,
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
