import { Component } from "@angular/core";

@Component({
  selector: 'svg-bordered-arrow',
  templateUrl: './svg/arrow-borded.svg',
})
export class SvgBorderedArrow {}

@Component({
  selector: 'svg-search',
  templateUrl: './svg/search.svg',
})
export class SvgSearch {}

@Component({
  selector: 'svg-checkmark',
  templateUrl: './svg/checkmark.svg',
})
export class SvgCheckmarkIcon {}

@Component({
  selector: 'svg-password-eye',
  templateUrl: './svg/password-eye.svg'
})
export class PasswordEye {}

@Component({
  selector: 'svg-password-eye-close',
  templateUrl: './svg/password-eye-close.svg'
})
export class PasswordEyeClose {}

@Component({
  selector: 'svg-checkmark-fill',
  templateUrl: './svg/checkmark.square.fill.svg',
})
export class SvgCheckmark {}

@Component({
  selector: 'svg-checkbox-empty',
  templateUrl: './svg/empty-checkbox.svg',
})
export class SvgEmptyCheckbox{}

@Component({
  selector: 'svg-checkbox-circle-empty',
  templateUrl: './svg/checkbox-circle-empty.svg',
})
export class SvgCheckboxCircleEmpty {}

@Component({
  selector: 'svg-checkbox-circle-fill',
  templateUrl: './svg/checkbox-circle-fill.svg',
})
export class SvgCheckboxCircleFill {}
