import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Store } from "../../../../../../../core/mvi/store";
import { RoleUsersState } from "./state/role-users-state.service";
import { RoleUsersExecutor } from "./state/role-users-executor.service";
import { RoleUsersAction, RoleUsersActionTypes } from "./state/role-users-action";
import { RoleUsersResultAction } from "./state/role-users-result-action";
import { RoleUsersReducer } from "./state/role-users-reducer.service";
import { mapUsersToIds } from "../../../utils/map-users-to-ids";
import { RoleConstants } from "../../../common/role-constants";

@Component({
  selector: "app-role-users",
  templateUrl: "./role-users.component.html",
  styleUrls: ["./role-users.component.scss"],
  providers: [
    RoleUsersState,
    RoleUsersExecutor,
    RoleUsersReducer
  ]
})
export class RoleUsersComponent extends Store<RoleUsersState,
  RoleUsersExecutor,
  RoleUsersAction,
  RoleUsersResultAction>
  implements OnChanges {
  @Input({ required: true }) roleId!: string;

  constructor(
    state: RoleUsersState,
    executor: RoleUsersExecutor,
    reducer: RoleUsersReducer
  ) {
    super(state, executor, reducer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.performAction({
      type: RoleUsersActionTypes.INIT,
      roleId: this.roleId
    })
  }

  protected readonly RoleUsersActionTypes = RoleUsersActionTypes
  protected readonly mapUsersToIds = mapUsersToIds;
  protected readonly RoleConstants = RoleConstants;
}
