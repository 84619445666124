import { NavigationSection } from "../components/navigation-section/navigation-section.component";
import { NavigationElement } from "../components/navigation-element/navigation-element.component";
import { MainRoutesPaths } from "src/app/routes/main-routes";
import { Tabs } from "../state/main-state";
import { SvgNavKnowledgeBase, SvgNavMyTeam, SvgNavNews, SvgNavSettings } from "../icons/main-icons";

export class MainConstants {
  public static readonly TRANSLOCO_READ = 'main'
  public static NAVIGATION_SECTIONS: NavigationSection[] = [
    {
      title: 'communication',
      elements: [
        {
          id: Tabs.NEWS,
          name: 'news',
          icon: SvgNavNews,
          url: MainRoutesPaths.NEWS,
          tabIds: [Tabs.NEWS]
        },
        {
          id: Tabs.EMPLOYEES,
          name: 'team',
          icon: SvgNavMyTeam,
          url: MainRoutesPaths.EMPLOYEES,
          tabIds: [Tabs.EMPLOYEES, Tabs.ROLE]
        },
      ]
    },
    {
      title: 'education',
      elements: [
        {
          id: Tabs.KNOWLEDGE_BASE,
          name: 'knowledge-base',
          icon: SvgNavKnowledgeBase,
          url: MainRoutesPaths.KNOWLEDGE_BASE,
          tabIds: [Tabs.KNOWLEDGE_BASE]
        },
      ]
    }
  ]
  public static NAVIGATION_SETTINGS_ELEMENT: NavigationElement = {
    id: Tabs.COMPANY_SETTINGS,
    name: 'settings',
    icon: SvgNavSettings,
    url: MainRoutesPaths.SETTINGS,
    tabIds: [Tabs.COMPANY_SETTINGS]
  }
}
