import { RoleSummeryEntity } from "../../../../domain/role";
import { RoleDeleteProps } from "../../../../domain/role-delete-props";

export type EmployeesRolesResultAction =
  | UpdateRolesResultAction
  | ChangeCreateRoleModalVisibleResultAction
  | FilterRolesResultAction
  | EditRoleResultAction
  | ChangeDeleteAlertModalVisibilityResultAction
  | ChangeIsLoadingResultAction
  | ChangeIsDeletingResultAction

export enum EmployeesRolesResultActionTypes {
  UPDATE_ROLES,
  CHANGE_CREATE_ROLE_MODAL_VISIBLE,
  FILTER_ROLES,
  EDIT_ROLE,
  CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
  CHANGE_IS_LOADING,
  CHANGE_IS_DELETING
}

export interface ChangeIsDeletingResultAction{
  readonly type: EmployeesRolesResultActionTypes.CHANGE_IS_DELETING
  readonly value: boolean
}

export interface ChangeIsLoadingResultAction{
  readonly type: EmployeesRolesResultActionTypes.CHANGE_IS_LOADING
  readonly value: boolean
}

export interface ChangeDeleteAlertModalVisibilityResultAction{
  readonly type: EmployeesRolesResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY
  readonly value: boolean
  readonly deleteProps?: RoleDeleteProps
}

export interface ChangeCreateRoleModalVisibleResultAction{
  readonly type: EmployeesRolesResultActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE;
  readonly value: boolean
}

export interface UpdateRolesResultAction{
  readonly type: EmployeesRolesResultActionTypes.UPDATE_ROLES;
  readonly canCreate: boolean
  readonly canEdit: boolean
  readonly canDelete: boolean
  readonly roles: RoleSummeryEntity[]
  readonly filteredRoles: RoleSummeryEntity[]
}

export interface FilterRolesResultAction{
  readonly type: EmployeesRolesResultActionTypes.FILTER_ROLES;
  readonly filterString: string
  readonly filteredRoles: RoleSummeryEntity[]
}

export interface EditRoleResultAction {
  readonly type: EmployeesRolesResultActionTypes.EDIT_ROLE;
  readonly id: string
}
