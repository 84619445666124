import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import { RegistrationState } from './registration-state';
import {
  RegistrationResultAction,
  RegistrationResultActionTypes,
} from './registration-result-action';
import { clone } from 'cloneable-ts';

@Injectable()
export class RegistrationReducer
  implements Reducer<RegistrationState, RegistrationResultAction>
{
  reduce(
    state: RegistrationState,
    action: RegistrationResultAction,
  ): RegistrationState {
    switch (action.type) {
      case RegistrationResultActionTypes.CHANGE_STATUS_STATE:
        return clone(state, {
          status: action.status,
        });

      case RegistrationResultActionTypes.CHANGE_NAME:
        return clone(state, {
          name: action.name,
          nameError: action.error,
        });

      case RegistrationResultActionTypes.CHANGE_SURNAME:
        return clone(state, {
          surname: action.surname,
          surnameError: action.error,
        });

      case RegistrationResultActionTypes.CHANGE_EMAIL:
        return clone(state, {
          email: action.email,
          emailError: action.error,
        });

      case RegistrationResultActionTypes.CHANGE_PHONE_NUMBER:
        return clone(state, {
          phoneNumber: action.phoneNumber,
          phoneNumberError: action.error,
        });

      case RegistrationResultActionTypes.VALIDATION_ERROR:
        return clone(state, {
          nameError: action.nameError,
          surnameError: action.surnameError,
          emailError: action.emailError,
          phoneNumberError: action.phoneNumberError,
        });

      case RegistrationResultActionTypes.CREATE:
        return clone(state, {
          status: 'create_loading',
        });
    }
  }
}
