import { Component } from "@angular/core";

@Component({
  standalone: true,
  selector: 'svg-warning',
  templateUrl: './svg/warning.svg',
})
export class SvgWarning {}

@Component({
  standalone: true,
  selector: 'svg-info-circle',
  templateUrl: './svg/info.circle.svg',
})
export class SvgInfoCircle {}
