import { Injectable } from '@angular/core';
import {
  TasksStatusEntity
} from "../../../../../agiles-desk-main/presentation/components/tasks-table/tasks-table.component";
import { TaskExecutor } from "../../../../../agiles-desk-main/presentation/components/task-item/task-item.component";

@Injectable()
export class AgilesDeskSettingsStatusesAndExecutorsState {
  readonly statuses: TasksStatusEntity[] = []
  readonly executors: TaskExecutor[] = []
  readonly isUnassignedTasksShow: boolean = false
}
