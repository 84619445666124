import {generateId, YooptaPlugin} from "@yoopta/editor";
import {string} from "slate";
import EmojiCalloutRenderer from "./render/emoji-callout-render";
import { ReactSVG } from "react-svg";

const EmojiCallout = new YooptaPlugin({
  type: 'EmojiCallout',
  elements: {
    emojiCallout: {
      render: EmojiCalloutRenderer,
      props: {
        type: string
      }
    },
  },
  options: {
    display: {
      title: 'Emoji Callout',
      icon: <ReactSVG src="assets/editor/action-menu/callout.svg"/>
    },
    shortcuts: ['emoji-callout'],
  },
  parsers: {
    html: {
      deserialize: {
        nodeNames: ['ASIDE'],
        parse: (el) => {
          if (el.nodeName === 'ASIDE') {
            const text = el.textContent ?? ''
            const type = el.getAttribute('callout-type')

            return {
              id: generateId(),
              type: 'emojiCallout',
              children: [{ text: text }],
              props: {
                type: type,
              },
            };
          }
          return
        },
      },
      serialize: (element, text, blockMeta) => {
        const { align = 'left', depth = 0 } = blockMeta || {};
        return `
          <aside
            data-meta-align="${align}"
            data-meta-depth="${depth}"
            callout-type="${element.props.type}"
            style="
              margin-left: ${depth} + 'px';
              text-align: ${align}"
          >${text}</aside>`;
      },
    },
  },
})

export {EmojiCallout}
