<app-core-auth-container
  *transloco="let t; read: PasswordRecoveryConstants.TRANSLOCO_READ"
  [title]="t('success-title')"
  [backButtonText]="t('back-button-text')"
>
  <app-core-auth-container-icon
    variant="success"
  >
    <svg-reset-password-success/>
  </app-core-auth-container-icon>

  <p>
    {{ t('success-description') }}
    <a
      class="text-tt-primary"
      [href]="'https://' + email"
      target="_blank"
    >{{email}}</a>
  </p>

</app-core-auth-container>
