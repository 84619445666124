import { Component } from "@angular/core";
import { AgilesListState } from "./state/agiles-list-state.service";
import { Store } from "../../../../../../core/mvi/store";
import { AgilesListExecutor } from "./state/agiles-list-executor.service";
import { AgilesListAction, AgilesListActionTypes } from "./state/agiles-list-action";
import { AgilesListResultAction } from "./state/agiles-list-result-action";
import { AgilesListReducer } from "./state/agiles-list-reducer.service";
import { AgilesListConstants } from "../common/agiles-list-constants";
import { provideTranslocoScope } from "@jsverse/transloco";

@Component({
  selector: 'app-agiles-list',
  templateUrl: './agiles-list.component.html',
  styleUrls: [
    './agiles-list.component.scss',
  ],
  providers: [
    AgilesListState,
    AgilesListExecutor,
    AgilesListReducer,
    provideTranslocoScope({ scope: "agiles/agiles-list", alias: AgilesListConstants.TRANSLOCO_READ }),
  ]
})

export class AgilesListComponent
  extends Store<AgilesListState,
  AgilesListExecutor,
  AgilesListAction,
  AgilesListResultAction>
{
  constructor(
    state: AgilesListState,
    executor: AgilesListExecutor,
    reducer: AgilesListReducer
  ) {
    super(state, executor, reducer);
  }

  protected readonly AgilesListActionTypes = AgilesListActionTypes;
  protected readonly AgilesListConstants = AgilesListConstants;
}
