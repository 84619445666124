<div
  class="w-full flex flex-col justify-between"
  *transloco="let t; read: AgileDeskSettingsConstants.TRANSLOCO_READ"
>
  <div>
    <app-core-settings-content-element
      [title]="t('main')"
    >
      <p>{{ t('main-description') }}</p>
    </app-core-settings-content-element>

    <div class="h-[1px] w-full bg-tt-border-default"></div>

    <app-core-settings-content-element>
      <app-core-input-field
        [label]="t('desk-name-label')"
        inputVariant="standard"
        placeholder=""
        [charsCounterMax]="100"
      />
      <app-core-input-field
        [label]="t('desk-ID-label')"
        className="mt-[16px]"
        inputVariant="standard"
        placeholder=""
        [charsCounterMax]="20"
      />
      <div class="mt-[24px] flex justify-between items-center gap-[8px]">
        <p class="text-tt-font-size_l text-tt-text-main">
          {{ t('use-sprints') }}
        </p>
        <app-core-switcher/>
      </div>
    </app-core-settings-content-element>

  </div>

</div>
