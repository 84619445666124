import { ReactSVG } from "react-svg";
import { YooptaEditorConstants } from "../../../../common/yoopta-editor-constants";

const SVG_PATH = YooptaEditorConstants.SVG_PATH + 'action-menu/'

export const ACTION_MENU_ICONS_MAP: Record<string, any> = {
  Paragraph: (<ReactSVG src={SVG_PATH + 'paragraph.svg'} />),
  HeadingOne: (<ReactSVG src={SVG_PATH + 'h1.svg'}/>),
  HeadingTwo: (<ReactSVG src={SVG_PATH + 'h2.svg'}/>),
  HeadingThree: (<ReactSVG src={SVG_PATH + 'h3.svg'}/>),
  Code: (<ReactSVG src={SVG_PATH + 'code.svg'}/>),
  Image: (<ReactSVG src={SVG_PATH + 'image.svg'}/>),
  Video: (<ReactSVG src={SVG_PATH + 'video.svg'}/>),
  Blockquote: (<ReactSVG src={SVG_PATH + 'blockquote.svg'}/>),
  TodoList: (<ReactSVG src={SVG_PATH + 'todo.svg'}/>),
  BulletedList: (<ReactSVG src={SVG_PATH + 'bulleted-list.svg'}/>),
  NumberedList: (<ReactSVG src={SVG_PATH + 'numbered-list.svg'}/>),
  Callout: (<ReactSVG src={SVG_PATH + 'callout.svg'}/>),
  File: (<ReactSVG src={SVG_PATH + 'file.svg'}/>),
};
