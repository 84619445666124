import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NewsRoutesPath } from "../../../routes/news-routes";
import { MainRoutesPaths } from "../../../routes/main-routes";
import { Clipboard } from "@angular/cdk/clipboard";
import { PlatformLocation } from "@angular/common";

@Injectable({ providedIn: 'root' })
export class NewsNavigator {
  baseUrl: string

  constructor(
    private router: Router,
    private platform: PlatformLocation,
    private clipboard: Clipboard,
  ) {
    this.baseUrl = platform.port ? platform.hostname + ':' + platform.port : platform.hostname
  }

  navigateToEdit(id: string){
    this.router.navigate(["/" + MainRoutesPaths.NEWS + "/" + id + '/' + NewsRoutesPath.EDIT]);
  }

  navigateToCreate(id: string){
    this.router.navigate(["/" + MainRoutesPaths.NEWS + "/" + id + '/' + NewsRoutesPath.CREATE]);
  }

  navigateToList(){
    this.router.navigate(["/" + MainRoutesPaths.NEWS])
  }

  copyLinkToNews(id: string){
    this.clipboard.copy(this.baseUrl + "/" + MainRoutesPaths.NEWS +'?newsId=' + id)
  }
}
