import { Component } from '@angular/core';
import { Store } from "../../../core/mvi/store";
import { PasswordResetState } from "./state/password-reset-state";
import { PasswordResetExecutor } from "./state/password-reset-executor";
import { PasswordResetAction, PasswordResetActionTypes } from "./state/password-reset-action";
import { PasswordResetResultAction } from "./state/password-reset-result-action";
import { PasswordResetReducer } from "./state/password-reset-reducer";
import { MainRoutesPaths } from "../../../routes/main-routes";
import { provideTranslocoScope } from "@jsverse/transloco";
import { PasswordResetConstants } from "../common/password-reset-constants";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  providers: [
    PasswordResetState,
    PasswordResetExecutor,
    PasswordResetReducer,
    provideTranslocoScope({ scope: "auth/password-reset", alias: PasswordResetConstants.TRANSCLOCO_READ })
  ]
})
export class PasswordResetComponent
  extends Store<PasswordResetState,
  PasswordResetExecutor,
  PasswordResetAction,
  PasswordResetResultAction>
{
  constructor(
    state: PasswordResetState,
    executor: PasswordResetExecutor,
    reducer: PasswordResetReducer
  ) {
    super(state, executor, reducer);
  }

  protected readonly PasswordResetActionTypes = PasswordResetActionTypes
  protected readonly MainRoutesPaths = MainRoutesPaths;
  protected readonly PasswordResetConstants = PasswordResetConstants;
}
