<ng-container
  *ngIf="selectedCount > 0"
>
  <div
    class="w-full px-[24px] pt-[8px] pb-[24px] flex justify-between gap-[16px] bg-white"
    *transloco="let t; read: AgileDeskConstants.TRANSLOCO_READ"
  >
    <div class="flex gap-[16px] items-center">
      <p class="text-tt-text-default text-tt-font-size_l">
        {{ t('selected', { count: selectedCount, countType: numWordType(selectedCount)}) }}
      </p>
      <app-core-button
        variant="soft"
        (onClick)="onUnselect.emit()"
      >
        {{ t('cancel') }}
      </app-core-button>
    </div>
    <div
      class="rounded-[4px] border border-tt-border-default flex"
    >
      <button
        class="flex justify-center items-center w-[56px] h-[40px] shrink-0"
        (click)="onDelete.emit()"
      >
        <svg-trash
          class="size-[24px] shrink-0 text-tt-delete-button"
        />
      </button>
    </div>
  </div>
</ng-container>

