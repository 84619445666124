import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent {
  @Input() isLoading = false;
  @Output() onPayClicked = new EventEmitter();
}
