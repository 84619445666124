import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NewsSummaryEntity } from "../../../domain/news-summary-entity";
import { NewsConstants } from "../../../../../common/news-constants";
import { NewsNavigator } from "../../../../../navigator/news-navigator.service";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
  selector: 'app-news-full-news',
  templateUrl: './full-news.component.html',
  styleUrls: ['./full-news.component.scss']
})
export class FullNewsComponent {
  @Input() news: NewsSummaryEntity = {
    id: '',
    title: '',
    description: '',
    longreadId: '',
    dateStr: ''
  };
  @Output() onClose = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @ViewChild('linkTooltipEl') linkTooltipEl!: MatTooltip;

  isPopupOpen: boolean = false;
  linkTooltip: string = '';
  isEditorLoading: boolean = true;

  protected readonly NewsConstants = NewsConstants;

  constructor(
    private newsNavigator: NewsNavigator) {
  }

  popupElementClicked(id: string): void {
    switch (id){
      case 'edit':
        this.newsNavigator.navigateToEdit(this.news.id)
        break
      case 'delete':
        this.onDelete.emit()
        break
    }
  }

  copyLink(){
    this.linkTooltip = 'Ссылка скопирована'
    this.linkTooltipEl.show()
    this.newsNavigator.copyLinkToNews(this.news.id)
    setTimeout(() => {
      this.linkTooltipEl.hide();
      this.linkTooltip = ''
    }, 3000)
  }
}
