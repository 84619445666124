import { NavTab } from "src/app/core/components/nav-tabs/nav-tabs.component";
import { Tabs } from "../../main/presentation/state/main-state";
import { SettingsRoutesPaths } from "../../../routes/settings-routes";

export class SettingsConstants {
  public static SETTINGS: string = 'settings'
  public static readonly TRANSLOCO_READ = 'settings'
  public static readonly COMPANY_NAME_MAX_LENGTH = 100

  public static NAV_TABS: NavTab[] = [
    {
      id: Tabs.COMPANY_SETTINGS,
      url: SettingsRoutesPaths.MAIN,
      title: 'main',
    },
    {
      id: Tabs.BILLING,
      url: SettingsRoutesPaths.BILLING,
      title: 'price-and-tariff',
    },
    {
      id: Tabs.BILLING,
      url: SettingsRoutesPaths.ORDERS,
      title: 'orders',
    },
  ]
}
