export function numToWordType(value: number): NumWordType{
  value = Math.abs(value) % 100;
  const num = value % 10;
  if(value > 10 && value < 20) return 'teen';
  if(num > 1 && num < 5) return 'few';
  if(num == 1) return 'one';
  return 'many';
}

export type NumWordType = 'teen' | 'few' | 'one' | 'many';
