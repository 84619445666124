import { Injectable, QueryList } from "@angular/core";
import { EmployeeRoleDto } from "../../../../../../data/dto/employee-dto";
import { InputFieldComponent } from "../../../../../../../../core/components/fields/components/input-field/input-field.component";

@Injectable()
export class CreateEmployeeModalState {
  readonly loadedEmployeeId: number = -1
  readonly loadedEmployeeName: string = ''

  readonly isLoading: boolean = false
  readonly isSendingRequest: boolean = false

  readonly canEditRole: boolean = true

  readonly roles: EmployeeRoleDto[] = []
  readonly topBgColorStr: string = ''
  readonly avatarPlaceholder: string = ''
  readonly avatarColorRGB: string = '129, 129, 129'
  readonly isValidForm: boolean = false
  readonly userForm: UserForm = {
    surname: { value: '', error: ''},
    name: { value: '', error: ''},
    patronymic: { value: '', error: ''},
    email: { value: '', error: ''},
    phoneNumber: { value: '', error: ''},
    roleIds: []
  }

  readonly initialForm: UserForm = {
    surname: { value: '', error: ''},
    name: { value: '', error: ''},
    patronymic: { value: '', error: ''},
    email: { value: '', error: ''},
    phoneNumber: { value: '', error: ''},
    roleIds: []
  }
  readonly isEqualInit: boolean = false
  readonly inputs?: QueryList<InputFieldComponent>

  readonly canDelete: boolean = false
  readonly canEdit: boolean = true
}

export interface UserForm{
  surname: FormField,
  name: FormField,
  patronymic: FormField,
  email: FormField,
  phoneNumber: FormField,
  roleIds: string[]
}

export interface FormField {
  value: string,
  error?: string | null
}
