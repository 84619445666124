<div
  [ngClass]="className"
  class="group"
>
  <div
    [ngClass]="{
      'size-[24px]': size === 's',
      'size-[32px]': size === 'm',
      'size-[36px]': size === 'ml',
      'size-[40px]': size === 'l',
      'size-[56px]': size === 'xl',
      'size-[72px]': size === '2xl',
      'size-[90px]': size === '3xl',
      'size-[120px]': size === '5xl',
      'rounded-[36%]': rounded === 'l',
      'rounded-full': rounded === 'full',
      'bg-[#BFCBDB]': variant === 'neutral' && bgRGB == '',
      'bg-tt-counter-primary': variant === 'primary' && bgRGB == '',
    }"
    class="relative"
  >
    <div
      [ngClass]="{
        'rounded-[36%]': rounded === 'l',
        'rounded-full': rounded === 'full'
      }"
      class="w-full h-full overflow-hidden border-white"
      [style.border-width]="borderSize + 'px'"
      [style.background-color]="'rgba(' + bgRGB + ', 1)'"
    >
      <img
        *ngIf="url"
        class="w-full h-full"
        draggable="false"
        src="{{ url }}"
        alt="{{ placeholder }}"
      />
      <div *ngIf="!url" class="flex items-center justify-center w-full h-full">
        <p
          *ngIf="placeholder != ''"
          [ngClass]="{
            'text-tt-text-main': variant === 'neutral',
            'text-tt-text-contrast-field': variant === 'primary',
            'text-[10px] font-normal leading-[16px]': size === 's',
            'text-[32px] font-medium leading-[39px]': size === '2xl',
            'text-tt-font-size_4xl font-medium': size === 'xl',
            'text-[30px] leading-[36px]': size === '3xl',
            'text-[40px] leading-[48px]': size === '5xl',
            'group-hover:hidden': haveContent
          }"
          class="text-nowrap"
        >
          {{ placeholder.toUpperCase() }}
        </p>

        <div
          [ngClass]="{
            'hidden group-hover:block': placeholder != '',
          }"
        >
          <ng-content/>
        </div>

      </div>
    </div>
    <div
      *ngIf="online"
      class="rounded-full absolute bottom-0 right-0 w-[12px] h-[12px] border-2 bg-tt-primary border-tt-text-contrast-field"
      [ngClass]="{
        'bottom-[-1px] right-[6px]': size === 'xl' || size === '2xl'
      }"
    ></div>
  </div>
</div>
