import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from "@angular/core";
import { Subject, distinctUntilChanged, fromEvent, takeUntil } from "rxjs";

@Directive({
  selector: "[resizeRightX]",
  standalone: true
})
export class ColumnResizeDirective implements OnInit, OnDestroy, OnChanges {
  @Input() maxWidth: number = 0;
  @Input() minWidth: number = 0;
  @Input() baseWidth: number = 0;
  @Input() resizeTooltip: string = "";
  @Input() maxType: "half-parent" | "px" = "px";
  @Input() resizeDisable: boolean = false;
  @Input() position: "relative" | "absolute" | "fixed" = "relative";

  @Output() onResize = new EventEmitter<number>();

  private startX!: number;
  private isResizing = false;
  private resizeLine!: Node;
  private tooltipElement!: HTMLElement;
  private hoverLine!: HTMLElement;
  private parentElement: HTMLElement | null = null;
  private resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(entry => {
      this.onResize.emit(entry.contentRect.width);
    });
  });

  private onDestroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['resizeTooltip'] && this.tooltipElement){
      this.tooltipElement.innerText = this.resizeTooltip
    }
  }

  ngOnInit(): void {
    this.createResizeLine()

    const nativeElement = this.elementRef?.nativeElement as HTMLElement;
    nativeElement.appendChild(this.resizeLine)
    this.parentElement = nativeElement.parentElement
    this.renderer.setStyle(nativeElement, 'position', this.position)
    this.renderer.setStyle(nativeElement, 'min-width', `${this.baseWidth}px`);
    this.renderer.setStyle(nativeElement, 'max-width', `${this.baseWidth}px`);

    this.resizeObserver.observe(nativeElement);

    const mousedown = fromEvent<MouseEvent>(this.resizeLine, 'mousedown');
    mousedown.pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(event => this.onMouseDown(event));
    fromEvent<MouseEvent>(this.resizeLine, 'click').pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(event => event.stopPropagation());
  }

  private onMouseDown(event: MouseEvent) {
    if(!this.resizeDisable)
    {
      event.preventDefault();
      this.startX = event?.pageX;
      this.isResizing = true;
      const maxWidth = this.maxType == 'px' || this.parentElement == null ? this.maxWidth : this.parentElement.offsetWidth / 2
      const element = this.elementRef.nativeElement as HTMLElement
      const pressedLineClass = 'bg-tt-primary'

      this.renderer.addClass(element, 'transition-none')
      this.renderer.setStyle(this.tooltipElement, 'display', 'none')
      this.renderer.addClass(this.hoverLine, pressedLineClass)

      if (this.elementRef) {
        const elementWidth = element.offsetWidth;

        const onMouseMove = (moveEvent: MouseEvent) => {
          if (this.isResizing) {
            const deltaX = moveEvent?.pageX - this.startX;
            const newWidth = elementWidth + deltaX;

            if (newWidth >= this.minWidth && newWidth <= maxWidth) {
              this.renderer.setStyle(this.elementRef?.nativeElement, 'min-width', `${newWidth}px`);
              this.renderer.setStyle(this.elementRef?.nativeElement, 'width', `${newWidth}px`);
              this.renderer.setStyle(this.elementRef?.nativeElement, 'max-width', `${newWidth}px`);
            }
          }
        };

        const onMouseUp = () => {
          this.isResizing = false;
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
          this.renderer.removeClass(this.hoverLine, pressedLineClass)
          this.renderer.removeClass(element, 'transition-none')
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      }
    }
  }

  private createResizeLine(){
    this.resizeLine = this.renderer.createElement('div');
    this.renderer.setStyle(this.resizeLine, 'width', '16px')
    this.renderer.setStyle(this.resizeLine, 'height', '100%')
    this.renderer.setStyle(this.resizeLine, 'cursor', 'w-resize')
    this.renderer.setStyle(this.resizeLine, 'position', 'absolute')
    this.renderer.setStyle(this.resizeLine, 'top', '0')
    this.renderer.setStyle(this.resizeLine, 'right', '-8px')
    this.renderer.addClass(this.resizeLine, 'group')

    this.resizeLine.addEventListener('mouseenter', (event)=>{
      this.onMouseEnter(event)
    })
    this.resizeLine.addEventListener('mouseleave', (event)=>{
      this.onMouseLeave(event)
    })

    this.hoverLine = this.renderer.createElement('div');
    this.renderer.setStyle(this.hoverLine, 'width', '2px')
    this.renderer.setStyle(this.hoverLine, 'height', '100%')
    this.renderer.addClass(this.hoverLine, 'group-hover:bg-tt-primary')
    this.renderer.addClass(this.hoverLine, 'mx-auto')

    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'p-[4px]')
    this.renderer.addClass(this.tooltipElement, 'rounded-[4px]')
    this.renderer.addClass(this.tooltipElement, 'bg-[#616161]')
    this.renderer.addClass(this.tooltipElement, 'text-[#FFF]')
    this.renderer.addClass(this.tooltipElement, 'text-[12px]')
    this.renderer.addClass(this.tooltipElement, 'leading-[16px]')
    this.renderer.addClass(this.tooltipElement, 'font-tt-font-tooltip')
    this.renderer.addClass(this.tooltipElement, 'tracking-[0.033em]');
    this.renderer.setStyle(this.tooltipElement, 'position', 'fixed')
    this.renderer.setStyle(this.tooltipElement, 'display', 'none')
    this.renderer.appendChild(this.tooltipElement, this.renderer.createText(this.resizeTooltip))

    this.resizeLine.appendChild(this.hoverLine)
    this.resizeLine.appendChild(this.tooltipElement)
  }

  onMouseEnter(event: any) {
    if(!this.isResizing)
    {
      event.preventDefault();
      this.renderer.setStyle(this.tooltipElement, 'display', 'block')
      this.renderer.setStyle(this.tooltipElement, 'top', event.clientY + 'px')
      this.renderer.setStyle(this.tooltipElement, 'left', this.hoverLine.getBoundingClientRect().left + 12 + 'px')
    }
  }

  onMouseLeave(event: any){
    if(!this.isResizing) {
      event.preventDefault();
      this.renderer.setStyle(this.tooltipElement, 'display', 'none')
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
