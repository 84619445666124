import { useFloating, inline, flip, shift, offset } from '@floating-ui/react';
import { VideoIcon } from '@radix-ui/react-icons';
import { CSSProperties, useState } from 'react';
import { VideoUploader } from "./video-uploader";
import { Loader } from "../../../components/loader";
import { translate } from "@jsverse/transloco";
import { YooptaEditorConstants } from "../../../../common/yoopta-editor-constants";

const loadingStyles: CSSProperties = {
  width: '100%',
  transition: 'width 100ms ease-in',
};

const Placeholder = ({ attributes, children, blockId }: any) => {
  const [isUploaderOpen, setIsUploaderOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    open: isUploaderOpen,
    onOpenChange: setIsUploaderOpen,
    middleware: [inline(), flip(), shift(), offset(10)],
  });

  const onSetLoading = (state: boolean) => setLoading(state);

  return (
    <div
      className="w-full user-select-none mt-[20px] mb-[10px] relative flex"
      {...attributes}
      contentEditable={false}
    >
      <button
        type="button"
        className={`yoopta-button yoopta-upload-placeholder`}
        onClick={() => setIsUploaderOpen(true)}
        disabled={loading}
        ref={refs.setReference}
      >
        {loading ? (
          <Loader className="mr-2 user-select-none" width={24} height={24} />
        ) : (
          <VideoIcon className="mr-2 user-select-none" width={24} height={24} />
        )}
        <span className="font-medium">{loading ? translate(YooptaEditorConstants.TRANSLOCO_READ + '.downloading') : translate(YooptaEditorConstants.TRANSLOCO_READ + '.video-placeholder')}</span>
        {loading && (
          <div
            className="absolute top-0 left-0 h-full bg-[rgba(55,53,47,0.16)]"
            style={loadingStyles}
          />
        )}
      </button>
      {isUploaderOpen && (
        <VideoUploader
          blockId={blockId}
          floatingStyles={floatingStyles}
          refs={refs}
          onClose={() => setIsUploaderOpen(false)}
          onSetLoading={onSetLoading}
        />
      )}
      {children}
    </div>
  );
};

export { Placeholder };
