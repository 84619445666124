import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionSection } from "../../../../../../../../../../core/components/action-menu/action-menu.component";
import {
  AgilesDeskSettingsAccessUser,
  AgilesDeskSettingsAccessUserRoles
} from "../../state/agiles-desk-settings-access-state";
import { AgileDeskSettingsConstants } from "../../../../../common/agile-desk-settings-constants";

@Component({
  selector: 'app-agiles-desk-settings-access-employee',
  templateUrl: './agiles-desk-access-employee.component.html',
  styleUrls: ['./agiles-desk-access-employee.component.scss']
})
export class AgilesDeskAccessEmployeeComponent {
  @Input({required: true}) user!: AgilesDeskSettingsAccessUser

  @Output() changeRole = new EventEmitter<string>();
  @Output() onDelete = new EventEmitter();

  isActionMenuOpen = false;

  readonly actionMenuSections: ActionSection[] =[
    {
      elements: [
        {
          id: AgilesDeskSettingsAccessUserRoles.ADMIN,
          name: AgilesDeskSettingsAccessUserRoles.ADMIN
        },{
          id: AgilesDeskSettingsAccessUserRoles.PARTICIPANT,
          name: AgilesDeskSettingsAccessUserRoles.PARTICIPANT
        },{
          id: AgilesDeskSettingsAccessUserRoles.PROHIBITED,
          name: AgilesDeskSettingsAccessUserRoles.PROHIBITED
        },
      ]
    }
  ]

  onActionMenuElementClicked(id: string){
    this.isActionMenuOpen = false
    if(id != this.user.role)
    {
      this.changeRole.emit(id)
    }
  }

  protected readonly AgilesDeskSettingsAccessUserRoles = AgilesDeskSettingsAccessUserRoles;
  protected readonly AgileDeskSettingsConstants = AgileDeskSettingsConstants;
}
