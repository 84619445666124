import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpaceItemComponent } from './presentation/components/space-item/space-item.component';
import { SpacesComponent } from './presentation/spaces.component';
import { CreateSpaceModalComponent } from './presentation/components/create-space-modal/create-space-modal.component';
import { RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SvgPlus } from "../../../../core/components/svg-components/svg.components";
import { ImageStubComponent } from "../../../../core/components/image-stub/image-stub.component";
import {
  TextWithHighlightingComponent
} from "../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { AlertModalComponent } from "../../../../core/components/alert-modal/alert-modal.component";
import { FieldsModule } from "../../../../core/components/fields/fields.module";
import { provideTranslocoScope, TranslocoDirective, TranslocoPipe } from "@jsverse/transloco";
import { SpacesConstants } from "./common/spaces-constants";

@NgModule({
  declarations: [
    SpaceItemComponent,
    SpacesComponent,
    CreateSpaceModalComponent
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    SvgPlus,
    RouterLink,
    MatProgressSpinnerModule,
    ImageStubComponent,
    TextWithHighlightingComponent,
    AlertModalComponent,
    FieldsModule,
    TranslocoDirective,
    TranslocoPipe
  ]
})
export class SpacesModule {}
