import { AfterViewInit, Component, ElementRef, HostListener, Input } from "@angular/core";
import { AgilesListItemType } from "../agiles-list-item/agiles-list-item.component";
import { AgilesListConstants } from "../../../common/agiles-list-constants";

@Component({
  selector: 'app-agiles-list-table',
  templateUrl: './agiles-list-table.component.html',
  styleUrls: [
    './agiles-list-table.component.scss',
    '../../../../../../../employees/common/employees.scss'
  ]
})
export class AgilesListTableComponent implements AfterViewInit {
  @Input() agiles: AgilesListItemType[] = []

  adaptiveState: 'onlyName'| 'withoutTag' | 'full' = 'full'

  constructor(private el: ElementRef) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(){
    if(this.el.nativeElement){
      const width = this.el.nativeElement.offsetWidth
      this.adaptiveState = width >= 800 ? 'full'
          : width >= 640 ? 'withoutTag' : 'onlyName'
    }
  }

  ngAfterViewInit(): void {
    this.onResize()
  }

  protected readonly AgilesListConstants = AgilesListConstants;
}
