import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownFieldComponent } from "./components/dropdown-field/dropdown-field.component";
import { TextareaFieldComponent } from "./components/textarea-field/textarea-field.component";
import { SearchFieldComponent } from "./components/search-field/search-field.component";
import { InputNumberComponent } from "./components/input-number/input-number.component";
import { InputFieldComponent } from "./components/input-field/input-field.component";
import { FieldErrorMessageComponent } from "./components/field-error-message/field-error-message.component";
import { CheckboxFieldComponent } from "./components/checkbox-field/checkbox-field.component";
import { AutocompleteFieldComponent } from "./components/autocomplete-field/autocomplete-field.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";
import {
  SvgMinus,
  SvgPlus,
  SvgXMark
} from "../svg-components/svg.components";
import { AvatarComponent } from "../avatar/avatar.component";
import { PopupDirective } from "../../directives/popup.directive";
import {
  PasswordEye,
  PasswordEyeClose,
  SvgBorderedArrow, SvgCheckboxCircleEmpty, SvgCheckboxCircleFill,
  SvgCheckmark,
  SvgCheckmarkIcon, SvgEmptyCheckbox,
  SvgSearch
} from "./icons/fields-icons";

@NgModule({
  declarations: [
    TextareaFieldComponent,
    SearchFieldComponent,
    InputNumberComponent,
    InputFieldComponent,
    FieldErrorMessageComponent,
    DropdownFieldComponent,
    CheckboxFieldComponent,
    AutocompleteFieldComponent,
    SvgBorderedArrow,
    SvgCheckmarkIcon,
    SvgSearch,
    PasswordEyeClose,
    PasswordEye,
    SvgCheckmark,
    SvgEmptyCheckbox,
    SvgCheckboxCircleEmpty,
    SvgCheckboxCircleFill,
  ],
  exports: [
    TextareaFieldComponent,
    InputFieldComponent,
    InputNumberComponent,
    DropdownFieldComponent,
    CheckboxFieldComponent,
    SearchFieldComponent,
    AutocompleteFieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    SvgPlus,
    SvgMinus,
    SvgXMark,
    AvatarComponent,
    PopupDirective,
  ],
  providers: [provideNgxMask()]
})
export class FieldsModule { }
