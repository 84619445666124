import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  AgilesDeskSettingsStatusesAndExecutorsResultAction,
  AgilesDeskSettingsStatusesAndExecutorsResultActionTypes
} from "./agiles-desk-settings-statuses-and-executors-result-action";
import { AgilesDeskSettingsStatusesAndExecutorsState } from './agiles-desk-settings-statuses-and-executors-state';

@Injectable()
export class AgilesDeskSettingsStatusesAndExecutorsReducer
  implements Reducer<AgilesDeskSettingsStatusesAndExecutorsState, AgilesDeskSettingsStatusesAndExecutorsResultAction>
{
  reduce(state: AgilesDeskSettingsStatusesAndExecutorsState, action: AgilesDeskSettingsStatusesAndExecutorsResultAction): AgilesDeskSettingsStatusesAndExecutorsState {
    switch (action.type) {
      case AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.INIT:
        return {...state,
          statuses: action.statuses,
          executors: action.executors,
          isUnassignedTasksShow: action.showUnassigned
        }
      case AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.CHANGE_EXECUTOR_LIST:
        return {...state, executors: action.list}
      case AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.CHANGE_STATUS_LIST:
        return {...state, statuses: action.list}
      case AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.CHANGE_UNASSIGNED_SHOW:
        return {...state, isUnassignedTasksShow: action.value}
    }
  }
}
