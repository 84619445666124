<h1
  class="font-medium text-tt-header-title line-clamp-1 leading-[48px]"
  [ngClass]="{
    'text-[26px]': textSize === '5xl',
    'text-[32px]': textSize === '6xl',
  }"
>{{title}}</h1>

<div class="text-tt-font-size_l text-tt-header-subtitle line-clamp-2">
  <ng-content/>
</div>

<app-nav-tabs
  *ngIf="navTabs.length > 0"
  [tabs]="navTabs"
  [selectedId]="selectedTabId"
  [navType]="navTabType"
  [borderBottom]="borderBottom"
  [queryParamsHandling]="queryParamsHandling"
  [startFromLine]="true"
  [translocoRead]="tabsTransclocoRead"
  [borderType]="'header'"
  (tabClicked)="tabClicked.emit($event)"
/>

<div *ngIf="navTabs.length == 0" class="mt-[16px] w-full h-[1px] bg-tt-separator-color"></div>
