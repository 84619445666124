<ng-container
  *transloco="let t; read: SpaceConstants.MAIN_TRANSLOCO_READ"
>
  <div class="h-full w-full child-routes-size overflow-y-scroll relative tiny-scrollbar flex flex-col"
    (scroll)="onScroll()"
    #scrollableEl
    id="yoopta-editor-scroll"
  >
    <div class="mt-[44px] mx-auto"
      *ngIf="state.isSpaceLoading"
    >
      <mat-spinner class="size-[64px]"></mat-spinner>
    </div>
    <div
      *ngIf="!state.isSpaceLoading"
      class="h-full"
    >
      <div class="px-[24px] relative flex items-center gap-[16px]">
        <app-core-page-header
          class="flex-grow-1"
          navTabType='query'
          queryParamsHandling="merge"
          textSize="5xl"
          [title]="state.name"
          [navTabs]="state.navTabs"
          [selectedTabId]="state.currentNavItem"
          [tabsTransclocoRead]="SpaceConstants.MAIN_TRANSLOCO_READ"
          [borderBottom]="false"
          (tabClicked)="performAction({
            type: SpaceActionTypes.CHANGE_NAV_ITEM,
            id: $event
          })"
        />

        <div
          class="pr-[48px] shrink-1"
          *ngIf="state.permissions.isCreateAvailable && state.currentNavItem == SpaceNavItems.MAIN"
        >
          <app-core-button
            (onClick)="performAction({
              type: SpaceActionTypes.CREATE_DOCUMENT,
            })"
          >
            {{ t('create-document') }}
          </app-core-button>
        </div>
        <div class="absolute bottom-0 h-[1px] space-header-border-width bg-tt-header-separator"></div>
      </div>

      <div *ngIf="state.currentNavItem == SpaceNavItems.MAIN"
        class="flex w-full h-full relative"
      >
        <div class="border-r-[1px] z-10 h-full border-tt-border-default flex flex-col bg-white document-list-transition"
          resizeRightX
          [baseWidth]="300"
          [minWidth]="300"
          [resizeDisable]="!state.isDocumentListVisible"
          [resizeTooltip]="t('resize-tooltip')"
          (onResize)="changeSideBarWidth($event)"
          maxType="half-parent"
          [ngClass]="{
            'fixed top-[48px]': scrollTopValue >= SpaceConstants.CONTENT_TOP_OFFSET,
            'min-w-0 max-w-0 w-0 overflow-hidden border-0': !state.isDocumentListVisible
          }"
        >
          <div class="p-[24px] border-b-[1px] border-tt-border-default"
            #documentsSearch
          >
            <app-core-search-field
              [placeholder]="t('search-placeholder')"
              (onChange)="performAction({
                type: SpaceActionTypes.FILTER_CONTENT,
                value: $event
              })"
            />
          </div>

          <app-core-loading-shading
            *ngIf="state.isContentLoading"
          />

          <app-core-loading-shading
            *ngIf="state.isContentFirstLoading"
            class="bg-white"
          />

          <div
            class="overflow-y-auto space-documents-list tiny-scrollbar flex flex-col"
            #documentsList
          >
            <space-document-item
              *ngFor="let document of state.filteredDocuments"
              [document]="document"
              [selectedId]="state.selectedDocument.id"
              [canEdit]="state.permissions.isCreateAvailable"
              [canDrop]="state.permissions.isCreateAvailable"
              [searchStr]="state.searchFieldValue"
              (moveTo)="performAction({
                type: SpaceActionTypes.MOVE_DOCUMENT,
                movingProps: $event
              })"
              (createClicked)="performAction({
                type: SpaceActionTypes.CREATE_DOCUMENT,
                parentId: $event
              })"
              (documentClicked)="performAction({
                type: SpaceActionTypes.OPEN_DOCUMENT,
                documentId: $event
              })"
              (deleteClicked)="performAction({
                type: SpaceActionTypes.OPEN_DELETE_DOCUMENT_MODAL,
                id: $event.id,
                name: $event.name
              })"
              (editClicked)="performAction({
                type: SpaceActionTypes.MOVE_TO_DOCUMENT_PAGE,
                id: state.selectedDocument.id
              })"
              (renameClicked)="performAction({
                type: SpaceActionTypes.CHANGE_RENAME_MODAL_VISIBILITY,
                value: true,
                id: $event.id,
                name: $event.name
              })"
            />
            <div
              class="font-normal text-tt-font-size_l text-center text-tt-text-secondary"
              *ngIf="state.filteredDocuments.length == 0"
            >
              <app-core-image-stub
                *ngIf="state.searchFieldValue == ''"
                [imageSrc]="''"
                [title]="t('documents-list-empty')"
                [description]="''"
              />
              <app-core-image-stub
                *ngIf="state.searchFieldValue != ''"
                [imageSrc]="''"
                [title]="t('not-found-documents-title')"
                [description]="t('not-found-documents-description')"
              />
            </div>

            <space-drop-to-root
              class="flex-grow w-full min-h-[48px]"
              (onDrop)="performAction({
                type: SpaceActionTypes.MOVE_DOCUMENT,
                movingProps: $event
              })"
            >
            </space-drop-to-root>
          </div>
          <div class="sticky w-full bottom-0 mt-auto">
            <div class="relative w-full">
              <button
                class="document-list-show-button absolute right-[16px] rounded-[20px] max-w-[40px] min-w-[40px] document-list-transition"
                [matTooltip]="t('documents-hide')"
                (click)="performAction({
                  type: SpaceActionTypes.CHANGE_DOCUMENT_LIST_VISIBLE,
                  value: false
                })"
              >
                <svg-arrow
                  class="max-w-[24px] min-w-[24px] max-h-[24px] min-h-[24px] text-tt-text-secondary rotate-180"
                />
              </button>
            </div>
          </div>

        </div>

        <button
          class="document-list-show-button overflow-hidden rounded-r-[20px] fixed mt-auto document-list-transition z-50"
          [matTooltip]="t('documents-show')"
          [ngClass]="{
            'max-w-[48px] min-w-[48px]': !state.isDocumentListVisible,
            'max-w-[0px] min-w-[0px] shadow-none invisible': state.isDocumentListVisible,
          }"
          (click)="performAction({
            type: SpaceActionTypes.CHANGE_DOCUMENT_LIST_VISIBLE,
            value: true
          })"
        >
          <svg-arrow
            class="max-w-[24px] min-w-[24px] max-h-[24px] min-h-[24px] text-tt-text-secondary"
            [class.rotate-180]="state.isDocumentListVisible"
          />
        </button>

        <div class="flex justify-center w-full absolute"
          [class.hidden]="state.selectedDocument.longreadId == ''"
          [style.padding-left]="sideBarWidth + 'px'"
          #editorSide
        >
          <div
            class="flex flex-col items-center w-full m-[24px] -left-[48px] max-w-[800px]"
            #editorContainer
          >
            <div
              class="w-full flex justify-center"
              [class.hidden]="!(state.selectedDocument.longreadId == '' || state.selectedDocumentIsLoading)"
            >
              <mat-spinner class="w-[48px] h-[48px]"></mat-spinner>
            </div>

            <div class="h-[48px] w-full bg-tt-bg-document-header pl-[40px]
              rounded-t-[8px] border-t-[1px] border-l-[1px] border-r-[1px] border-tt-border-default
              flex justify-between items-center"
              [class.hidden]="state.selectedDocument.longreadId == '' || state.selectedDocumentIsLoading"
            >
              <p class="text-tt-font-size_l text-tt-text-secondary">{{ t('last-update-document') }}: {{state.selectedDocumentUpdatedAt}}</p>
              <space-document-header-triple-dot
                *ngIf="state.permissions.isCreateAvailable"
                (editClicked)="performAction({
                  type: SpaceActionTypes.MOVE_TO_DOCUMENT_PAGE,
                  id: state.selectedDocument.id
                })"
                (deleteClicked)="performAction({
                  type: SpaceActionTypes.OPEN_DELETE_DOCUMENT_MODAL,
                  id: state.selectedDocument.id,
                  name: state.selectedDocument.name
                })"
                (renameClicked)="performAction({
                  type: SpaceActionTypes.CHANGE_RENAME_MODAL_VISIBILITY,
                  value: true,
                  id: state.selectedDocument.id,
                  name: state.selectedDocument.name
                })"
              />
            </div>

            <div
             class="rounded-b-[8px] px-[40px] bg-white w-full border-b-[1px] border-l-[1px] border-r-[1px] border-tt-border-default"
             [class.hidden]="state.selectedDocument.longreadId == '' || state.selectedDocumentIsLoading"
            >
              <app-yoopta-editor
                [isReadOnly]="true"
                [isHaveTitle]="true"
                [title]="state.selectedDocument.name"
                [longreadId]="state.selectedDocument.longreadId"
                (startLoading)="performAction({
                  type: SpaceActionTypes.DOCUMENT_START_LOAD
                })"
                (endLoading)="performAction({
                  type: SpaceActionTypes.DOCUMENT_END_LOAD,
                  updatedAt: $event
                })"
              />
            </div>

          </div>
        </div>
      </div>

      <space-settings
        *ngIf="state.currentNavItem == SpaceNavItems.SETTINGS"
        class="flex items-center flex-col w-full p-[24px]"
        [spaceSettingsInput]="{
          spaceId: state.id,
          spaceTitle: state.name
        }"
      />
    </div>

  </div>

  <app-core-alert-modal
    *ngIf="state.isDeleteModalOpen && state.alertDocumentName"
    [title]="t('delete-document')"
    type="warning"
    [cancelButtonText]="t('cancel')"
    [confirmButtonText]="t('delete')"
    [isLoading]="state.alertIsLoading"
    (onConfirmClicked)="performAction({
    type: SpaceActionTypes.DELETE_DOCUMENT
  })"
    (onCancelClicked)="performAction({
    type: SpaceActionTypes.CLOSE_DELETE_DOCUMENT_MODAL
  })"
  >
    <p
      [textParse]="t('delete-document-description', {documentName:state.alertDocumentName})"
      [parseElements]="[
        {
          type: 'text',
          text: state.alertDocumentName,
          className: 'font-medium'
        }
      ]"
    ></p>

  </app-core-alert-modal>

  <app-core-alert-modal
    *ngIf="state.isRenameModalOpen"
    [title]="t('rename-document')"
    type="info"
    [cancelButtonText]="t('cancel')"
    [confirmButtonText]="t('save')"
    [isLoading]="state.alertIsLoading"
    (onConfirmClicked)="performAction({
      type: SpaceActionTypes.SAVE_DOCUMENT_NAME
    })"
    (onCancelClicked)="performAction({
      type: SpaceActionTypes.CHANGE_RENAME_MODAL_VISIBILITY,
      value: false
    })"
    [isInitFocus]="false"
  >
    <div
      class="px-[2px]"
    >
      <app-core-input-field
        inputVariant="standard"
        [label]="t('new-document-name-label')"
        [placeholder]="t('without-name')"
        [value]="state.alertDocumentName ? state.alertDocumentName : ''"
        [defaultValue]="state.alertDocumentName ? state.alertDocumentName : ''"
        [charsCounterMax]="KnowledgeBaseConstants.DOCUMENT_TITLE_MAX_LENGTH"
        [autoFocus]="true"
        (onChange)="performAction({
          type: SpaceActionTypes.CHANGE_ALERT_NAME,
          value: $event
        })"
      />
    </div>
  </app-core-alert-modal>

</ng-container>

<div
  class="fixed left-0 top-0 w-screen h-screen z-50"
  *ngIf="state.isPageLoading"
>
  <app-core-loading-shading/>
</div>

