<div
  [ngClass]="{
    'h-[0px] overflow-hidden': !isVisible,
    'relative flex flex-col gap-[6px] py-[3px] px-[8px]': isVisible
  }"
>
  <app-task-item
    *ngFor="let task of tasks"
    [class]="'drag-box'"
    [task]="task"
    [ngClass]="{
      'hidden': task.selectType !== 'unselect' && isDraggingSelect
    }"
    [executor]="executor"
    [isCompleted]="isCompleted"
    (ctrlClicked)="onMultipleSelectTask.emit(task)"
    (clicked)="onSingleSelectTask.emit(task)"

    cdkDrag
    [cdkDragData]="task"
    (cdkDragStarted)="dragStart($event)"
  >
    <div
      class="drag-placeholder"
      *cdkDragPlaceholder
    ></div>

    <div
      class="relative rounded-[8px] z-[1]"
      *cdkDragPreview
      [style.width]="previewWidth + 'px'"
    >
      <app-task-item
        class="w-full block"
        [task]="task"
        [executor]="executor"
        [isCompleted]="isCompleted"
      />
      <div
        *ngIf="selectedTasks.length > 1 && isDraggingSelect"
        class="
          absolute right-[-12px] top-[-8px]
          h-[24px] min-w-[24px] px-[7px] rounded-full
          flex items-center justify-center bg-tt-primary"
      >
        <p class="text-tt-text-contrast text-tt-font-size_l">{{selectedTasks.length}}</p>
      </div>
    </div>
  </app-task-item>

  <button
    *ngIf="isVisible"
    class="absolute bottom-[-24px] text-tt-text-secondary hover:text-tt-primary flex items-center"
    (click)="onAdd.emit(tasks)"
    [class.invisible]="!showAddButton"
  >
    <svg-plus class="size-[24px]"/>
    <p
      class="text-tt-font-size_s hidden group-hover:block"
      *transloco="let t; read: AgileDeskConstants.TRANSLOCO_READ"
    >
      {{ t('new-card') }}
    </p>
  </button>
</div>
