import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { NewsListDto } from "./dto/news-list-dto";
import { DraftDto } from "./dto/draft-dto";
import { SaveNewsDto } from "./dto/save-news-dto";
import { CreateNewsResponseDto } from "./dto/create-news-response-dto";
import { CreateNewsDto } from "./dto/create-news-dto";
import { NewsEditorInfoDto } from "./dto/news-editor-info-dto";

@Injectable()
export class NewsService {
  private readonly apiUrl: string = `${environment.apiUrl}/news`;

  constructor(private http: HttpClient) {
  }

  getNewsList(): Observable<NewsListDto> {
    return this.http.get<NewsListDto>(`${this.apiUrl}/list`)
  }

  createNews(body: CreateNewsDto): Observable<CreateNewsResponseDto> {
    return this.http.post<CreateNewsResponseDto>(`${this.apiUrl}/create`, body)
  }

  getDraft(): Observable<DraftDto> {
    return this.http.get<DraftDto>(`${this.apiUrl}/draft`)
  }

  saveNews(newsId: string, body: SaveNewsDto): Observable<null>{
    return this.http.post<null>(`${this.apiUrl}/${newsId}/save`, body)
  }

  removeNews(newsId: string): Observable<null> {
    return this.http.delete<null>(`${this.apiUrl}/${newsId}`)
  }

  publishNews(newsId: string): Observable<null> {
    return this.http.post<null>(`${this.apiUrl}/${newsId}/publish`, {})
  }

  uploadImage(newsId: string, data: FormData): Observable<{id: string}> {
    return this.http.post<{id: string}>(`${this.apiUrl}/${newsId}/image`, data)
  }

  downloadImage(imageId: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/${imageId}/image`, {
      headers:{
        Etag: imageId
      },
      responseType: 'blob'
    })
  }

  getNewsEditorInfo(newsId: string): Observable<NewsEditorInfoDto>{
    return this.http.get<NewsEditorInfoDto>(`${this.apiUrl}/${newsId}/editor`)
  }
}
