<app-core-page-shading
  appearanceType="animate"
>
  <div class="flex flex-col w-screen h-screen">
    <div class="h-[40px] flex justify-end shrink-0">
      <button class="size-[40px] text-[#DBDBDE] flex justify-center items-center rounded-full"
        (click)="onClose.emit()"
      >
        <svg-x-mark/>
      </button>
    </div>
    <div class="flex-grow bg-white rounded-t-[24px] flex flex-col overflow-hidden appearance">
      <div class="w-full shrink-0 flex justify-center px-[40px]">
        <div class="max-w-[720px] w-full flex py-[12px] gap-[16px]">
          <div class="flex-grow flex flex-col py-[2px] gap-[4px] text-tt-font-size_l">
            <p class="text-tt-text-main font-medium line-clamp-1">{{ news.title }}</p>
            <p class="text-tt-text-secondary line-clamp-1">Опубликовано {{ news.dateStr }}</p>
          </div>
          <div class="max-w-[96px] flex">
            <app-core-button-icon
              #linkTooltipEl="matTooltip"
              [matTooltip]="linkTooltip"
              (onClick)="copyLink()"
            >
              <svg-link/>
            </app-core-button-icon>
            <div>
              <app-core-button-icon
                (onClick)="isPopupOpen = !isPopupOpen"
              >
                <svg-triple-dot/>
              </app-core-button-icon>

              <app-core-action-menu
                *ngIf="isPopupOpen"
                [sections]="NewsConstants.FULL_NEWS_ACTION_MENU_SECTIONS"
                (elementClicked)="popupElementClicked($event)"
                appPopup
                (popupClose)="isPopupOpen = false"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="w-full h-[1px] bg-tt-border-default"></div>

      <div
        class="flex-grow overflow-y-auto tiny-scrollbar w-full px-[40px]"
        id="yoopta-editor-scroll"
      >
        <div
          class="w-full flex justify-center mt-[20px]"
          *ngIf="isEditorLoading"
        >
          <mat-spinner class="w-[48px] h-[48px]"></mat-spinner>
        </div>

        <app-yoopta-editor
          class="flex justify-center"
          [class.invisible]="isEditorLoading"
          [longreadId]="news.longreadId"
          [title]="news.title"
          [isHaveTitle]="true"
          [isReadOnly]="true"
          (startLoading)="isEditorLoading = true"
          (endLoading)="isEditorLoading = false"
        />
      </div>
    </div>
  </div>

</app-core-page-shading>
