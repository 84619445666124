import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordResetComponent } from "./presentation/password-reset.component";
import {
  SvgResetPasswordError,
  SvgResetPasswordKey,
  SvgResetPasswordSuccess
} from "../../core/components/svg-components/svg.components";
import { Validator } from "../../core/validators/validator";
import { EmptyRule, MaxLengthRule } from "../../core/validators/rule";
import { RouterLink } from "@angular/router";
import { ButtonComponent } from "../../core/components/buttons/button/button.component";
import { AuthLayoutComponent } from "../../core/components/auth/auth-layout/auth-layout.component";
import { FieldsModule } from "../../core/components/fields/fields.module";
import { TranslocoDirective } from "@jsverse/transloco";

@NgModule({
  declarations: [
    PasswordResetComponent
  ],
  imports: [
    CommonModule,
    SvgResetPasswordKey,
    RouterLink,
    SvgResetPasswordSuccess,
    SvgResetPasswordError,
    ButtonComponent,
    AuthLayoutComponent,
    FieldsModule,
    TranslocoDirective
  ],
  providers: [
    {
      provide: 'PasswordResetPasswordValidator',
      useExisting: Validator,
      useFactory: PasswordResetModule.passwordValidatorFactory,
    },
  ]
})
export class PasswordResetModule {
  public static passwordValidatorFactory = () =>
    new Validator([
      new EmptyRule('password-error-empty'),
    ]);
}
