<span
  *ngFor="let el of parsedElements"
  [ngClass]="el.className"
>
  <span
   *ngIf="el.type === 'text'"
  >{{ el.text }}</span>
  <a
    *ngIf="el.type === 'link'"
    [href]="el.urlType === 'href' ? el.url : undefined"
    [routerLink]="el.urlType === 'routerLink' ? el.url : undefined"
  >{{ el.text }}</a>
</span>
