import { Component, EventEmitter, Output } from "@angular/core";
import { Store } from "../../../../../../../core/mvi/store";
import { PaymentState } from "./state/payment-state";
import { PaymentExecutor } from "./state/payment-executor";
import { PaymentReducer } from "./state/payment-reducer";
import { PaymentAction, PaymentActionTypes } from "./state/payment-action";
import { PaymentResultAction } from "./state/payment-result-action";
import { PaymentConstants } from "./common/payment-constants";
import { translate } from "@jsverse/transloco";
import { PaymentAndTariffConstants } from "../../../common/payment-and-tariff-constants";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: [
    './payment.component.scss',
    '../../../../../common/settings.scss'
  ],
  providers: [
    PaymentState,
    PaymentExecutor,
    PaymentReducer
  ]
})
export class PaymentComponent
  extends Store<
    PaymentState,
    PaymentExecutor,
    PaymentAction,
    PaymentResultAction
  >
{
  @Output() onPayClicked = new EventEmitter();

  constructor(
    state: PaymentState,
    executor: PaymentExecutor,
    reducer: PaymentReducer,
  ) {
    super(state, executor, reducer);
  }

  protected readonly InvoicingModalActionTypes = PaymentActionTypes;
  protected readonly PaymentActionTypes = PaymentActionTypes;
  protected readonly PaymentConstants = PaymentConstants;

  translateAmount(amount: string): string{
    return translate(PaymentAndTariffConstants.TRANSLOCO_READ + '.payment.total-to-pay', {price:amount})
  }
}
