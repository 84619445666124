<div
  class="flex max-w-[136px]"
>
  <button
    (click)="decrementValue()"
  >
    <svg-minus/>
  </button>

  <input
    class="text-tt-text-main text-tt-font-size_2xl text-center min-w-[76px] w-max px-[12px] outline-0"
    (input)="onChangeValue($event)"
    [formControl]="numberControl"
    [autocomplete]="false"
    [mask]="'000||0 000||00 000'"
  />

  <button
    (click)="incrementValue()"
  >
    <svg-plus class="shrink-0 size-[24px]"/>
  </button>
</div>

