import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { MainRoutesPaths } from "../../../../../routes/main-routes";
import { SettingsRoutesPaths } from "../../../../../routes/settings-routes";
import { MainConstants } from "../../common/main-constants";
import { numToWordType } from "../../../../../core/utils/num-to-word-type";

@Component({
  selector: 'main-remaining-subscription-modal',
  templateUrl: './remaining-subscription-modal.component.html',
  styleUrls: [
    './remaining-subscription-modal.component.scss',
  ]
})
export class RemainingSubscriptionModalComponent {
  @Input() daysLeft: number | undefined = 0

  isVisible: boolean = true;

  constructor(private router: Router) {}

  close(){
    this.isVisible = false;
  }

  goToPayment(){
    this.router.navigateByUrl(`/${MainRoutesPaths.SETTINGS}/${SettingsRoutesPaths.BILLING}`)
    this.isVisible = false;
  }

  protected readonly MainConstants = MainConstants;
  protected readonly numWordType = numToWordType;
}
