import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AgileDeskConstants } from "../../../common/agile-desk-constants";

@Component({
  selector: "app-task-item",
  templateUrl: "./task-item.component.html",
  styleUrls: ["./task-item.component.scss"]
})
export class TaskItemComponent implements OnChanges {
  @Input({required: true}) task!: TaskEntity

  @Input() isCompleted = false;
  @Input() executor: TaskExecutor | null = null
  @Input() showType: 'base' | 'hide' = 'base'

  @Output() clicked = new EventEmitter()
  @Output() ctrlClicked = new EventEmitter();

  taskTagNumber: string = ''

  protected readonly TaskPriority = TaskPriority;

  onClick(event: MouseEvent) {
    event.stopPropagation();
    if (event.ctrlKey || event.metaKey || event.shiftKey) {
      this.ctrlClicked.emit()
    } else {
      this.clicked.emit()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['task'])
    {
      this.taskTagNumber = this.task.projectTag + '-' + this.task.number
    }
  }

  protected readonly AgileDeskConstants = AgileDeskConstants;
}

export type TaskSelectType = 'unselect' | 'single' | 'multiple'

export interface TaskEntity{
  number: number,
  projectTag: string
  title: string,
  description: string,
  priority: TaskPriority,
  type: TaskType,
  selectType: TaskSelectType
}
export interface TaskExecutor{
  id: number,
  name: string,
  surname: string,
  patronymic?: string,
  email: string,
}

export enum TaskType {TASK, ERROR, BUGFIX}
export enum TaskPriority { NORMAL, CRITICAL}
