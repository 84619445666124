import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgilesListComponent } from "./presentation/agiles-list.component";
import { RouterLink } from "@angular/router";
import { SvgTripleDot } from "../../../../../core/components/svg-components/svg.components";
import { Validator } from "../../../../../core/validators/validator";
import { EmptyRule, MaxLengthRule } from "../../../../../core/validators/rule";
import { AgilesListItemComponent } from "./presentation/components/agiles-list-item/agiles-list-item.component";
import { CreateDeskModalComponent } from "./presentation/components/create-desk-modal/create-desk-modal.component";
import { AgilesListTableComponent } from './presentation/components/agiles-list-table/agiles-list-table.component';
import { AlertModalComponent } from "../../../../../core/components/alert-modal/alert-modal.component";
import {
  TripleDotActionMenuComponent
} from "../../../../../core/components/triple-dot-action-menu/triple-dot-action-menu.component";
import { AvatarComponent } from "../../../../../core/components/avatar/avatar.component";
import {
  TextWithHighlightingComponent
} from "../../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { ButtonComponent } from "../../../../../core/components/buttons/button/button.component";
import { FieldsModule } from "../../../../../core/components/fields/fields.module";
import { TranslocoDirective } from "@jsverse/transloco";
import {
  UserCardOpenContainerComponent
} from "../../../../../core/components/user-card/components/user-card-open-container/user-card-open-container.component";

@NgModule({
  declarations: [
    AgilesListComponent,
    AgilesListItemComponent,
    CreateDeskModalComponent,
    AgilesListTableComponent,
  ],
  imports: [
    CommonModule,
    RouterLink,
    SvgTripleDot,
    AlertModalComponent,
    TripleDotActionMenuComponent,
    AvatarComponent,
    TextWithHighlightingComponent,
    ButtonComponent,
    FieldsModule,
    TranslocoDirective,
    UserCardOpenContainerComponent
  ],
  providers:[
    {
      provide: 'AgilesDeskNameValidator',
      useExisting: Validator,
      useFactory: AgilesListModule.deskNameValidatorFactory,
    },
  ]
})
export class AgilesListModule {
  public static deskNameValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите название Agile-доски'),
      new MaxLengthRule('Название Agile-доски не может быть больше 100 символов', 100),
    ]);
}
