import { Component, Input, OnChanges } from "@angular/core";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { RouterLink } from "@angular/router";

@Component({
  selector: "[textParse]",
  templateUrl: "./text-parser.component.html",
  imports: [
    NgForOf,
    NgClass,
    RouterLink,
    NgIf
  ],
  standalone: true
})
export class TextParserComponent implements OnChanges {
  @Input({required: true}) textParse!: string
  @Input({required: true}) parseElements!: ParseElement[]

  parsedElements: ParseElement[] = []

  ngOnChanges(): void {
    this.parsing()
  }

  private parsing(){
    let findElements: FindElement[] = []
    let currentIndex = 0
    this.parseElements.forEach((element) => {
      const findIndex = this.textParse.indexOf(element.text, currentIndex)
      if(findIndex != -1){
        findElements.push({
          startIndex: findIndex,
          endIndex: findIndex + element.text.length,
          element: element
        })
        currentIndex = findIndex + element.text.length
      }
    })

    findElements = findElements.sort((a, b)=> {
      return a.startIndex < b.startIndex ? -1 : 1
    })

    currentIndex = 0
    this.parsedElements = []
    const baseTextElement : ParseElement = {
      text: '',
      type: 'text',
      className: ''
    }
    findElements.forEach(element => {
      if(element.startIndex !== currentIndex){
        this.parsedElements.push({
          ...baseTextElement,
          text: this.textParse.substring(currentIndex, element.startIndex)
        })
      }
      this.parsedElements.push(element.element)
      currentIndex = element.endIndex
    })

    if(currentIndex != this.textParse.length){
      this.parsedElements.push({
        ...baseTextElement,
        text: this.textParse.substring(currentIndex)
      })
    }
  }
}

type FindElement = {
  startIndex: number,
  endIndex: number,
  element: ParseElement
}

export type ParseElement = {
  text: string
  className: string | string[]
  type: 'link' | 'text'
  urlType?: 'href' | 'routerLink'
  url?: string
}
