<a
  class="
      px-[16px] py-[4px] rounded-[16px]
      nav-title-color
      flex gap-[16px] items-center
    "
  routerLink="{{navElement.url}}"
  [matTooltip]="type === 'hide' ? navElement.name :''"
  [matTooltipPosition]="'above'"
  routerLinkActive="bg-tt-side-bar-item-selected-background font-normal text-tt-text-main"
  #rla="routerLinkActive"
  [ngClass]="{
    'hover:bg-tt-hover-nav-sidebar': !rla.isActive,
    'justify-center': type === 'hide'
  }"
>
  <ng-template [ngComponentOutlet]="navElement.icon"></ng-template>

  <p
    *ngIf="type !== 'hide'"
    class="text-tt-font-size_l"
  >
    <span
      *transloco="let t; read: MainConstants.TRANSLOCO_READ + '.features'"
    >{{ t(navElement.name) }}</span>
  </p>

</a>
