import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsButtonComponent } from "./settings-button/settings-button.component";
import { SettingsContainerComponent } from "./settings-container/settings-container.component";
import { SettingsContentElementComponent } from "./settings-content-element/settings-content-element.component";
import { SettingsNavContainerComponent } from "./settings-nav-container/settings-nav-container.component";
import { SettingsNavItemComponent } from "./settings-nav-item/settings-nav-item.component";
import { ButtonComponent } from "../buttons/button/button.component";

@NgModule({
  declarations: [
    SettingsButtonComponent,
    SettingsContainerComponent,
    SettingsContentElementComponent,
    SettingsNavContainerComponent,
    SettingsNavItemComponent
  ],
  exports: [
    SettingsNavItemComponent,
    SettingsNavContainerComponent,
    SettingsContainerComponent,
    SettingsButtonComponent,
    SettingsContentElementComponent
  ],
  imports: [
    CommonModule,
    ButtonComponent
  ]
})
export class SettingsModule { }
