export type MainAction =
  | LogoutAction
  | SideBarAction
  | ChangeSubscriptionWindowVisibilityAction
  | InitAction

export enum MainActionTypes {
  LOGOUT,
  SHOW_SIDEBAR,
  CHANGE_SUBSCRIPTION_WINDOW_VISIBILITY,
  INIT
}

export interface ChangeSubscriptionWindowVisibilityAction {
  readonly type: MainActionTypes.CHANGE_SUBSCRIPTION_WINDOW_VISIBILITY;
  readonly value: boolean;
}

export interface InitAction {
  readonly type: MainActionTypes.INIT;
}

export interface LogoutAction {
  readonly type: MainActionTypes.LOGOUT;
}

export interface SideBarAction {
  readonly type: MainActionTypes.SHOW_SIDEBAR;
}
