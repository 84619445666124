import { Component, ElementRef, ViewChild } from "@angular/core";
import { HeaderService } from "../../../main/data/header-service";
import { NavTab } from "../../../../core/components/nav-tabs/nav-tabs.component";
import { Tabs } from "../../../main/presentation/state/main-state";
import { MainRoutesPaths } from "../../../../routes/main-routes";
import { AgilesMainConstants } from "../common/agiles-main-constants";
import { provideTranslocoScope } from "@jsverse/transloco";

@Component({
  selector: 'app-agiles-main',
  templateUrl: './agiles-main.component.html',
  styleUrls: [
    './agiles-main.component.scss',
    '../../../main/presentation/main.component.scss',
  ],
  providers: [
    provideTranslocoScope({ scope: "agiles/agiles-main", alias: AgilesMainConstants.TRANSLOCO_READ })
  ]
})
export class AgilesMainComponent {
  @ViewChild('scrollable') scrollableEl!: ElementRef

  navTabs: NavTab[]= [
    {
      id: Tabs.EMPLOYEES,
      url: '/' + MainRoutesPaths.AGILES,
      title: 'main',
    },
  ]

  constructor(
    private headerService: HeaderService,
  ){
    headerService.ChangeStyle('bg-tt-bg-page')
    headerService.ChangeBreadCrumbs([])
  }

  onScroll(){
    if(this.scrollableEl){
      if(this.scrollableEl.nativeElement.scrollTop > 0){
        this.headerService.ChangeStyle('bg-tt-bg-page border-b-[1px] border-tt-border-default')
      }
      else{
        this.headerService.ChangeStyle('bg-tt-bg-page')
      }
      if(this.scrollableEl.nativeElement.scrollTop > 50){
        this.headerService.ChangeBreadCrumbs([
          {
            name: 'agile-desks',
            href: '/' + MainRoutesPaths.AGILES
          }
        ])
      }
      else{
        this.headerService.ChangeBreadCrumbs([])
      }
    }
  }

  protected readonly AgilesMainConstants = AgilesMainConstants;
}
