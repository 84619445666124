import { Component, Input } from "@angular/core";
import { AppPaths } from "../../../../app-routing.module";
import { ButtonComponent } from "../../buttons/button/button.component";
import { NgIf } from "@angular/common";
import { RouterLink } from "@angular/router";

@Component({
  selector: "app-core-auth-container",
  templateUrl: "./auth-container.component.html",
  styleUrls: ["./auth-container.component.scss"],
  standalone: true,
  imports: [
    ButtonComponent,
    NgIf,
    RouterLink,
  ],
  host: {
    class: "w-full flex flex-col items-center"
  }
})
export class AuthContainerComponent {
  @Input() title: string = ''
  @Input() withBackButton: boolean = true
  @Input() backButtonText: string = ''
  protected readonly AppPaths = AppPaths;
}
