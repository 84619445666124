import { AfterViewInit, Component, HostListener, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { Store } from "../../../../../core/mvi/store";
import { NewsEditorState } from "./state/news-editor-state";
import { NewsEditorExecutor } from "./state/news-editor-executor";
import { NewsEditorAction, NewsEditorActionTypes } from "./state/news-editor-action";
import { NewsEditorResultAction } from "./state/news-editor-result-action";
import { NewsEditorReducer } from "./state/news-editor-reducer";
import { AuthService } from "../../../../authorization/domain/auth.service";
import { YooptaEditorComponent } from "../../../../../core/components/yoopta-editor/presentation/yoopta-editor.component";
import { InputFieldComponent } from "../../../../../core/components/fields/components/input-field/input-field.component";
import { TextareaFieldComponent } from "../../../../../core/components/fields/components/textarea-field/textarea-field.component";
import { provideTranslocoScope } from "@jsverse/transloco";
import { YooptaEditorConstants } from "../../../../../core/components/yoopta-editor/common/yoopta-editor-constants";

@Component({
  selector: 'app-news-creation-window',
  templateUrl: './news-editor.component.html',
  styleUrls: ['./news-editor.component.scss'],
  providers: [
    NewsEditorState,
    NewsEditorExecutor,
    NewsEditorReducer,
  ]
})
export class NewsEditorComponent
  extends Store<
    NewsEditorState,
    NewsEditorExecutor,
    NewsEditorAction,
    NewsEditorResultAction
  > implements AfterViewInit
{
  @ViewChild('editor') editor?: YooptaEditorComponent;
  @ViewChildren('publishInput') inputEls?: QueryList<InputFieldComponent | TextareaFieldComponent>

  constructor(
    state: NewsEditorState,
    executor: NewsEditorExecutor,
    reducer: NewsEditorReducer,
    private authService: AuthService,
  ) {
    super(state, executor, reducer);
  }

  protected readonly NewsEditorActionTypes = NewsEditorActionTypes;

  ngAfterViewInit(): void {
    this.performAction({
      type: NewsEditorActionTypes.INIT_CHILDREN,
      editor: this.editor,
      inputEls: this.inputEls
    })
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    event.preventDefault()
    this.performAction({
      type: NewsEditorActionTypes.CLOSE_SESSION
    })
  }

  @HostListener('window:unload', [])
  sendRequest(){
    this.performAction({
      type: NewsEditorActionTypes.PAGE_UNLOAD
    })
  }
}

