<table
  class="w-full table-fixed"
  *transloco="let t; read: AgilesListConstants.TRANSLOCO_READ"
>
  <colgroup
    *ngIf="adaptiveState === 'full'"
  >
    <col span="1" style="width: 100%">
    <col span="1" style="width: 160px;">
    <col span="1" style="width: 240px;">
    <col span="1" style="width: 100px;">
  </colgroup>

  <colgroup
    *ngIf="adaptiveState === 'withoutTag'"
  >
    <col span="1" style="min-width: 300px; width: 100%">
    <col span="1" style="width: 240px;">
    <col span="1" style="width: 100px;">
  </colgroup>

  <colgroup
    *ngIf="adaptiveState === 'onlyName'"
  >
    <col span="1" style="width: 100%">
    <col span="1" style="width: 100px;">
  </colgroup>

  <thead class="bg-tt-light-blue h-[33px]">
  <tr>
    <th class="table-column-header-text pl-[20px] pr-[24px] truncate">
      {{ t('table-header-name') }}
    </th>
    <th
      *ngIf="adaptiveState === 'full'"
      class="table-column-header-text pr-[24px] truncate"
    >{{ t('table-header-tag') }}</th>
    <th
      class="table-column-header-text pr-[24px] truncate"
      *ngIf="adaptiveState !== 'onlyName'"
    >
      {{ t('table-header-admin') }}
    </th>
    <th class="table-column-header-text pr-[20px] text-right truncate">
      {{ t('table-header-actions') }}
    </th>
  </tr>
  </thead>
  <tbody class="bg-white">
  <tr
    *ngFor="let item of agiles; let i = index"
    app-agiles-list-item
    [agile]="item"
    [showTag]="adaptiveState === 'full'"
    [showAdmin]="adaptiveState !== 'onlyName'"

    class="h-[48px] pl-[20px] pr-[16px] relative
      group/triple-dot hover:bg-tt-icon-button-background-hover"
  >
    <div
      *ngIf="i != agiles.length - 1"
      class="absolute bottom-0 left-[20px] bg-tt-border-default w-[calc(100%_-_36px)] h-[1px]"
    ></div>
  </tr>
  </tbody>
</table>
