import { Injectable } from '@angular/core';

export type ResetPasswordStatus = 'idle' | 'success';

@Injectable()
export class PasswordRecoveryState {
  readonly status: ResetPasswordStatus = 'idle';
  readonly email: string = '';
  readonly emailError: string = '';
  readonly isLoading: boolean = false;
}
