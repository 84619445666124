import { numToWordType } from "../../../../../../core/utils/num-to-word-type";
import { PurchasePeriod } from "../../../../data/dto/all-tariffs-dto";
import {
  DropdownItem
} from "../../../../../../core/components/fields/components/dropdown-field/dropdown-field.component";
import { translate } from "@jsverse/transloco";
import { PaymentAndTariffConstants } from "../../common/payment-and-tariff-constants";

export function convertDurationToDropDownItem(item: PurchasePeriod): DropdownItem{
  return {
    id: item.monthsCount.toString(),
    name: getForDurationString(item.monthsCount),
    suffix: item.discount ? getDiscountTranslate(item.discount) : undefined,
  }
}

export function getDiscountTranslate(discount: number): string{
  return ' ' + translate(PaymentAndTariffConstants.TRANSLOCO_READ + ".discount", { discount: discount})
}

export function getForDurationString(countMonth: number): string{
  return translate(
    PaymentAndTariffConstants.TRANSLOCO_READ + '.for-duration',
    { duration: getDurationString(countMonth) }
  )
}

export function getDurationString(countMonth: number, translocoRead: string = PaymentAndTariffConstants.TRANSLOCO_READ): string{
  let duration = ''
  const countYears = countMonth / 12
  if(countYears >= 1) {
    duration = translate(
      translocoRead + '.count-year',
      {
        count: countYears,
        countType: numToWordType(countYears),
      })
  } else {
    switch (countMonth){
      case 6:
        duration = translate(translocoRead + '.half-year')
        break
      default:
        duration = translate(
          translocoRead + '.count-month',
          {
            count: countMonth,
            countType: numToWordType(countMonth),
          })
        break
    }
  }
  return duration
}
