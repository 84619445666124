<td
  class="pl-[20px] pr-[24px]"
>
  <a
    class="text-tt-font-size_l truncate h-full w-full flex items-center"
    app-core-with-highlighting
    [text]="agile.nameParts"
    [routerLink]="agile.id"
  >
  </a>
</td>

<td
  *ngIf="showTag"
  class="truncate text-tt-font-size_m pr-[24px]"
  app-core-with-highlighting
  [text]="agile.tagParts"
>
</td>

<td
  *ngIf="showAdmin"
>
  <div
    class="flex items-center gap-[8px] pr-[24px]"
  >
    <app-user-card-open-container
      [userId]="agile.admin.id"
    >
      <app-core-avatar
        class="block shrink-0"
        size="s"
        variant="primary"
        [placeholder]="agile.admin.surname[0] + agile.admin.name[0]"
        [bgRGB]="stringToRgb(agile.admin.email)"
      />
    </app-user-card-open-container>
    <p class="flex-grow truncate text-tt-font-size_m">
      {{agile.admin.surname + ' '+ agile.admin.name + (agile.admin.patronymic ? ' ' + agile.admin.patronymic : '')}}
    </p>
  </div>
</td>

<td class="pr-[16px]"
>
  <div
    class="flex justify-end"
  >
    <app-core-triple-dot-action-menu
      [translocoRead]="AgilesListConstants.TRANSLOCO_READ"
      [sections]="actionMenuSections"
    />
  </div>
</td>

<ng-content/>
