import React, { useEffect, useState } from "react";
import { PluginElementRenderProps } from "@yoopta/editor";

function NumberedListRenderer (props: PluginElementRenderProps) {

  const { children, element, attributes, } = props;

  const  [index, setIndex]  = useState(0)

  useEffect(() => {
    if('count' in props && typeof props.count === 'number'){
      setIndex(props['count'])
    }
  }, [props]);

  return (
    <div
      className={'yoopta-numbered-list'}
      {...attributes}
      style={{
        paddingLeft: `${index.toString().length > 2? (index.toString().length + 1) * 8 : 24}px`,
      }}
    >
      <span
        className={'absolute text-nowrap left-0 user-select-none text-center max-w-[' + (index.toString().length > 2? (index.toString().length + 1) * 8 : 24) + 'px]'}
        contentEditable={false}
      >{index}. </span>
      {children}
    </div>
  );
}

export default NumberedListRenderer;
