import { ElementRef, Renderer2 } from "@angular/core"
import { HeaderService } from "src/app/features/main/data/header-service"
import { KnowledgeBaseConstants } from "../../../../common/knowledge-base-constants"
import { MainRoutesPaths } from "src/app/routes/main-routes"

export const onSpaceScroll = (
  scrollableEl: ElementRef | undefined,
  headerService: HeaderService,
  spaceId: string,
  spaceName: string,
  renderer: Renderer2,
  searchFieldEl: ElementRef | undefined,
  documentsContainerEl: ElementRef | undefined,
): number => {
  if(scrollableEl)
  {
    const scrollValue = scrollableEl.nativeElement.scrollTop

    changeHeader(scrollValue, headerService, spaceId, spaceName)
    resizeDocumentsContainer(renderer, searchFieldEl, documentsContainerEl)

    return scrollValue
  }

  return 0
}

export const resizeDocumentsContainer = (
  renderer: Renderer2,
  searchFieldRef: ElementRef | undefined,
  documentsContainerRef: ElementRef | undefined,
) => {
  if(searchFieldRef && documentsContainerRef)
  {
    const searchField = searchFieldRef.nativeElement as HTMLElement
    const documentsList = documentsContainerRef.nativeElement as HTMLElement
    const rect = searchField.getBoundingClientRect()
    const documentsListHeight = window.innerHeight - rect.top - searchField.offsetHeight
    renderer.setStyle(documentsList, 'height', documentsListHeight + 'px')
  }
}

const changeHeader = (
  scrollValue: number,
  headerService: HeaderService,
  spaceId: string,
  spaceName: string
) => {
  if(scrollValue > 0){
    headerService.ChangeStyle('bg-tt-bg-page border-b-[1px] border-tt-border-default')
  }
  else{
    headerService.ChangeStyle('bg-tt-bg-page')
  }

  if(scrollValue > 40){
    headerService.ChangeBreadCrumbs([
      {
        name: KnowledgeBaseConstants.KNOWLEDGE_BASE,
        href: '/' + MainRoutesPaths.KNOWLEDGE_BASE
      },
      {
        name: spaceName,
        href: `/${MainRoutesPaths.KNOWLEDGE_BASE}/space/${spaceId}`,
        notLocalise: true
      }
    ])
  }
  else{
    headerService.ChangeBreadCrumbs([{
      name: KnowledgeBaseConstants.KNOWLEDGE_BASE,
      href: '/' + MainRoutesPaths.KNOWLEDGE_BASE
    },])
  }
}
