import { Component, ElementRef, ViewChild } from "@angular/core";
import { Store } from 'src/app/core/mvi/store';
import { RoleConstants } from "../common/role-constants";
import { RoleNavItems, RoleState } from "./state/role-state";
import { RoleExecutor } from "./state/role-executor";
import { RoleReducer } from "./state/role-reducer";
import { RoleResultAction } from "./state/role-result-action";
import { RoleAction, RoleActionTypes } from "./state/role-action";
import { HeaderBreadCrumb, HeaderService } from "../../../../main/data/header-service";
import { EmployeesConstants } from "../../../common/employees-constants";
import { MainRoutesPaths } from "../../../../../routes/main-routes";
import { EmployeesRoutesPath } from "../../../../../routes/employees-routes";
import { provideTranslocoScope } from "@jsverse/transloco";

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: [
    './role.component.scss',
    '../../../../main/presentation/main.component.scss',
  ],
  providers:[
    RoleState,
    RoleExecutor,
    RoleReducer,
    provideTranslocoScope({scope: 'employees/role', alias: RoleConstants.TRANSLOCO_READ}),
  ],
})

export class RoleComponent extends Store<
  RoleState,
  RoleExecutor,
  RoleAction,
  RoleResultAction
>
{
  @ViewChild('scrollable', { read: ElementRef }) scrollableEl?: ElementRef

  protected readonly RoleActionTypes = RoleActionTypes;

  INIT_BREAD_CRUMBS: HeaderBreadCrumb[] = [
    {
      name: EmployeesConstants.EMPLOYEES,
      href: '/' + MainRoutesPaths.EMPLOYEES
    },
    {
      name: RoleConstants.ROLES,
      href: '/' + MainRoutesPaths.EMPLOYEES + '/' + EmployeesRoutesPath.ROLES
    }
  ]

  constructor(
    state: RoleState,
    executor: RoleExecutor,
    reducer: RoleReducer,
    private headerService: HeaderService
  ) {
    super(state, executor, reducer);
    headerService.ChangeBreadCrumbs(this.INIT_BREAD_CRUMBS)
    headerService.ChangeStyle('bg-tt-bg-page')
  }

  onScroll(){
    if(this.scrollableEl){
      if(this.scrollableEl.nativeElement.scrollTop > 0){
        this.headerService.ChangeStyle('bg-tt-bg-page border-b-[1px] border-tt-border-default')
      }
      else{
        this.headerService.ChangeStyle('bg-tt-bg-page')
      }
      if(this.scrollableEl.nativeElement.scrollTop > 50){
        this.headerService.ChangeBreadCrumbs([...this.INIT_BREAD_CRUMBS,{
          name: this.state.mainData.name,
          href: MainRoutesPaths.ROLE + '/' + this.state.id,
          notLocalise: true
        }])
      }
      else{
        this.headerService.ChangeBreadCrumbs(this.INIT_BREAD_CRUMBS)
      }
    }
  }

  protected readonly RoleNavItems = RoleNavItems;
  protected readonly RoleConstants = RoleConstants;
}

