import { AgilesDeskSettingsStatusesAndExecutorsState } from "./agiles-desk-settings-statuses-and-executors-state";
import {
  AgilesDeskSettingsStatusesAndExecutorsAction,
  AgilesDeskSettingsStatusesAndExecutorsActionTypes
} from "./agiles-desk-settings-statuses-and-executors-action";
import {
  AgilesDeskSettingsStatusesAndExecutorsResultAction,
  AgilesDeskSettingsStatusesAndExecutorsResultActionTypes
} from "./agiles-desk-settings-statuses-and-executors-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { AgilesService } from "../../../../../../data/agiles.service";

@Injectable()
export class AgilesDeskSettingsStatusesAndExecutorsExecutor extends Executor<
  AgilesDeskSettingsStatusesAndExecutorsState,
  AgilesDeskSettingsStatusesAndExecutorsAction,
  AgilesDeskSettingsStatusesAndExecutorsResultAction
> {

  constructor(
    private agilesService: AgilesService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<AgilesDeskSettingsStatusesAndExecutorsState, AgilesDeskSettingsStatusesAndExecutorsResultAction>,
    getState: () => AgilesDeskSettingsStatusesAndExecutorsState,
    onReduced: (state: AgilesDeskSettingsStatusesAndExecutorsState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.handleInit()
  }

  execute(action: AgilesDeskSettingsStatusesAndExecutorsAction) {
    switch (action.type) {
      case AgilesDeskSettingsStatusesAndExecutorsActionTypes.CHANGE_EXECUTOR_LIST:
        this.agilesService.setExecutors(action.list)
        this.reduce({
          type: AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.CHANGE_EXECUTOR_LIST,
          list: action.list
        })
        break;
      case AgilesDeskSettingsStatusesAndExecutorsActionTypes.CHANGE_STATUS_LIST:
        this.agilesService.setStatuses(action.list)
        this.reduce({
          type: AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.CHANGE_STATUS_LIST,
          list: action.list
        })
        break;
      case AgilesDeskSettingsStatusesAndExecutorsActionTypes.CHANGE_UNASSIGNED_SHOW:
        this.agilesService.changeShowUnassigned(action.value)
        this.reduce({
          type: AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.CHANGE_UNASSIGNED_SHOW,
          value: action.value
        })
        break;
    }
  }

  private handleInit(){
    this.reduce({
      type: AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.INIT,
      statuses: this.agilesService.getStatuses(),
      executors: this.agilesService.getExecutors(),
      showUnassigned: this.agilesService.getShowUnassigned()
    })
  }
}
