<ng-container
  *transloco="let t; read: AgileDeskSettingsConstants.TRANSLOCO_READ"
>
  <app-core-settings-content-element
    [title]="t('access-rights')"
  >
    <p
      [textParse]="t('access-rights-description')"
      [parseElements]="[{
        text: t('access-rights-description-link'),
        type: 'text',
        className: 'text-tt-primary cursor-pointer',
      }]"
    ></p>
  </app-core-settings-content-element>

  <div class="h-[1px] w-full bg-tt-border-default"></div>

  <app-core-settings-content-element
    class="bg-[#F5F8FD]"
  >
    <div
      class="flex gap-[16px] w-full items-center"
    >
      <app-core-search-field
        class="w-full"
        [placeholder]="t('access-rights-search')"
        (onChange)="performAction({
        type: AgilesDeskSettingsAccessActionTypes.CHANGE_SEARCH_FIELD,
        value: $event
      })"
      />

      <app-core-button
        class="shrink-0 block"
        (onClick)="performAction({
        type: AgilesDeskSettingsAccessActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY,
        value: true
      })"
      >
        {{ t('add') }}
      </app-core-button>
    </div>
  </app-core-settings-content-element>

  <app-agiles-desk-settings-access-employee
    *ngFor="let user of state.filteredUsers"
    [user]="user"
    (changeRole)="performAction({
    type: AgilesDeskSettingsAccessActionTypes.CHANGE_USER_ROLE,
    user: user,
    roleId: $event
  })"
    (onDelete)="performAction({
    type: AgilesDeskSettingsAccessActionTypes.DELETE_USER,
    user: user
  })"
  />

  <app-select-user-modal
    *ngIf="state.isOpenSelectUsersModal"
    [alreadySelectedUserIds]="mapUsersToIds(state.users)"
    (onClose)="performAction({
    type: AgilesDeskSettingsAccessActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY,
    value: false
  })"
    (onAddUsers)="performAction({
    type: AgilesDeskSettingsAccessActionTypes.ADD_USERS,
    users: $event
  })"
  />

  <app-core-alert-modal
    *ngIf="state.isOpenDeleteUserModal && state.userToDelete"
    type="warning"
    [title]="t('delete-participant')"
    [confirmButtonText]="t('delete')"
    [cancelButtonText]="t('cancel')"
    (onCancelClicked)="performAction({
    type: AgilesDeskSettingsAccessActionTypes.CLOSE_DELETE_MODAL
  })"
    (onConfirmClicked)="performAction({
    type: AgilesDeskSettingsAccessActionTypes.DELETE_CONFIRM
  })"
  >
    <p
      [textParse]="t('delete-participant-description', { participantName: state.userToDelete.user.surname + ' ' + state.userToDelete.user.name })"
      [parseElements]="[{
        type: 'text',
        text: state.userToDelete.user.surname + ' ' + state.userToDelete.user.name,
        className: 'font-medium'
      }]"
    ></p>
  </app-core-alert-modal>
</ng-container>
