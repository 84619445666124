import { Component, Input, OnInit } from '@angular/core';
import { ToastsService } from 'src/app/core/components/toast-alert/services/toast-alert.service';
import { NgForOf, NgIf } from "@angular/common";
import { SvgError, SvgSuccess } from "./icons/toast-alert-icons";

@Component({
  selector: "app-core-toast-alert",
  templateUrl: "./toast-alert.component.html",
  imports: [
    SvgError,
    SvgSuccess,
    NgIf,
    NgForOf
  ],
  standalone: true
})
export class ToastComponent {
  constructor(private toastsService: ToastsService) {
    this.toasts = toastsService.getToasts();
  }

  toasts: ToastAtributes[] = [];

  onCrossClick(index: number) {
    this.toastsService.closeToast(index);
  }

  isSuccess(toastState: ToastState): boolean {
    return toastState == ToastState.SUCCESS;
  }

  isError(toastState: ToastState): boolean {
    return toastState == ToastState.ERROR;
  }
}

export enum ToastState {
  SUCCESS,
  ERROR,
}

export interface ToastAtributes {
  title: string;
  description: string;
  state: ToastState;
}
