import { Injectable } from "@angular/core";
import { RoleSummeryEntity } from "../../../../domain/role";
import { RoleDeleteProps } from "../../../../domain/role-delete-props";

@Injectable()
export class EmployeesRolesState {
  readonly roles: RoleSummeryEntity[] = []
  readonly filteredRoles: RoleSummeryEntity[] = []
  readonly editRoleId: string = ''
  readonly searchFieldValue: string = ''
  readonly isCreateRoleModalVisible: boolean = false
  readonly isLoading: boolean = false
  readonly isDeleting: boolean = false
  readonly canCreate: boolean = false
  readonly canDelete: boolean = false
  readonly canEdit: boolean = false

  readonly isDeleteAlertVisible: boolean = false
  readonly roleDeleteProps: RoleDeleteProps | null = null
}
