<p
  *ngIf="label != ''"
  class="text-tt-text-main text-tt-font-size_l"
>
  {{label}}
</p>

<button
  class="rounded-[16px] w-[35px] h-[22px] p-[2px] transition group flex items-center"
  [ngClass]="{
    'bg-[#EEF1F4]': !value,
    'bg-opacity-90': isDisabled && !value,
    'bg-tt-primary-button': value && !isDisabled,
    'bg-[#B8BECD]': value && isDisabled,
  }"
  (click)="onSwitch.emit()"
  [disabled]="isDisabled"
>
  <div
    class="h-full my-[2px] aspect-square transition duration-200 rounded-full"
    [ngClass]="{
      'bg-white translate-x-[13px]': value,
      'bg-[#B8BECD]': !value,
      'bg-opacity-80': isDisabled
    }"
  ></div>
</button>
