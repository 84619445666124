import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PermissionsDto } from "src/app/core/services/role-service/dto/create-role-dto";
import { provideTranslocoScope, TranslocoDirective } from "@jsverse/transloco";
import { FieldsModule } from "../fields/fields.module";

const TRANSLOCO_READ = 'permissionsForm';

@Component({
  selector: "app-core-permissions-form",
  templateUrl: "./permissions-form.component.html",
  styleUrls: ["./permissions-form.component.scss"],
  standalone: true,
  imports: [
    TranslocoDirective,
    FieldsModule
  ],
  providers: [
    provideTranslocoScope({ scope: "core/permissions-form", alias: TRANSLOCO_READ })
  ]
})
export class PermissionsFormComponent {
  @Input() isReadOnly: boolean = false;
  @Input() permissions: PermissionsDto = {
    knowledgeBase: {
      viewKnowledgeBase: true,
      createKnowledgeBase: false,
      editKnowledgeBase: false,
      deleteKnowledgeBase: false
    },
    company: {
      viewEmployee: true,
      createEmployee: false,
      editEmployee: false,
      deleteEmployee: false
    },
    settings: {
      billing: false,
      main: false,
    },
    role: {
      viewRole: true,
      createRole: false,
      editRole: false,
      deleteRole: false
    }
  }

  @Output() onChanges = new EventEmitter<PermissionsDto>()

  createEditEmployeeToggle(){
    this.permissions.company.createEmployee = !this.permissions.company.createEmployee
    this.permissions.company.editEmployee = !this.permissions.company.editEmployee
  }

  createEditRoleToggle(){
    this.permissions.role.createRole = !this.permissions.role.createRole
    this.permissions.role.editRole = !this.permissions.role.editRole
  }

  protected readonly TRANSLOCO_READ = TRANSLOCO_READ;
}

