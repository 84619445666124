import { Component } from '@angular/core';
import { TranslocoService } from "@jsverse/transloco";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  localisation: 'ru' | 'en' = 'ru'

  constructor(
    private translocoService: TranslocoService
  ) {
  }

  changeLocalisation(){
    if(this.localisation === 'ru'){
      this.localisation = 'en'
    } else{
      this.localisation = 'ru'
    }

    this.translocoService.setActiveLang(this.localisation)
  }
}
