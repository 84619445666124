import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MainRoutesPaths } from "../../../routes/main-routes";
import { SettingsRoutesPaths } from "../../../routes/settings-routes";

@Injectable()
export class SettingsNavigator {
  constructor(private router: Router) {
  }

  navigateToBilling(){
    this.router.navigate([`/${MainRoutesPaths.SETTINGS}/${SettingsRoutesPaths.BILLING}`]);
  }

  navigateToOrders(){
    this.router.navigate([`/${MainRoutesPaths.SETTINGS}/${SettingsRoutesPaths.ORDERS}`]);
  }
}
