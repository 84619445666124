import { Component, Input } from "@angular/core";
import { AppPaths } from "../../../../../app-routing.module";
import { RegistrationConstants } from "../../../common/registration-constants";

@Component({
  selector: 'app-registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss']
})
export class RegistrationCompleteComponent {
  @Input({required: true}) email!: string

  protected readonly AppPaths = AppPaths;
  protected readonly RegistrationConstants = RegistrationConstants;
}
