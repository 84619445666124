import { Component } from '@angular/core';
import { Store } from "../../../../../core/mvi/store";
import { PaymentAndTariffState } from "./state/payment-and-tariff-state";
import { PaymentAndTariffExecutor } from "./state/payment-and-tariff-executor";
import { PaymentAndTariffAction } from "./state/payment-and-tariff-action";
import { PaymentAndTariffResultAction } from "./state/payment-and-tariff-result-action";
import { PaymentAndTariffReducer } from "./state/payment-and-tariff-reducer";
import { ReceiptService } from "../data/receipt-service";
import { PaymentAndTariffConstants } from "../common/payment-and-tariff-constants";
import { provideTranslocoScope } from "@jsverse/transloco";

@Component({
  selector: 'app-payment-and-tariff',
  templateUrl: './payment-and-tariff.component.html',
  styleUrls: [
    './payment-and-tariff.component.scss',
    '../../../common/settings.scss'
  ],
  providers: [
    PaymentAndTariffState,
    PaymentAndTariffExecutor,
    PaymentAndTariffReducer,
    ReceiptService,
    provideTranslocoScope({ scope: 'settings/payment-and-tariff', alias: PaymentAndTariffConstants.TRANSLOCO_READ }),
  ]
})
export class PaymentAndTariffComponent
  extends Store<PaymentAndTariffState,
    PaymentAndTariffExecutor,
    PaymentAndTariffAction,
    PaymentAndTariffResultAction>
{
  constructor(
    state: PaymentAndTariffState,
    executor: PaymentAndTariffExecutor,
    reducer: PaymentAndTariffReducer
  ) {
    super(state, executor, reducer);
  }

  protected readonly PaymentAndTariffConstants = PaymentAndTariffConstants;
}
