import { OrderEntity } from "../../domain/order-entity";
import { InvoiceDto } from "../../../../data/dto/invoices-list-dto";

export type OrdersResultAction =
  | InitResultAction
  | ChangeCancelModalVisibleResultAction
  | ChangeIsLoadingResultAction
  | ChangeCancelModalIsLoadingResultAction
  | ChangeLangResultAction

export enum OrdersResultActionTypes {
  INIT,
  CHANGE_CANCEL_MODAL_VISIBILITY,
  CHANGE_IS_LOADING,
  CHANGE_CANCEL_MODAL_IS_LOADING,
  CHANGE_LANG
}

export interface ChangeCancelModalVisibleResultAction {
  readonly type: OrdersResultActionTypes.CHANGE_CANCEL_MODAL_VISIBILITY
  readonly value: boolean
}

export interface ChangeCancelModalIsLoadingResultAction {
  readonly type: OrdersResultActionTypes.CHANGE_CANCEL_MODAL_IS_LOADING
  readonly value: boolean
}

export interface ChangeIsLoadingResultAction {
  readonly type: OrdersResultActionTypes.CHANGE_IS_LOADING
  readonly value: boolean
  readonly isError: boolean
}

export interface InitResultAction {
  readonly type: OrdersResultActionTypes.INIT
  readonly activeOrderDto?: InvoiceDto
  readonly storyOrdersDto: InvoiceDto[]
  readonly activeOrder?: OrderEntity
  readonly storyOrders: OrderEntity[]
  readonly activeOrderDueDate: string
}

export interface ChangeLangResultAction {
  readonly type: OrdersResultActionTypes.CHANGE_LANG
  readonly activeOrder?: OrderEntity
  readonly storyOrders: OrderEntity[]
}
