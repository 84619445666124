import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  SvgTripleDot,
  SvgXMark
} from "src/app/core/components/svg-components/svg.components";
import {
  DateRule,
  EmptyRule,
  MaxLengthRule,
} from 'src/app/core/validators/rule';
import { Validator } from 'src/app/core/validators/validator';
import { EmployeesListComponent } from './presentation/employees-list.component';
import { CreateEmployeeModalComponent } from './presentation/components/create-employee-modal/create-employee-modal.component';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatCardModule } from "@angular/material/card";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { EmployeeTrComponent } from './presentation/components/employee-tr/employee-tr.component';
import { ChipsComponent } from './presentation/components/create-employee-modal/components/role-chips/chips.component';
import { RolesPopupComponent } from './presentation/components/create-employee-modal/components/roles-popup/roles-popup.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { ButtonComponent } from "../../../../core/components/buttons/button/button.component";
import { ModalWindowModule } from "../../../../core/components/modal-window/modal-window.module";
import { AvatarComponent } from "../../../../core/components/avatar/avatar.component";
import { StrToColoredBgComponent } from "../../../../core/components/str-to-colored-bg/str-to-colored-bg.component";
import { AlertModalComponent } from "../../../../core/components/alert-modal/alert-modal.component";
import { ImageStubComponent } from "../../../../core/components/image-stub/image-stub.component";
import { ActionMenu } from "../../../../core/components/action-menu/action-menu.component";
import { PopupDirective } from "../../../../core/directives/popup.directive";
import {
  TextWithHighlightingComponent
} from "../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { UserCardComponent } from "../../../../core/components/user-card/user-card.component";
import { FieldsModule } from "../../../../core/components/fields/fields.module";
import { SvgChipsX, SvgPhoto, SvgQuestionMarkCircle } from "./presentation/icons/employees-list-icons";
import {
  UserCardOpenContainerComponent
} from "../../../../core/components/user-card/components/user-card-open-container/user-card-open-container.component";
import { TranslocoDirective } from "@jsverse/transloco";
import { TextParserComponent } from "../../../../core/components/text-parser/text-parser.component";
import { EmployeeListConstants } from "./common/employees-list-constants";

@NgModule({
  declarations: [
    EmployeesListComponent,
    CreateEmployeeModalComponent,
    EmployeeTrComponent,
    ChipsComponent,
    RolesPopupComponent,
    SvgPhoto,
    SvgChipsX,
    SvgQuestionMarkCircle
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    SvgXMark,
    SvgTripleDot,
    MatNativeDateModule,
    MatCardModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    NgOptimizedImage,
    ButtonComponent,
    ModalWindowModule,
    AvatarComponent,
    StrToColoredBgComponent,
    AlertModalComponent,
    ImageStubComponent,
    ActionMenu,
    PopupDirective,
    TextWithHighlightingComponent,
    UserCardComponent,
    FieldsModule,
    UserCardOpenContainerComponent,
    TranslocoDirective,
    TextParserComponent
  ],
  providers: [
    {
      provide: "NewEmployeeFirstNameValidator",
      useExisting: Validator,
      useFactory: EmployeesListModule.firstNameValidatorFactory
    },
    {
      provide: "NewEmployeeLastNameValidator",
      useExisting: Validator,
      useFactory: EmployeesListModule.lastNameValidatorFactory
    },
    {
      provide: "NewEmployeeDateOfBirthValidator",
      useExisting: Validator,
      useFactory: EmployeesListModule.dateOfBirthValidatorFactory
    },
    {
      provide: "NewEmployeePatronymicValidator",
      useExisting: Validator,
      useFactory: EmployeesListModule.patronymicValidatorFactory
    }
  ],
})
export class EmployeesListModule {

  public static firstNameValidatorFactory = () =>
    new Validator([
      new EmptyRule('name-error-empty'),
      new MaxLengthRule('name-error-max-length', EmployeeListConstants.INPUT_MAX_LENGTH),
    ]);

  public static lastNameValidatorFactory = () =>
    new Validator([
      new EmptyRule('surname-error-empty'),
      new MaxLengthRule('surname-error-max-length', EmployeeListConstants.INPUT_MAX_LENGTH),
    ]);

  public static dateOfBirthValidatorFactory = () =>
    new Validator([
      new EmptyRule(''),
      new DateRule(''),
    ]);

  public static patronymicValidatorFactory = () =>
      new Validator([
        new MaxLengthRule('patronymic-error-max-length', EmployeeListConstants.INPUT_MAX_LENGTH),
      ]);
}
