import {Inject, Injectable} from '@angular/core';
import {Executor, Reducer} from 'src/app/core/mvi/store';
import {Validator} from 'src/app/core/validators/validator';
import {RegistrationAction, RegistrationActionTypes,} from './registration-action';
import {RegistrationResultAction, RegistrationResultActionTypes,} from './registration-result-action';
import {RegistrationState} from './registration-state';
import {RegistrationService} from '../../domain/registration-service';
import {RegistrationResult} from '../../domain/model/registration-result';
import { translate, TranslocoService } from "@jsverse/transloco";
import { RegistrationConstants } from "../../common/registration-constants";

@Injectable()
export class RegistrationExecutor extends Executor<
  RegistrationState,
  RegistrationAction,
  RegistrationResultAction
> {

  constructor(
    @Inject('RegistrationEmailValidator') private emailValidator: Validator,
    @Inject('RegistrationNameValidator') private nameValidator: Validator,
    @Inject('RegistrationSurnameValidator') private surnameValidator: Validator,
    @Inject('RegistrationPhoneNumberValidator')
    private phoneNumberValidator: Validator,
    private service: RegistrationService,
    private translocoService: TranslocoService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<RegistrationState, RegistrationResultAction>,
    getState: () => RegistrationState,
    onReduced: (state: RegistrationState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.translocoService.langChanges$.subscribe(() => this.validateFields())
  }

  execute(action: RegistrationAction) {
    switch (action.type) {
      case RegistrationActionTypes.VALIDATE:
        this.validateFields()
        break;
      case RegistrationActionTypes.CHANGE_NAME:
        this.handleChangeName(action.name)
        break;
      case RegistrationActionTypes.CHANGE_SURNAME:
        this.handleChangeSurname(action.surname)
        break;
      case RegistrationActionTypes.CHANGE_EMAIL:
        this.handleChangeEmail(action.email)
        break;
      case RegistrationActionTypes.CHANGE_PHONE_NUMBER:
        this.handleChangePhoneNumber(action.phoneNumber)
        break;
      case RegistrationActionTypes.CREATE:
        this.handleCreate();
        break;
    }
  }

  private validateFields(){
    this.handleChangeName(this.getState().name)
    this.handleChangeSurname(this.getState().surname)
    this.handleChangePhoneNumber(this.getState().phoneNumber)
    this.handleChangeEmail(this.getState().email)
  }

  private handleChangeSurname(value: string){
    const error = this.surnameValidator.validate(value)
    this.reduce({
      type: RegistrationResultActionTypes.CHANGE_SURNAME,
      surname: value,
      error: error ?
        translate(
          RegistrationConstants.TRANSLOCO_READ + '.' + error,
          {maxLength: RegistrationConstants.INPUTS_MAX_LENGTH}
        )
        : '',
    });
  }

  private handleChangeName(value: string){
    const error = this.nameValidator.validate(value)
    this.reduce({
      type: RegistrationResultActionTypes.CHANGE_NAME,
      name: value,
      error:  error ?
        translate(
          RegistrationConstants.TRANSLOCO_READ + '.' + error,
          {maxLength: RegistrationConstants.INPUTS_MAX_LENGTH}
        )
        : '',
    });
  }

  private handleChangeEmail(value: string){
    const error = this.emailValidator.validate(value)
    this.reduce({
      type: RegistrationResultActionTypes.CHANGE_EMAIL,
      email: value,
      error: error ?
        translate(RegistrationConstants.TRANSLOCO_READ + '.' + error)
        : '',
    });
  }

  private handleChangePhoneNumber(value: string){
    const error = this.phoneNumberValidator.validate(value)
    this.reduce({
      type: RegistrationResultActionTypes.CHANGE_PHONE_NUMBER,
      phoneNumber: value,
      error: error ?
        translate(RegistrationConstants.TRANSLOCO_READ + '.' + error)
        : '',
    });
  }

  private handleCreate() {
    this.validateFields()

    const state = this.getState();
    if (
      state.nameError.length != 0 ||
      state.phoneNumberError.length != 0 ||
      state.emailError.length != 0 ||
      state.surnameError.length != 0
    ) {
      return;
    }

    this.reduce({
      type: RegistrationResultActionTypes.CHANGE_STATUS_STATE,
      status: 'create_loading',
    });

    this.service
      .registerCompany({
        name: state.name.trim().split(' ').filter((str) => str.length > 0).join(' '),
        surname: state.surname.trim().split(' ').filter((str) => str.length > 0).join(' '),
        patronymic: '',
        phone: '+' + state.phoneNumber.replace(/\D/g, ''),
        email: state.email.trim().split(' ').filter((str) => str.length > 0).join(' '),
        stuffSize: {id: 1, max: 100, min: 1},
        specializationId: 'medicine-and-health-care',
      })
      .subscribe({
        next: (result) => {
          switch (result) {
            case RegistrationResult.SUCCESS:
              this.reduce({
                type: RegistrationResultActionTypes.CHANGE_STATUS_STATE,
                status: "success"
              });
              break;

            case RegistrationResult.EMAIL_EXISTS_ERROR:
              this.reduce({
                type: RegistrationResultActionTypes.CHANGE_STATUS_STATE,
                status: "already_exist_error"
              });
              break;

            case RegistrationResult.SERVER_ERROR:
              this.reduce({
                type: RegistrationResultActionTypes.CHANGE_STATUS_STATE,
                status: "create_error"
              });
              break;
          }
        }
      });
  }
}
