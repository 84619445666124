<app-core-auth-container
  *transloco="let t; read: RegistrationConstants.TRANSLOCO_READ"
  class="max-w-[512px] w-full"
  [title]="t('title')"
  [withBackButton]="false"
>
  <app-core-auth-container-icon
    variant="base"
  >
    <svg-registration-icon/>
  </app-core-auth-container-icon>

  <p>{{ t('description') }}</p>

  <div
    class="mt-[24px] flex flex-col gap-[24px]"
  >
    <app-core-input-field
      class="w-full"
      inputVariant="standard"
      [label]="t('surname-label')"
      size="xl"
      errorShowType="onUnFocused"
      (onFocused)="focus()"
      [charsCounterMax]="RegistrationConstants.INPUTS_MAX_LENGTH"
      [required]="true"
      [error]="surnameError"
      (onChange)="onSurnameChange.emit($event)"
    />
    <app-core-input-field
      class="w-full"
      inputVariant="standard"
      [label]="t('name-label')"
      size="xl"
      errorShowType="onUnFocused"
      (onFocused)="focus()"
      [charsCounterMax]="RegistrationConstants.INPUTS_MAX_LENGTH"
      [required]="true"
      [error]="nameError"
      (onChange)="onNameChange.emit($event)"
    />
    <app-core-input-field
      class="w-full"
      inputVariant="standard"
      [label]="t('email-label')"
      size="xl"
      errorShowType="onUnFocused"
      (onFocused)="focus()"
      [required]="true"
      [error]="emailError"
      (onChange)="onEmailChange.emit($event)"
    />
    <app-core-input-field
      class="w-full"
      inputVariant="standard"
      [label]="t('phone-number-label')"
      size="xl"
      prefix="+"
      mask="0 (000) 000-00-00||00 (000) 000-00-00||000 (000) 000-00-00"
      errorShowType="onUnFocused"
      (onFocused)="focus()"
      [required]="true"
      [error]="phoneNumberError"
      (onChange)="onPhoneNumberChange.emit($event)"
    />

    <app-core-button
      size="l"
      className="w-full"
      (onClick)="onSubmit.emit()"
    >
      {{ t('submit-button') }}
    </app-core-button>

    <p
      class="text-tt-text-secondary text-tt-font-size_l"
    >
      {{ t('details-prev-link') }}
      <span class="text-tt-primary">{{ t('details-link') }}</span>
      {{ t('details-post-link') }}
    </p>
  </div>

</app-core-auth-container>
