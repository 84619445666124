import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AgilesDeskSettingsNavItems } from "../../state/agiles-desk-settings-state";
import { AgileDeskSettingsConstants } from "../../../common/agile-desk-settings-constants";

@Component({
  selector: 'app-agiles-desk-settings-nav',
  templateUrl: './agiles-desk-settings-nav.component.html',
  styleUrls: ['./agiles-desk-settings-nav.component.scss']
})
export class AgilesDeskSettingsNavComponent {
  @Input({required: true}) selectedNav!: AgilesDeskSettingsNavItems
  @Output() onClickNav = new EventEmitter<AgilesDeskSettingsNavItems>();

  protected readonly AgilesDeskSettingsNavItems = AgilesDeskSettingsNavItems;
  protected readonly AgileDeskSettingsConstants = AgileDeskSettingsConstants;
}
