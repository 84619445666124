import { Component, Input } from "@angular/core";
import { AppPaths } from "../../../../../app-routing.module";
import { RegistrationConstants } from "../../../common/registration-constants";

@Component({
  selector: 'app-registration-error',
  templateUrl: './registration-error.component.html',
  styleUrls: ['./registration-error.component.scss']
})
export class RegistrationErrorComponent {
  @Input({required: true}) type!: 'email-exist' | 'unknown'
  @Input() email: string = ''
  protected readonly AppPaths = AppPaths;
  protected readonly RegistrationConstants = RegistrationConstants;
}
