import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpaceComponent } from './presentation/space.component';
import {
  SvgPlus,
  SvgHeaderArrow,
  SvgTripleDot,
  SvgArrow,
  SpaceSettingsHome,
  SvgPerson,
  SvgTrash
} from "../../../../core/components/svg-components/svg.components";
import { DocumentItemComponent } from './presentation/components/document-item/document-item.component';
import { SpaceSettingsComponent } from './presentation/components/space-settings/space-settings.component';
import { DocumentHeaderTripleDotComponent } from './presentation/components/document-header-triple-dot/document-header-triple-dot.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DocumentsDragAndDropService } from "./services/documents-drag-and-drop.service";
import { DropBoxToRootComponent } from "./presentation/components/drop-box-to-root/drop-box-to-root.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LoadingShadingComponent } from "../../../../core/components/loading-shading/loading-shading.component";
import { AlertModalComponent } from "../../../../core/components/alert-modal/alert-modal.component";
import { ImageStubComponent } from "../../../../core/components/image-stub/image-stub.component";
import { ColumnResizeDirective } from "../../../../core/directives/resize-x.directive";
import { ButtonComponent } from "../../../../core/components/buttons/button/button.component";
import { PageHeaderComponent } from "../../../../core/components/page-header/page-header.component";
import { SettingsModule } from "../../../../core/components/settings/settings.module";
import { ActionMenu } from "../../../../core/components/action-menu/action-menu.component";
import { ClickOutsideDirective } from "../../../../core/directives/click-outside.directive";
import {
  TextWithHighlightingComponent
} from "../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { PaymentAndTariffModule } from "../../../settings/modules/payment-and-tariff/payment-and-tariff.module";
import { FieldsModule } from "../../../../core/components/fields/fields.module";
import { SvgDocText } from "./icons/space-icons";
import { TranslocoModule } from "@jsverse/transloco";
import { TextParserComponent } from "../../../../core/components/text-parser/text-parser.component";
import { YooptaEditorModule } from "../../../../core/components/yoopta-editor/yoopta-editor.module";
import { PopupDirective } from "../../../../core/directives/popup.directive";

@NgModule({
  declarations: [
    SpaceComponent,
    DocumentItemComponent,
    SpaceSettingsComponent,
    DocumentHeaderTripleDotComponent,
    DropBoxToRootComponent,
    SvgDocText
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    SvgPlus,
    SvgHeaderArrow,
    SvgTripleDot,
    SvgArrow,
    SpaceSettingsHome,
    SvgPerson,
    MatProgressSpinnerModule,
    SvgTrash,
    MatTooltipModule,
    LoadingShadingComponent,
    AlertModalComponent,
    YooptaEditorModule,
    ImageStubComponent,
    ColumnResizeDirective,
    ButtonComponent,
    PageHeaderComponent,
    SettingsModule,
    ActionMenu,
    ClickOutsideDirective,
    TextWithHighlightingComponent,
    PaymentAndTariffModule,
    FieldsModule,
    TextParserComponent,
    TranslocoModule,
    PopupDirective
  ],
  providers: [
    DocumentsDragAndDropService
  ]
})
export class SpaceModule {

}
