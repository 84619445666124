import { generateId, YooptaPlugin } from '@yoopta/editor';
import { MyVideoRender } from "./render/my-video-render";
import { VideoCommands, VideoElementProps } from "@yoopta/video";
import { VideoPluginOptions } from "./types/video-plugin-options";
import { VideoElementMap } from "./types/video-plugin-element";

const ALIGNS_TO_JUSTIFY = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const MyVideo = new YooptaPlugin<VideoElementMap, VideoPluginOptions>({
  type: 'Video',
  elements: {
    // [TODO] - caption element??,
    video: {
      render: MyVideoRender,
      props: {
        src: null,
        srcSet: null,
        bgColor: null,
        sizes: { width: 650, height: 400 },
        nodeType: 'void',
        fit: 'cover',
        provider: {
          type: null,
          id: '',
        },
        settings: {
          controls: false,
          loop: true,
          muted: true,
          autoPlay: true,
        },
      },
    },
  },
  commands: VideoCommands,
  options: {
    accept: 'video/*',
    maxSizes: { maxWidth: 720, maxHeight: 550 },
    onUpload: async () => Promise.resolve({ src: '' }),
    display: {
      title: 'Video',
    },
  },
  parsers: {
    html: {
      deserialize: {
        nodeNames: ['VIDEO'],
        parse: (el) => {
          if (el.nodeName === 'VIDEO') {
            const src = el.getAttribute('src');
            const srcSet = el.getAttribute('srcset');
            const bgColor = el.getAttribute('bgcolor');
            const sizes = { width: el.getAttribute('width'), height: el.getAttribute('height') };
            const controls = el.getAttribute('controls');
            const loop = el.getAttribute('loop');
            const muted = el.getAttribute('muted');
            const autoPlay = el.getAttribute('autoplay');
            const fit = el.getAttribute('objectFit') || 'cover';

            const props = {
              src,
              srcSet,
              bgColor,
              sizes,
              fit,
              settings: {
                controls: !!controls,
                loop: !!loop,
                muted: !!muted,
                autoPlay: !!autoPlay,
              },
            };

            return {
              id: generateId(),
              type: 'video',
              children: [{ text: '' }],
              props,
            };
          }
          return
        },
      },
      serialize: (element, text, blockMeta) => {
        const { align = 'center', depth = 0 } = blockMeta || {};
        const justify = ALIGNS_TO_JUSTIFY[align] || 'center';

        return `
        <div style="margin-left: ${depth}px; display: flex; width: 100%; justify-content: "${justify}"">
        <video data-meta-align="${align}" data-meta-depth="${depth}" src="${element.props.src}" width="${element.props.sizes.width}" height="${element.props.sizes.height}" controls="${element.props.settings.controls}" loop="${element.props.settings.loop}" muted="${element.props.settings.muted}" autoplay="${element.props.settings.autoPlay}" style="margin: 0 auto;" objectFit="${element.props.fit}" />
        </div>`;
      },
    },
    markdown: {
      serialize: (element, text) => {
        return `![${element.props.src}](${element.props.src})\n`;
      },
    },
  },
});

export { MyVideo };
