import {Injectable} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  mergeMap,
  Observable,
  of,
} from 'rxjs';
import {AuthService} from '../domain/auth.service';
import {LoginByPasswordData} from '../domain/login-by-password-data';
import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {LoginResponseJson} from './json/login-response-json';
import {LoginStatus} from '../domain/login-status';
import {AuthorizationNavigator} from '../presentation/navigation/authorization-navigator';
import {CryptUtils} from '../../../core/crypt/crypt-utils';
import {SaltResponseJson} from './json/salt-response-json';
import {Account} from '../domain/account';
import {RefreshTokenResponseJson} from './json/refresh-token-response-json';
import {AppPaths} from "../../../app-routing.module";
import {CheckPasswordResponseDto} from "../../employees/data/dto/check-password-response-dto";
import {StartPasswordRestoreResponseDto} from "../../employees/data/dto/start-password-restore-response-dto";

@Injectable({
  providedIn: 'root',
})
export class AuthServiceImpl implements AuthService {
  private readonly apiUrl: string = `${environment.apiUrl}/authorization`;

  private userSubject: BehaviorSubject<Account | null>;
  public userObservable: Observable<Account | null>;

  constructor(
    private http: HttpClient,
    private navigator: AuthorizationNavigator,
  ) {
    this.userSubject = new BehaviorSubject<Account | null>(this.getAccount());
    this.userObservable = this.userSubject.asObservable();
  }

  getAccount(): Account | null {
    const account = localStorage.getItem('account')
    if (account) {
      return JSON.parse(account) as Account;
    }
    return null
  }

  setStorageAccount() {
    const account = localStorage.getItem('account')
    if (account) {
      this.userSubject.next(JSON.parse(account) as Account)
    }
    return null
  }

  isAuthenticated(): boolean {
    const account = this.getAccount()
    return account != null && this.userSubject.value?.userId === account.userId;
  }

  login(data: LoginByPasswordData): Observable<LoginStatus> {
    return this.http
      .get<SaltResponseJson>(
        `${this.apiUrl}/salt/${data.login}`,
        {withCredentials: true},
      )
      .pipe(
        mergeMap((response) => {
          localStorage.setItem('salt', response.salt);
          return this.loginRequest(response, data)
        }),
        catchError((err) => {
          if (
            err instanceof HttpErrorResponse &&
            err.status == HttpStatusCode.NotFound
          ) {
            return of(LoginStatus.INCORRECT_CREDENTIALS);
          } else {
            return of(LoginStatus.UNKNOWN);
          }
        }),
      );
  }

  private loginRequest(response: SaltResponseJson, data: LoginByPasswordData) {
    const hashPassword = CryptUtils.toSha256Hash(
      data.password,
      response.salt,
    );
    const body = {email: data.login, password: hashPassword};
    return this.http
      .post<LoginResponseJson>(
        `${this.apiUrl}/login`,
        body,
        {withCredentials: true},
      )
      .pipe(
        map((response) => {
          const account = new Account(
            response.accessJwtToken,
            response.user.userId,
          );
          localStorage.setItem('account', JSON.stringify(account))

          this.userSubject.next(account);
          return LoginStatus.SUCCESS;
        }),
      );
  }

  logout(withoutRequest?: boolean): void {
    if (!withoutRequest) {
      this.http
        .post<any>(
          `${this.apiUrl}/logout`,
          {},
          {withCredentials: true},
        )
        .subscribe({
          next: (value) => {
            localStorage.removeItem("account");
            this.userSubject.next(null);
            this.navigator.openLogin();
          },
          error: (err) => {
            localStorage.removeItem("account");
            this.userSubject.next(null);
            this.navigator.openLogin();
          }
        });
    } else {
      localStorage.removeItem('account')
      this.userSubject.next(null);
      this.navigator.openLogin(true);
    }
  }

  refreshToken() {
    const httpOptions = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      observe: 'response' as 'response',
    };

    return this.http
      .get<RefreshTokenResponseJson>(
        `${this.apiUrl}/refresh-token`,
        httpOptions,
      )
      .pipe(
        map((response) => {
          const accountStr = localStorage.getItem('account')
          if (accountStr) {
            const account = JSON.parse(accountStr) as Account;
            const newAccount = new Account(
              response.body?.accessJwtToken!,
              account.userId
            );

            localStorage.setItem('account', JSON.stringify(newAccount))

            this.userSubject.next(newAccount);
          } else {
            this.logout(true)
          }

          return response;
        }),
      );
  }

  checkPassword(password: string, salt: string): Observable<CheckPasswordResponseDto> {

    const body = {
      password: CryptUtils.toSha256Hash(password, salt)
    }
    return this.http.post<CheckPasswordResponseDto>(`${this.apiUrl}/password/check`, body)
  }

  changePassword(prevPassword: string, newPassword: string, salt: string): Observable<null> {
    const body = {
      prevPassword: CryptUtils.toSha256Hash(prevPassword, salt),
      newPassword: CryptUtils.toSha256Hash(newPassword, salt)
    }
    return this.http.post<null>(`${this.apiUrl}/password/change`, body)
  }

  notifyAboutPasswordChange(email: string): Observable<null> {
    const body = {
      url: AppPaths.PASSWORD_RESET,
      email: email
    }
    return this.http.post<null>(`${this.apiUrl}/password/restore/notify`, body)
  }

  startPasswordRestore(token: string): Observable<StartPasswordRestoreResponseDto> {
    return this.http.post<StartPasswordRestoreResponseDto>(`${this.apiUrl}/password/restore/start/${token}`, {})
  }

  finishPasswordRestore(token: string, newPassword: string, salt: string): Observable<null> {
    const body = {
      newPassword: CryptUtils.toSha256Hash(newPassword, salt)
    }
    return this.http.post<null>(`${this.apiUrl}/password/restore/finish/${token}`, body)
  }
}
