import { RoleMainEntity } from "../components/role-main/role-main.component";
import { RoleNavItems } from "./role-state";
import { PermissionsDto } from "../../../../../../core/services/role-service/dto/create-role-dto";

export type RoleResultAction =
  | UpdateRoleResultAction
  | ChangeNavItemResultAction
  | SaveMainDataResultAction
  | SavePermissionsResultAction
  | ChangeDeleteAlertModalVisibilityResultAction
  | ChangeIsLoadingResultAction
  | ChangeIsDeletingResultAction
  | ChangeIsPageLoadingResultAction

export enum RoleResultActionTypes {
  UPDATE_ROLE,
  CHANGE_NAV_ITEM,
  SAVE_MAIN_DATA,
  SAVE_PERMISSIONS,
  CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
  CHANGE_IS_LOADING,
  CHANGE_IS_DELETING,
  CHANGE_IS_PAGE_LOADING,
}

export interface ChangeIsPageLoadingResultAction {
  readonly type: RoleResultActionTypes.CHANGE_IS_PAGE_LOADING
  readonly value: boolean
}

export interface ChangeIsDeletingResultAction {
  readonly type: RoleResultActionTypes.CHANGE_IS_DELETING
  readonly value: boolean
}

export interface ChangeIsLoadingResultAction {
  readonly type: RoleResultActionTypes.CHANGE_IS_LOADING
  readonly value: boolean
}

export interface ChangeDeleteAlertModalVisibilityResultAction{
  readonly type: RoleResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY
  readonly value: boolean
}

export interface SaveMainDataResultAction {
  readonly type: RoleResultActionTypes.SAVE_MAIN_DATA;
  readonly mainData: RoleMainEntity
}

export interface SavePermissionsResultAction {
  readonly type: RoleResultActionTypes.SAVE_PERMISSIONS,
  readonly permissions: PermissionsDto
}

export interface ChangeNavItemResultAction{
  readonly type: RoleResultActionTypes.CHANGE_NAV_ITEM;
  readonly navItem: RoleNavItems
}

export interface UpdateRoleResultAction {
  readonly id: string
  readonly isCustom: boolean
  readonly type: RoleResultActionTypes.UPDATE_ROLE;
  readonly mainData: RoleMainEntity
  readonly permissions: PermissionsDto
  readonly canEdit: boolean
  readonly canDelete: boolean
}
