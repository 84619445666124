import { Component } from '@angular/core';
import { Store } from 'src/app/core/mvi/store';
import { EmployeesRolesState } from "./state/employees-roles-state";
import { EmployeesRolesExecutor } from "./state/employees-roles-executor";
import { EmployeesRolesReducer } from "./state/employees-roles-reducer";
import { EmployeesRolesAction, EmployeesRolesActionTypes } from "./state/employees-roles-action";
import { EmployeesRolesResultAction } from "./state/employees-roles-result-action";
import { EmployeesRolesConstants } from "../common/employees-roles-constants";
import { provideTranslocoScope } from "@jsverse/transloco";

@Component({
  selector: 'app-employees-roles',
  templateUrl: './employees-roles.component.html',
  styleUrls: [
    './employees-roles.component.scss',
    '../../../common/employees.scss',
  ],
  providers:[
    EmployeesRolesState,
    EmployeesRolesExecutor,
    EmployeesRolesReducer,
    provideTranslocoScope({ scope: "employees/roles-list", alias: EmployeesRolesConstants.TRANSLOCO_READ }),
  ],
})

export class EmployeesRolesComponent extends Store<
  EmployeesRolesState,
  EmployeesRolesExecutor,
  EmployeesRolesAction,
  EmployeesRolesResultAction
>
{
  protected readonly EmployeesActionTypes = EmployeesRolesActionTypes;

  constructor(
    state: EmployeesRolesState,
    executor: EmployeesRolesExecutor,
    reducer: EmployeesRolesReducer,
  ) {
    super(state, executor, reducer);
    this.performAction({
      type: EmployeesRolesActionTypes.UPDATE_ROLES
    })
  }

  protected readonly EmployeesRolesActionTypes = EmployeesRolesActionTypes;
  protected readonly EmployeesRolesConstants = EmployeesRolesConstants;
}

