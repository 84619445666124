import { OrdersState } from "./orders-state";
import { OrdersAction, OrdersActionTypes } from "./orders-action";
import { OrdersResultAction, OrdersResultActionTypes } from "./orders-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { SubscriptionService } from "../../../../data/subscription.service";
import {
  InvoiceDto,
  InvoiceDtoDetails,
  InvoiceDtoDetailsExpand,
  InvoiceDtoDetailsItem
} from "../../../../data/dto/invoices-list-dto";
import { OrderEntity } from "../../domain/order-entity";
import { ReceiptField, ReceiptSection } from "../../../payment-and-tariff/domain/receipt-secton";
import { numToWordType } from "../../../../../../core/utils/num-to-word-type";
import { getDurationString } from "../../../payment-and-tariff/presentation/utils/convert-tariff-duration";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";
import { translate, TranslocoService } from "@jsverse/transloco";
import { DatePipe } from "@angular/common";
import { OrdersConstants } from "../../common/orders-constants";

@Injectable()
export class OrdersExecutor extends Executor<
  OrdersState,
  OrdersAction,
  OrdersResultAction
> {

  constructor(
    private subscriptionService: SubscriptionService,
    private toastsService: ToastsService,
    private datePipe: DatePipe,
    private translocoService: TranslocoService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<OrdersState, OrdersResultAction>,
    getState: () => OrdersState,
    onReduced: (state: OrdersState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.handleInit()
    this.translocoService.langChanges$.subscribe(()=>{
      this.handleChangeLang()
    })
  }

  execute(action: OrdersAction) {
    switch (action.type) {
      case OrdersActionTypes.CHANGE_CANCEL_MODAL_VISIBLE:
        this.reduce({
          type: OrdersResultActionTypes.CHANGE_CANCEL_MODAL_VISIBILITY,
          value: action.value
        })
        break;
      case OrdersActionTypes.PAY_ORDER:
        if(this.getState().activeOrder)
        {
          this.subscriptionService.payInvoice(this.getState().activeOrder!.id)
            .subscribe({
              next: value => {
                this.handleInit()
              },
              error: err => {
                this.toastsService.createToast({
                  title: 'Не удалось оплатить заказ',
                  description: 'Попробуйте позднее',
                  state: ToastState.ERROR
                })
              }
            })
        }
        break;
      case OrdersActionTypes.CANCEL_ORDER:
        if(this.getState().activeOrder)
        {
          this.reduce({
            type: OrdersResultActionTypes.CHANGE_CANCEL_MODAL_IS_LOADING,
            value: true
          })
          this.subscriptionService.cancelInvoice(this.getState().activeOrder!.id)
            .subscribe({
              next: value => {
                this.handleInit()
                this.reduce({
                  type: OrdersResultActionTypes.CHANGE_CANCEL_MODAL_IS_LOADING,
                  value: false
                })
                this.reduce({
                  type: OrdersResultActionTypes.CHANGE_CANCEL_MODAL_VISIBILITY,
                  value: false
                })
              },
              error: err => {
                this.toastsService.createToast({
                  title: 'Не удалось отменить заказ',
                  description: 'Попробуйте позднее',
                  state: ToastState.ERROR
                })
                this.reduce({
                  type: OrdersResultActionTypes.CHANGE_CANCEL_MODAL_IS_LOADING,
                  value: false
                })
              }
            })
        }
        break;
    }
  }

  private handleChangeLang(){
    this.reduce({
      type: OrdersResultActionTypes.CHANGE_LANG,
      activeOrder: this.getState().activeOrderDto ? this.mapToOrderEntity(this.getState().activeOrderDto!) : undefined,
      storyOrders: this.getState().storyOrdersDto.map((invoice) => this.mapToOrderEntity(invoice))
    })
  }

  private handleInit(){
    this.reduce({
      type: OrdersResultActionTypes.CHANGE_IS_LOADING,
      value: true,
      isError: false
    })
    this.subscriptionService.getInvoicesList().subscribe({
      next: (dto) => {
        const dueDate = dto.activeInvoice ? new Date(dto.activeInvoice.dueDate * 1000) : undefined
        this.reduce({
          type: OrdersResultActionTypes.INIT,
          activeOrderDto: dto.activeInvoice,
          storyOrdersDto: dto.invoices,
          activeOrderDueDate: dueDate ? dueDate.getDate() + '.' + (dueDate.getMonth() + 1) + '.' + dueDate.getFullYear() : '',
          activeOrder: dto.activeInvoice ? this.mapToOrderEntity(dto.activeInvoice) : undefined,
          storyOrders: dto.invoices.map((invoice) => this.mapToOrderEntity(invoice))
        })
        this.reduce({
          type: OrdersResultActionTypes.CHANGE_IS_LOADING,
          value: false,
          isError: false
        })
      },
      error: err => {
        this.toastsService.createToast({
          title: 'Не удалось загрузить список заказов',
          description: 'Попробуйте позднее',
          state: ToastState.ERROR
        })
        this.reduce({
          type: OrdersResultActionTypes.CHANGE_IS_LOADING,
          value: false,
          isError: true
        })
      }
    })
  }

  private mapToOrderEntity(order: InvoiceDto): OrderEntity{
    return {
      id: order.id,
      createdAt: new Date(order.createdAt * 1000),
      paymentType: order.paymentType,
      totalValue: order.totalAmount,
      status: order.status,
      receiptSections: this.mapInvoiceDetailsToReceiptSections(order.details, order.tariffName),
    }
  }

  private mapInvoiceDetailsToReceiptSections(details: InvoiceDtoDetails, tariffName: string): ReceiptSection[]{
    const result: ReceiptSection[] = []
    if (details.buy){
      result.push({
        fields: this.getBuyFields(details.buy, tariffName),
      })
    }

    if(details.expand){
      result.push({
        title: translate(OrdersConstants.RECEIPT_TRANSLOCO_READ + '.expansion'),
        fields: this.getExpandFields(details.expand),
      })
    }

    if(details.extend){
      result.push({
        title: translate(OrdersConstants.RECEIPT_TRANSLOCO_READ + '.extension'),
        fields: this.getExtendFields(details.extend, tariffName),
      })
    }

    return result
  }

  private getBuyFields(buyDetails: InvoiceDtoDetailsItem, tariffName: string): ReceiptField[] {
    const fields: ReceiptField[] = []
    if(buyDetails.usersCount > 0){
      fields.push({
        text: translate(
        OrdersConstants.RECEIPT_TRANSLOCO_READ + '.subscription-tariff',
        {
          tariffName: tariffName,
          usersCount: buyDetails.usersCount,
          usersCountType: numToWordType(buyDetails.usersCount),
          durationString: getDurationString(buyDetails.monthsCount, OrdersConstants.RECEIPT_TRANSLOCO_READ)
        }
        ),
        price: buyDetails.userCost * buyDetails.monthsCount * buyDetails.usersCount,
      })
    }

    if(buyDetails.usersDiscount && buyDetails.usersCount > 0)
    {
      fields.push({
        text: translate(
          OrdersConstants.RECEIPT_TRANSLOCO_READ + '.subscription-tariff-discount',
          {
            discount: buyDetails.usersDiscount,
            durationString: getDurationString(buyDetails.monthsCount, OrdersConstants.RECEIPT_TRANSLOCO_READ),
          }
        ),
        price: -Math.round(buyDetails.usersCount * buyDetails.usersDiscount * buyDetails.userCost * buyDetails.monthsCount * 100 / 100) / 100,
      })
    }

    if(buyDetails.gigabytesCount > 0){
      fields.push({
        text: translate(
          OrdersConstants.RECEIPT_TRANSLOCO_READ + '.subscription-storage',
          {
            gigabytesCount: buyDetails.gigabytesCount,
          }
        ),
        price: buyDetails.gigabyteCost * buyDetails.gigabytesCount * buyDetails.monthsCount
      })
    }

    return fields
  }

  private getExtendFields(extendDetails: InvoiceDtoDetailsItem, tariffName: string): ReceiptField[] {
    const fields: ReceiptField[] = []

    if(extendDetails.usersCount > 0){
      fields.push({
        text: translate(
          OrdersConstants.RECEIPT_TRANSLOCO_READ + '.extension-tariff',
          {
            tariffName: tariffName,
            durationString: getDurationString(extendDetails.monthsCount, OrdersConstants.RECEIPT_TRANSLOCO_READ),
            usersCount: extendDetails.usersCount,
            usersCountType: numToWordType(extendDetails.usersCount),
          }
        ),
        price: extendDetails.userCost * extendDetails.monthsCount * extendDetails.usersCount,
      })
    }

    if(extendDetails.usersDiscount && extendDetails.usersCount > 0)
    {
      fields.push({
        text: translate(OrdersConstants.RECEIPT_TRANSLOCO_READ + '.extension-tariff-discount', {
          discount: extendDetails.usersDiscount,
          durationString: getDurationString(extendDetails.monthsCount, OrdersConstants.RECEIPT_TRANSLOCO_READ),
        }),
        price: -Math.round(extendDetails.usersCount * extendDetails.usersDiscount * extendDetails.userCost * extendDetails.monthsCount * 100 / 100) / 100,
      })
    }

    if (extendDetails.gigabytesCount > 0)
    {
      fields.push({
        text: translate(OrdersConstants.RECEIPT_TRANSLOCO_READ + '.extension-storage', {
          gigabytesCount: extendDetails.gigabytesCount,
        }),
        price: extendDetails.gigabyteCost * extendDetails.gigabytesCount * extendDetails.monthsCount
      })
    }

    return fields
  }

  private getExpandFields(expandDetails: InvoiceDtoDetailsExpand): ReceiptField[] {
    const fields: ReceiptField[] = []
    const expandDate = this.datePipe.transform(
      new Date(expandDetails.expandedUntil * 1000),
      'd MMM YYYY',
      undefined,
      'ru-RU'
    )
    if(expandDetails.data.usersCount > 0) {
      fields.push({
        text: translate(OrdersConstants.RECEIPT_TRANSLOCO_READ + '.expansion-users', {
          usersCount: expandDetails.data.usersCount,
          usersCountType: numToWordType(expandDetails.data.usersCount),
          date: expandDate
        }),
        price: expandDetails.data.userCost * expandDetails.data.monthsCount * expandDetails.data.usersCount,
      })
    }

    if(expandDetails.data.gigabytesCount)
    {
      fields.push({
        text: translate(OrdersConstants.RECEIPT_TRANSLOCO_READ + '.expansion-storage', {
          gigabytesCount: expandDetails.data.gigabytesCount,
          date: expandDate
        }),
        price: expandDetails.data.gigabyteCost * expandDetails.data.gigabytesCount * expandDetails.data.monthsCount
      })
    }

    return fields
  }
}
