<app-core-modal
  [open]="isVisible"
  [isLoading]="state.isSendingRequest || state.isLoading"
  [paddingType]="'none'"
  [className]="
    'w-full flex flex-row relative max-w-[702px] min-h-[544px] max-h-[80%]'"
  (onClose)="close()"
  *transloco="let t; read: EmployeeListConstants.CREATE_MODAL_TRANSLOCO_READ"
>
  <app-core-modal-content
    class="p-[8px] pb-[16px] min-h-[544px] w-full"
    [footer]="true"
    *ngIf="!state.isLoading"
  >
    <div class="relative">
      <app-str-to-colored-bg
        class="w-full h-[120px] absolute left-0 top-0"
        [colorRGB]="state.avatarColorRGB"
      />
    </div>
    <div class="pb-[32px]">
      <div class="px-[24px] pt-[60px] flex"
        *ngIf="!state.isLoading"
      >
        <app-core-avatar
          size="5xl"
          [bgRGB]="state.avatarColorRGB"
          [borderSize]="4"
          variant="primary"
          [placeholder]="state.avatarPlaceholder"
          [haveContent]="true"
        >
          <svg-photo/>
        </app-core-avatar>

        <div class="mt-[16px] ml-[16px] flex flex-col gap-[12px] max-w-[502px] w-full">
          <app-core-input-field
            inputVariant="borderless"
            [placeholder]="t('surname-placeholder')"
            [textStyle]="'font-semibold text-[22px] leading-[27px] text-tt-text-default'"
            error="{{ state.userForm.surname.error }}"
            errorShowType="onUnFocused"
            [defaultValue]="state.userForm.surname.value"
            [autoFocus]="true"
            [value]="state.userForm.surname.value"
            (onChange)="performAction({
              type: CreateEmployeeModalActionTypes.CHANGE_SURNAME,
              value: $event
            })"
            #employeeInput
          />

          <div class="flex gap-[16px] w-full">
            <app-core-input-field
              class="w-full max-w-[34%] block"
              inputVariant="borderless"
              [placeholder]="t('name-placeholder')"
              [textStyle]="'font-semibold text-[22px] leading-[27px] text-tt-text-default'"
              error="{{ state.userForm.name.error }}"
              errorShowType="onUnFocused"
              [defaultValue]="state.userForm.name.value"
              [value]="state.userForm.name.value"
              (onChange)="performAction({
                type: CreateEmployeeModalActionTypes.CHANGE_NAME,
                value: $event
              })"
              #employeeInput
            />
            <app-core-input-field
              class="w-full flex-grow block"
              inputVariant="borderless"
              [placeholder]="t('patronymic-placeholder')"
              [textStyle]="'font-semibold text-[22px] leading-[27px] text-tt-text-default'"
              error="{{ state.userForm.patronymic.error }}"
              errorShowType="onUnFocused"
              [defaultValue]="state.userForm.patronymic.value"
              [value]="state.userForm.patronymic.value"
              (onChange)="performAction({
                type: CreateEmployeeModalActionTypes.CHANGE_PATRONYMIC,
                value: $event
              })"
              #employeeInput
            />
          </div>

          <app-core-input-field
            inputVariant="borderless"
            [placeholder]="t('email-placeholder')"
            [readonly]="state.loadedEmployeeId !== -1"
            [textStyle]="'text-[16px] leading-[19px] text-tt-text-default'"
            error="{{ state.userForm.email.error }}"
            errorShowType="onUnFocused"
            [defaultValue]="state.userForm.email.value"
            [value]="state.userForm.email.value"
            (onChange)="performAction({
              type: CreateEmployeeModalActionTypes.CHANGE_EMAIL,
              value: $event
            })"
            (onUnFocused)="performAction({
              type: CreateEmployeeModalActionTypes.CHANGE_AVATAR_COLOR
            })"
            #employeeInput
          />

          <app-core-input-field
            inputVariant="borderless"
            [placeholder]="t('phone-number-placeholder')"
            [textStyle]="'text-[16px] leading-[19px] text-tt-text-default'"
            error="{{ state.userForm.phoneNumber.error }}"
            errorShowType="onUnFocused"
            prefix="+"
            mask="0 (000) 000-00-00||00 (000) 000-00-00||000 (000) 000-00-00"
            [defaultValue]="state.userForm.phoneNumber.value"
            [value]="state.userForm.phoneNumber.value"
            (onChange)="performAction({
              type: CreateEmployeeModalActionTypes.CHANGE_PHONE_NUMBER,
              value: $event
            })"
            #employeeInput
          />

          <div
            class="pt-[4px] flex flex-col gap-[12px] w-full"
          >
            <p
              class="font-medium text-[16px] leading-[19px] text-tt-text-default"
            >
              {{ t('user-roles') }}
            </p>

            <div class="w-full flex gap-x-[8px] gap-y-[12px] flex-wrap">
              <app-chips
                *ngIf="state.loadedEmployeeId == -1"
                [chips]="{id: '', name: t('viewer'), type: 'readonly'}"
              />
              <app-chips
                *ngFor="let role of state.roles"
                [chips]="{
                  id: role.id,
                  name: role.name,
                  type: role.isEditable ? 'base' : 'readonly'
                }"
                (deleteClicked)="performAction({
                  type: CreateEmployeeModalActionTypes.REMOVE_ROLE,
                  roleId: $event
                })"
              />
            </div>

            <employees-roles-popup
              *ngIf="state.canEditRole"
              class="w-max"
              [activeRoleIds]="state.userForm.roleIds"
              (roleClicked)="performAction({
                type: CreateEmployeeModalActionTypes.ADD_ROLE,
                role: $event
              })"
            />
          </div>
        </div>
      </div>

    </div>

    <app-core-modal-content-footer>
      <div class="w-full flex justify-between">
        <app-core-button
          (onClick)="onDelete()"
          variant="soft"
          type="danger"
          *ngIf="canDelete && state.canDelete"
        >
          <span>{{ t('delete') }}</span>
        </app-core-button>

        <div></div>

        <div class="flex gap-[16px]">
          <app-core-button
            variant="soft"
            (onClick)="close()"
          >
            {{ t('cancel') }}
          </app-core-button>

          <app-core-button
            *ngIf="state.canEdit"
            [disabled]="!state.isValidForm || state.isEqualInit"
            (onClick)="performAction({
              type: CreateEmployeeModalActionTypes.SAVE_EMPLOYEE
            })"
          >
            <span
              *ngIf="state.loadedEmployeeId == -1"
            >{{ t('create-user') }}</span>
            <span
              *ngIf="state.loadedEmployeeId != -1"
            >{{ t('save') }}</span>
          </app-core-button>

        </div>
      </div>
    </app-core-modal-content-footer>

  </app-core-modal-content>

</app-core-modal>
