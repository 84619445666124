<div class="pt-[24px] px-[44px] w-full flex justify-center"
  *transloco="let t; read: AgilesListConstants.TRANSLOCO_READ"
>
  <div class="w-full max-w-[1256px] rounded-[8px] overflow-hidden border-[1px] border-tt-border-default bg-tt-light-blue">
    <div class="w-full flex px-[16px] py-[10px] gap-[16px]">
      <app-core-search-field class="w-full"
        [placeholder]="t('search-placeholder')"
      />
      <app-core-button
        (onClick)="performAction({
          type: AgilesListActionTypes.CHANGE_IS_OPEN_CREATE_MODAL,
          value: true
        })"
      >
        {{ t('new-agile-desk') }}
      </app-core-button>
    </div>

    <app-agiles-list-table
      class="w-full block"
      [agiles]="state.agileList"
    />
  </div>
</div>

<app-create-desk-modal
  *ngIf="state.isOpenCreateModal"
  (onCreate)="performAction({
    type: AgilesListActionTypes.CREATE_DESK,
    name: $event
  })"
  (onClose)="performAction({
    type: AgilesListActionTypes.CHANGE_IS_OPEN_CREATE_MODAL,
    value: false
  })"
/>
