import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

export type DropdownItem = {
  id: string;
  name: string;
  suffix?: string
};

@Component({
  selector: "app-core-dropdown-field",
  templateUrl: "./dropdown-field.component.html",
})
export class DropdownFieldComponent {
  constructor(private el: ElementRef) {}

  @Input() className: string | string[] = [];
  @Input() items: DropdownItem[] = [];
  @Input() error?: string;
  @Input() placeholder: string = 'Выберите элемент';
  @Input() label?: string;
  @Input() selectedItem?: DropdownItem | null;
  @Input() required = false;
  @Input() variant: 'outlined' | 'standard' = 'outlined';

  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() onUnselect: EventEmitter<string> = new EventEmitter<string>();

  opened = false;
  focused = false;

  trackItemByFn(_: number, item: any) {
    return item.id;
  }

  onFocus() {
    this.focused = true;
  }

  onUnFocus() {
    this.focused = false;
  }

  switch() {
    if (!this.onSelect.observed) return;
    this.opened = !this.opened;
  }

  onClickDropwdown() {
    if (this.onClick) {
      this.onClick.emit();
    }
    this.switch();
  }

  onSelectItem(id: string) {
    if (this.onSelect) {
      this.onSelect.emit(id);
    }
    this.switch();
  }

  onUnselectItem(event: MouseEvent, id: string) {
    event.stopPropagation();
    if (this.onUnselect) {
      this.onUnselect.emit(id);
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (this.opened && !this.el.nativeElement.contains(event.target)) {
      this.opened = false;
    }
  }
}
