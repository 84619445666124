<div
  *ngIf="informerText"
  class="px-[24px] py-[12px] flex gap-[12px]"
  [ngClass]="{
    'bg-tt-informer-info-background text-tt-informer-info-text': type === 'info',
    'bg-tt-informer-warning-background text-tt-informer-warning-text': type === 'warning',
  }"
>
  <div
    class="min-w-[24px] min-h-[24px] w-[24px] h-[24px]"
  >
    <svg-info-circle *ngIf="type === 'info'" class="w-[24px] h-[24px] text-tt-informer-info-icon"/>
    <svg-warning *ngIf="type === 'warning'" class="w-[24px] h-[24px] text-tt-informer-warning-icon"/>
  </div>
  <div class="flex items-center">
    <p class="text-tt-font-size_l">{{informerText}}</p>
  </div>
</div>
