import { AgilesDeskSettingsNavItems, AgilesDeskSettingsState } from "./agiles-desk-settings-state";
import { AgilesDeskSettingsAction, AgilesDeskSettingsActionTypes } from "./agiles-desk-settings-action";
import { AgilesDeskSettingsResultAction, AgilesDeskSettingsResultActionTypes } from "./agiles-desk-settings-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";

@Injectable()
export class AgilesDeskSettingsExecutor extends Executor<
  AgilesDeskSettingsState,
  AgilesDeskSettingsAction,
  AgilesDeskSettingsResultAction
> {

  constructor(

  ) {
    super();
  }

  override init(
    reducer: Reducer<AgilesDeskSettingsState, AgilesDeskSettingsResultAction>,
    getState: () => AgilesDeskSettingsState,
    onReduced: (state: AgilesDeskSettingsState) => void,
  ) {
    super.init(reducer, getState, onReduced);
  }

  execute(action: AgilesDeskSettingsAction) {
    switch (action.type) {
      case AgilesDeskSettingsActionTypes.CHANGE_NAV_ITEM:
        this.reduce({
          type: AgilesDeskSettingsResultActionTypes.CHANGE_NAV_ITEM,
          navItem: action.navItem
        })
        break;
    }
  }

}
