import { SubscriptionEntity } from "../../domain/subscription-entity";
import { TariffEntity } from "../../domain/tariff-entity";
import { TariffDuration } from "../../domain/tariff-duration";

export type PaymentAndTariffResultAction =
  | ChangeIsOpenSubscriptionFormResultAction
  | ChangeIsOpenTariffFormResultAction
  | ChangeIsLoadingResultAction
  | InitActiveSubscriptionResultAction
  | InitTariffsResultAction

export enum PaymentAndTariffResultActionTypes {
  CHANGE_IS_OPEN_SUBSCRIPTION_FORM,
  CHANGE_IS_OPEN_TARIFF_FORM,
  CHANGE_IS_LOADING,
  INIT_ACTIVE_SUBSCRIPTION,
  INIT_TARIFFS,
}

export interface ChangeIsLoadingResultAction {
  readonly type: PaymentAndTariffResultActionTypes.CHANGE_IS_LOADING
  readonly value: boolean
  readonly isError: boolean
}

export interface InitTariffsResultAction {
  readonly type: PaymentAndTariffResultActionTypes.INIT_TARIFFS
  readonly currentTariff?: TariffEntity,
  readonly tariffs: TariffEntity[]
  readonly currentDuration: TariffDuration | null
  readonly durations: TariffDuration[]
}

export interface InitActiveSubscriptionResultAction {
  readonly type: PaymentAndTariffResultActionTypes.INIT_ACTIVE_SUBSCRIPTION
  readonly isEditable: boolean
  readonly subscription?: SubscriptionEntity
}

export interface ChangeIsOpenTariffFormResultAction {
  readonly type: PaymentAndTariffResultActionTypes.CHANGE_IS_OPEN_TARIFF_FORM
  readonly value: boolean
}

export interface ChangeIsOpenSubscriptionFormResultAction {
  readonly type: PaymentAndTariffResultActionTypes.CHANGE_IS_OPEN_SUBSCRIPTION_FORM
  readonly value: boolean
}
