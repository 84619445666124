import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Directive({
  selector: '[appMousePressedScroll]',
  standalone: true
})
export class MousePressedScrollDirective{
  @Input() scrollYStartDelta: number = 30
  @Input() isScrolled: boolean = false;
  @Input() screenTopScrollOffset: number = 0
  @Output() onScrollY = new EventEmitter<number>()

  private container: HTMLElement;
  private scrollSpeed: number = 5

  constructor(private elRef: ElementRef) {
    this.container = elRef.nativeElement;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if(this.isScrolled)
    {
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      const containerRect = this.container.getBoundingClientRect();
      if (mouseX < containerRect.left + containerRect.width / 10) {
        this.container.scrollLeft -= this.scrollSpeed;
      } else if (mouseX > containerRect.right - containerRect.width / 10) {
        this.container.scrollLeft += this.scrollSpeed;
      }
      if (mouseY < containerRect.top + this.scrollYStartDelta || event.y < this.screenTopScrollOffset + this.scrollYStartDelta) {
        this.onScrollY.emit(-this.scrollSpeed);
      } else if (mouseY > containerRect.bottom - this.scrollYStartDelta || event.y > window.innerHeight - this.scrollYStartDelta) {
        this.onScrollY.emit(this.scrollSpeed);
      }
    }
  }
}
