import { Injectable } from '@angular/core';
import { ShortEmployee } from "../../../../../../../../core/components/select-user-modal/select-user-modal.component";

@Injectable()
export class RoleUsersState {
  readonly roleId: string = ''
  readonly isLoading: boolean = false

  readonly filterString: string = ''
  readonly isOpenSelectUsersModal: boolean = false
  readonly isLoadingSelectUsersModal: boolean = false

  readonly isOpenDeleteUserModal: boolean = false
  readonly isLoadingDeleteUserModal: boolean = false
  readonly userToDelete: ShortEmployee | null = null

  readonly users: ShortEmployee[] = []
  readonly filteredUsers: ShortEmployee[] = this.users.slice()
}
