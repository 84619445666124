import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgilesDeskComponent } from "./presentation/agiles-desk.component";
import { RouterOutlet } from "@angular/router";
import { AgilesDeskMainModule } from "./modules/agiles-desk-main/agiles-desk-main.module";
import { AgilesDeskSettingsModule } from "./modules/agiles-desk-settings/agiles-desk-settings.module";
import { PageHeaderComponent } from "../../../core/components/page-header/page-header.component";

@NgModule({
  declarations: [
    AgilesDeskComponent,
  ],
  imports: [
    CommonModule,
    AgilesDeskMainModule,
    AgilesDeskSettingsModule,
    RouterOutlet,
    PageHeaderComponent
  ]
})
export class AgileDeskModule { }
