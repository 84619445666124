import { Injectable } from '@angular/core';
import { SpaceSummeryEntity } from '../components/space-item/space-item.component';

@Injectable()
export class SpacesState {
  readonly isLoading: boolean = false
  readonly isLoadingError: boolean = false
  readonly spaces: SpaceSummeryEntity[] = [];
  readonly recentSpaces: SpaceSummeryEntity[] = [];
  readonly filterString: string = ''
  readonly filteredSpaces: SpaceSummeryEntity[] =[]
  readonly canCreate: boolean = true
  readonly isCreating: boolean = false
  readonly isCreateSpaceModalVisible: boolean = false
}
