import { TariffFormState } from "./tariff-form-state";
import { TariffFormAction, TariffFormActionTypes } from "./tariff-form-action";
import { TariffFormResultAction, TariffFormResultActionTypes } from "./tariff-form-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import {
  DropdownItem
} from "../../../../../../../../core/components/fields/components/dropdown-field/dropdown-field.component";
import { ReceiptService } from "../../../../data/receipt-service";
import { convertDurationToDropDownItem, getDurationString } from "../../../utils/convert-tariff-duration";
import { ReceiptField, ReceiptSection } from "../../../../domain/receipt-secton";
import { ToastsService } from "../../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../../../core/components/toast-alert/toast-alert.component";
import { DatePipe, Location } from "@angular/common";
import { numToWordType } from "../../../../../../../../core/utils/num-to-word-type";
import { PurchasePeriod } from "../../../../../../data/dto/all-tariffs-dto";
import { AdditionalTariff, SubscriptionInfo, TariffInfo } from "../../../../../../data/dto/get-update-invoice-data-dto";
import { SubscriptionService } from "../../../../../../data/subscription.service";
import { SettingsNavigator } from "../../../../../../navigator/settings-navigator";
import { translate, TranslocoService } from "@jsverse/transloco";
import { TariffFormConstants } from "../common/tariff-form-constants";
import { PaymentAndTariffConstants } from "../../../../common/payment-and-tariff-constants";

@Injectable()
export class TariffFormExecutor extends Executor<
  TariffFormState,
  TariffFormAction,
  TariffFormResultAction
> {

  constructor(
    private receiptService: ReceiptService,
    private subscriptionService: SubscriptionService,
    private toastsService: ToastsService,
    private location: Location,
    private settingsNavigator: SettingsNavigator,
    private datePipe: DatePipe,
    private translocoService: TranslocoService
  ) {
    super();
  }

  override init(
    reducer: Reducer<TariffFormState, TariffFormResultAction>,
    getState: () => TariffFormState,
    onReduced: (state: TariffFormState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.handleInit()
    this.translocoService.langChanges$.subscribe(() => this.handleLangChanges())
  }

  execute(action: TariffFormAction) {
    switch (action.type) {
      case TariffFormActionTypes.CREATE_ORDER:
        this.handleCreateOrder()
        break;
      case TariffFormActionTypes.CHANGE_DURATION:
        const findDuration = this.getState().durationDropDownItems.find(tariff => tariff.id === action.durationId)
        if(findDuration) {
          this.reduce({
            type: TariffFormResultActionTypes.CHANGE_DURATION,
            duration: findDuration
          })
        }
        break
      case TariffFormActionTypes.CHANGE_ADDITIONAL_MEMORY:
        this.reduce({
          type: TariffFormResultActionTypes.CHANGE_ADDITIONAL_MEMORY,
          value: action.value
        })
        break
      case TariffFormActionTypes.CHANGE_USERS_COUNT:
        this.reduce({
          type: TariffFormResultActionTypes.CHANGE_USERS_COUNT,
          value: action.value
        })
        break
      case TariffFormActionTypes.CHANGE_IS_DURATION_OPEN:
        this.reduce({
          type: TariffFormResultActionTypes.CHANGE_IS_DURATION_OPEN,
          value: action.value
        })
        break
      case TariffFormActionTypes.CHANGE_IS_EXTENSIONS_OPEN:
        this.reduce({
          type: TariffFormResultActionTypes.CHANGE_IS_EXTENSIONS_OPEN,
          value: action.value
        })
        break
    }
    this.updateReceipt()
  }

  private handleLangChanges(){
    this.updateReceipt()
    let currentDuration: DropdownItem | null = null;
    const durations = this.getState().purchasePeriods
      .map<DropdownItem>((duration) => {
        const result = convertDurationToDropDownItem(duration)
        if (duration.monthsCount === parseInt(this.getState().currentDuration!.id)){
          currentDuration = result
        }
        return result
      }
    )
    this.reduce({
      type: TariffFormResultActionTypes.CHANGE_LANG,
      durationDropDownItems: durations,
      currentDuration: currentDuration
    })
  }

  private handleCreateOrder(){
    const state = this.getState()
    this.subscriptionService.createUpdateInvoice({
      expansionData: {
        additionalGigabytes: state.isExpansionOpen ? state.memoryCount : 0,
        additionalUsersCount: state.isExpansionOpen ?state.usersCount : 0
      },
      renewalData: {
        monthsCount: state.isDurationOpen ? state.currentDuration ? parseInt(state.currentDuration!.id) : 0 : 0,
      }
    }).subscribe({
      next: value => {
        this.settingsNavigator.navigateToOrders()
      },
      error: err => {
        this.toastsService.createToast({
          title: 'Произошла ошибка при создании заказа',
          description: 'Попробуйте позднее',
          state: ToastState.ERROR
        })
      }
    })
  }

  private handleInit() {
    this.reduce({
      type: TariffFormResultActionTypes.CHANGE_IS_LOADING,
      value: true
    })
    this.subscriptionService.getUpdateInvoiceData().subscribe({
      next: (dto) => {
        let currentDuration: DropdownItem | null = null;
        const durations = dto.purchasePeriods.map<DropdownItem>(
          (duration, i) => {
            const result = convertDurationToDropDownItem(duration)
            if (i === 0){
              currentDuration = result
            }
            return result
          }
        )

        this.reduce({
          type: TariffFormResultActionTypes.CHANGE_IS_LOADING,
          value: false
        })

        this.reduce({
          type: TariffFormResultActionTypes.INIT,
          subscriptionInfo: dto.subscriptionInfo,
          purchasePeriods: dto.purchasePeriods,
          currentTariff: dto.currentTariff,
          additionalTariff: dto.additionalTariff,
          durationDropDownItems: durations,
          currentDuration: currentDuration
        })

        setTimeout(() => this.updateReceipt())
      },
      error: err => {
        this.toastsService.createToast({
          title: 'Произошла ошибка при загрузке данных для продления подписки',
          description: 'Попробуйте позднее',
          state: ToastState.ERROR
        })
        this.reduce({
          type: TariffFormResultActionTypes.CHANGE_IS_LOADING,
          value: false
        })
        this.location.back()
      }
    })
  }

  private updateReceipt(){
    const state = this.getState()
    const subscriptionInfo = state.subscriptionInfo
    const currentTariff = state.currentTariff
    const additionalTariff = state.additionalTariff
    const currentDuration = state.currentDuration
    if(subscriptionInfo && currentTariff && currentDuration && additionalTariff)
    {
      const findDuration = state.purchasePeriods.find(dur => dur.monthsCount === parseInt(currentDuration.id))
      let receiptSections: ReceiptSection[] = []
      if(findDuration)
      {
        if(state.isExpansionOpen){
          this.addReceiptExpandSection(
            receiptSections,
            state.usersCount,
            state.memoryCount,
            subscriptionInfo,
            currentTariff,
            additionalTariff
          )
        }
        if(state.isDurationOpen){
          this.addReceiptExtendSection(
            receiptSections,
            state.usersCount,
            state.memoryCount,
            subscriptionInfo,
            currentTariff,
            additionalTariff,
            state.isExpansionOpen,
            currentDuration,
            findDuration
          )
        }
      }

      this.receiptService.updateSections(receiptSections)
    }
  }

  private addReceiptExtendSection(
    receiptSections: ReceiptSection[],
    usersCount: number,
    memoryCount: number,
    subscriptionInfo: SubscriptionInfo,
    currentTariff: TariffInfo,
    additionalData: AdditionalTariff,
    isExtensionsOpen: boolean,
    currentDuration: DropdownItem,
    findDuration: PurchasePeriod
  ){
    let fields: ReceiptField[] = []
    const currentUsersCount = isExtensionsOpen ? usersCount + subscriptionInfo.maxUsersCount : subscriptionInfo.maxUsersCount
    const currentMemoryCount = isExtensionsOpen ? memoryCount + subscriptionInfo.additionalGigabytes : subscriptionInfo.additionalGigabytes

    const durationString = getDurationString(parseInt(currentDuration.id))
    fields.push({
      text: translate(TariffFormConstants.TRANSLOCO_READ + '.extension-tariff', {
        tariffName: currentTariff.name,
        durationString: durationString,
        usersCount: currentUsersCount,
        usersCountType: numToWordType(currentUsersCount),
      }),
      price: currentTariff.userCost * currentUsersCount * findDuration.monthsCount
    })
    if(findDuration.discount){
      fields.push({
        text: translate(TariffFormConstants.TRANSLOCO_READ + '.extension-tariff-discount', {
          discount: findDuration.discount,
          durationString: durationString,
        }),
        price: -Math.round((currentTariff.userCost * currentUsersCount * findDuration.monthsCount * findDuration.discount / 100) * 100) / 100
      })
    }

    const expandStorageCost = additionalData.storageTariffs.find((cost) => cost.minCount <= currentMemoryCount && currentMemoryCount <= cost.maxCount)

    if(expandStorageCost){
      fields.push({
        text: translate(TariffFormConstants.TRANSLOCO_READ + '.extension-storage', {
          gigabytesCount: currentMemoryCount,
        }),
        price: expandStorageCost.cost * findDuration.monthsCount * currentMemoryCount
      })
    }

    if(fields.length > 0){
      receiptSections.push({
        title: translate(TariffFormConstants.TRANSLOCO_READ + '.extension'),
        fields: fields
      })
    }
  }

  private addReceiptExpandSection(
    receiptSections: ReceiptSection[],
    usersCount: number,
    memoryCount: number,
    subscriptionInfo: SubscriptionInfo,
    currentTariff: TariffInfo,
    additionalData: AdditionalTariff
  ){
    let fields: ReceiptField[] = []
    const expandDate = this.datePipe.transform(
      new Date(subscriptionInfo.expirationDate * 1000),
      'd MMM YYYY',
      undefined,
      'ru-RU'
    )
    if(usersCount > 0){
      fields.push({
        text: translate(TariffFormConstants.TRANSLOCO_READ + '.expansion-users', {
          usersCount: usersCount,
          usersCountType: numToWordType(usersCount),
          date: expandDate
        }),
        price: currentTariff.userCost * subscriptionInfo.monthsCountToExpirationDate * usersCount
      })
    }
    if(memoryCount > 0){
      const extensionStorageCost = additionalData.storageTariffs.find(
        (cost) => cost.minCount <= memoryCount && memoryCount <= cost.maxCount
      )
      if(extensionStorageCost)
      {
        fields.push({
          text: translate(TariffFormConstants.TRANSLOCO_READ + '.expansion-storage', {
            gigabytesCount: memoryCount,
            date: expandDate
          }),
          price: extensionStorageCost.cost  * subscriptionInfo.monthsCountToExpirationDate * memoryCount
        })
      }
    }
    if(fields.length > 0){
      receiptSections.push({
        title: translate(TariffFormConstants.TRANSLOCO_READ + '.expansion'),
        fields: fields
      })
    }
  }

  private monthsBetween(date1: Date, date2: Date): number {
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();

    const monthsDifference = (year2 - year1) * 12 + (month2 - month1);

    return Math.abs(monthsDifference);
  }
}
