<div [ngClass]="className" class="inline-block">
  <button
    (click)="onClickValue($event)"
    [disabled]="disabled || loading"
    class="
      font-normal w-full relative overflow-hidden flex items-center justify-center
      px-[16px] py-[8px] rounded-[8px] transition-colors whitespace-nowrap
      group outline-0
    "
    [ngClass]="{
      'cursor-pointer': !disabled && !loading,
      'text-tt-font-size_l': size === 'm' ||  size === 'l',
      'h-[44px]': size === 'l',
      'text-tt-font-size_2xl h-[56px]': size === 'xl',
      'text-tt-text-contrast': variant === 'solid' && !disabled,
      'outline-tt-informer-info-icon outline-[2px]': variant === 'soft' && !disabled ,
      'bg-tt-primary-button hover:bg-tt-primary-button-hover':
        type === 'basic' && variant === 'solid' && !disabled,
      'bg-tt-primary-button-disable hover:bg-tt-primary-button-disable': variant === 'solid' && disabled ,
      'hover:bg-tt-cancel-button-hover':
        type === 'basic' && variant === 'soft',
      'text-tt-text-secondary': type === 'basic' && (variant === 'soft' || (variant === 'solid' && disabled)),
      'bg-tt-delete-button hover:bg-tt-delete-button-hover':
        type === 'danger' && variant === 'solid',
      'text-tt-delete-button-lite-title hover:bg-tt-delete-button-lite-background-hover':
        type === 'danger' && variant === 'soft',
    }"
    #button
  >
    <div
      *ngIf="variant === 'solid' && !isPressed"
      class="
      absolute left-[2px] top-[2px]
      solid-outline-block border-[2px] border-tt-text-contrast rounded-[6px]
      hidden group-focus:block"
    ></div>

    <div
      *ngIf="loading"
      class="absolute w-full h-full flex justify-center items-center z-10 bg-inherit"
      [ngClass]="{
        'text-[#82adf1]': variant === 'solid',
        'text-tt-primary': variant === 'soft'
      }"
    >
      <div class="animate-spin">
        <svg-loading-icon />
      </div>
    </div>
    <ng-content />
  </button>
</div>
