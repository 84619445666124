import { CSSProperties } from 'react';
import { RenderElementProps } from 'slate-react';
import DailyMotion from "./players/daily-motion-player";
import VimeoPlayer from "./players/vimeo-player";
import YouTubePlayer from "./players/yotube-player";
import { VideoElementProps } from "@yoopta/video";

type VideoComponentProps = Omit<VideoElementProps, 'sizes'> & {
  width: number;
  height: number;
} & Pick<RenderElementProps, 'attributes' | 'children'>;

const PROVIDERS = {
  vimeo: VimeoPlayer,
  youtube: YouTubePlayer,
  dailymotion: DailyMotion,
};

const VideoComponent = ({
    width,
    height,
    src,
    bgColor,
    poster,
    provider,
    fit,
    attributes,
    children,
  }: VideoComponentProps) => {
  const style: CSSProperties = {
    backgroundColor: bgColor || 'transparent',
    objectFit: fit || 'contain',
  };

  // @ts-ignore
  if (provider && provider.id && provider.type && PROVIDERS[provider.type]) {
    // @ts-ignore
    const Provider = PROVIDERS[provider.type];
    return (
      <Provider videoId={provider.id} width={width} height={height} attributes={attributes}>
        {children}
      </Provider>
    );
  }

  return (
    <div className="w-full" {...attributes}>
      {src && (
        <video
          preload="metadata"
          poster={poster}
          src={src}
          width={width}
          height={height}
          onDragStart={(e) => e.preventDefault()}
          className="object-cover w-full h-full"
          style={style}
          playsInline
          controls
        />
      )}
      {children}
    </div>
  );
};

export { VideoComponent, VideoComponentProps };
