import { Component } from '@angular/core';
import { TasksTableEntity } from "./components/tasks-table/tasks-table.component";
import { AgilesService } from "../../../data/agiles.service";
import { provideTranslocoScope } from "@jsverse/transloco";
import { AgileDeskConstants } from "../common/agile-desk-constants";

@Component({
  selector: 'app-agiles-desk-main',
  templateUrl: './agiles-desk-main.component.html',
  styleUrls: ['./common/agiles-desk.component.scss'],
  providers: [
    provideTranslocoScope({scope: 'agiles/agile-desk/main', alias: AgileDeskConstants.TRANSLOCO_READ}),
  ]
})
export class AgilesDeskMainComponent {
  showModal: boolean = false;
  table: TasksTableEntity

  constructor(private agilesService: AgilesService) {
    this.table = this.agilesService.getTable()
  }
}
