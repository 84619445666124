import { Component, Input } from "@angular/core";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-core-auth-container-icon",
  templateUrl: "./auth-container-icon.component.html",
  styleUrls: ["./auth-container-icon.component.scss"],
  imports: [
    NgClass
  ],
  standalone: true
})
export class AuthContainerIconComponent {
  @Input() variant: 'base' | 'danger' | 'success' = 'base'
}
