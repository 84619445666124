import { Injectable } from '@angular/core';
import { SubscriptionEntity, TariffType } from "../../domain/subscription-entity";
import { TariffEntity } from "../../domain/tariff-entity";
import { TariffDuration } from "../../domain/tariff-duration";

@Injectable()
export class PaymentAndTariffState {
  readonly isEditable: boolean = false;
  readonly isLoading: boolean = false;
  readonly isLoadingError: boolean = false;

  readonly isSubscriptionFormOpen: boolean = false;
  readonly isTariffFormOpen: boolean = false;

  readonly currentDuration: TariffDuration | null = null;
  readonly durationItems: TariffDuration[] = []

  readonly currentTariff?: TariffEntity
  readonly tariffs: TariffEntity[] = []

  readonly subscription?: SubscriptionEntity
}
