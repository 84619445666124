export type TariffFormAction =
  | ChangeUsersCountAction
  | ChangeAdditionalMemoryAction
  | ChangeDurationAction
  | ChangeIsExtensionsOpenAction
  | ChangeIsDurationOpenAction
  | CreateOrderAction

export enum TariffFormActionTypes {
  CHANGE_DURATION,
  CHANGE_USERS_COUNT,
  CHANGE_ADDITIONAL_MEMORY,
  CHANGE_IS_EXTENSIONS_OPEN,
  CHANGE_IS_DURATION_OPEN,
  CREATE_ORDER,
}

export interface CreateOrderAction {
  readonly type: TariffFormActionTypes.CREATE_ORDER
}

export interface ChangeIsExtensionsOpenAction {
  readonly type: TariffFormActionTypes.CHANGE_IS_EXTENSIONS_OPEN
  readonly value: boolean
}

export interface ChangeIsDurationOpenAction {
  readonly type: TariffFormActionTypes.CHANGE_IS_DURATION_OPEN
  readonly value: boolean
}

export interface ChangeAdditionalMemoryAction {
  readonly type: TariffFormActionTypes.CHANGE_ADDITIONAL_MEMORY
  readonly value: number
}

export interface ChangeUsersCountAction {
  readonly type: TariffFormActionTypes.CHANGE_USERS_COUNT
  readonly value: number
}
export interface ChangeDurationAction {
  readonly type: TariffFormActionTypes.CHANGE_DURATION
  readonly durationId: string
}
