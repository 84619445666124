import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SpaceSettingsHome,
  SvgPerson,
  SvgTrash, SvgTripleDot, SvgXMark
} from "../../../../../core/components/svg-components/svg.components";
import { AgilesDeskSettingsComponent } from "./presentation/agiles-desk-settings.component";
import { AgilesDeskSettingsMainComponent } from './presentation/components/agiles-desk-settings-main/agiles-desk-settings-main.component';
import { AgilesDeskSettingsNavComponent } from './presentation/components/agiles-desk-settings-nav/agiles-desk-settings-nav.component';
import { AgilesDeskSettingsStatusesAndExecutorsComponent } from './presentation/components/agiles-desk-settings-statuses-and-executors/agiles-desk-settings-statuses-and-executors.component';
import { CdkDrag, CdkDragHandle, CdkDragPlaceholder, CdkDropList } from "@angular/cdk/drag-drop";
import { StatusListComponent } from './presentation/components/agiles-desk-settings-statuses-and-executors/components/status-list/status-list.component';
import { ExecutorListComponent } from './presentation/components/agiles-desk-settings-statuses-and-executors/components/executor-list/executor-list.component';
import { Validator } from "../../../../../core/validators/validator";
import { EmptyRule, MaxLengthRule } from "../../../../../core/validators/rule";
import { AgilesDeskSettingsAccessComponent } from './presentation/components/agiles-desk-settings-access/agiles-desk-settings-access.component';
import { AgilesDeskAccessEmployeeComponent } from './presentation/components/agiles-desk-settings-access/components/agiles-desk-settings-access-employee/agiles-desk-access-employee.component';
import { SwitcherComponent } from "../../../../../core/components/switcher/switcher.component";
import { AlertModalComponent } from "../../../../../core/components/alert-modal/alert-modal.component";
import { ButtonTextComponent } from "../../../../../core/components/buttons/button-text/button-text.component";
import {
  TripleDotActionMenuComponent
} from "../../../../../core/components/triple-dot-action-menu/triple-dot-action-menu.component";
import { TagComponent } from "../../../../../core/components/tag/tag.component"
import { SelectUserModalComponent } from "../../../../../core/components/select-user-modal/select-user-modal.component";
import { PaymentAndTariffModule } from "../../../../settings/modules/payment-and-tariff/payment-and-tariff.module";
import { SettingsModule } from "../../../../../core/components/settings/settings.module";
import { ActionMenu } from "../../../../../core/components/action-menu/action-menu.component";
import { PopupDirective } from "../../../../../core/directives/popup.directive";
import { UserSummeryComponent } from "../../../../../core/components/user-summery/user-summery.component";
import { ButtonComponent } from "../../../../../core/components/buttons/button/button.component";
import { FieldsModule } from "../../../../../core/components/fields/fields.module";
import { SvgAgilesCard, SvgAgilesStatuses, SvgStatusDragHandle } from "./presentation/icons/agiles-desk-icons";
import { TranslocoDirective } from "@jsverse/transloco";
import { TextParserComponent } from "../../../../../core/components/text-parser/text-parser.component";

@NgModule({
  declarations: [
    AgilesDeskSettingsComponent,
    AgilesDeskSettingsMainComponent,
    AgilesDeskSettingsNavComponent,
    AgilesDeskSettingsStatusesAndExecutorsComponent,
    StatusListComponent,
    ExecutorListComponent,
    AgilesDeskSettingsAccessComponent,
    AgilesDeskAccessEmployeeComponent,
    SvgStatusDragHandle,
    SvgAgilesCard,
    SvgAgilesStatuses,
  ],
  imports: [
    CommonModule,
    SpaceSettingsHome,
    SvgTrash,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    CdkDragPlaceholder,
    SvgTripleDot,
    SvgXMark,
    SvgPerson,
    SwitcherComponent,
    AlertModalComponent,
    ButtonTextComponent,
    TripleDotActionMenuComponent,
    TagComponent,
    SelectUserModalComponent,
    PaymentAndTariffModule,
    SettingsModule,
    ActionMenu,
    PopupDirective,
    UserSummeryComponent,
    ButtonComponent,
    FieldsModule,
    TranslocoDirective,
    TextParserComponent
  ],
  providers: [
    {
      provide: "TaskStatusTitleValidator",
      useExisting: Validator,
      useFactory: AgilesDeskSettingsModule.tasksStatusTitleValidatorFactory
    },
  ]
})
export class AgilesDeskSettingsModule {
  public static tasksStatusTitleValidatorFactory = () =>
    new Validator([
      new EmptyRule('Название статуса не может быть пустым'),
      new MaxLengthRule('Название статуса не может быть длиннее 40 символов', 40),
    ]);
}
