<div
  class="w-full flex flex-col justify-between"
  *transloco="let t; read: AgileDeskSettingsConstants.TRANSLOCO_READ"
>
  <div>
    <app-core-settings-content-element
      [title]="t('statuses-and-executors')"
    >
      <p>{{ t('statuses-and-executors-description') }}</p>
    </app-core-settings-content-element>

    <div class="h-[1px] w-full bg-tt-border-default"></div>

    <app-core-settings-content-element
      [title]="t('statuses')"
      class="pb-[0px]"
    >
      <p>{{ t('statuses-description') }}</p>
      <app-status-list
        [statusList]="state.statuses"
        (onChangeList)="performAction({
          type: AgilesDeskSettingsStatusesAndExecutorsActionTypes.CHANGE_STATUS_LIST,
          list: $event
        })"
      />
    </app-core-settings-content-element>

    <app-core-settings-content-element
      [title]="t('executors')"
    >
      <p>{{ t('executors-description') }}</p>
      <app-executor-list
        [showUnassignedTasks]="state.isUnassignedTasksShow"
        [executorList]="state.executors"
        (showUnassignedTasksChanged)="performAction({
          type: AgilesDeskSettingsStatusesAndExecutorsActionTypes.CHANGE_UNASSIGNED_SHOW,
          value: $event
        })"
        (onChangeList)="performAction({
          type: AgilesDeskSettingsStatusesAndExecutorsActionTypes.CHANGE_EXECUTOR_LIST,
          list: $event
        })"
      />
    </app-core-settings-content-element>
  </div>


</div>
