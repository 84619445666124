import { Component, EventEmitter, Output } from "@angular/core";
import { AgilesDeskSettingsNavItems } from "../../state/agiles-desk-settings-state";
import { AgileDeskSettingsConstants } from "../../../common/agile-desk-settings-constants";

@Component({
  selector: 'app-agiles-desk-settings-main',
  templateUrl: './agiles-desk-settings-main.component.html',
  styleUrls: ['./agiles-desk-settings-main.component.scss']
})
export class AgilesDeskSettingsMainComponent {
  protected readonly AgileDeskSettingsConstants = AgileDeskSettingsConstants;
}
