import { MovingDocumentProps } from "../components/document-item/document-item.component";

export type SpaceAction =
  | ChangeNavItemAction
  | CreateAction
  | FilterContentAction
  | UpdateSpaceAction
  | UpdateContentAction
  | MoveToDocumentPageAction
  | OpenDocumentAction
  | UpdateSpaceTitleAction
  | DeleteDocumentAction
  | DocumentStartLoadAction
  | DocumentEndLoadAction
  | MovingDocumentAction
  | OpenDeleteDocumentModalAction
  | CloseDeleteDocumentModalAction
  | ChangeRenameModalVisibilityAction
  | ChangeAlertNameAction
  | SaveDocumentNameAction
  | ChangeDocumentListVisibleAction

export enum SpaceActionTypes {
  CHANGE_NAV_ITEM,
  FILTER_CONTENT,
  CREATE_DOCUMENT,
  DELETE_DOCUMENT,
  OPEN_DELETE_DOCUMENT_MODAL,
  CLOSE_DELETE_DOCUMENT_MODAL,
  UPDATE_SPACE,
  UPDATE_CONTENT,
  OPEN_DOCUMENT,
  MOVE_TO_DOCUMENT_PAGE,
  MOVE_DOCUMENT,
  UPDATE_SPACE_TITLE,
  DOCUMENT_START_LOAD,
  DOCUMENT_END_LOAD,
  CHANGE_RENAME_MODAL_VISIBILITY,
  CHANGE_ALERT_NAME,
  SAVE_DOCUMENT_NAME,
  CHANGE_DOCUMENT_LIST_VISIBLE,
}
export interface ChangeDocumentListVisibleAction {
  readonly type: SpaceActionTypes.CHANGE_DOCUMENT_LIST_VISIBLE,
  readonly value: boolean
}

export interface SaveDocumentNameAction {
  readonly type: SpaceActionTypes.SAVE_DOCUMENT_NAME,
}

export interface ChangeAlertNameAction {
  readonly type: SpaceActionTypes.CHANGE_ALERT_NAME,
  readonly value: string
}

export interface ChangeRenameModalVisibilityAction {
  readonly type: SpaceActionTypes.CHANGE_RENAME_MODAL_VISIBILITY,
  readonly value: boolean,
  readonly id?: string,
  readonly name?: string,
}

export interface MovingDocumentAction {
  readonly type: SpaceActionTypes.MOVE_DOCUMENT,
  readonly movingProps: MovingDocumentProps
}

export interface DocumentStartLoadAction {
  readonly type: SpaceActionTypes.DOCUMENT_START_LOAD,
}

export interface DocumentEndLoadAction {
  readonly type: SpaceActionTypes.DOCUMENT_END_LOAD,
  readonly updatedAt: number
}

export interface OpenDeleteDocumentModalAction {
  readonly type: SpaceActionTypes.OPEN_DELETE_DOCUMENT_MODAL,
  readonly id: string
  readonly name: string
}

export interface CloseDeleteDocumentModalAction {
  readonly type: SpaceActionTypes.CLOSE_DELETE_DOCUMENT_MODAL,
}

export interface DeleteDocumentAction {
  readonly type: SpaceActionTypes.DELETE_DOCUMENT,
}

export interface UpdateSpaceTitleAction {
  readonly type: SpaceActionTypes.UPDATE_SPACE_TITLE,
  readonly title: string
}


export interface OpenDocumentAction {
  readonly type: SpaceActionTypes.OPEN_DOCUMENT
  readonly documentId: string,
}

export interface MoveToDocumentPageAction {
  readonly type: SpaceActionTypes.MOVE_TO_DOCUMENT_PAGE
  readonly id: string
}

export interface UpdateContentAction {
  readonly type: SpaceActionTypes.UPDATE_CONTENT
}

export interface UpdateSpaceAction {
  readonly type: SpaceActionTypes.UPDATE_SPACE
}

export interface ChangeNavItemAction {
  readonly type: SpaceActionTypes.CHANGE_NAV_ITEM;
  readonly id: string
}

export interface FilterContentAction {
  readonly type: SpaceActionTypes.FILTER_CONTENT;
  readonly value: string
}

export interface CreateAction {
  readonly type: SpaceActionTypes.CREATE_DOCUMENT;
  readonly parentId?: string
  readonly sectionId?: string
}
