import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-core-button-text",
  templateUrl: "./button-text.component.html",
  standalone: true,
  imports: [
    NgClass
  ],
  styleUrls: ["./button-text.component.scss"]
})
export class ButtonTextComponent {
  @Input() isDisabled = false;
  @Input() type: 'base' | 'danger' | 'main' = 'base'

  @Output() onClick = new EventEmitter();
}
