<app-auth-layout
  topOffset='constant'
  bgType="white"
  backButtonType="back"
  [isHaveBackButton]="!state.recoveryToken"
  [backButtonText]="t('back-to-system')"
  [isLoading]="state.isLoading || state.currentStage === 'loading'"
  *transloco="let t; read: PasswordResetConstants.TRANSCLOCO_READ"
>
  <div class="mt-[120px] max-w-[400px] w-full flex flex-col gap-[24px]"
    *ngIf="state.currentStage !== 'loading'"
  >
    <div
      class="w-full flex flex-col items-center"
    >
      <div
        class="flex items-center justify-center size-[56px] rounded-full"
        [ngClass]="{
          'bg-[#0B57D01A] text-tt-primary': state.currentStage === 'init' || state.currentStage==='new-password',
          'bg-[#35B37E1A] text-[#35B37E]': state.currentStage === 'success',
          'bg-[#D3302F1A] text-tt-input-field-failure-text': state.currentStage === 'error' || state.currentStage === 'session-expired' || state.currentStage === 'already-changed',
        }"
      >
        <svg-reset-password-key
          *ngIf="state.currentStage === 'init' || state.currentStage==='new-password'"
        />
        <svg-reset-password-success
          *ngIf="state.currentStage === 'success'"
        />
        <svg-reset-password-error
          *ngIf="state.currentStage === 'error' || state.currentStage === 'session-expired' || state.currentStage === 'already-changed'"
        />
      </div>
      <p
        class="mt-[16px] text-tt-font-size_5xl text-tt-text-main"
      >
        <span *ngIf="state.currentStage==='init'">{{ t('prove-yourself-title') }}</span>
        <span *ngIf="state.currentStage==='error'">{{ t('error-unknown-title') }}</span>
        <span *ngIf="state.currentStage==='new-password'">{{ t('set-new-password-title') }}</span>
        <span *ngIf="state.currentStage==='success'">{{ t('password-changed-title') }}</span>
        <span *ngIf="state.currentStage==='session-expired'">{{ t('link-expired-title') }}</span>
        <span *ngIf="state.currentStage==='already-changed'">{{ t('password-already-changed-title') }}</span>
      </p>
      <p
        class="mt-[8px] text-tt-font-size_l text-tt-text-secondary text-center"
      >
        <span *ngIf="state.currentStage==='init'">{{ t('prove-yourself-description') }}</span>
        <span *ngIf="state.currentStage==='new-password' && !state.recoveryToken">{{ t('set-new-password-description-authorized') }}</span>
        <span *ngIf="state.currentStage==='new-password' && state.recoveryToken">{{ t('set-new-password-description-unauthorized') }}</span>
        <span *ngIf="state.currentStage==='success' && !state.recoveryToken">{{ t('password-changed-description-authorized') }}</span>
        <span *ngIf="state.currentStage==='success' && state.recoveryToken">{{ t('password-changed-description-unauthorized') }}</span>
        <span *ngIf="state.currentStage==='error'">{{ t('error-unknown-description') }}</span>
        <span *ngIf="state.currentStage==='session-expired'">{{ t('link-expired-description') }}</span>
        <span *ngIf="state.currentStage==='already-changed'">{{ t('password-already-changed-description') }}</span>
      </p>
    </div>

    <app-core-input-field
      *ngIf="state.currentStage==='init'"
      size="xl"
      inputVariant="standard"
      [label]="t('current-password-label')"
      [placeholder]="t('current-password-placeholder')"
      type="password"
      [error]="state.currentPasswordError"
      errorShowType="onUnFocused"
      (onFocused)="performAction({
        type: PasswordResetActionTypes.CHANGE_CURRENT_PASSWORD,
        value: state.currentPassword
      })"
      (onChange)="performAction({
        type: PasswordResetActionTypes.CHANGE_CURRENT_PASSWORD,
        value: $event
      })"
    />

    <app-core-input-field
      *ngIf="state.currentStage==='new-password'"
      size="xl"
      inputVariant="standard"
      [label]="t('new-password-label')"
      [placeholder]="t('new-password-placeholder')"
      type="password"
      [error]="state.newPasswordError"
      errorShowType="onUnFocused"
      (onFocused)="performAction({
        type: PasswordResetActionTypes.CHANGE_CURRENT_PASSWORD,
        value: state.newPassword
      })"
      (onChange)="performAction({
        type: PasswordResetActionTypes.CHANGE_NEW_PASSWORD,
        value: $event
      })"
    />

    <app-core-input-field
      *ngIf="state.currentStage==='new-password'"
      size="xl"
      inputVariant="standard"
      [label]="t('confirm-password-label')"
      [placeholder]="t('confirm-password-placeholder')"
      type="password"
      [error]="state.newConfirmPasswordError"
      errorShowType="onUnFocused"
      (onFocused)="performAction({
        type: PasswordResetActionTypes.CHANGE_CURRENT_PASSWORD,
        value: state.newConfirmPassword
      })"
      (onChange)="performAction({
        type: PasswordResetActionTypes.CHANGE_NEW_CONFIRM_PASSWORD,
        value: $event
      })"
    />

    <div>
      <app-core-button
        *ngIf="state.currentStage === 'init' || state.currentStage === 'new-password'"
        size="l"
        className="w-full"
        (onClick)="performAction({
          type: PasswordResetActionTypes.GO_TO_NEXT_STAGE
        })"
      >
        <span
          *ngIf="state.currentStage === 'init'"
        >{{ t('continue') }}</span>
        <span
          *ngIf="state.currentStage === 'new-password'"
        >{{ t('save-new-password') }}</span>
      </app-core-button>

      <app-core-button
        *ngIf="!(state.recoveryToken && state.currentStage === 'new-password') && state.currentStage !== 'init' && state.currentStage !== 'new-password'"
        size="l"
        className="w-full"
        (onClick)="performAction({
          type: PasswordResetActionTypes.GO_BACK
        })"
      >
        <span *ngIf="state.currentStage !== 'success'">{{ t('back-to-main') }}</span>
        <span *ngIf="state.currentStage === 'success'">{{ t('complete') }}</span>
      </app-core-button>
    </div>
  </div>

</app-auth-layout>
