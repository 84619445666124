import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: "app-core-switcher",
  templateUrl: "./switcher.component.html",
  styleUrls: ["./switcher.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    NgIf
  ],
  host: {
    class: "flex justify-between items-center"
  }
})
export class SwitcherComponent {
  @Input() label: string = ''
  @Input() value: boolean = false;
  @Input() isDisabled: boolean = false;
  @Output() onSwitch = new EventEmitter();
}
