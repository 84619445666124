import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-header-action-button',
  templateUrl: './header-action-button.component.html',
  styleUrls: ['./header-action-button.component.scss'],
  host: {
    class: 'w-full'
  }
})
export class HeaderActionButtonComponent {
  @Input() variant: 'base' | 'danger' = 'base'

  @Output() onClick = new EventEmitter();
}
