import { Component, ElementRef, ViewChild } from "@angular/core";
import { NewsListState } from "./state/news-list-state";
import { NewsListReducer } from "./state/news-list-reducer";
import { NewsListExecutor } from "./state/news-list-executor";
import { Store } from "../../../../../core/mvi/store";
import { NewsListAction, NewsListActionTypes } from "./state/news-list-action";
import { NewsListResultAction } from "./state/news-list-result-action";
import { NewsConstants } from "../../../common/news-constants";
import { MainRoutesPaths } from "../../../../../routes/main-routes";
import { HeaderService } from "../../../../main/data/header-service";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { NewsService } from "../../../data/news-service";
import { NewsRoutesPath } from "../../../../../routes/news-routes";
import { filter } from "rxjs";
import { NavTab } from "../../../../../core/components/nav-tabs/nav-tabs.component";
import { Tabs } from "../../../../main/presentation/state/main-state";

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss', '../../../../main/presentation/main.component.scss'],
  providers: [
    NewsListState,
    NewsListReducer,
    NewsListExecutor
  ]
})
export class NewsListComponent extends Store<
  NewsListState,
  NewsListExecutor,
  NewsListAction,
  NewsListResultAction
>{
  @ViewChild('scrollable', { read: ElementRef }) scrollableEl?: ElementRef

  readonly NAV_TABS: NavTab[] = [
    {
      id: Tabs.NEWS,
      url: '/' + MainRoutesPaths.NEWS,
      title: 'Главная'
    }
  ]

  constructor(
    state: NewsListState,
    executor: NewsListExecutor,
    reducer: NewsListReducer,
    private headerService: HeaderService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super(state, executor, reducer);

    headerService.ChangeStyle('bg-tt-bg-page')
    headerService.ChangeBreadCrumbs([])
  }

  onScroll(){
    if(this.scrollableEl){
      if(this.scrollableEl.nativeElement.scrollTop > 0){
        this.headerService.ChangeStyle('bg-tt-bg-page border-b-[1px] border-tt-border-default')
      }
      else{
        this.headerService.ChangeStyle('bg-tt-bg-page')
      }
      if(this.scrollableEl.nativeElement.scrollTop > 50){
        this.headerService.ChangeBreadCrumbs([
          {
            name: NewsConstants.NEWS,
            href: '/' + MainRoutesPaths.NEWS
          }
        ])
      }
      else{
        this.headerService.ChangeBreadCrumbs([])
      }
    }
  }

  protected readonly NewsListActionTypes = NewsListActionTypes;
  protected readonly NewsConstants = NewsConstants;
}
