
  <div class="h-screen flex flex-col relative">
    <div
      class="bg-white flex align-middle justify-between w-full min-h-[60px] max-h-[60px] gap-[40px] border-b-[1px] border-tt-separator-color"
      *transloco="let t; read: DocumentConstants.TRANSLOCO_READ"
    >
      <div class="flex items-center overflow-hidden flex-grow">
        <app-core-button-icon
          class="ml-[16px]"
          (click)="performAction({
          type: DocumentActionTypes.BACK_CLICKED
        })"
        >
          <svg-back-arrow
            class="size-[24px]"
          />
        </app-core-button-icon>

        <p class="ml-[16px] flex-grow line-clamp-1 py-[2px] px-[8px] rounded-[4px] text-tt-text-main">
          {{ state.title == '' ? SpaceConstants.EMPTY_DOCUMENT_NAME: state.title }}
        </p>
      </div>
      <div class="mr-[24px] my-auto flex items-center gap-[16px]">
        <app-core-button
          (onClick)="performAction({
            type: DocumentActionTypes.SAVE_CLICKED
          })"
        >
          {{ t('save-changes') }}
        </app-core-button>
      </div>
    </div>

    <app-yoopta-editor
      #editor
      class="pt-[40px] w-full flex justify-center flex-grow overflow-y-auto"
      id="yoopta-editor-scroll"
      [longreadId]="state.longreadId"
      [isReadOnly]="false"
      [isHaveTitle]="true"
      [title]="state.title"
      (titleChange)="performAction({
        type: DocumentActionTypes.CHANGE_TITLE,
        title: $event
      })"
        (saveClicked)="performAction({
        type: DocumentActionTypes.SAVE_CLICKED
      })"
    />
  </div>

<ng-container
  *transloco="let t; read: DocumentConstants.TRANSLOCO_READ"
>
  <app-core-alert-modal
    *ngIf="state.saveModalVisible"
    [title]="t('changes-saved-informer-title')"
    type="info"
    [isLoading]="state.isLoadingSaveModal"
    [hideContentOnLoad]="true"
    [cancelButtonText]="t('continue')"
    [confirmButtonText]="t('back-to-space')"
    (onConfirmClicked)="performAction({
    type: DocumentActionTypes.MOVE_BACK
  })"
    (onCancelClicked)="performAction({
    type: DocumentActionTypes.CLOSE_GO_TO_SPACE_MODAL
  })"
  >
    <p>{{ t('changes-saved-informer-description') }}</p>
  </app-core-alert-modal>

  <app-core-alert-modal
    *ngIf="state.backModalVisible"
    [title]="t('save-changes-informer-title')"
    type="info"
    closeActionType="self"
    [isLoading]="state.isLoadingBackModal"
    [hideContentOnLoad]="true"
    [cancelButtonText]="t('exit')"
    [confirmButtonText]="t('save-and-exit')"
    (onCancelClicked)="performAction({
    type: DocumentActionTypes.MOVE_BACK
  })"
    (onConfirmClicked)="performAction({
    type: DocumentActionTypes.SAVE_AND_MOVE
  })"
    (onCloseClicked)="performAction({
    type: DocumentActionTypes.CLOSE_BACK_MODAL
  })"
  >
    <p>{{ t('save-changes-informer-description') }}</p>
  </app-core-alert-modal>

  <app-core-alert-modal
    *ngIf="state.afkModalVisible"
    [title]="t('are-you-here-informer')"
    type="info"
    closeActionType="confirm"
    [isLoading]="state.isLoadingBackModal"
    [hideContentOnLoad]="true"
    [cancelButtonText]="t('save-and-exit')"
    [confirmButtonText]="t('stay')"
    (onCancelClicked)="performAction({
      type: DocumentActionTypes.SAVE_AND_MOVE
    })"
    (onConfirmClicked)="performAction({
      type: DocumentActionTypes.CLOSE_AFK_MODAL
    })"
  >
    <app-core-stopwatch
      class="w-full flex justify-center"
      type="moveBack"
      [time]="300"
      [stopTime]="0"
    />
    <p class="mt-[8px]">{{ t('are-you-here-informer-description') }}</p>
  </app-core-alert-modal>

  <div
    class="w-screen h-screen absolute left-0 top-0 bg-white z-50 flex items-center justify-center"
    *ngIf="state.isLoading"
  >
    <mat-spinner
      class="size-[40px]"
    />
  </div>

  <app-core-alert-modal
    *ngIf="state.alreadyEditModalVisible"
    [title]="t('session-conflict-informer-title')"
    type="info"
    closeActionType="confirm"
    [confirmButtonText]="t('close')"
    (onConfirmClicked)="performAction({
    type: DocumentActionTypes.MOVE_BACK
  })"
  >
    <p>{{ t('session-conflict-informer-description') }}</p>
  </app-core-alert-modal>

</ng-container>
