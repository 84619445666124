<app-core-alert-modal
  *transloco="let t; read: SpacesConstants.TRANSLOCO_READ"
  [title]="t('new-space')"
  type="info"
  [cancelButtonText]="t('cancel')"
  [isLoading]="isCreating"
  [isInitFocus]="false"
  [confirmButtonText]="t('create')"
  [confirmDisabled]="spaceName == '' || spaceNameError != null"
  (onConfirmClicked)="create()"
  (onCancelClicked)="closeClicked.emit()"
>
  <div
    class="px-[2px]"
  >
    <app-core-input-field
      [label]="t('new-space-title-label')"
      inputVariant="standard"
      placeholder=""
      error="{{ spaceNameError }}"
      errorShowType="onUnFocused"
      [autoFocus]="true"
      [charsCounterMax]="KnowledgeBaseConstants.SPACE_TITLE_MAX_LENGTH"
      [required]="true"
      (onChange)="spaceNameChange($event)"
    />
  </div>

</app-core-alert-modal>
