import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-core-settings-content-element',
  templateUrl: './settings-content-element.component.html',
  host: {
    class: 'p-[24px] w-full flex flex-col gap-[12px]',
  }
})
export class SettingsContentElementComponent {
  @Input() title: string = ''
}
