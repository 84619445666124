<div
  [ngClass]="{
    'px-[24px] py-[14px]': type === 'show',
    'py-[8px]': type === 'hide' && !isFirst,
    'pt-[8px]': type === 'hide' && isFirst,
  }"
>
  <div
    *ngIf="type === 'hide' && !isFirst"
    class="w-full h-[1px] bg-tt-border-default"
  ></div>
  <p
    *ngIf="type === 'show'"
    class="nav-title-color font-normal text-[12px] leading-[16px]"
  >
    <span *transloco="let t; read: MainConstants.TRANSLOCO_READ + '.nav'">
      {{ t(navSection.title) }}
    </span>
  </p>
</div>

<div class="px-[4px]">
  <main-navigation-element
    *ngFor="let element of navSection.elements"
    [navElement]="element"
    [type]="type"
  />
</div>
