export type PaymentAction =
  | ChangeOrganisationNameAction
  | ChangeLegalAddressAction
  | ChangeINNAction
  | ChangeKPPAction
  | ChangePhoneNumberAction
  | ToggleSoloProprietorAction
  | InitOnChangesAction
  | OpenInvoicePaymentModalAction
  | TogglePaymentTypeAction
  | ChangeEmailAction
  | ChangeReceiptPriceAction

export enum PaymentActionTypes {
  CHANGE_ORGANISATION_NAME,
  CHANGE_LEGAL_ADDRESS,
  TOGGLE_I_SOLE_PROPRIETOR,
  CHANGE_INN,
  CHANGE_KPP,
  CHANGE_PHONE_NUMBER,
  CHANGE_EMAIL,
  INIT_ON_CHANGES,
  OPEN_INVOICE_PAYMENT_MODAL,
  TOGGLE_PAYMENT_TYPE,
  CHANGE_RECEIPT_PRICE,
}

export interface ChangeReceiptPriceAction {
  readonly type: PaymentActionTypes.CHANGE_RECEIPT_PRICE
  readonly value: number
}

export interface TogglePaymentTypeAction{
  readonly type: PaymentActionTypes.TOGGLE_PAYMENT_TYPE,
  readonly id: string
}

export interface OpenInvoicePaymentModalAction{
  readonly type: PaymentActionTypes.OPEN_INVOICE_PAYMENT_MODAL,
}

export interface InitOnChangesAction{
  readonly type: PaymentActionTypes.INIT_ON_CHANGES,
  readonly countUsers: number
  readonly periodType: 'month' | 'year'
}

export interface ChangeEmailAction{
  readonly type: PaymentActionTypes.CHANGE_EMAIL,
  readonly value: string
}

export interface ChangeOrganisationNameAction{
  readonly type: PaymentActionTypes.CHANGE_ORGANISATION_NAME,
  readonly value: string
}

export interface ChangeLegalAddressAction {
  readonly type: PaymentActionTypes.CHANGE_LEGAL_ADDRESS;
  readonly value: string
}

export interface ChangeINNAction {
  readonly type: PaymentActionTypes.CHANGE_INN;
  readonly value: string
}

export interface ChangeKPPAction {
  readonly type: PaymentActionTypes.CHANGE_KPP;
  readonly value: string
}

export interface ChangePhoneNumberAction {
  readonly type: PaymentActionTypes.CHANGE_PHONE_NUMBER;
  readonly value: string
}

export interface ToggleSoloProprietorAction {
  readonly type: PaymentActionTypes.TOGGLE_I_SOLE_PROPRIETOR;
}
