import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { UserCardComponent } from "../../user-card.component";
import { Offsets, PopupDirective, PopupOffsetXSide, PopupOffsetYSide } from "../../../../directives/popup.directive";

@Component({
  selector: 'app-user-card-open-container',
  standalone: true,
  imports: [CommonModule, UserCardComponent, PopupDirective],
  templateUrl: './user-card-open-container.component.html',
  styleUrls: ['./user-card-open-container.component.scss'],
  host: {
    class: 'relative w-max h-max'
  }
})
export class UserCardOpenContainerComponent {
  @Input({required: true}) userId!: number
  @Input() disabled: boolean = false

  @Input() parentOffset: Offsets = { x: 0, y: 0 };
  @Input() popupOffsetYSide: PopupOffsetYSide = 'bottom'
  @Input() popupOffsetXSide: PopupOffsetXSide = 'left'

  @Input() nameType: 'SurnameNamePatronymic' | 'SurnameName' | 'SurnameNP' | 'NameSurname' = 'SurnameNamePatronymic'
  @Output() messageClicked = new EventEmitter()
  @Output() linkClicked = new EventEmitter()
  @Output() editClicked = new EventEmitter()
  @Output() deleteClicked = new EventEmitter()

  isUserCardOpened: boolean = false
}
