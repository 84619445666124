import { SpaceSettingsNavItems } from "./space-settings-state"

export type SpaceSettingsResultAction =
  | InitResultAction
  | ChangeSpaceTitleResultAction
  | CancelTitleResultAction
  | ChangeNavItemResultAction
  | ChangeDeleteAlertModalVisibilityResultAction
  | ChangeAlertIsLoadingResultAction
  | ChangeIsPageLoadingResultAction

export enum SpaceSettingsResultActionTypes {
  INIT,
  CHANGE_SPACE_TITLE,
  CANCEL_TITLE,
  CHANGE_NAV_ITEM,
  CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
  CHANGE_ALERT_IS_LOADING,
  CHANGE_IS_PAGE_LOADING,
}

export interface ChangeIsPageLoadingResultAction {
  readonly type: SpaceSettingsResultActionTypes.CHANGE_IS_PAGE_LOADING,
  readonly value: boolean
}

export interface ChangeAlertIsLoadingResultAction {
  readonly type: SpaceSettingsResultActionTypes.CHANGE_ALERT_IS_LOADING,
  readonly value: boolean
}

export interface ChangeDeleteAlertModalVisibilityResultAction{
  readonly type: SpaceSettingsResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY
  readonly value: boolean
}

export interface ChangeNavItemResultAction {
  readonly type: SpaceSettingsResultActionTypes.CHANGE_NAV_ITEM
  readonly navItem: SpaceSettingsNavItems
}

export interface CancelTitleResultAction {
  readonly type: SpaceSettingsResultActionTypes.CANCEL_TITLE
}

export interface InitResultAction {
  readonly type: SpaceSettingsResultActionTypes.INIT
  readonly spaceId: string,
  readonly spaceTitle: string
}

export interface ChangeSpaceTitleResultAction {
  readonly type: SpaceSettingsResultActionTypes.CHANGE_SPACE_TITLE
  readonly newSpaceTitle: string
  readonly spaceTitleError: string | null
}
