import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClueComponent } from "./clue/clue.component";
import { ModalComponent } from "./modal/modal.component";
import { ModalContentComponent } from "./modal-content/modal-content.component";
import { ModalContentFooterComponent } from "./modal-content-footer/modal-content-footer.component";
import { LoadingShadingComponent } from "../loading-shading/loading-shading.component";
import { SvgXMark } from "../svg-components/svg.components";
import { A11yModule } from "@angular/cdk/a11y";
import { NavTabsComponent } from "../nav-tabs/nav-tabs.component";

@NgModule({
  declarations: [
    ClueComponent,
    ModalComponent,
    ModalContentComponent,
    ModalContentFooterComponent
  ],
  exports: [
    ModalContentFooterComponent,
    ModalContentComponent,
    ModalComponent,
    ClueComponent
  ],
  imports: [
    CommonModule,
    LoadingShadingComponent,
    SvgXMark,
    A11yModule,
    NavTabsComponent
  ]
})
export class ModalWindowModule { }
