import { AgilesDeskSettingsNavItems } from "./agiles-desk-settings-state";

export type AgilesDeskSettingsAction =
  | ChangeNavItemAction

export enum AgilesDeskSettingsActionTypes {
  CHANGE_NAV_ITEM,
}

export interface ChangeNavItemAction {
  readonly type: AgilesDeskSettingsActionTypes.CHANGE_NAV_ITEM
  readonly navItem: AgilesDeskSettingsNavItems
}
