import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentComponent } from "./presentation/view/document.component";
import { SvgArrow, SvgArrowBack } from "../../../core/components/svg-components/svg.components";
import { RouterOutlet } from "@angular/router";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { EditorChangesService } from "../../../core/components/yoopta-editor/data/editor-changes-service";
import { SessionService } from "../../../core/services/session-service/session-service";
import { StopwatchComponent } from "../../../core/components/stopwatch/stopwatch.component";
import { AlertModalComponent } from "../../../core/components/alert-modal/alert-modal.component";
import { ButtonComponent } from "../../../core/components/buttons/button/button.component";
import { PaymentAndTariffModule } from "../../settings/modules/payment-and-tariff/payment-and-tariff.module";
import { TranslocoDirective } from "@jsverse/transloco";
import { YooptaEditorModule } from "../../../core/components/yoopta-editor/yoopta-editor.module";

@NgModule({
  declarations: [
    DocumentComponent,
  ],
  imports: [
    CommonModule,
    SvgArrow,
    RouterOutlet,
    SvgArrowBack,
    MatProgressSpinnerModule,
    StopwatchComponent,
    AlertModalComponent,
    YooptaEditorModule,
    ButtonComponent,
    PaymentAndTariffModule,
    TranslocoDirective
  ],
  providers: [
    EditorChangesService,
    SessionService,
  ]
})
export class DocumentModule { }
