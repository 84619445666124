import { Component, Input } from "@angular/core";
import { PasswordRecoveryConstants } from "../../../common/password-recovery-constants";

@Component({
  selector: 'app-password-recovery-complete',
  templateUrl: './password-recovery-complete.component.html',
  styleUrls: ['./password-recovery-complete.component.scss']
})
export class PasswordRecoveryCompleteComponent {
  @Input({required: true}) email!: string
  protected readonly PasswordRecoveryConstants = PasswordRecoveryConstants;
}
