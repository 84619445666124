import { Injectable } from '@angular/core';
import { AgilesListItemType } from "../components/agiles-list-item/agiles-list-item.component";
import { parseToHighlightedParts } from "../../../../../../../core/utils/parse-to-highlighted-parts";

@Injectable()
export class AgilesListState {
  readonly isOpenCreateModal: boolean = false;
  readonly agileList: AgilesListItemType[] = [
    {
      id: '1',
      name: 'qwe',
      nameParts: parseToHighlightedParts('qwe', ''),
      tag: 'QWE',
      tagParts: parseToHighlightedParts('QWE', ''),
      admin: {
        id: 1,
        surname: 'qwe',
        name: 'qwe',
        email: 'qwe@qwe.com',
      }
    },
    {
      id: '2',
      name: 'qwe',
      nameParts: parseToHighlightedParts('qwe', ''),
      tag: 'QWE',
      tagParts: parseToHighlightedParts('QWE', ''),
      admin: {
        id: 1,
        surname: 'qwe',
        name: 'qwe',
        email: 'qwe@qwe.com',
      }
    },
    {
      id: '3',
      name: 'qwe',
      nameParts: parseToHighlightedParts('qwe', ''),
      tag: 'QWE',
      tagParts: parseToHighlightedParts('QWE', ''),
      admin: {
        id: 1,
        surname: 'qwe',
        name: 'qwe',
        email: 'qwe@qwe.com',
      }
    }
  ]
}
