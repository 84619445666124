import { Reducer } from 'src/app/core/mvi/store';
import { PaymentState } from "./payment-state";
import {
  PaymentResultAction,
  PaymentResultActionTypes,
} from './payment-result-action';
import { Injectable } from '@angular/core';
import { PaymentAndTariffResultActionTypes } from "../../../state/payment-and-tariff-result-action";

@Injectable()
export class PaymentReducer
  implements Reducer<PaymentState, PaymentResultAction>
{
  reduce(
    state: PaymentState,
    action: PaymentResultAction,
  ): PaymentState {
    switch (action.type) {
      case PaymentResultActionTypes.CHANGE_INN:
        return {...state, INN: action.value}
      case PaymentResultActionTypes.CHANGE_EMAIL:
        return {...state, email: action.value}
      case PaymentResultActionTypes.CHANGE_KPP:
        return {...state, KPP: action.value}
      case PaymentResultActionTypes.CHANGE_LEGAL_ADDRESS:
        return {...state, legalAddress: action.value}
      case PaymentResultActionTypes.CHANGE_ORGANISATION_NAME:
        return {...state, organisationName: action.value}
      case PaymentResultActionTypes.CHANGE_PHONE_NUMBER:
        return {...state, phoneNumber: action.value}
      case PaymentResultActionTypes.TOGGLE_I_SOLE_PROPRIETOR:
        return {...state, isISoloProprietor: action.value}
      case PaymentResultActionTypes.INIT_ON_CHANGES:
        return {...state, countUsers: action.countUsers, periodType: action.periodType}
      case PaymentResultActionTypes.OPEN_INVOICE_PAYMENT_MODAL:
        return {...state, isInvoicePaymentModalVisible: true}
      case PaymentResultActionTypes.VALIDATE_FIELDS:
        return {...state,
          organisationName: action.organisationName,
          legalAddress: action.legalAddress,
          phoneNumber: action.phoneNumber,
          KPP: action.KPP,
          INN: action.INN,
        }
      case PaymentResultActionTypes.TOGGLE_PAYMENT_TYPE:
        return {...state, selectedPaymentType: action.value}
      case PaymentResultActionTypes.CHANGE_RECEIPT_PRICE:
        return {...state, receiptPrice: action.value}
      case PaymentResultActionTypes.LOCALISE:
        return {...state, togglerItems: action.togglerItems}
    }
  }
}
