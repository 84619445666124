import { map, Observable, of } from "rxjs";
import { ShortEmployee } from "src/app/core/components/select-user-modal/select-user-modal.component";
import {
  SelectUserModalService
} from "../../../../../../../../../core/services/select-user-modal-service/select-user-modal.service";
import { environment } from "../../../../../../../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { EmployeeAllDto } from "../../../../../../../../employees/data/dto/employee-all-dto";
import { parseToHighlightedParts } from "../../../../../../../../../core/utils/parse-to-highlighted-parts";
import { Injectable } from "@angular/core";

@Injectable()
export class SelectUsersModalServiceImpl implements SelectUserModalService {
  private readonly apiUrl: string = `${environment.apiUrl}/employee`;

  constructor(private httpClient: HttpClient) {}

  getUsers(): Observable<ShortEmployee[]> {
    return this.httpClient.get<EmployeeAllDto>(`${this.apiUrl}/all`).pipe(
      map((employeesDto) => {
        const shortEmployee: ShortEmployee[] = employeesDto.employees.map(employee => {
          return {
            id: employee.id,
            surname: employee.surname,
            name: employee.name,
            patronymic: employee.patronymic,
            email: employee.email,
            isSelected: false,
            nameParts: parseToHighlightedParts(employee.surname + ' ' + employee.name + ' ' + employee.patronymic, ''),
            emailParts: parseToHighlightedParts(employee.email, ''),
          }
        });
        return shortEmployee
      }),
    )
  }

}
