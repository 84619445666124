import { Routes } from "@angular/router";
import { EmployeesComponent } from "../features/employees/presentation/employees.component";
import { EMPLOYEES_ROUTES } from "./employees-routes";
import { SettingsComponent } from "../features/settings/presentation/settings.component";
import { KnowledgeBaseComponent } from "../features/knowledge-base/presentation/knowledge-base.component";
import { KNOWLEDGE_BASE_ROUTES } from "./knowledge-base-routes";
import { SETTINGS_ROUTES } from "./settings-routes";
import { NewsComponent } from "../features/news/presentation/news.component";
import { NEWS_ROUTES } from "./news-routes";
import { RoleComponent } from "../features/employees/modules/role/presentation/role.component";
import { AGILE_DESK_ROUTES } from "./agile-desk-routes";
import { AgilesDeskComponent } from "../features/agiles/agile-desk/presentation/agiles-desk.component";
import { AGILES_ROUTES } from "./agiles-routes";
import { AgilesMainComponent } from "../features/agiles/agiles-main/presentation/agiles-main.component";

export enum MainRoutesPaths {
  EMPLOYEES = 'employees',
  ROLE = 'employees/role',
  SETTINGS = 'settings',
  KNOWLEDGE_BASE = 'knowledge-base',
  NEWS = 'news',
  AGILES = 'agiles',
  AGILE_DESK = 'agiles/:id',
}

export const MAIN_ROUTES: Routes =
[
  {
    path: MainRoutesPaths.ROLE + '/:id',
    component: RoleComponent,
  },
  {
    path: MainRoutesPaths.EMPLOYEES,
    component: EmployeesComponent,
    children: EMPLOYEES_ROUTES,
  },
  {
    path: MainRoutesPaths.SETTINGS,
    component: SettingsComponent,
    children: SETTINGS_ROUTES
  },
  {
    path: MainRoutesPaths.KNOWLEDGE_BASE,
    component: KnowledgeBaseComponent,
    children: KNOWLEDGE_BASE_ROUTES
  },
  {
    path: MainRoutesPaths.NEWS,
    component: NewsComponent,
    children: NEWS_ROUTES
  },
  {
    path: MainRoutesPaths.AGILE_DESK,
    component: AgilesDeskComponent,
    children: AGILE_DESK_ROUTES
  },
  {
    path: MainRoutesPaths.AGILES,
    component: AgilesMainComponent,
    children: AGILES_ROUTES
  }
];
