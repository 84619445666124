import { Injectable } from "@angular/core";
import {
  CreateRoleModalConstants,
  CreateRoleModalClueTexts,
  CreateRoleModalClueTitles,
  CreateRoleModalNavItems
} from "../common/create-role-modal-constants";
import { PermissionsDto } from "src/app/core/services/role-service/dto/create-role-dto";

@Injectable()
export class CreateRoleModalState {
  readonly loadedRoleId: string = ''
  readonly isLoading: boolean = false
  readonly isCreating: boolean = false

  readonly roleName: string = ''
  readonly roleNameError: string | null = null
  readonly roleDescription: string = ''
  readonly roleDescriptionError: string | null = null
  readonly isValid: boolean = false

  readonly permissions: PermissionsDto = {
    knowledgeBase: {
      viewKnowledgeBase: true,
      createKnowledgeBase: false,
      editKnowledgeBase: false,
      deleteKnowledgeBase: false
    },
    company: {
      viewEmployee: true,
      createEmployee: false,
      editEmployee: false,
      deleteEmployee: false
    },
    settings: {
      billing: false,
      main: false,
    },
    role: {
      viewRole: true,
      createRole: false,
      editRole: false,
      deleteRole: false
    }
  }

  readonly stepViewState: StepViewState = new StepViewState()
}

export class StepViewState{
  readonly stepNumber: number = 1
  readonly selectedNavItem: CreateRoleModalNavItems = CreateRoleModalNavItems.MAIN
  readonly clueText: CreateRoleModalClueTexts = CreateRoleModalClueTexts.MAIN
  readonly clueTitle: CreateRoleModalClueTitles = CreateRoleModalClueTitles.MAIN
  readonly isShowErrorMain: boolean = false
  readonly isShowErrorContacts: boolean = false
}
