import { Component, Input } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";
import { SvgInfoCircle, SvgWarning } from "./icons/informer-icons";

@Component({
  selector: "app-core-informer-text",
  templateUrl: "./informer-text.component.html",
  standalone: true,
  imports: [
    SvgWarning,
    SvgInfoCircle,
    NgClass,
    NgIf
  ],
  styleUrls: ["./informer-text.component.scss"]
})
export class InformerTextComponent {
  @Input() type: InformerTextType = 'info'
  @Input() informerText?: string;
}

export type InformerTextType = 'warning' | 'info'
