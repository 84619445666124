import { Injectable } from '@angular/core';

@Injectable()
export class AuthorizationState {
  readonly email: string = '';
  readonly emailError: string = '';
  readonly password: string = '';
  readonly passwordError: string = '';
  readonly isLoading: boolean = false;
  readonly loginError: LoginErrorState | null = null;
  readonly rememberMe: boolean = true;
}

export enum LoginErrorState {
  INCORRECT_CREDENTIALS,
  CONNECTION_ERROR,
}
