import { DropdownItem } from "../../../../../../../../core/components/fields/components/dropdown-field/dropdown-field.component";
import { PurchasePeriod, TariffDto } from "../../../../../../data/dto/all-tariffs-dto";
import { AdditionalTariff } from "../../../../../../data/dto/get-update-invoice-data-dto";

export type SubscriptionFormResultAction =
  | InitResultAction
  | ChangeAdditionalMemoryResultAction
  | ChangeUsersCountResultAction
  | ChangeTariffResultAction
  | ChangeDurationResultAction
  | ChangeIsLoadingResultAction
  | ChangeReceiptButtonIsLoadingResultAction
  | ChangeLangResultAction

export enum SubscriptionFormResultActionTypes {
  INIT,
  CHANGE_TARIFF,
  CHANGE_DURATION,
  CHANGE_USERS_COUNT,
  CHANGE_ADDITIONAL_MEMORY,
  CHANGE_IS_LOADING,
  CHANGE_RECEIPT_BUTTON_IS_LOADING,
  CHANGE_LANG,
}

export interface ChangeLangResultAction{
  readonly type: SubscriptionFormResultActionTypes.CHANGE_LANG
  readonly durationDropDownItems: DropdownItem[]
  readonly currentDuration: DropdownItem | null
}

export interface ChangeIsLoadingResultAction {
  readonly type: SubscriptionFormResultActionTypes.CHANGE_IS_LOADING
  readonly value: boolean
}

export interface ChangeReceiptButtonIsLoadingResultAction {
  readonly type: SubscriptionFormResultActionTypes.CHANGE_RECEIPT_BUTTON_IS_LOADING
  readonly value: boolean
}

export interface ChangeAdditionalMemoryResultAction {
  readonly type: SubscriptionFormResultActionTypes.CHANGE_ADDITIONAL_MEMORY
  readonly value: number
}

export interface ChangeUsersCountResultAction {
  readonly type: SubscriptionFormResultActionTypes.CHANGE_USERS_COUNT
  readonly value: number
}

export interface ChangeTariffResultAction {
  readonly type: SubscriptionFormResultActionTypes.CHANGE_TARIFF
  readonly tariff: DropdownItem
}

export interface ChangeDurationResultAction {
  readonly type: SubscriptionFormResultActionTypes.CHANGE_DURATION
  readonly duration: DropdownItem
}

export interface InitResultAction {
  readonly type: SubscriptionFormResultActionTypes.INIT
  readonly durationDropdownItems: DropdownItem[]
  readonly currentDuration: DropdownItem | null
  readonly durations: PurchasePeriod[]

  readonly tariffDropdownItems: DropdownItem[]
  readonly currentTariff: DropdownItem | null
  readonly allTariffs: TariffDto[]

  readonly additionalTariff: AdditionalTariff | null
}
