import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  AgilesDeskSettingsAccessResultAction,
  AgilesDeskSettingsAccessResultActionTypes
} from "./agiles-desk-settings-access-result-action";
import { AgilesDeskSettingsAccessState } from './agiles-desk-settings-access-state';

@Injectable()
export class AgilesDeskSettingsAccessReducer
  implements Reducer<AgilesDeskSettingsAccessState, AgilesDeskSettingsAccessResultAction>
{
  reduce(state: AgilesDeskSettingsAccessState, action: AgilesDeskSettingsAccessResultAction): AgilesDeskSettingsAccessState {
    switch (action.type) {
      case AgilesDeskSettingsAccessResultActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY:
        return {...state, isOpenSelectUsersModal: action.value}
      case AgilesDeskSettingsAccessResultActionTypes.CHANGE_USERS:
        return {...state, users: action.users, isOpenSelectUsersModal: false}
      case AgilesDeskSettingsAccessResultActionTypes.FILTER_USERS:
        return {...state, filteredUsers: action.filteredUsers, filterString:action.filterString }
      case AgilesDeskSettingsAccessResultActionTypes.DELETE_USER:
        return {...state, userToDelete: action.user, isOpenDeleteUserModal: true}
      case AgilesDeskSettingsAccessResultActionTypes.CHANGE_DELETE_USER_MODAL_VISIBILITY:
        return {...state, isOpenDeleteUserModal: action.value}
    }
  }
}
