export type OrdersAction =
  | ChangeCancelModalVisibleAction
  | PayOrderAction
  | CancelOrderAction

export enum OrdersActionTypes {
  CHANGE_CANCEL_MODAL_VISIBLE,
  PAY_ORDER,
  CANCEL_ORDER,
}

export interface ChangeCancelModalVisibleAction {
  readonly type: OrdersActionTypes.CHANGE_CANCEL_MODAL_VISIBLE
  readonly value: boolean
}

export interface PayOrderAction {
  readonly type: OrdersActionTypes.PAY_ORDER
}

export interface CancelOrderAction {
  readonly type: OrdersActionTypes.CANCEL_ORDER
}
