import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class AgilesScrollService {
  private agilesPageScrollableElement: BehaviorSubject<HTMLElement | null> = new BehaviorSubject<HTMLElement | null>(null);

  agilesPageScrollableElementObservable: Observable<HTMLElement | null> = this.agilesPageScrollableElement.asObservable();

  setScrollableElement(element: HTMLElement): void {
    this.agilesPageScrollableElement.next(element)
  }

  getScrollableElement(): Observable<HTMLElement | null> {
    return this.agilesPageScrollableElementObservable;
  }

  scrollElement(scrollDelta: number): void {
    const element = this.agilesPageScrollableElement.value
    if(element)
    {
      element.scrollTop += scrollDelta;
    }
  }
}
