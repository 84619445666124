import { ImageUploadResponse } from "@yoopta/image";
import { Editor2ServiceImpl } from "../../../../data/editor-service-impl";

export const onImageUpload = (file: File, editorService: Editor2ServiceImpl): Promise<ImageUploadResponse> =>{
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target && event.target.result) {
        const longReadId = sessionStorage.getItem('longreadId');
        let formData = new FormData();
        formData.append('file', file, file.name);
        if (longReadId) {
          editorService.uploadFile(longReadId, formData)
            .subscribe({
              next: (fileDto) => {
                const result: ImageUploadResponse = {
                  src: fileDto.id,
                  alt: fileDto.name,
                }

                resolve(result)
              },
              error: err => {
                reject(err)
              }
            })
        }
      }
    }
    reader.readAsDataURL(file)
  })
}
