<div
  class="max-w-full w-full overflow-x-auto tiny-scrollbar bg-[#E5ECF9]"
  [ngClass]="{
    'mb-[74px]': selectedTaskEntities.length > 0
  }"
  (scroll)="scrollTable($event)"
  appMousePressedScroll
  [screenTopScrollOffset]="48"
  [isScrolled]="draggingTask != null"
  (onScrollY)="scrollY($event)"
>
  <table
    class="table-fixed w-full"
    #tableEl
    cdkDropListGroup
    appClickOutside
    (clickOutside)="unSelect()"
    (click)="unSelect()"
  >
    <colgroup>
      <col
        *ngFor="let col of table.columns"
        [class]="'min-w-[190px] max-w-[calc(100%_/_' + table.columns.length + ')]'"
      >
    </colgroup>

    <div class="h-[32px] z-10 bg-[#E5ECF9]"
      #tableHeadBox
    >
      <div
        class="h-[32px] w-full border-b border-tt-border-default flex items-center"
        #tableHead
      >
        <div
          *ngFor="let col of table.columns; let i = index"
          [class]="'min-w-[190px] w-full w-[calc(100%_/_' + table.columns.length + ')]'"
        >
          <div class="flex w-full items-center">
            <div *ngIf="i != 0" class="h-[32px] w-[1px] bg-tt-border-default"></div>
            <div class="flex items-center pl-[8px]">
              <span class="line-clamp-1 text-tt-font-size_l font-normal text-tt-text-default">{{col.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="topScroll >= 90" class="pt-[32px]"></div>

    <tbody
      *ngFor="let row of table.rows"
      app-task-table-row
      [row]="row"
      [selectedTaskEntities]="selectedTaskEntities"
      [draggingTask]="draggingTask"
      [showUnassigned]="table.showUnassigned"
      [isDragCancelled]="isDragCanceled"
      (onAdd)="pushNew($event)"
      (onDragStart)="dragStart($event)"
      (onDrop)="drop($event)"
      (onSelectTask)="addSelectedTask($event)"

      class="border-b border-tt-border-default relative min-h-[100px]"
      [ngClass]="{
        'not-assigned-row-bg': row.executor === null,
      }"
    >
    </tbody>
  </table>
</div>

<app-tasks-toolbar
  class="fixed bottom-0 z-[100]"
  [selectedCount]="selectedTaskEntities.length"
  (onUnselect)="unSelect()"
  (onDelete)="deleteSelected()"
  #tasksToolbar
/>
