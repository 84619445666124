import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  TariffFormResultAction,
  TariffFormResultActionTypes
} from "./tariff-form-result-action";
import { TariffFormState } from './tariff-form-state';

@Injectable()
export class TariffFormReducer
  implements Reducer<TariffFormState, TariffFormResultAction>
{
  reduce(state: TariffFormState, action: TariffFormResultAction): TariffFormState {
    switch (action.type) {
      case TariffFormResultActionTypes.CHANGE_DURATION:
        return {...state, currentDuration: action.duration}
      case TariffFormResultActionTypes.CHANGE_USERS_COUNT:
        return {...state, usersCount: action.value}
      case TariffFormResultActionTypes.CHANGE_ADDITIONAL_MEMORY:
        return {...state, memoryCount: action.value}
      case TariffFormResultActionTypes.CHANGE_IS_DURATION_OPEN:
        return {...state, isDurationOpen: action.value}
      case TariffFormResultActionTypes.CHANGE_IS_EXTENSIONS_OPEN:
        return {...state, isExpansionOpen: action.value}
      case TariffFormResultActionTypes.INIT:
        return {...state,
          subscriptionInfo: action.subscriptionInfo,
          currentTariff: action.currentTariff,
          purchasePeriods: action.purchasePeriods,
          additionalTariff: action.additionalTariff,
          durationDropDownItems: action.durationDropDownItems,
          currentDuration: action.currentDuration,
        }
      case TariffFormResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value}
      case TariffFormResultActionTypes.CHANGE_LANG:
        return {...state,
          durationDropDownItems: action.durationDropDownItems,
          currentDuration: action.currentDuration,
        }
    }
  }
}
