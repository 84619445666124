<button
  class="h-[40px] pl-[24px] pr-[16px]
  flex items-center bg-tt-field-background-color
  rounded-[20px] border-[1px] border-tt-border-default"
  (click)="isOpen = !isOpen"
>
  <p class="text-[14px] leading-[18px] text-tt-text-main">{{name}}</p>
  <svg-header-arrow
    [ngClass]="{
      'rotate-180': isOpen,
    }"
  />
</button>
