<ng-container
  *transloco="let t; read: SettingsMainConstants.TRANSLOCO_READ"
>
  <div class="w-full flex flex-col items-center py-[24px]">
    <app-core-settings-container>
      <app-core-settings-nav-container
        [title]="t('nav-title')"
      >
        <app-core-settings-nav-item
          [isSelected]="state.currentNavItem == SettingsMainNavItems.MAIN"
          (onClick)="performAction({
          type: SettingsMainActionTypes.CHANGE_NAV_ITEM,
          navItem: SettingsMainNavItems.MAIN
        })"
        >
          <svg-settings-home/>
          <p>{{ t('main') }}</p>
        </app-core-settings-nav-item>

      </app-core-settings-nav-container>

      <div
        class="w-full flex flex-col justify-between"
        *ngIf="state.currentNavItem == SettingsMainNavItems.MAIN"
      >
        <div>
          <app-core-settings-content-element
            [title]="t('main')"
          >
            <p>{{ t('main-description') }}</p>
          </app-core-settings-content-element>

          <div class="h-[1px] w-full bg-tt-border-default"></div>

          <app-core-settings-content-element>
            <app-core-input-field
              [label]="t('company-name-label')"
              inputVariant="standard"
              placeholder=""
              [charsCounterMax]="SettingsConstants.COMPANY_NAME_MAX_LENGTH"
              error="{{state.organisationTitleError}}"
              [defaultValue]="state.organisationTitle"
              [value]="state.newOrganisationTitle"
              (onChange)="performAction({
                type: SettingsMainActionTypes.CHANGE_ORGANISATION_NAME,
                value: $event
              })"
            />
          </app-core-settings-content-element>

        </div>
        <app-core-settings-content-element>
          <div
            class="flex w-full justify-end gap-[16px]"
            *ngIf="state.organisationTitle != state.newOrganisationTitle"
          >
            <app-core-button
              variant="soft"
              (onClick)="performAction({
              type: SettingsMainActionTypes.CANCEL_TITLE
            })"
            >
              {{ t('cancel') }}
            </app-core-button>
            <app-core-button
              (onClick)="performAction({
              type: SettingsMainActionTypes.SAVE_TITLE
            })"
            >
              {{ t('save') }}
            </app-core-button>
          </div>
        </app-core-settings-content-element>

      </div>
    </app-core-settings-container>

    <ng-container
      *ngIf="state.currentNavItem == SettingsMainNavItems.MAIN"
    >
      <div
        class="mt-[24px] max-w-[935px] w-full h-[1px] bg-tt-border-header-popup-without-alpha"
      ></div>

      <app-core-settings-button
        class="mt-[24px] flex justify-center"
        (onClick)="performAction({
          type: SettingsMainActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
          value: true
        })"
      >
        <svg-trash class="min-h-[24px] min-w-[24px] max-h-[24px] max-w-[24px]"/>
        <p class="text-[14px]">{{ t('delete-company') }}</p>
      </app-core-settings-button>
    </ng-container>
  </div>

  <app-core-alert-modal
    *ngIf="state.isDeleteAlertVisible"
    [title]="t('delete-company')"
    type="warning"
    [description]="t('delete-company-inform')"
    [cancelButtonText]="t('cancel')"
    [confirmButtonText]="t('Удалить')"
    (onConfirmClicked)="performAction({
    type: SettingsMainActionTypes.DELETE
  })"
    (onCancelClicked)="performAction({
    type: SettingsMainActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
    value: false
  })"
  >
    <p>{{ t('delete-company-description') }}</p>

  </app-core-alert-modal>

  <div
    class="fixed left-0 top-0 w-screen h-screen z-50"
    *ngIf="state.isPageLoading"
  >
    <app-core-loading-shading/>
  </div>

</ng-container>
