<div
  class="flex flex-col justify-between w-full h-full"
  *transloco="let t; read: RoleConstants.TRANSLOCO_READ"
>
  <div>
    <app-core-settings-content-element
      class="text-tt-text-secondary text-[14px]"
      [title]="t('main')"
    >
      <p>{{ t('main-description') }}</p>
    </app-core-settings-content-element>

    <div class="h-[1px] w-full bg-tt-border-default"></div>

    <app-core-settings-content-element>
      <div class="flex flex-col gap-[24px]">
        <app-core-input-field
          [label]="t('name-label')"
          inputVariant="standard"
          placeholder=""
          error="{{nameError}}"
          [readonly]="isReadOnly"
          [charsCounterMax]="128"
          [defaultValue]="data.name"
          [value]="data.name"
          (onChange)="nameChange($event)"
        />

        <app-core-textarea-field
          [label]="t('description-label')"
          variant="standard"
          placeholder=""
          error="{{descriptionError}}"
          [readonly]="isReadOnly"
          [charsCounterMax]="180"
          [defaultValue]="data.description"
          [value]="data.description"
          (onChange)="descriptionChange($event)"
        />
      </div>

    </app-core-settings-content-element>
  </div>

  <app-core-settings-content-element
    *ngIf="data.name != initData.name || data.description != initData.description"
  >
    <div class="flex w-full justify-end gap-[16px]">
      <app-core-button
        variant="soft"
        (onClick)="decline()"
      >
        {{ t('cancel') }}
      </app-core-button>
      <app-core-button
        (onClick)="save()"
      >
        {{ t('save') }}
      </app-core-button>
    </div>

  </app-core-settings-content-element>
</div>
