import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  selector: "app-core-loading-shading",
  templateUrl: "./loading-shading.component.html",
  standalone: true,
  imports: [
    MatProgressSpinnerModule
  ],
  host: {
    class: "absolute left-0 top-0 bg-tt-informer-loading-background w-full h-full z-50 flex justify-center items-center"
  }
})
export class LoadingShadingComponent {

}
