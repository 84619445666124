import { Injectable } from "@angular/core";
import {
  TasksCell,
  TasksStatusEntity,
  TasksTableEntity
} from "../modules/agiles-desk-main/presentation/components/tasks-table/tasks-table.component";
import { TaskExecutor } from "../modules/agiles-desk-main/presentation/components/task-item/task-item.component";
import { clone } from "cloneable-ts";

@Injectable({providedIn: "root"})
export class AgilesService{
  private desk: TasksTableEntity =  {
    showUnassigned: true,
    columns: [
      {
        id: '1',
        name: 'Not started',
        isCompleted: false,
        isDeletable: false,
        isOpen: true,
      },
      {
        id: '2',
        name: 'In progress',
        isCompleted: false,
        isDeletable: true,
        isOpen: true,
      },
      {
        id: '4',
        name: 'In progress',
        isCompleted: false,
        isDeletable: true,
        isOpen: true,
      },
      {
        id: '5',
        name: 'In progress',
        isCompleted: false,
        isDeletable: true,
        isOpen: true,
      },
      {
        id: '6',
        name: 'In progress',
        isCompleted: false,
        isDeletable: true,
        isOpen: true,
      },
      {
        id: '3',
        name: 'Completed',
        isDeletable: false,
        isCompleted: true,
        isOpen: true,
      },
    ],
    rows: [
      {
        isOpen: true,
        executor: {
          id: 1,
          name: 'Дмитрий',
          surname: 'Воронин',
          email: 'vorondim@teamtells.com',
        },
        countTasks: 100500,
        tasksCells: [
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: true }
        ]
      },
      {
        isOpen: true,
        executor: {
          id: 2,
          name: 'Алексей',
          surname: 'Язаджи',
          email: 'yazalex@teamtells.com',
        },
        countTasks: 100500,
        tasksCells: [
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: true }
        ]
      },
      {
        isOpen: true,
        countTasks: 100500,
        executor: null,
        tasksCells: [
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: false },
          { isDragOver: false, tasks:[], isCompleted: true }
        ]
      },
    ],

  }

  getTable(){
    return this.desk
  }

  setTable(desk: TasksTableEntity) {
    this.desk = desk;
  }

  getStatuses(): TasksStatusEntity[]{
    return this.desk.columns.slice()
  }

  setStatuses(statuses: TasksStatusEntity[]) {
    this.desk.columns = statuses.slice();
    this.desk.rows.forEach(row=>{
      const diff = statuses.length - row.tasksCells.length
      for (let i = 0; i < Math.abs(diff); i++) {
        if(diff < 0){
          row.tasksCells.pop()
        }else if(diff > 0){
          row.tasksCells.push({
            isDragOver: false,
            isCompleted: statuses[i].isCompleted,
            tasks: []
          })
        }
      }
    })
  }

  getExecutors(): TaskExecutor[]{
    return this.desk.rows.map(row=>{return row.executor!})
      .filter(executor => {
        return executor != null
      })
  }

  setExecutors(executors: TaskExecutor[]) {
    const taskCells: TasksCell[] = []
    this.desk.columns.forEach(col=>{
      taskCells.push({
        isDragOver: false,
        isCompleted: col.isCompleted,
        tasks: []
      })
    })
    this.desk.rows = executors.map(executor=>{
      const findRow = this.desk.rows.find(row=> row.executor && row.executor.id === executor.id)

      return findRow ? findRow: {
        isOpen: true,
        executor: executor,
        countTasks: 0,
        tasksCells: clone(taskCells),
      }
    })
  }

  changeShowUnassigned(value: boolean){
    this.desk.showUnassigned = value
  }

  getShowUnassigned(): boolean{
    return this.desk.showUnassigned
  }
}
