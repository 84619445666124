import { Component } from "@angular/core";

@Component({
  standalone: true,
  selector: 'svg-success',
  templateUrl: './svg/success.svg',
})
export class SvgSuccess {}

@Component({
  standalone: true,
  selector: 'svg-error',
  templateUrl: './svg/error.svg',
})
export class SvgError {}
