import { ShortEmployee } from "../../../../../../../../../core/components/select-user-modal/select-user-modal.component";
import { AgilesDeskSettingsAccessUser } from "./agiles-desk-settings-access-state";

export type AgilesDeskSettingsAccessAction =
  | ChangeUsersModalVisibilityAction
  | AddUsersVisibilityAction
  | ChangeUserRoleAction
  | DeleteUserRoleAction
  | ChangeSearchFieldAction
  | CloseDeleteModalAction
  | DeleteConfirmAction

export enum AgilesDeskSettingsAccessActionTypes {
  CHANGE_ADD_USERS_MODAL_VISIBILITY,
  CHANGE_SEARCH_FIELD,
  ADD_USERS,
  CHANGE_USER_ROLE,
  DELETE_USER,
  DELETE_CONFIRM,
  CLOSE_DELETE_MODAL
}

export interface AddUsersVisibilityAction {
  readonly type: AgilesDeskSettingsAccessActionTypes.ADD_USERS
  readonly users: ShortEmployee[]
}

export interface CloseDeleteModalAction {
  readonly type: AgilesDeskSettingsAccessActionTypes.CLOSE_DELETE_MODAL
}

export interface DeleteConfirmAction {
  readonly type: AgilesDeskSettingsAccessActionTypes.DELETE_CONFIRM
}

export interface ChangeSearchFieldAction {
  readonly type: AgilesDeskSettingsAccessActionTypes.CHANGE_SEARCH_FIELD
  readonly value: string
}

export interface ChangeUserRoleAction {
  readonly type: AgilesDeskSettingsAccessActionTypes.CHANGE_USER_ROLE
  readonly user: AgilesDeskSettingsAccessUser
  readonly roleId: string
}

export interface DeleteUserRoleAction {
  readonly type: AgilesDeskSettingsAccessActionTypes.DELETE_USER
  readonly user: AgilesDeskSettingsAccessUser
}

export interface ChangeUsersModalVisibilityAction {
  readonly type: AgilesDeskSettingsAccessActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY
  readonly value: boolean
}
