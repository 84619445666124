<ng-container
  *transloco="let t; read: SpaceConstants.SETTINGS_TRANSLOCO_READ"
>
  <app-core-settings-container
  >
    <app-core-settings-nav-container
      [title]="t('nav-title')"
    >
      <app-core-settings-nav-item
        [isSelected]="state.currentNavItem == SpaceSettingsNavItems.MAIN"
        (onClick)="performAction({
        type: SpaceSettingsActionTypes.CHANGE_NAV_ITEM,
        navItem: SpaceSettingsNavItems.MAIN
      })"
      >
        <svg-settings-home/>
        <p>{{ t('main') }}</p>

      </app-core-settings-nav-item>

    </app-core-settings-nav-container>

    <div class="flex flex-col justify-between w-full"
      *ngIf="state.currentNavItem == SpaceSettingsNavItems.MAIN"
    >
      <div>
        <app-core-settings-content-element
          [title]="t('main')"
        >
          <p>{{ t('main-description') }}</p>
        </app-core-settings-content-element>

        <div class="h-[1px] w-full bg-tt-border-default"></div>

        <app-core-settings-content-element>
          <app-core-input-field
            [label]="t('space-name-label')"
            inputVariant="standard"
            placeholder=""
            error="{{state.spaceTitleError}}"
            [charsCounterMax]="100"
            [defaultValue]="state.spaceTitle"
            [value]="state.newSpaceTitle"
            (onChange)="performAction({
            type: SpaceSettingsActionTypes.CHANGE_SPACE_TITLE,
            value: $event
          })"
          />
        </app-core-settings-content-element>
      </div>

      <app-core-settings-content-element
        *ngIf="state.spaceTitle != state.newSpaceTitle"
      >
        <div
          class="flex w-full justify-end gap-[16px]"
        >
          <app-core-button
            variant="soft"
            (onClick)="performAction({
          type: SpaceSettingsActionTypes.CANCEL_TITLE
        })"
          >
            {{ t('cancel') }}
          </app-core-button>
          <app-core-button
            (onClick)="performAction({
          type: SpaceSettingsActionTypes.SAVE_TITLE
        })"
          >
            {{ t('save') }}
          </app-core-button>
        </div>

      </app-core-settings-content-element>
    </div>

  </app-core-settings-container>

  <ng-container
    *ngIf="state.currentNavItem == SpaceSettingsNavItems.MAIN"
  >
    <div class="mt-[48px] max-w-[935px] w-full h-[1px] bg-tt-border-header-popup-without-alpha"></div>

    <app-core-settings-button
      class="mt-[24px] flex justify-center"
      (onClick)="performAction({
        type: SpaceSettingsActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
        value: true
      })"
    >
      <svg-trash class="min-h-[24px] min-w-[24px] max-h-[24px] max-w-[24px]"/>
      <p class="text-[14px]">{{ t('delete-space') }}</p>
    </app-core-settings-button>
  </ng-container>


  <app-core-alert-modal
    *ngIf="state.isDeleteAlertVisible"
    [title]="t('delete-space')"
    type="warning"
    [description]="t('delete-space-description')"
    [cancelButtonText]="t('cancel')"
    [confirmButtonText]="t('delete')"
    [isLoading]="state.alertIsLoading"
    (onConfirmClicked)="performAction({
    type: SpaceSettingsActionTypes.DELETE
  })"
    (onCancelClicked)="performAction({
    type: SpaceSettingsActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
    value: false
  })"
  >
    <p
      [textParse]="t('delete-space-text', { name: state.spaceTitle })"
      [parseElements]="[{
        text: state.spaceTitle,
        className: 'font-medium',
        type: 'text'
      }]"
    ></p>

  </app-core-alert-modal>

</ng-container>


<div
  class="fixed left-0 top-0 w-screen h-screen z-50"
  *ngIf="state.isPageLoading"
>
  <app-core-loading-shading/>
</div>
