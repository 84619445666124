import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersComponent } from "./presentation/orders.component";
import { OrderComponent } from './presentation/components/order/order.component';
import { SvgArrow } from "../../../../core/components/svg-components/svg.components";
import { PaymentAndTariffModule } from "../payment-and-tariff/payment-and-tariff.module";
import { SubscriptionService } from "../../data/subscription.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AlertModalComponent } from "../../../../core/components/alert-modal/alert-modal.component";
import { InformerTextComponent } from "../../../../core/components/informer-text/informer-text.component";
import { ImageStubComponent } from "../../../../core/components/image-stub/image-stub.component";
import { ButtonComponent } from "../../../../core/components/buttons/button/button.component";
import { provideTranslocoScope, TranslocoDirective } from "@jsverse/transloco";
import { OrdersConstants } from "./common/orders-constants";

@NgModule({
  declarations: [
    OrdersComponent,
    OrderComponent
  ],
  imports: [
    CommonModule,
    SvgArrow,
    PaymentAndTariffModule,
    MatProgressSpinnerModule,
    AlertModalComponent,
    InformerTextComponent,
    ImageStubComponent,
    ButtonComponent,
    TranslocoDirective
  ],
  providers: [
    SubscriptionService
  ]
})
export class OrdersModule { }
