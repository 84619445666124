<app-core-user-summery
  className="px-[24px]"
  actionType="delete"
  [user]="user.user"
  [isDeletable]="user.role !== AgilesDeskSettingsAccessUserRoles.OWNER"
  (onDelete)="onDelete.emit()"
  *transloco="let t; read: AgileDeskSettingsConstants.TRANSLOCO_READ"
>
  <div>
    <app-core-button-text
      [type]="user.role === AgilesDeskSettingsAccessUserRoles.PROHIBITED ? 'danger' : 'base'"
      [isDisabled]="user.role === AgilesDeskSettingsAccessUserRoles.OWNER"
      (onClick)="isActionMenuOpen = !isActionMenuOpen"
    >
      <span class="text-tt-text-secondary" *ngIf="user.role === AgilesDeskSettingsAccessUserRoles.OWNER">{{ t(AgilesDeskSettingsAccessUserRoles.OWNER) }}</span>
      <span *ngIf="user.role === AgilesDeskSettingsAccessUserRoles.ADMIN">{{ t(AgilesDeskSettingsAccessUserRoles.ADMIN) }}</span>
      <span *ngIf="user.role === AgilesDeskSettingsAccessUserRoles.PARTICIPANT">{{ t(AgilesDeskSettingsAccessUserRoles.PARTICIPANT) }}</span>
      <span *ngIf="user.role === AgilesDeskSettingsAccessUserRoles.PROHIBITED">{{ t(AgilesDeskSettingsAccessUserRoles.PROHIBITED) }}</span>
    </app-core-button-text>

    <app-core-action-menu
      *ngIf="isActionMenuOpen"
      appPopup
      [sections]="actionMenuSections"
      [translocoRead]="AgileDeskSettingsConstants.TRANSLOCO_READ"
      (elementClicked)="onActionMenuElementClicked($event)"
      (popupClose)="isActionMenuOpen = false"
    />
  </div>

</app-core-user-summery>
