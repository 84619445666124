import { ShortEmployee } from "../../../../../../../../core/components/select-user-modal/select-user-modal.component";

export type RoleUsersAction =
  | ChangeUsersModalVisibilityAction
  | AddUsersVisibilityAction
  | DeleteUserRoleAction
  | ChangeSearchFieldAction
  | CloseDeleteModalAction
  | DeleteConfirmAction
  | InitAction

export enum RoleUsersActionTypes {
  CHANGE_ADD_USERS_MODAL_VISIBILITY,
  CHANGE_SEARCH_FIELD,
  ADD_USERS,
  DELETE_USER,
  DELETE_CONFIRM,
  CLOSE_DELETE_MODAL,
  INIT,
}

export interface AddUsersVisibilityAction {
  readonly type: RoleUsersActionTypes.ADD_USERS
  readonly users: ShortEmployee[]
}

export interface InitAction {
  readonly type: RoleUsersActionTypes.INIT
  readonly roleId: string
}

export interface CloseDeleteModalAction {
  readonly type: RoleUsersActionTypes.CLOSE_DELETE_MODAL
}

export interface DeleteConfirmAction {
  readonly type: RoleUsersActionTypes.DELETE_CONFIRM
}

export interface ChangeSearchFieldAction {
  readonly type: RoleUsersActionTypes.CHANGE_SEARCH_FIELD
  readonly value: string
}

export interface DeleteUserRoleAction {
  readonly type: RoleUsersActionTypes.DELETE_USER
  readonly user: ShortEmployee
}

export interface ChangeUsersModalVisibilityAction {
  readonly type: RoleUsersActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY
  readonly value: boolean
}
