import { AfterViewInit, Component } from "@angular/core";
import { RegistrationState } from "../state/registration-state";
import { RegistrationExecutor } from "../state/registration-exectutor";
import { RegistrationAction, RegistrationActionTypes } from "../state/registration-action";
import { RegistrationResultAction } from "../state/registration-result-action";
import { Store } from "src/app/core/mvi/store";
import { RegistrationReducer } from "../state/registration-reducer";
import { provideTranslocoScope } from "@jsverse/transloco";
import { RegistrationConstants } from "../../common/registration-constants";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['../../../authorization/presentation/view/authorization.component.scss'],
  providers: [
    provideTranslocoScope({ scope: 'auth/registration', alias: RegistrationConstants.TRANSLOCO_READ}),
    RegistrationState,
    RegistrationExecutor,
    RegistrationReducer
  ]
})
export class RegistrationComponent extends Store<
  RegistrationState,
  RegistrationExecutor,
  RegistrationAction,
  RegistrationResultAction
> {
  constructor(
    state: RegistrationState,
    executor: RegistrationExecutor,
    reducer: RegistrationReducer,
  ) {
    super(state, executor, reducer);
  }

  protected readonly RegistrationActionTypes = RegistrationActionTypes;
}
