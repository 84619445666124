import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgilesMainComponent } from "./presentation/agiles-main.component";
import { RouterOutlet } from "@angular/router";
import { AgilesListModule } from "./modules/agiles-list/agiles-list.module";
import { PageHeaderComponent } from "../../../core/components/page-header/page-header.component";
import { TranslocoDirective } from "@jsverse/transloco";

@NgModule({
  declarations: [
    AgilesMainComponent,
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    AgilesListModule,
    PageHeaderComponent,
    TranslocoDirective
  ],
})
export class AgilesMainModule { }
