import { Injectable } from "@angular/core";
import { DocumentState } from "./document-state";
import { DocumentAction, DocumentActionTypes } from "./document-action";
import { DocumentResultAction, DocumentResultActionTypes } from "./document-result-action";
import { Executor, Reducer } from "../../../../../core/mvi/store";
import { ActivatedRoute, Router } from "@angular/router";
import { KnowledgeBaseService } from "../../../../knowledge-base/data/knowledge-base-service";
import { MainRoutesPaths } from "../../../../../routes/main-routes";
import { KnowledgeBasePaths } from "../../../../../routes/knowledge-base-routes";
import { EditorChangesService } from "../../../../../core/components/yoopta-editor/data/editor-changes-service";
import { SessionService } from "../../../../../core/services/session-service/session-service";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastState } from "../../../../../core/components/toast-alert/toast-alert.component";
import { ToastsService } from "../../../../../core/components/toast-alert/services/toast-alert.service";

@Injectable()
export class DocumentExecutor extends Executor<
  DocumentState,
  DocumentAction,
  DocumentResultAction
> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private knowledgeBaseService: KnowledgeBaseService,
    private editorChangesService: EditorChangesService,
    private sessionService: SessionService,
    private toastsService: ToastsService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<DocumentState, DocumentResultAction>,
    getState: () => DocumentState,
    onReduced: (state: DocumentState) => void)
  {
    super.init(reducer, getState, onReduced);

    this.route.paramMap.subscribe(paramMap => {
      const documentId = paramMap.get('documentId')
      if (documentId) {
        this.loadDocument(documentId)
      }
    })

  }

  execute(action: DocumentAction) {
    this.editorChangesService.onChanges(this.editorChangesService.editorChanged)
    switch (action.type) {
      case DocumentActionTypes.CHANGE_TITLE:
        this.reduce({
          type: DocumentResultActionTypes.CHANGE_TITLE,
          title: action.title
        })
        break
      case DocumentActionTypes.SAVE_CLICKED:
        this.handleSave()
        break
      case DocumentActionTypes.SAVE_AND_MOVE:
        this.handleSaveAndMove()
        break
      case DocumentActionTypes.CLOSE_SESSION:
        this.sessionService.closeSession(this.getState().sessionId).subscribe()
        if(this.getState().pingIntervalId != -1){
          clearInterval(this.getState().pingIntervalId)
        }
        break
      case DocumentActionTypes.MOVE_BACK:
        this.moveToSpace()
        break
      case DocumentActionTypes.BACK_CLICKED:
        if(!this.editorChangesService.editorChanged && this.getState().initTitle == this.getState().title)
        {
          this.moveToSpace()
        }
        else {
          this.getState().editorComponent?.editor.blur()
          this.reduce({
            type: DocumentResultActionTypes.CHANGE_BACK_SPACE_MODAL_VISIBILITY,
            visibility: true,
          })
        }
        break

      case DocumentActionTypes.CLOSE_GO_TO_SPACE_MODAL:
        this.reduce({
          type: DocumentResultActionTypes.CHANGE_SAVE_SPACE_MODAL_VISIBILITY,
          visibilityValue: false,
          isLoadingSpaceModal: false
        })
        break
      case DocumentActionTypes.CLOSE_BACK_MODAL:
        this.reduce({
          type: DocumentResultActionTypes.CHANGE_BACK_SPACE_MODAL_VISIBILITY,
          visibility: false,
        })
        break
      case DocumentActionTypes.CLOSE_AFK_MODAL:
        this.reduce({
          type: DocumentResultActionTypes.CHANGE_AFK_MODAL_VISIBILITY,
          visibility: false,
        })
        break
      case DocumentActionTypes.INIT_CHILDREN:
        this.reduce({
          type: DocumentResultActionTypes.INIT_CHILDREN,
          editor: action.editor
        })
        break
    }
  }

  private handleSaveAndMove(){
    this.reduce({
      type: DocumentResultActionTypes.CHANGE_BACK_SPACE_MODAL_VISIBILITY,
      visibility: true,
      isLoading: true
    })
    this.save(true,() => {
      this.reduce({
        type: DocumentResultActionTypes.CHANGE_BACK_SPACE_MODAL_VISIBILITY,
        visibility: true,
        isLoading: false
      })
    })
  }

  private handleSave(){
    this.getState().editorComponent?.editor.blur()
    this.reduce({
      type: DocumentResultActionTypes.CHANGE_SAVE_SPACE_MODAL_VISIBILITY,
      visibilityValue: true,
      isLoadingSpaceModal: true
    })

    this.save(false, () => {
      this.reduce({
        type: DocumentResultActionTypes.CHANGE_SAVE_SPACE_MODAL_VISIBILITY,
        visibilityValue: true,
        isLoadingSpaceModal: false
      })
    })
  }

  private save(withMove: boolean, endMethod: () => void){
    let editorSaved: boolean = false
    let documentSaved: boolean = false
    let error: any | null = null

    const endLoadingModal = () => {
      if(documentSaved && editorSaved) {
        if(error === null){
          if(withMove)
          {
            this.moveToSpace()
          }
        }
        else {
          this.toastsService.createToast({
            title: 'Произошла ошибка при сохранении документа',
            description: 'Попробуйте позднее',
            state: ToastState.ERROR
          })
        }
        endMethod()
      }
    }

    this.getState().editorComponent?.saveDocument().subscribe({
      next:()=> {
        editorSaved = true
        this.editorChangesService.onChanges(false)
        endLoadingModal()
      },
      error: (err: any) => {
        editorSaved = true
        error = err
        endLoadingModal()
      }
    })

    if(this.getState().initTitle != this.getState().title) {
      this.knowledgeBaseService.updateDocument(
        this.getState().id,
        {
          name: this.getState().title,
        }
      ).subscribe({
        next: ()=>{
          documentSaved = true
        },
        error: (err: any) => {
          documentSaved = true
          error = err
          endLoadingModal()
        }
      })
    }
    else{
      documentSaved = true
    }
  }

  private moveToSpace(){
    if(this.getState().pingIntervalId != -1){
      clearInterval(this.getState().pingIntervalId)
    }
    if(this.getState().sessionId !== '')
    {
      this.sessionService.closeSession(this.getState().sessionId).subscribe()
    }
    this.router.navigateByUrl(`${MainRoutesPaths.KNOWLEDGE_BASE}/${KnowledgeBasePaths.SPACE}/${this.getState().knowledgeBaseID}?documentId=${this.getState().id}`)
  }

  private loadDocument(id: string){
    this.reduce({
      type: DocumentResultActionTypes.CHANGE_IS_LOADING,
      value: true
    })
    this.knowledgeBaseService.getDocument(id).subscribe(
    {
      next: (documentDto) => {
        this.sessionService.startSession(documentDto.longreadID).subscribe({
          next: (sessionDto) => {
            this.reduce({
              type: DocumentResultActionTypes.SAVE_SESSION,
              sessionId: sessionDto.sessionID,
              pingIntervalId: setInterval(() =>
                this.sessionService.ping(sessionDto.sessionID).subscribe(), 300000)
            });
            this.editorChangesService.setTimer(() => {
                this.getState().editorComponent?.editor.blur();
                this.reduce({
                  type: DocumentResultActionTypes.CHANGE_AFK_MODAL_VISIBILITY,
                  visibility: true
                });
              },
              () => {
                this.editorChangesService.init();
                this.execute({
                  type: DocumentActionTypes.SAVE_AND_MOVE
                });
              }
            );

            this.reduce({
              type: DocumentResultActionTypes.INIT_DOCUMENT,
              id: id,
              name: documentDto.name,
              longreadId: documentDto.longreadID,
              knowledgeBaseID: documentDto.knowledgeBaseID
            });
            this.reduce({
              type: DocumentResultActionTypes.CHANGE_IS_LOADING,
              value: false
            });
          },
          error: err => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 409) {
                this.reduce({
                  type: DocumentResultActionTypes.CHANGE_ALREADY_EDIT_MODAL_VISIBILITY,
                  visibility: true,
                  knowledgeBaseId: documentDto.knowledgeBaseID,
                  documentId: id
                });
              }
              else {
                this.toastsService.createToast({
                  title: 'Не удалось начать сессию',
                  description: 'Попробуйте позднее',
                  state: ToastState.ERROR
                })
                this.moveToSpace()
              }
            }
          }
        });
      },
      error: err => {
        this.toastsService.createToast({
          title: 'Произошла ошибка при загрузке документа',
          description: 'Попробуйте позднее',
          state: ToastState.ERROR
        })
        this.router.navigateByUrl(`/${MainRoutesPaths.KNOWLEDGE_BASE}`)
      }
    })
  }
}
