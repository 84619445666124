import { Component } from '@angular/core';
import { Store } from "src/app/core/mvi/store";
import { AgilesDeskSettingsNavItems, AgilesDeskSettingsState } from "./state/agiles-desk-settings-state";
import { AgilesDeskSettingsExecutor } from "./state/agiles-desk-settings-executor";
import { AgilesDeskSettingsAction, AgilesDeskSettingsActionTypes } from "./state/agiles-desk-settings-action";
import { AgilesDeskSettingsResultAction } from "./state/agiles-desk-settings-result-action";
import { AgilesDeskSettingsReducer } from "./state/agiles-desk-settings-reducer";
import { provideTranslocoScope } from "@jsverse/transloco";
import { AgileDeskSettingsConstants } from "../common/agile-desk-settings-constants";

@Component({
  selector: 'app-agiles-desk-settings',
  templateUrl: './agiles-desk-settings.component.html',
  styleUrls: ['./agiles-desk-settings.component.scss'],
  providers: [
    AgilesDeskSettingsState,
    AgilesDeskSettingsExecutor,
    AgilesDeskSettingsReducer,
    provideTranslocoScope({scope: 'agiles/agile-desk/settings', alias: AgileDeskSettingsConstants.TRANSLOCO_READ}),
  ]
})
export class AgilesDeskSettingsComponent
  extends Store<AgilesDeskSettingsState,
    AgilesDeskSettingsExecutor,
    AgilesDeskSettingsAction,
    AgilesDeskSettingsResultAction>
{
  constructor(
    state: AgilesDeskSettingsState,
    executor: AgilesDeskSettingsExecutor,
    reducer: AgilesDeskSettingsReducer
  ) {
    super(state, executor, reducer);
  }

  protected readonly AgilesDeskSettingsActionTypes = AgilesDeskSettingsActionTypes
  protected readonly AgilesDeskSettingsNavItems = AgilesDeskSettingsNavItems;
}
