import { UI } from '@yoopta/editor';
import { translate } from "@jsverse/transloco";
import { YooptaEditorConstants } from "../../../../common/yoopta-editor-constants";

const { Overlay, Portal } = UI;

const InputAltText = ({ floatingStyles, onClose, refs, value, onChange, onSave, onDelete }: any) => {
  return (
    <Portal id="uploader-portal">
      <Overlay lockScroll className="z-[100]" onClick={onClose}>
        <div ref={refs.setFloating} style={floatingStyles} onClick={(e) => e.stopPropagation()}>
          <div className="yoopta-image-input-root shadow-y-[4px]">
            <div>
              <label htmlFor="alt" className="yoopta-image-input-label">
                {translate(YooptaEditorConstants.TRANSLOCO_READ + ".alt-text")}
              </label>
              <input
                id="alt"
                type="text"
                className="yoopta-image-input-ui"
                name="alt"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder={translate(YooptaEditorConstants.TRANSLOCO_READ + ".alt-text-edit")}
                autoComplete="off"
              />
            </div>

            <div className="mt-2 flex justify-between">
              <button
                type="button"
                className="yoopta-button yoopta-image-alt-input-button"
                style={{
                  backgroundColor: '#1183ff',
                  color: 'white',
                }}
                disabled={!value}
                onClick={onSave}
              >
                {translate(YooptaEditorConstants.TRANSLOCO_READ + '.update')}
              </button>
              <button
                type="button"
                className="yoopta-button ml-2 yoopta-image-alt-input-button"
                style={{
                  backgroundColor: '#f4f4f5',
                  color: 'black',
                }}
                onClick={onDelete}
              >
                {translate(YooptaEditorConstants.TRANSLOCO_READ + '.delete')}
              </button>
            </div>
          </div>
        </div>
      </Overlay>
    </Portal>
  );
};

export { InputAltText };
