import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { AngularReactModule } from "@bubblydoo/angular-react";
import { FormsModule } from "@angular/forms";
import { AutoResizableTextareaDirective } from "../../directives/auto-resizeable-textarea.directive";
import { YooptaEditorComponent } from "./presentation/yoopta-editor.component";
import { TranslocoModule } from "@jsverse/transloco";
import { LocalisedEditorTextComponent } from "./presentation/components/localised-text/localised-editor-text.component";
import { TooltipBoxComponent } from "./presentation/components/tooltip-box/tooltip-box.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    YooptaEditorComponent,
    LocalisedEditorTextComponent,
    TooltipBoxComponent
  ],
  exports: [
    YooptaEditorComponent
  ],
  imports: [
    CommonModule,
    AngularReactModule,
    FormsModule,
    AutoResizableTextareaDirective,
    TranslocoModule,
    MatTooltipModule
  ]
})
export class YooptaEditorModule { }
