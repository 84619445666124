import { map, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserCardEntity } from "../user-card.component";
import { GetEmployeeDto } from "../../../../features/employees/data/dto/get-employee-dto";
import { environment } from "../../../../../environments/environment";
import { UserCardService } from "./user-card-service";

@Injectable()
export class UserCardServiceImpl implements UserCardService {
  constructor(private http: HttpClient) {
  }

  getUser(id: number): Observable<UserCardEntity> {
    return this.http.get<GetEmployeeDto>(`${environment.apiUrl}/employee/${id}`).pipe(
      map((data: GetEmployeeDto) => {
        return {
          id: id,
          surname: data.employee.surname,
          name: data.employee.name,
          patronymic: data.employee.patronymic,
          email: data.employee.email,
          phoneNumber: data.employee.phoneNumber,
          icon: data.employee.avatarUrl,
          canDelete: data.canDelete,
          canEdit: data.canEdit,
        }
      })
    )
  }
}
