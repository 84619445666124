import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { InformerTextComponent, InformerTextType } from "../informer-text/informer-text.component";
import { ButtonComponent } from "../buttons/button/button.component";
import { NgClass, NgIf } from "@angular/common";
import { SvgXMark } from "../svg-components/svg.components";
import { LoadingShadingComponent } from "../loading-shading/loading-shading.component";
import { A11yModule } from "@angular/cdk/a11y";
import { PageShadingComponent } from "../page-shading/page-shading.component";

@Component({
  selector: "app-core-alert-modal",
  templateUrl: "./alert-modal.component.html",
  standalone: true,
  imports: [
    ButtonComponent,
    NgIf,
    NgClass,
    InformerTextComponent,
    SvgXMark,
    LoadingShadingComponent,
    A11yModule,
    PageShadingComponent
  ],
  styleUrls: ["./alert-modal.component.scss"]
})
export class AlertModalComponent implements AfterViewInit{
  @Input() className: string | string[] = [];
  @Input() title: string = ''
  @Input() description: string = ''
  @Input() type: InformerTextType = 'info'
  @Input() confirmButtonText: string = ''
  @Input() cancelButtonText: string = ''
  @Input() confirmDisabled: boolean = false
  @Input() isLoading: boolean = false
  @Input() isConfirmLoading: boolean = false
  @Input() hideContentOnLoad: boolean = false
  @Input() closeActionType: 'confirm' | 'cancel' | 'self' = 'cancel'
  @Input() isInitFocus: boolean = true
  @Input() withButtonSeparator: boolean = false
  @Input() scrollable: boolean = false

  @Output() onConfirmClicked = new EventEmitter()
  @Output() onCancelClicked = new EventEmitter()
  @Output() onCloseClicked = new EventEmitter()

  @ViewChild('closeButton') closeButton!: ElementRef;

  onClose(event: MouseEvent) {
    event.preventDefault()
    switch (this.closeActionType){
      case "cancel":
        this.onCancelClicked.emit()
        break
      case "confirm":
        this.onConfirmClicked.emit()
        break
      case "self":
        this.onCloseClicked.emit()
        break
    }
  }

  ngAfterViewInit() {
    if(this.isInitFocus){
      this.closeButton.nativeElement.focus()
      this.closeButton.nativeElement.blur()
    }
  }
}
