import { Component } from '@angular/core';
import { Store } from "src/app/core/mvi/store";
import { OrdersState } from "./state/orders-state";
import { OrdersExecutor } from "./state/orders-executor";
import { OrdersAction, OrdersActionTypes } from "./state/orders-action";
import { OrdersResultAction } from "./state/orders-result-action";
import { OrdersReducer } from "./state/orders-reducer";
import { ReceiptService } from "../../payment-and-tariff/data/receipt-service";
import { OrdersConstants } from "../common/orders-constants";
import { provideTranslocoScope } from "@jsverse/transloco";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: [
    './orders.component.scss',
    '../../../common/settings.scss'
  ],
  providers: [
    OrdersState,
    OrdersExecutor,
    OrdersReducer,
    ReceiptService,
    provideTranslocoScope({ scope: 'settings/orders', alias: OrdersConstants.TRANSLOCO_READ }),
  ]
})
export class OrdersComponent
  extends Store<OrdersState,
    OrdersExecutor,
    OrdersAction,
    OrdersResultAction>
{
  constructor(
    state: OrdersState,
    executor: OrdersExecutor,
    reducer: OrdersReducer
  ) {
    super(state, executor, reducer);
  }

  protected readonly PaymentAndTariffActionTypes = OrdersActionTypes
  protected readonly OrdersActionTypes = OrdersActionTypes;
  protected readonly OrdersConstants = OrdersConstants;
}
