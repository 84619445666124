import { Component, EventEmitter, Input, Output } from "@angular/core";
import { stringToRgb } from "../../utils/string-to-rgb";
import { ShortEmployee } from "../select-user-modal/select-user-modal.component";
import { SvgXMark } from "../svg-components/svg.components";
import { NgClass, NgIf } from "@angular/common";
import {
  PaymentAndTariffModule
} from "../../../features/settings/modules/payment-and-tariff/payment-and-tariff.module";
import { TextWithHighlightingComponent } from "../text-with-highlighting/text-with-highlighting.component";
import { AvatarComponent } from "../avatar/avatar.component";
import { FieldsModule } from "../fields/fields.module";
import {
  UserCardOpenContainerComponent
} from "../user-card/components/user-card-open-container/user-card-open-container.component";

@Component({
  selector: "app-core-user-summery",
  templateUrl: "./user-summery.component.html",
  standalone: true,
  imports: [
    SvgXMark,
    NgIf,
    PaymentAndTariffModule,
    TextWithHighlightingComponent,
    AvatarComponent,
    NgClass,
    FieldsModule,
    UserCardOpenContainerComponent
  ],
  styleUrls: ["./user-summery.component.scss"]
})
export class UserSummeryComponent {
  @Input({required: true}) user!: ShortEmployee
  @Input() actionType: 'select' | 'delete' | 'none' = 'none';
  @Input() isDeletable: boolean = false;
  @Input() borderBottom: boolean = false;
  @Input() className: string | string[] = [];

  @Output() onDelete = new EventEmitter()
  @Output() onSelect = new EventEmitter();

  protected readonly stringToRgb = stringToRgb;
}
