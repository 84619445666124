import { DropdownItem } from "../../../../../../../../core/components/fields/components/dropdown-field/dropdown-field.component";import {
  AdditionalTariff, SubscriptionInfo, TariffInfo
} from "../../../../../../data/dto/get-update-invoice-data-dto";
import { PurchasePeriod } from "../../../../../../data/dto/all-tariffs-dto";

export type TariffFormResultAction =
  | ChangeAdditionalMemoryResultAction
  | ChangeUsersCountResultAction
  | ChangeDurationResultAction
  | ChangeIsExtensionsOpenResultAction
  | ChangeIsDurationOpenResultAction
  | InitResultAAction
  | ChangeIsLoadingResultAction
  | ChangeLangResultAction

export enum TariffFormResultActionTypes {
  INIT,
  CHANGE_IS_LOADING,
  CHANGE_DURATION,
  CHANGE_USERS_COUNT,
  CHANGE_ADDITIONAL_MEMORY,
  CHANGE_IS_EXTENSIONS_OPEN,
  CHANGE_IS_DURATION_OPEN,
  CHANGE_LANG
}

export interface InitResultAAction {
  readonly type: TariffFormResultActionTypes.INIT
  readonly subscriptionInfo: SubscriptionInfo | null
  readonly currentTariff: TariffInfo | null
  readonly purchasePeriods: PurchasePeriod[]
  readonly additionalTariff: AdditionalTariff
  readonly durationDropDownItems: DropdownItem[]
  readonly currentDuration: DropdownItem | null
}

export interface ChangeLangResultAction{
  readonly type: TariffFormResultActionTypes.CHANGE_LANG
  readonly durationDropDownItems: DropdownItem[]
  readonly currentDuration: DropdownItem | null
}

export interface ChangeIsExtensionsOpenResultAction {
  readonly type: TariffFormResultActionTypes.CHANGE_IS_EXTENSIONS_OPEN
  readonly value: boolean
}
export interface ChangeIsLoadingResultAction {
  readonly type: TariffFormResultActionTypes.CHANGE_IS_LOADING
  readonly value: boolean
}

export interface ChangeIsDurationOpenResultAction {
  readonly type: TariffFormResultActionTypes.CHANGE_IS_DURATION_OPEN
  readonly value: boolean
}

export interface ChangeAdditionalMemoryResultAction {
  readonly type: TariffFormResultActionTypes.CHANGE_ADDITIONAL_MEMORY
  readonly value: number
}

export interface ChangeUsersCountResultAction {
  readonly type: TariffFormResultActionTypes.CHANGE_USERS_COUNT
  readonly value: number
}

export interface ChangeDurationResultAction {
  readonly type: TariffFormResultActionTypes.CHANGE_DURATION
  readonly duration: DropdownItem
}
