import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ActiveSubscriptionDto } from "./dto/active-subscription-dto";
import { AllTariffsDto } from "./dto/all-tariffs-dto";
import { CreateInvoiceDto } from "./dto/create-invoice-dto";
import { CreateUpdateInvoice } from "./dto/create-update-invoice";
import { InvoicesListDto } from "./dto/invoices-list-dto";
import { GetUpdateInvoiceDataDto } from "./dto/get-update-invoice-data-dto";
import { GetCreateInvoiceDataDto } from "./dto/get-create-invoice-data-dto";
import { environment } from "../../../../environments/environment";

@Injectable()
export class SubscriptionService {
  private readonly apiUrl: string = `${environment.apiUrl}/subscription`;

  constructor(private http: HttpClient) {}

  getActiveSubscription(): Observable<ActiveSubscriptionDto> {
    return this.http.get<ActiveSubscriptionDto>(`${this.apiUrl}/current`);
  }

  getAllTariffs(): Observable<AllTariffsDto> {
    return this.http.get<AllTariffsDto>(`${this.apiUrl}/tariffs`);
  }

  getUpdateInvoiceData(): Observable<GetUpdateInvoiceDataDto> {
    return this.http.get<GetUpdateInvoiceDataDto>(`${this.apiUrl}/update-invoice-data`)
  }

  getCreateInvoiceData(): Observable<GetCreateInvoiceDataDto>{
    return this.http.get<GetCreateInvoiceDataDto>(`${this.apiUrl}/create-invoice-data`)
  }

  createInvoice(invoice: CreateInvoiceDto): Observable<{paymentId: string}>{
    return this.http.post<{paymentId: string}>(`${this.apiUrl}/create/invoice`, invoice)
  }

  createUpdateInvoice(invoice: CreateUpdateInvoice) {
    return this.http.post<{paymentId: string}>(`${this.apiUrl}/create/update-invoice`, invoice)
  }

  getInvoicesList(): Observable<InvoicesListDto>{
    return this.http.get<InvoicesListDto>(`${this.apiUrl}/invoices`);
  }

  cancelInvoice(id: string): Observable<null>{
    return this.http.post<null>(`${this.apiUrl}/invoice/${id}/cancel`, {});
  }

  payInvoice(id: string): Observable<null>{
    return this.http.post<null>(`${this.apiUrl}/invoice/${id}/pay`, {});
  }
}
