<div
  class="flex flex-col gap-[16px]"
>
  <div class="h-[1px] w-full bg-tt-border-default"></div>

  <div
    class="flex flex-col gap-[4px] text-tt-font-size_m text-tt-text-secondary"
    *ngFor="let section of sections"
  >
    <p class="font-medium" *ngIf="section.title">{{section.title}}:</p>
    <div
      *ngFor="let field of section.fields"
      class="flex justify-between gap-[16px]"
      [class.text-tt-primary]="field.price < 0"
    >
      <p class="flex-grow break-words">{{field.text}}</p>
      <p class="shrink-0">{{ (field.price | number: '1.0-2') + ' ₽'}}</p>
    </div>
  </div>

  <div
    class="flex justify-between items-center
      text-tt-text-main text-tt-font-size_3xl font-medium"
    [textParse]="resultLocal((finalPrice| number: '1.0-2') + ' ₽')"
    [parseElements]="[{
      text: (finalPrice| number: '1.0-2') + ' ₽',
      type: 'text',
      className: 'text-tt-font-size_5xl font-normal'
    }]"
  ></div>
</div>
