import { RoleMainEntity } from "../components/role-main/role-main.component";
import { PermissionsDto } from "../../../../../../core/services/role-service/dto/create-role-dto";

export type RoleAction =
  | ChangeNavItemAction
  | SaveMainDataAction
  | SavePermissionsAction
  | DeleteAction
  | ChangeDeleteAlertModalVisibilityAction

export enum RoleActionTypes {
  CHANGE_NAV_ITEM,
  SAVE_MAIN_DATA,
  SAVE_PERMISSIONS,
  DELETE,
  CHANGE_DELETE_ALERT_MODAL_VISIBILITY
}

export interface ChangeDeleteAlertModalVisibilityAction{
  readonly type: RoleActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY
  readonly value: boolean
}

export interface DeleteAction {
  readonly type: RoleActionTypes.DELETE;
}

export interface ChangeNavItemAction {
  readonly type: RoleActionTypes.CHANGE_NAV_ITEM;
  readonly navItem: string
}

export interface SaveMainDataAction {
  readonly type: RoleActionTypes.SAVE_MAIN_DATA
  readonly data: RoleMainEntity
}

export interface SavePermissionsAction {
  readonly type: RoleActionTypes.SAVE_PERMISSIONS
  readonly permissions: PermissionsDto
}
