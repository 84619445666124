<button
  class="group/button-text"
  (click)="onClick.emit()"
  [disabled]="isDisabled"
>
  <p
    class="text-tt-font-size_l "
    [ngClass]="{
      'text-[#9B9B9B]': isDisabled,
      'underline-offset-[3px] group-hover/button-text:underline': !isDisabled,
      'text-tt-primary': type === 'base',
      'text-tt-delete-button': type === 'danger',
      'text-tt-main': type === 'main',
    }"
  ><ng-content/></p>
</button>
