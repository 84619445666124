<ng-container
  *transloco="let t; read: EmployeesRolesConstants.TRANSLOCO_READ"
>
  <div class="flex w-full justify-center py-[44px]">
    <div class="max-w-[935px] w-full rounded-[8px] overflow-hidden border-[1px] border-tt-border-default bg-tt-light-blue">
      <div class="w-full px-[16px]">
        <div class="w-full flex py-[10px] gap-[16px]">
          <app-core-search-field
            class="w-full"
            [placeholder]="t('search-placeholder')"
            (onChange)="performAction({
              type: EmployeesActionTypes.FILTER_ROLES,
              filterStr: $event
            })"
          />
          <app-core-button
            *ngIf="state.canCreate"
            (onClick)="performAction({
              type: EmployeesActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE,
              value: true
            })"
          >
            {{ t('create-role') }}
          </app-core-button>

        </div>

        <div class="h-[33px] px-[8px] flex justify-between items-center">
          <p class="table-column-header-text">
            {{ t('name') }}
          </p>
          <p class="table-column-header-text">
            {{ t('actions') }}
          </p>
        </div>

      </div>

      <div
        class="w-full"
        *ngFor="let role of state.filteredRoles; let i = index"
      >
        <app-employees-roles-item
          [role]="role"
          [canEdit]="state.canEdit"
          [canDelete]="state.canDelete"
          (editClicked)="performAction({
            type: EmployeesActionTypes.EDIT_ROLE,
            id: role.id
          })"
          (deleteClicked)="performAction({
            type: EmployeesRolesActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
            value: true,
            deleteProps: {id: role.id, name: role.name}
          })"
        />

        <div
          *ngIf="i != state.roles.length - 1"
          class="h-[1px] w-full bg-tt-separator-color"
        ></div>
      </div>

      <div
        class="w-full flex justify-center p-[24px] bg-white"
        *ngIf="state.isLoading"
      >
        <mat-spinner class="w-[48px] h-[48px]"></mat-spinner>
      </div>

      <app-core-image-stub
        class="bg-white"
        *ngIf="!state.isLoading && state.filteredRoles.length == 0"
        [imageSrc]="''"
        [title]="state.roles.length > 0 ? t('no-matches') : t('load-error-title')"
        [description]="state.roles.length > 0 ? t('no-matches-description') : t('load-error-description')"
      />
    </div>
  </div>

  <employees-create-role-modal
    *ngIf="state.isCreateRoleModalVisible"
    [isVisible]="state.isCreateRoleModalVisible"
    [editRoleId]="state.editRoleId"
    (closeClicked)="performAction({
      type: EmployeesActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE,
      value: false
    })"
  />

  <app-core-alert-modal
    *ngIf="state.isDeleteAlertVisible && state.roleDeleteProps"
    [title]="t('delete-role')"
    type="warning"
    [cancelButtonText]="t('cancel')"
    [confirmButtonText]="t('delete')"
    [isLoading]="state.isDeleting"
    (onConfirmClicked)="performAction({
      type: EmployeesRolesActionTypes.DELETE_ROLE
    })"
    (onCancelClicked)="performAction({
      type: EmployeesRolesActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
      value: false
    })"
  >
    <p
      [textParse]="t('delete-role-description', { roleName: state.roleDeleteProps.name })"
      [parseElements]="[{
        text: state.roleDeleteProps.name,
        className: 'font-medium',
        type: 'text'
      }]"
    ></p>

  </app-core-alert-modal>

</ng-container>
