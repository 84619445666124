<app-auth-layout
  topOffset="constant"
  bgType="white"
  [isHaveBackButton]="state.status !== 'success'"
  *transloco="let t; read: PasswordRecoveryConstants.TRANSLOCO_READ"
  [backButtonText]="t('back-to-login')"
>
  <div
    class="mt-[120px] max-w-[400px] w-full"
  >
    <app-password-recovery-form
      *ngIf="state.status === 'idle'"
      [emailError]="state.emailError"
      [isLoading]="state.isLoading"
      (onSubmit)="performAction({ type: PasswordRecoveryActionTypes.RESET })"
      (firstFocused)="performAction({
        type: PasswordRecoveryActionTypes.VALIDATE
      })"
      (onEmailChange)="performAction({
        type: PasswordRecoveryActionTypes.CHANGE_EMAIL,
        email: $event
      })"
    />

    <app-password-recovery-complete
      *ngIf="state.status === 'success'"
      [email]="state.email"
      class="w-full"
    />
  </div>

</app-auth-layout>
