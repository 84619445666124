import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  AgilesListResultAction,
  AgilesListResultActionTypes
} from "./agiles-list-result-action";
import { AgilesListState } from './agiles-list-state.service';

@Injectable()
export class AgilesListReducer
  implements Reducer<AgilesListState, AgilesListResultAction>
{
  reduce(state: AgilesListState, action: AgilesListResultAction): AgilesListState {
    switch (action.type) {
      case AgilesListResultActionTypes.CHANGE_IS_OPEN_CREATE_MODAL:
        return {...state, isOpenCreateModal: action.value}
      case AgilesListResultActionTypes.CHANGE_DESK_LIST:
        return {...state, agileList: action.agileList}
    }
  }
}
