import { NavTab } from "src/app/core/components/nav-tabs/nav-tabs.component";
import { StepViewState } from "../state/create-role-modal-state";
import { PermissionsDto } from "../../../../../../../../core/services/role-service/dto/create-role-dto";
import { EmployeesRolesConstants } from "../../../../common/employees-roles-constants";

export enum CreateRoleModalNavItems {
  MAIN = 'main',
  ACCESSES = 'accesses',
}

export enum CreateRoleModalClueTexts {
  MAIN = 'main-clue-description',
  ACCESSES = 'accesses-clue-description',
}

export enum CreateRoleModalClueTitles {
  MAIN = 'main-clue-title',
  ACCESSES = 'accesses-clue-title',
}

export class CreateRoleModalConstants {
  public static readonly TRANSLOCO_READ = EmployeesRolesConstants.TRANSLOCO_READ + '.create-role-modal'

  public static readonly INIT_STEP_STATE: StepViewState = {
    stepNumber: 1,
    selectedNavItem: CreateRoleModalNavItems.MAIN,
    clueText: CreateRoleModalClueTexts.MAIN,
    clueTitle: CreateRoleModalClueTitles.MAIN,
    isShowErrorMain: false,
    isShowErrorContacts: false,
  }

  public static readonly NAV_ITEMS: NavTab[] = [
    {
      id: '',
      url: CreateRoleModalNavItems.MAIN,
      title: 'main'
    },
    {
      id: '',
      url: CreateRoleModalNavItems.ACCESSES,
      title: 'accesses'
    },
  ]

  public static readonly INIT_PERMISSIONS: PermissionsDto = {
    knowledgeBase: {
      viewKnowledgeBase: true,
      createKnowledgeBase: false,
      editKnowledgeBase: false,
      deleteKnowledgeBase: false
    },
    company: {
      viewEmployee: true,
      createEmployee: false,
      editEmployee: false,
      deleteEmployee: false
    },
    settings: {
      billing: false,
      main: false,
    },
    role: {
      viewRole: true,
      createRole: false,
      editRole: false,
      deleteRole: false
    }
  }
}
