import { Component, Input } from "@angular/core";

@Component({
  selector: "app-core-tag",
  templateUrl: "./tag.component.html",
  standalone: true,
  styleUrls: ["./tag.component.scss"]
})
export class TagComponent {
  @Input() tag: string = '';
}
