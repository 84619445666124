<div
  class="size-[56px] flex items-center justify-center rounded-full"
  [ngClass]="{
    'bg-[#0B57D01A] text-tt-primary': variant === 'base',
    'bg-[#D3302F1A] text-tt-input-field-failure-text': variant === 'danger',
    'bg-[#35B37E1A] text-[#35B37E]': variant === 'success',
  }"
>
  <ng-content/>
</div>
