<div
  class="py-[8px] h-full w-max bg-tt-action-menu-background rounded-[8px]"
  [ngClass]="{
    'shadow-tt-shadow-action-menu': variant == 'shaded',
    'border border-tt-separator-color': variant == 'standard',
  }"
  *transloco="let t; read: translocoRead"
>
  <div [ngClass]="{
      'overflow-y-scroll h-full tiny-scrollbar': isScrollable,
    }"
    *ngFor="let section of sections"
  >
    <div *ngIf="sections.indexOf(section) !== 0" class="p-[8px]">
      <div class="h-[1px] bg-tt-separator-color"></div>
    </div>
    <button
      *ngFor="let element of section.elements"
      [id]="START_ID + element.id"
      class="flex align-middle h-[32px] w-full px-[12px] py-[6px] hover:bg-tt-action-menu-item-hover"
      (click)="onElementClick($event, element.id)"
      [ngClass]="{
        'bg-tt-selected-tab-color': element.id == selectedId
      }"
    >
      <img
        width="24"
        height="24"
        *ngIf="element.icon"
        src="{{ element.icon }}"
        alt=""
      />
      <p
        class="my-auto text-tt-font-size_l text-tt-action-menu-text"
        [ngClass]="{
          'ml-[8px]': element.icon
        }"
      >
        {{ t(element.name) }}
      </p>
    </button>
  </div>
</div>
