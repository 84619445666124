import { Injectable } from '@angular/core';
import { ShortEmployee } from "../../../../../../../../../core/components/select-user-modal/select-user-modal.component";
import { parseToHighlightedParts } from "../../../../../../../../../core/utils/parse-to-highlighted-parts";

@Injectable()
export class AgilesDeskSettingsAccessState {
  readonly filterString: string = ''
  readonly isOpenSelectUsersModal: boolean = false

  readonly isOpenDeleteUserModal: boolean = false
  readonly userToDelete: AgilesDeskSettingsAccessUser | null = null

  readonly users: AgilesDeskSettingsAccessUser[] = [{
    user: {
      id: 12312,
      surname: 'owner',
      name: 'owner',
      patronymic: 'owner',
      nameParts: parseToHighlightedParts('owner owner owner', ''),
      email: 'owner@owner.owner',
      emailParts: parseToHighlightedParts('owner@owner.owner', ''),
      isSelected: false,
    },
    role: AgilesDeskSettingsAccessUserRoles.OWNER
  }]
  readonly filteredUsers: AgilesDeskSettingsAccessUser[] = this.users.slice()
}

export interface AgilesDeskSettingsAccessUser {
  user: ShortEmployee,
  role: AgilesDeskSettingsAccessUserRoles
}

export enum AgilesDeskSettingsAccessUserRoles {OWNER='owner', ADMIN='admin', PARTICIPANT='participant', PROHIBITED='prohibited'}
