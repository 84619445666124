import { Injectable } from '@angular/core';
import { DropdownItem } from "../../../../../../../../core/components/fields/components/dropdown-field/dropdown-field.component";
import { PurchasePeriod, TariffDto } from "../../../../../../data/dto/all-tariffs-dto";
import { AdditionalTariff } from "../../../../../../data/dto/get-update-invoice-data-dto";

@Injectable()
export class SubscriptionFormState {
  readonly isLoading: boolean = false
  readonly receiptButtonIsLoading: boolean = false

  readonly usersCount: number = 10
  readonly memoryCount: number = 0

  readonly durationDropdownItems: DropdownItem[] = []
  readonly currentDuration: DropdownItem | null = null
  readonly durations: PurchasePeriod[] = []

  readonly tariffDropdownItems: DropdownItem[] = []
  readonly currentTariff: DropdownItem | null = null
  readonly allTariffs: TariffDto[] = []

  readonly additionalTariff: AdditionalTariff | null = null
}
