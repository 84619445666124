import { Observable } from 'rxjs';
import { LoginByPasswordData } from './login-by-password-data';
import { Account } from './account';
import { LoginStatus } from './login-status';
import { CheckPasswordResponseDto } from "../../employees/data/dto/check-password-response-dto";
import { AppPaths } from "../../../app-routing.module";
import { StartPasswordRestoreResponseDto } from "../../employees/data/dto/start-password-restore-response-dto";

export abstract class AuthService {
  public abstract userObservable: Observable<Account | null>;

  abstract getAccount(): Account | null;

  abstract isAuthenticated(): boolean;

  abstract setStorageAccount(): void;

  abstract login(data: LoginByPasswordData): Observable<LoginStatus>;

  abstract logout(withoutRequest?: boolean): void;

  abstract refreshToken(): Observable<any>;

  abstract checkPassword(password: string, salt: string): Observable<CheckPasswordResponseDto>;

  abstract changePassword(prevPassword: string, newPassword: string, salt: string): Observable<null>;

  abstract notifyAboutPasswordChange(email: string): Observable<null>;

  abstract startPasswordRestore(token: string): Observable<StartPasswordRestoreResponseDto>;

  abstract finishPasswordRestore(token: string, newPassword: string, salt: string): Observable<null>;

}
