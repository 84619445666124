import { Elements, PluginElementRenderProps, useBlockData, useYooptaEditor } from '@yoopta/editor';
import { TodoListElementProps } from "@yoopta/lists";
import { AngularWrapper } from "@bubblydoo/angular-react";
import { CheckboxFieldComponent } from "../../../../../fields/components/checkbox-field/checkbox-field.component";

const MyTodoListRender = (props: PluginElementRenderProps) => {
  const { attributes, element, children, blockId, HTMLAttributes = {} } = props;
  const editor = useYooptaEditor();
  const block = useBlockData(blockId);

  const { className = '', ...htmlAttrs } = HTMLAttributes;
  const { checked = false } = (element.props || {}) as TodoListElementProps;

  const currentAlign = block?.meta?.align || 'left';
  const alignClass = `yoopta-align-${currentAlign}`;

  return (
    <div
      className={`yoopta-todo-list h-[40px] ${alignClass} ${className}`}
      data-checked={checked}
      {...htmlAttrs}
      {...attributes}
    >
      <div
        contentEditable={false}
        className="absolute w-[36px] h-[36px] left-0 top-0"
      >
        <AngularWrapper
          component={CheckboxFieldComponent}
          inputs={{ value: checked, inactive: editor.readOnly, hoverType: 'blue', inactiveStyle: 'base'}}
          outputs={{ onToggle: () => Elements.updateElement(editor, blockId, { type: 'todo-list', props: { checked: !checked } }) }}
        />
      </div>
      <div className="yoopta-todo-list-content">
        {children}
      </div>
    </div>
  );
};

export { MyTodoListRender };
