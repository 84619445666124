<td class="pl-[16px]">
  <app-user-card-open-container
    [userId]="employee.id"
    [parentOffset]="{x: 0, y: 10}"
    (editClicked)="editClicked.emit()"
    (deleteClicked)="deleteClicked.emit()"
  >
    <div class="flex h-full gap-[16px] items-center">
      <div
        class="shrink-0"
      >
        <app-core-avatar
          *ngIf="!employee.icon"
          size="l"
          variant="primary"
          placeholder="{{avatarPlaceHolder}}"
          bgRGB="{{avatarBgColor}}"
          [url]="employee.icon"
        />
      </div>

      <p
        class="flex-grow line-clamp-1 text-left text-[14px] leading-[18px] font-normal"
        app-core-with-highlighting
        [text]="employee.nameParts"
      >
      </p>
    </div>
  </app-user-card-open-container>
</td>

<td
  *ngIf="windowWidth >= 768"
  class="
    text-[13px] leading-[16px] text-tt-text-secondary truncate
  "
  app-core-with-highlighting
  [text]="employee.emailParts"
>
</td>

<td
  *ngIf="windowWidth >= 1024"
  class="
    text-[13px] leading-[16px] text-tt-text-secondary truncate"
>
  {{phoneNumber}}
</td>

<td class="pr-[16px]"
>
  <div
    *ngIf="sections[0] ? sections[0].elements.length > 0 : false"
    class="w-full h-full flex items-center justify-end group-hover:visible"
    [ngClass]="{
      'invisible': !isPopupVisible
    }"
  >
    <button class="p-[4px] text-tt-icon-default group/tripledot"
      (click)="tripleDotClick()"
    >
      <div class="size-[40px] flex items-center justify-center rounded-[20px] hover:bg-tt-button-hover">
        <svg-triple-dot />
      </div>
    </button>

    <app-core-action-menu
      *ngIf="isPopupVisible"
      [sections]="sections"
      [translocoRead]="EmployeeListConstants.TRANSLOCO_READ"
      (elementClicked)="onElementClick($event)"

      appPopup
      [parentOffset]="{x: -4, y: -44}"
      (popupClose)="popupClose()"
    />
  </div>
</td>

