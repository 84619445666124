<app-core-alert-modal
  className="max-w-[700px]"
  *transloco="let t; read: TRANSLOCO_READ"
  [title]="t('select-employees')"
  [scrollable]="true"
  [cancelButtonText]="'Отмена'"
  [isLoading]="isLoading"
  [withButtonSeparator]="true"
  [confirmButtonText]="selectedUsers.length > 0 ? t('add-users', { count: selectedUsers.length, countType: numToWordType(selectedUsers.length) }) : t('add')"
  [confirmDisabled]="!(selectedUsers.length > 0)"
  (onCancelClicked)="onClose.emit()"
  (onConfirmClicked)="addUsers()"
>
  <app-core-alert-modal-unscrollable-box
    class="mt-[16px] block"
  >
    <app-core-search-field
      class="w-full"
      [class.invisible]="isLoading"
      placeholder="Поиск"
      (onChange)="onFilter($event)"
    />
  </app-core-alert-modal-unscrollable-box>

  <div
    class="mt-[8px] flex flex-col max-h-full gap-[16px] min-h-[250px] relative"
    #employeeList
  >
    <app-core-loading-shading
      *ngIf="isListLoading"
      class="bg-white"
    />
    <div
      class="flex-grow"
      *ngIf="!isListLoading"
    >
      <app-core-user-summery
        *ngFor="let user of filteredEmployees; let i = index"
        actionType="select"
        [user]="user"
        [borderBottom]="i != users.length - 1"
        (onSelect)="onSelect(user)"
      />
      <app-core-image-stub
        *ngIf="filteredEmployees.length === 0"
        [title]="users.length > 0 ? t('no-matches-title') : t('Список пользователей пуст')"
        [description]="users.length > 0 ? t('no-matches-description') : ''"
      />
    </div>
  </div>

</app-core-alert-modal>
