import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  OrdersResultAction,
  OrdersResultActionTypes
} from "./orders-result-action";
import { OrdersState } from './orders-state';

@Injectable()
export class OrdersReducer
  implements Reducer<OrdersState, OrdersResultAction>
{
  reduce(state: OrdersState, action: OrdersResultAction): OrdersState {
    switch (action.type) {
      case OrdersResultActionTypes.INIT:
        return {...state,
          activeOrder: action.activeOrder,
          storyOrders: action.storyOrders,
          activeOrderDueDate: action.activeOrderDueDate,
          activeOrderDto: action.activeOrderDto,
          storyOrdersDto: action.storyOrdersDto,
        }
      case OrdersResultActionTypes.CHANGE_CANCEL_MODAL_VISIBILITY:
        return {...state, cancelModalVisibility: action.value}
      case OrdersResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value, isLoadingError: action.isError}
      case OrdersResultActionTypes.CHANGE_CANCEL_MODAL_IS_LOADING:
        return {...state, cancelModalIsLoading: action.value}
      case OrdersResultActionTypes.CHANGE_LANG:
        return {...state,
          activeOrder: action.activeOrder,
          storyOrders: action.storyOrders,
        }
    }
  }
}
