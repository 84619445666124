<app-auth-layout
  topOffset="constant"
  bgType="white"
  [isLoading]="state.isLoading"
  (keyup.enter)="performAction({ type: AuthorizationActionTypes.LOGIN })"
>
  <app-core-auth-container
    *transloco="let t; read: AuthorizationConstants.TRANSLOCO_READ"
    class="mt-[120px]"
    [withBackButton]="false"
    [title]="t('title')"
  >
    <app-core-auth-container-icon>
      <svg-login-icon/>
    </app-core-auth-container-icon>

    <p>{{ t('description') }}</p>

    <div
      class="mt-[24px] flex flex-col gap-[24px]"
    >
      <app-core-input-field
        inputVariant="standard"
        [label]="t('email-label')"
        [placeholder]="t('email-placeholder')"
        size="xl"
        type="text"
        [value]="state.email"
        [defaultValue]="state.email"
        [error]="state.emailError"
        errorShowType="onUnFocused"
        (onChange)="
          performAction({
            type: AuthorizationActionTypes.CHANGE_EMAIL,
            email: $event
          })
        "
      />

      <app-core-input-field
        inputVariant="standard"
        [placeholder]="t('password-placeholder')"
        [label]="t('password-label')"
        size="xl"
        type="password"
        [error]="state.passwordError"
        errorShowType="onUnFocused"
        (onChange)="
          performAction({
            type: AuthorizationActionTypes.CHANGE_PASSWORD,
            password: $event
          })
        "
      />

      <div class="flex justify-between items-center h-6 gap-[8px]">
        <app-core-checkbox-field
          [label]="t('remember-me')"
          [value]="state.rememberMe"
          (onToggle)="
            performAction({
              type: AuthorizationActionTypes.TOGGLE_REMEMBER_ME
            })
          "
        />
        <a type="button" class="text-tt-font-size_l text-tt-text-main underline-offset-[3px] hover:underline" [routerLink]="'/' + AppPaths.PASSWORD_RECOVERY">
          {{ t('forgot-password') }}
        </a>
      </div>

      <app-core-button
        className="w-full"
        size="l"
        (onClick)="performAction({ type: AuthorizationActionTypes.LOGIN })"
      >
        {{ t('login') }}
      </app-core-button>
    </div>

    <p class="mt-[24px]">{{ t('registration-question') }} <a
      class="text-tt-primary underline-offset-[3px] hover:underline"
      [routerLink]="'/' + AppPaths.SIGNUP"
    >{{ t('register') }}</a></p>
  </app-core-auth-container>
</app-auth-layout>
