import { Injectable } from '@angular/core';

@Injectable()
export class PasswordResetState {
  readonly isLoading: boolean = false
  readonly currentStage: PasswordResetStageType = 'init'

  readonly recoverySalt?: string
  readonly recoveryToken?: string

  readonly currentPassword: string = ''
  readonly currentPasswordError: string | null = null
  readonly newPassword: string = ''
  readonly newPasswordError: string | null = null
  readonly newConfirmPassword: string = ''
  readonly newConfirmPasswordError: string | null = null
}

export type PasswordResetStageType = 'init' | 'error' | 'loading' | 'new-password' | 'success' | 'session-expired' | 'already-changed'
