export type PasswordRecoveryAction =
  | ChangeEmailAction
  | ResetAction
  | ValidateAction

export enum PasswordRecoveryActionTypes {
  CHANGE_EMAIL,
  RESET,
  VALIDATE
}

export interface ChangeEmailAction {
  readonly type: PasswordRecoveryActionTypes.CHANGE_EMAIL;
  readonly email: string;
}

export interface ResetAction {
  readonly type: PasswordRecoveryActionTypes.RESET;
}

export interface ValidateAction {
  readonly type: PasswordRecoveryActionTypes.VALIDATE;
}
