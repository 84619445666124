<div
  class="rounded-[8px] overflow-hidden border border-tt-border-default flex gap-[4px] "
  [ngClass]="{
    'border-tt-primary outline outline-[1px] outline-tt-primary': task.selectType === 'single' || task.selectType === 'multiple',
    'bg-[#D2E3FC]': task.selectType === 'multiple',
    'bg-white': task.selectType !== 'multiple',

  }"
  (click)="onClick($event)"
  *transloco="let t; read: AgileDeskConstants.TRANSLOCO_READ"
>
  <div
    class="w-[3px]"
    [ngClass]="{
      'bg-tt-icon-success': task.priority === TaskPriority.NORMAL,
      'critical-bg': task.priority === TaskPriority.CRITICAL,
    }"
  ></div>
  <div class="py-[8px] pr-[8px] flex flex-col gap-[8px]">
    <p class="text-tt-font-size_l text-tt-text-default">
      <span
        class="text-tt-text-secondary pr-[8px]"
        [class.line-through]="isCompleted"
      >
        {{ taskTagNumber }}</span>{{task.title}}
    </p>

    <p class="text-tt-font-size_s text-tt-text-secondary line-clamp-3">
      {{task.description}}
    </p>

    <div
      class="flex gap-[8px] items-center text-tt-text-secondary text-tt-font-size_s"
    >
      <app-task-avatar
        *ngIf="executor"
        [executor]="executor"
      />

      <div
        class="flex gap-[4px] items-center "
      >
        <div
          class="size-[8px] rounded-[4px] shrink-0"
          [ngClass]="{
            'bg-tt-icon-success': task.priority === TaskPriority.NORMAL,
            'critical-bg': task.priority === TaskPriority.CRITICAL,
          }"
        ></div>
        <p>{{ task.priority === TaskPriority.NORMAL ? t('task-priority-normal') : t('task-priority-critical')}}</p>
      </div>

      <p>{{ t('task') }}</p>
    </div>
  </div>
</div>
<ng-content/>
