<button
  class="w-full py-[6px] px-[8px] flex gap-[12px] rounded-[8px] items-center hover:bg-tt-icon-button-background-hover"
  (click)="onClick.emit()"
>
  <div
    class="size-[24px]"
    [ngClass]="{
      'text-tt-text-secondary': variant === 'base',
      'text-tt-input-field-failure-text': variant === 'danger'
    }"
  >
    <ng-content/>
  </div>
  <div
    class="text-tt-font-size_l"
    [ngClass]="{
      'text-tt-text-main': variant === 'base',
      'text-tt-input-field-failure-text': variant === 'danger'
    }"
  >
    <ng-content select="p"/>
  </div>
</button>
