<app-core-auth-container
  *transloco="let t; read: RegistrationConstants.TRANSLOCO_READ"
  [title]="t('registration-error-title')"
>
  <app-core-auth-container-icon
    variant="danger"
  >
    <svg-reset-password-error/>
  </app-core-auth-container-icon>

  <div>
    <p *ngIf="type === 'unknown'">
      {{ t('registration-error-unknown') }}
    </p>
    <p *ngIf="type === 'email-exist'" class="mt-[8px]">
      {{ t('registration-error-email-exist-prev-email') }}
      <span class="text-tt-primary">{{email}}</span>
      {{ t('registration-error-email-exist-post-email')}}
    </p>
  </div>

</app-core-auth-container>
