import { Editor2ServiceImpl } from "../../data/editor-service-impl";

export function onFileDownload(id: string, editorService: Editor2ServiceImpl, name?: string | null) {
  editorService.downloadFile(id).subscribe((result)=>{
    const fileUrl = URL.createObjectURL(result);
    let linkElement = document.createElement('a');
    linkElement.href = fileUrl;
    linkElement.download = name ? name: '';
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
  })
}

export default { openFileToDownload: onFileDownload };
