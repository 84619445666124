import {
  TasksStatusEntity
} from "../../../../../agiles-desk-main/presentation/components/tasks-table/tasks-table.component";
import { TaskExecutor } from "../../../../../agiles-desk-main/presentation/components/task-item/task-item.component";

export type AgilesDeskSettingsStatusesAndExecutorsResultAction =
  | InitResultAction
  | ChangeStatusListResultAction
  | ChangeExecutorListResultAction
  | ChangeUnassignedShowResultAction

export enum AgilesDeskSettingsStatusesAndExecutorsResultActionTypes {
  INIT,
  CHANGE_STATUS_LIST,
  CHANGE_EXECUTOR_LIST,
  CHANGE_UNASSIGNED_SHOW,
}

export interface ChangeUnassignedShowResultAction {
  readonly type: AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.CHANGE_UNASSIGNED_SHOW
  readonly value: boolean
}

export interface ChangeStatusListResultAction {
  readonly type: AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.CHANGE_STATUS_LIST
  readonly list: TasksStatusEntity[]
}

export interface ChangeExecutorListResultAction {
  readonly type: AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.CHANGE_EXECUTOR_LIST
  readonly list: TaskExecutor[]
}

export interface InitResultAction {
  readonly type: AgilesDeskSettingsStatusesAndExecutorsResultActionTypes.INIT
  readonly statuses: TasksStatusEntity[]
  readonly executors: TaskExecutor[]
  readonly showUnassigned: boolean
}
