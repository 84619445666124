import { Observable, of } from "rxjs";
import { ShortEmployee } from "src/app/core/components/select-user-modal/select-user-modal.component";
import {
  SelectUserModalService
} from "../../../../../../../../../core/services/select-user-modal-service/select-user-modal.service";

export class SelectUsersModalServiceImpl implements SelectUserModalService {
  getUsers(): Observable<ShortEmployee[]> {
    return of([
      {
        id: 123,
        surname: '1',
        name: '1',
        patronymic: '1',
        email: '1@1.1',
        isSelected: false,
        nameParts: [],
        emailParts: [],
      },{
        id: 1234,
        surname: '2',
        name: '2',
        patronymic: '2',
        email: '2@2.2',
        isSelected: false,
        nameParts: [],
        emailParts: [],
      },{
        id: 12345,
        surname: '3',
        name: '3',
        patronymic: '3',
        email: '3@3.3',
        isSelected: false,
        nameParts: [],
        emailParts: [],
      },{
        id: 123456,
        surname: '4',
        name: '4',
        patronymic: '4',
        email: '4@4.4',
        isSelected: false,
        nameParts: [],
        emailParts: [],
      },{
        id: 1234567,
        surname: '5',
        name: '5',
        patronymic: '5',
        email: '5@5.5',
        isSelected: false,
        nameParts: [],
        emailParts: [],
      },{
        id: 12345678,
        surname: '6',
        name: '6',
        patronymic: '6',
        email: '6@6.6',
        isSelected: false,
        nameParts: [],
        emailParts: [],
      },
    ])
  }

}
