<div
  class="child-routes-size px-[24px] tiny-scrollbar"
  (scroll)="onScroll()"
  #scrollable
  *transloco="let t; read: SettingsConstants.TRANSLOCO_READ"
>
  <app-core-page-header
    [title]="t('settings')"
    [navTabs]="navTabs"
    [tabsTransclocoRead]="SettingsConstants.TRANSLOCO_READ"
    navTabType="link"
  />

  <router-outlet></router-outlet>
</div>
