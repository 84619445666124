import { Component } from "@angular/core";

@Component({
  selector: 'svg-change-password',
  templateUrl: './svg/change-password-icon.svg',
})
export class SvgChangePassword {}

@Component({
  selector: 'svg-exit',
  templateUrl: './svg/exit-icon.svg',
})
export class SvgExit {}

@Component({
  selector: 'svg-nav-knowledge-base',
  templateUrl: './svg/knowledge-base.svg',
})
export class SvgNavKnowledgeBase {}

@Component({
  selector: 'svg-nav-my-team',
  templateUrl: './svg/my-team.svg',
})
export class SvgNavMyTeam {}

@Component({
  selector: 'svg-nav-news',
  templateUrl: './svg/news.svg',
})
export class SvgNavNews {}

@Component({
  selector: 'svg-nav-settings',
  templateUrl: './svg/settings.svg',
})
export class SvgNavSettings {}
