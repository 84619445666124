import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { TariffDuration } from "../../../domain/tariff-duration";
import { Store } from "../../../../../../../core/mvi/store";
import { TariffFormResultAction } from "./state/tariff-form-result-action";
import { TariffFormAction, TariffFormActionTypes } from "./state/tariff-form-action";
import { TariffFormExecutor } from "./state/tariff-form-executor";
import { TariffFormState } from "./state/tariff-form-state";
import { TariffFormReducer } from "./state/tariff-form-reducer";
import { ReceiptService } from "../../../data/receipt-service";
import { PaymentAndTariffConstants } from "../../../common/payment-and-tariff-constants";
import { TariffFormConstants } from "./common/tariff-form-constants";

@Component({
  selector: "app-tariff-form",
  templateUrl: "./tariff-form.component.html",
  styleUrls: [
    "./tariff-form.component.scss",
    "../../../../../common/settings.scss"
  ],
  providers: [
    TariffFormState,
    TariffFormExecutor,
    TariffFormReducer,
  ]
})
export class TariffFormComponent
  extends Store<
    TariffFormState,
    TariffFormExecutor,
    TariffFormAction,
    TariffFormResultAction
  > implements OnDestroy
{
  constructor(
    state: TariffFormState,
    executor: TariffFormExecutor,
    reducer: TariffFormReducer,
    private receiptService: ReceiptService
  ) {
    super(state, executor, reducer);
  }

  ngOnDestroy(): void {
    this.receiptService.updateSections([])
  }

  protected readonly TariffFormActionTypes = TariffFormActionTypes;
  protected readonly PaymentAndTariffConstants = PaymentAndTariffConstants;
  protected readonly TariffFormConstants = TariffFormConstants;
}
