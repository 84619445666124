<app-core-auth-container
  *transloco="let t; read: RegistrationConstants.TRANSLOCO_READ"
  [title]="t('registration-success-title')"
>
  <app-core-auth-container-icon
    variant="success"
  >
    <svg-reset-password-success/>
  </app-core-auth-container-icon>

  <p>
    {{ t("registration-success-description") }}
    <span class="text-tt-primary">{{email}}</span>
  </p>

</app-core-auth-container>
