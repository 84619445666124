import { Component, Input } from "@angular/core";

@Component({
  selector: "app-core-page-shading",
  templateUrl: "./page-shading.component.html",
  standalone: true
})
export class PageShadingComponent {
  @Input() appearanceType: 'base' | 'animate' = "base"

  mouseDown(event: MouseEvent) {
    event.stopPropagation()
  }
}
