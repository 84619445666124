<div class="child-routes-size tiny-scrollbar relative"
 (scroll)="onScroll()"
 #scrollable
>
  <div
    class="px-[24px]"
  >
    <app-core-page-header
      [title]="'@project_name'"
      [navTabs]="navTabs"
      [tabsTransclocoRead]="AgileDeskConstants.TRANSLOCO_READ"
      navTabType="link"
      textSize="5xl"
    />
  </div>

  <div class="w-full">
    <router-outlet/>
  </div>

</div>
