<div class="w-full flex flex-col items-center py-[24px]">
  <app-core-settings-container>
    <app-agiles-desk-settings-nav
      [selectedNav]="state.currentNavItem"
      (onClickNav)="performAction({
        type: AgilesDeskSettingsActionTypes.CHANGE_NAV_ITEM,
        navItem: $event
      })"
    />

    <app-agiles-desk-settings-main
      class="w-full"
      *ngIf="state.currentNavItem == AgilesDeskSettingsNavItems.MAIN"
    />
    <app-agiles-desk-settings-statuses-and-executors
      class="w-full"
      *ngIf="state.currentNavItem == AgilesDeskSettingsNavItems.STATUSES_AND_EXECUTORS"
    />
    <app-agiles-desk-settings-access
      class="w-full"
      *ngIf="state.currentNavItem == AgilesDeskSettingsNavItems.ACCESS"
    />

  </app-core-settings-container>

</div>
