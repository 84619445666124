import { Reducer } from 'src/app/core/mvi/store';
import { CreateRoleModalState } from './create-role-modal-state';
import {
  CreateRoleModalResultAction,
  CreateRoleModalResultActionTypes,
} from './create-role-modal-result-action';
import { Injectable } from '@angular/core';
import { CreateRoleModalConstants } from "../common/create-role-modal-constants";

@Injectable()
export class CreateRoleModalReducer
  implements Reducer<CreateRoleModalState, CreateRoleModalResultAction>
{
  reduce(
    state: CreateRoleModalState,
    action: CreateRoleModalResultAction,
  ): CreateRoleModalState {
    switch (action.type) {
      case CreateRoleModalResultActionTypes.CHANGE_NAV_ITEM:
        return {...state, stepViewState: action.stepState}
      case CreateRoleModalResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value}
      case CreateRoleModalResultActionTypes.CHANGE_ROLE_NAME:
        return {...state, roleName: action.value, roleNameError: action.error, isValid: action.isValid}
      case CreateRoleModalResultActionTypes.CHANGE_ROLE_DESCRIPTION:
        return {...state, roleDescription: action.value, roleDescriptionError: action.error, isValid: action.isValid }
      case CreateRoleModalResultActionTypes.CHECK_NAME_DESCRIPTION_VALID:
        return {...state, roleDescriptionError: action.errorDescription, roleNameError: action.errorName }
      case CreateRoleModalResultActionTypes.INIT:
        return {...state,
          loadedRoleId: '',
          roleName: '',
          roleNameError: null,
          roleDescription: '',
          roleDescriptionError: null,
          permissions: CreateRoleModalConstants.INIT_PERMISSIONS,
          isValid: false
        }
      case CreateRoleModalResultActionTypes.LOAD_ROLE:
        return {...state,
          loadedRoleId: action.id,
          roleName: action.name,
          roleDescription: action.description,
          permissions: action.permissions
        }
      case CreateRoleModalResultActionTypes.CHANGE_IS_CREATING:
        return {...state, isCreating: action.value}
    }
  }
}
