<div class="child-routes-size px-[24px] tiny-scrollbar"
     (scroll)="onScroll()"
     #scrollable
>
  <div class="w-full flex justify-between relative">
    <app-core-page-header
      [title]="NewsConstants.NEWS"
      [navTabs]="NAV_TABS"
      navTabType="link"
      [borderBottom]="false"
    />

    <div
      class="absolute right-[48px] h-full flex items-center"
    >
      <app-core-button
        (onClick)="performAction({
          type: NewsListActionTypes.CREATE_NEWS
        })"
      >
        Создать новость
      </app-core-button>
    </div>

    <div class="absolute bottom-0 h-[1px] w-full bg-tt-border-default"></div>
  </div>

  <div class="w-full flex justify-center">
    <div class="w-full mt-[44px]"
       [class.max-w-[935px]]="state.listType === 'list-element'"
       [class.max-w-[1256px]]="state.listType === 'grid-cell'"
    >
      <div class="flex justify-end">
        <app-grid-list-toggle-button
          [currentType]="state.listType"
          (toggleGrid)="performAction({
            type: NewsListActionTypes.CHANGE_LIST_TYPE,
            listType: 'grid-cell'
          })"
          (toggleList)="performAction({
            type: NewsListActionTypes.CHANGE_LIST_TYPE,
            listType: 'list-element'
          })"
        />
      </div>

      <div class="mt-[24px] w-full flex gap-[24px] flex-wrap">
        <app-news-item
          *ngFor="let newsItem of state.news"
          [news]="newsItem"
          [type]="state.listType"
          [class.w-full]="state.listType === 'list-element'"
          (onClick)="performAction({
            type: NewsListActionTypes.OPEN_FULL_NEWS,
            news: $event
          })"
        />
      </div>

    </div>

  </div>
</div>

<app-core-alert-modal
  *ngIf="state.openDraftModalVisibility"
  type="info"
  closeActionType="self"
  [title]="'Продолжить создание?'"
  [cancelButtonText]="'Нет, создать новую'"
  [isLoading]="state.openDraftModalIsLoading"
  [confirmButtonText]="'Продолжить'"
  (onCloseClicked)="performAction({
    type: NewsListActionTypes.CLOSE_DRAFT_MODAL
  })"
  (onConfirmClicked)="performAction({
    type: NewsListActionTypes.CONTINUE_DRAFT
  })"
  (onCancelClicked)="performAction({
    type: NewsListActionTypes.CREATE_NEW_DRAFT
  })"
>
  <p>Ранее вы уже создавали новость “<span class="font-medium">{{ state.draft?.title == '' ? 'Без названия' : state.draft?.title }}</span>”, хотите продолжить?</p>
</app-core-alert-modal>

<app-news-full-news
  *ngIf="state.isFullNewsOpen && state.fullNews"
  [news]="state.fullNews"
  (onClose)="performAction({
    type: NewsListActionTypes.CLOSE_FULL_NEWS
  })"
  (onDelete)="performAction({
    type: NewsListActionTypes.DELETE_NEWS,
    id: state.fullNews.id
  })"
/>

<div
  class="fixed left-0 top-0 w-screen h-screen z-50"
  *ngIf="state.isPageLoading"
>
  <app-core-loading-shading/>
</div>
