import { Component } from "@angular/core";

@Component({
  selector: 'svg-photo',
  templateUrl: './svg/photo.svg',
})
export class SvgPhoto {}

@Component({
  selector: 'svg-chips-x',
  templateUrl: './svg/chips-x.svg',
})
export class SvgChipsX {}

@Component({
  selector: 'svg-question-mark-circle',
  templateUrl: './svg/question-mark-circle.svg',
})
export class SvgQuestionMarkCircle {}
