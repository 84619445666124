export type PasswordResetAction =
  | GoToNextStageAction
  | ChangeCurrentPasswordAction
  | ChangeNewPasswordAction
  | ChangeNewConfirmPasswordAction
  | GoBackAction

export enum PasswordResetActionTypes {
  GO_BACK,
  GO_TO_NEXT_STAGE,
  CHANGE_CURRENT_PASSWORD,
  CHANGE_NEW_PASSWORD,
  CHANGE_NEW_CONFIRM_PASSWORD,
}

export interface ChangeCurrentPasswordAction {
  readonly type: PasswordResetActionTypes.CHANGE_CURRENT_PASSWORD
  readonly value: string
}

export interface GoBackAction {
  readonly type: PasswordResetActionTypes.GO_BACK
}

export interface ChangeNewPasswordAction {
  readonly type: PasswordResetActionTypes.CHANGE_NEW_PASSWORD
  readonly value: string
}

export interface ChangeNewConfirmPasswordAction {
  readonly type: PasswordResetActionTypes.CHANGE_NEW_CONFIRM_PASSWORD
  readonly value: string
}

export interface GoToNextStageAction {
  readonly type: PasswordResetActionTypes.GO_TO_NEXT_STAGE
}
