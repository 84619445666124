<ng-container
  *transloco="let t; read: RoleConstants.TRANSLOCO_READ"
>
  <app-core-settings-content-element
    [title]="t('users')"
  >
    <p
      [textParse]="t('users-description')"
      [parseElements]="[{
          text: t('users-description-link'),
          className: 'text-tt-primary cursor-pointer',
          type: 'text'
        }]"
    ></p>
  </app-core-settings-content-element>

  <div class="h-[1px] w-full bg-tt-border-default"></div>

  <app-core-settings-content-element
    class="bg-[#F5F8FD]"
  >
    <div
      class="flex gap-[16px] w-full items-center"
    >
      <app-core-search-field
        class="w-full"
        [placeholder]="t('search-placeholder')"
        (onChange)="performAction({
          type: RoleUsersActionTypes.CHANGE_SEARCH_FIELD,
          value: $event
        })"
      />

      <app-core-button
        class="shrink-0 block"
        (onClick)="performAction({
        type: RoleUsersActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY,
        value: true
      })"
      >
        {{ t('add') }}
      </app-core-button>
    </div>
  </app-core-settings-content-element>

  <div>
    <app-core-user-summery
      className="px-[24px]"
      *ngFor="let user of state.filteredUsers"
      [user]="user"
      actionType="delete"
      [isDeletable]="true"
      (onDelete)="performAction({
        type: RoleUsersActionTypes.DELETE_USER,
        user: user
      })"
    />

    <div class="mt-[24px] relative py-[24px]">
      <app-core-loading-shading
        *ngIf="state.isLoading"
      />
    </div>

  </div>


  <app-select-user-modal
    *ngIf="state.isOpenSelectUsersModal"
    [alreadySelectedUserIds]="mapUsersToIds(state.users)"
    [isLoading]="state.isLoadingSelectUsersModal"
    (onClose)="performAction({
      type: RoleUsersActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY,
      value: false
    })"
    (onAddUsers)="performAction({
      type: RoleUsersActionTypes.ADD_USERS,
      users: $event
    })"
  />

  <app-core-alert-modal
    *ngIf="state.isOpenDeleteUserModal && state.userToDelete"
    type="warning"
    [title]="t('remove-role')"
    [isLoading]="state.isLoadingDeleteUserModal"
    [confirmButtonText]="t('remove')"
    [cancelButtonText]="t('cancel')"
    (onCancelClicked)="performAction({
      type: RoleUsersActionTypes.CLOSE_DELETE_MODAL
    })"
    (onConfirmClicked)="performAction({
      type: RoleUsersActionTypes.DELETE_CONFIRM
    })"
  >
    <p
      [textParse]="t('remove-role-description', { userName: state.userToDelete.surname + ' ' + state.userToDelete.name })"
      [parseElements]="[{
        text: state.userToDelete.surname + ' ' + state.userToDelete.name,
        className: 'font-medium',
        type: 'text'
      }]"
    ></p>
  </app-core-alert-modal>

</ng-container>
