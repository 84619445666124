import { Injectable } from '@angular/core';
import { DropdownItem } from "../../../../core/components/fields/components/dropdown-field/dropdown-field.component";

export type RegistrationStatus =
  | 'idle'
  | 'create_loading'
  | 'success'
  | 'create_error'
  | 'already_exist_error';

@Injectable()
export class RegistrationState {
  readonly status: RegistrationStatus = 'idle';

  readonly surname: string = '';
  readonly surnameError: string = '';

  readonly name: string = '';
  readonly nameError: string = '';

  readonly email: string = '';
  readonly emailError: string = '';

  readonly phoneNumber: string = '';
  readonly phoneNumberError: string = '';
}
