import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AppPaths } from "../../../../../app-routing.module";
import { PasswordRecoveryConstants } from "../../../common/password-recovery-constants";
import { PasswordRecoveryResultAction } from "../../state/password-recovery-result-action";

@Component({
  selector: 'app-password-recovery-form',
  templateUrl: './password-recovery-form.component.html',
  styleUrls: ['./password-recovery-form.component.scss']
})
export class PasswordRecoveryFormComponent {
  @Input() emailError: string | null = null
  @Input() isLoading: boolean = false;

  @Output() onSubmit = new EventEmitter();
  @Output() onEmailChange = new EventEmitter<string>();
  @Output() firstFocused = new EventEmitter();

  private wasFocused = false;

  focus(){
    if(!this.wasFocused){
      this.wasFocused = true;
      this.firstFocused.emit()
    }
  }

  protected readonly AppPaths = AppPaths;
  protected readonly PasswordRecoveryConstants = PasswordRecoveryConstants;
}
