import { Injectable } from "@angular/core";
import { Reducer } from "src/app/core/mvi/store";
import { NewsEditorResultAction, NewsListResultActionTypes } from "./news-editor-result-action";
import { NewsEditorState } from "./news-editor-state";

@Injectable()
export class NewsEditorReducer
  implements Reducer<NewsEditorState, NewsEditorResultAction>
{
  reduce(state: NewsEditorState, action: NewsEditorResultAction): NewsEditorState {
    switch (action.type) {
      case NewsListResultActionTypes.CHANGE_DESCRIPTION:
        return {...state,
          currentNews: {...state.currentNews,
            description: action.value
          },
          descriptionError: action.error,
        }
      case NewsListResultActionTypes.CHANGE_TITLE:
        return {...state,
          currentNews: {...state.currentNews,
            title: action.value
          },
          titleError: action.error,
        }
      case NewsListResultActionTypes.CHANGE_IMAGE_ID:
        return {...state,
          currentNews: {...state.currentNews,
            imageId: action.value
          }
        }
      case NewsListResultActionTypes.CHANGE_PUBLISH_SIDEBAR_VISIBLE:
        return {...state, isPublishSideBarOpen: action.value};
      case NewsListResultActionTypes.INIT:
        return {...state,
          newsId: action.newsId,
          loadedNews: action.loadedNews,
          editorType: action.editorType,
          currentNews: {
            id: action.loadedNews.id,
            title: action.loadedNews.title,
            description: action.loadedNews.description,
            longreadId: action.loadedNews.longreadID,
            dateStr: ''
          }
        };
      case NewsListResultActionTypes.SAVE_SESSION:
        return {...state, sessionId: action.sessionId, pingIntervalId: action.pingIntervalId};
      case NewsListResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value}
      case NewsListResultActionTypes.CHANGE_AFK_MODAL_VISIBILITY:
        return {...state, afkModalVisibility: action.value}
      case NewsListResultActionTypes.CHANGE_ALREADY_EDIT_MODAL_VISIBILITY:
        return {...state, alreadyEditModalVisibility: action.value}
      case NewsListResultActionTypes.INIT_CHILDREN:
        return {...state, editorComponent: action.editor, inputs: action.inputEls}
      case NewsListResultActionTypes.CHANGE_PREVIEW_TYPE:
        return {...state, previewType: action.value}
      case NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING:
        return {...state, isPageLoading: action.value}
      case NewsListResultActionTypes.CHANGE_IS_EDITOR_LOADING:
        return {...state, isEditorLoading: action.value}
      case NewsListResultActionTypes.CHANGE_IS_LOADING_AFK_MODAL:
        return {...state, isLoadingAfkModal: action.value}
    }
  }
}
