import { NavTab } from "src/app/core/components/nav-tabs/nav-tabs.component";
import { EmployeesRoutesPath } from "src/app/routes/employees-routes";
import { Tabs } from "../../main/presentation/state/main-state";
import { ActionElement } from "../../../core/components/action-menu/action-menu.component";

export class EmployeesConstants{
  public static readonly EMPLOYEES = 'team'
  public static readonly TRANSLOCO_READ = 'employeesMain'
  public static readonly BASE_ROLES_TRANSLOCO_READ = this.TRANSLOCO_READ + '.base-roles'

  public static NAV_TABS: NavTab[] = [
    {
      id: Tabs.EMPLOYEES,
      url: EmployeesRoutesPath.LIST,
      title: 'main',
    },
    {
      id: Tabs.ROLE,
      url: EmployeesRoutesPath.ROLES,
      title: 'roles',
    },
  ]

  public static EDIT_ACTION_MENU: ActionElement = {
    id: 'edit',
    name: 'edit'
  }

  public static DELETE_ACTION_MENU: ActionElement = {
    id: 'delete',
    name: 'delete'
  }

  public static readonly OWNER = 'owner'

  public static readonly BASE_ROLES = new Map<string, BaseRolesData>()
    .set(
        'owner',
        {
            name: 'owner-title',
            description: 'owner-description'
        },
    ).set(
        'admin',
        {
            name: 'admin-title',
            description: 'admin-description'
        },
    ).set(
        'viewer', {
            name: 'viewer-title',
            description: 'viewer-description'
        },
    ).set(
        'kb_editor', {
            name: 'kb-editor-title',
            description: 'kb-editor-description'
        }
    )
}


export interface BaseRolesData{
  name: string,
  description: string
}
