import { Inject, Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { Validator } from "src/app/core/validators/validator";
import { PasswordRecoveryAction, PasswordRecoveryActionTypes, ResetAction } from "./password-recovery-action";
import { PasswordRecoveryResultAction, PasswordRecoveryResultActionTypes } from "./password-recovery-result-action";
import { PasswordRecoveryState } from "./password-recovery-state";
import { AuthService } from "../../../authorization/domain/auth.service";
import { ToastsService } from "../../../../core/components/toast-alert/services/toast-alert.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastState } from "../../../../core/components/toast-alert/toast-alert.component";
import { translate, TranslocoService } from "@jsverse/transloco";
import { PasswordRecoveryConstants } from "../../common/password-recovery-constants";

@Injectable()
export class PasswordRecoveryExecutor extends Executor<
  PasswordRecoveryState,
  ResetAction,
  PasswordRecoveryResultAction
> {
  constructor(
    @Inject('RegistrationEmailValidator') private emailValidator: Validator,
    private authService: AuthService,
    private toastsService: ToastsService,
    private translocoService: TranslocoService,
  ) {
    super();
  }
  override init(
    reducer: Reducer<PasswordRecoveryState, PasswordRecoveryResultAction>,
    getState: () => PasswordRecoveryState,
    onReduced: (state: PasswordRecoveryState) => void
  ) {
    super.init(reducer, getState, onReduced);
    this.translocoService.langChanges$.subscribe(() => this.handleEmailValidate(this.getState().email))
  }

  execute(action: PasswordRecoveryAction) {
    switch (action.type) {
      case PasswordRecoveryActionTypes.VALIDATE:
        this.handleEmailValidate(this.getState().email)
        break

      case PasswordRecoveryActionTypes.CHANGE_EMAIL:
        this.handleEmailValidate(action.email)
        break;

      case PasswordRecoveryActionTypes.RESET:
        this.handleReset();
        break;
    }
  }

  private handleEmailValidate(value: string){
    const emailError = this.emailValidator.validate(value);

    this.reduce({
      type: PasswordRecoveryResultActionTypes.CHANGE_EMAIL,
      email: value,
      emailError: emailError ? translate(PasswordRecoveryConstants.TRANSLOCO_READ + '.' + emailError) : '',
    });
  }

  private handleReset() {
    this.handleEmailValidate(this.getState().email)

    if(this.getState().emailError.length != 0){
      return
    }

    this.reduce({ type: PasswordRecoveryResultActionTypes.CHANGE_LOADING_STATE });

    this.authService.notifyAboutPasswordChange(this.getState().email).subscribe({
      next: value => {
        this.reduce({
          type: PasswordRecoveryResultActionTypes.CHANGE_STATUS_STATE,
          status: 'success',
        });
        this.reduce({
          type: PasswordRecoveryResultActionTypes.CHANGE_LOADING_STATE,
        });
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404)
        {
          this.toastsService.createToast({
            title: 'Пользователь с таким email не найден',
            description: 'Проверьте правильность введенного email и повторите попытку',
            state: ToastState.ERROR
          })
        }
        else {
          this.toastsService.createToast({
            title: 'Произошла неизвестная ошибка',
            description: 'Попробуйте позднее',
            state: ToastState.ERROR
          })
        }

        this.reduce({
          type: PasswordRecoveryResultActionTypes.CHANGE_LOADING_STATE,
        });
      }
    })

  }
}
