import { Routes } from "@angular/router";
import { SettingsMainComponent } from "../features/settings/modules/main/presentation/settings-main.component";
import {
  PaymentAndTariffComponent
} from "../features/settings/modules/payment-and-tariff/presentation/payment-and-tariff.component";
import { OrdersComponent } from "../features/settings/modules/orders/presentation/orders.component";

export enum SettingsRoutesPaths {MAIN = 'main', BILLING = 'billing', ORDERS = 'orders'}

export const SETTINGS_ROUTES: Routes = [
  {
    path: SettingsRoutesPaths.ORDERS,
    component: OrdersComponent,
  },
  {
    path: SettingsRoutesPaths.BILLING,
    component: PaymentAndTariffComponent,
  },
  {
    path: SettingsRoutesPaths.MAIN,
    component: SettingsMainComponent,
  },
];
