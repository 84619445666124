import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EmptyRule } from '../../core/validators/rule';
import { Validator } from '../../core/validators/validator';
import { AuthorizationComponent } from './presentation/view/authorization.component';
import { RouterLink } from "@angular/router";
import { ButtonComponent } from "../../core/components/buttons/button/button.component";
import {
  AuthContainerIconComponent
} from "../../core/components/auth/auth-container/auth-container-icon/auth-container-icon.component";
import { AuthContainerComponent } from "../../core/components/auth/auth-container/auth-container.component";
import { AuthLayoutComponent } from "../../core/components/auth/auth-layout/auth-layout.component";
import { FieldsModule } from "../../core/components/fields/fields.module";
import { SvgLoginIcon } from "./presentation/icons/authorization-icons";
import { TranslocoModule } from "@jsverse/transloco";

@NgModule({
  declarations: [
    AuthorizationComponent,
    SvgLoginIcon
  ],
  exports: [AuthorizationComponent],
  imports: [
    CommonModule,
    NgOptimizedImage,
    HttpClientModule,
    RouterLink,
    ButtonComponent,
    AuthContainerIconComponent,
    AuthContainerComponent,
    AuthLayoutComponent,
    FieldsModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: 'AuthEmailValidator',
      useExisting: Validator,
      useFactory: AuthorizationModule.emailValidatorFactory,
    },
    {
      provide: 'AuthPasswordValidator',
      useExisting: Validator,
      useFactory: AuthorizationModule.passwordValidatorFactory,
    },
  ],
})
export class AuthorizationModule {
  public static emailValidatorFactory = () =>
    new Validator([new EmptyRule('email-error-empty')]);

  public static passwordValidatorFactory = () =>
    new Validator([new EmptyRule('password-error-empty')]);
}
