<div
  class="dropdown-block"
  *transloco="let t; read: OrdersConstants.TRANSLOCO_READ"
>
  <div class="flex gap-[16px] items-center">
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        {{ t('order-number') }}
      </p>
      <p class="subscription-part-value">
        {{ order.id }}
      </p>
    </div>
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        {{ t('creation-date') }}
      </p>
      <p class="subscription-part-value">
        {{getCreatedAtString()}}
      </p>
    </div>
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        {{ t('payment-method') }}
      </p>
      <p class="subscription-part-value">
        {{ order.paymentType === PaymentType.CARD ? t('online-payment') : t('payment-by-invoice') }}
      </p>
    </div>
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        {{ t('amount-to-paid') }}
      </p>
      <p class="subscription-part-value">
        {{ getFormatNumberString(order.totalValue) }} ₽
      </p>
    </div>
    <div
      class="w-full"
    >
      <p class="subscription-part-title">
        {{ t('status') }}
      </p>
      <p class="subscription-part-value">
        <span *ngIf="order.status === InvoiceStatus.CANCELED">
          {{ t('canceled') }}
        </span>
        <span
          class="text-[#3BB46C]"
          *ngIf="order.status === InvoiceStatus.PAID"
        >
          {{ t('paid') }}
        </span>
        <span
          class="text-tt-primary"
          *ngIf="order.status === InvoiceStatus.PENDING"
        >
          {{ t('awaiting-payment') }}
        </span>
      </p>
    </div>

    <button
      class="flex-grow-0 size-[36px] rounded-[8px] shrink-0 bg-[#F0F5FC] flex justify-center items-center"
      (click)="isOpen = !isOpen"
    >
      <svg-arrow class="size-[24px] block text-tt-text-secondary transition-transform"
        [class.rotate-90]="!isOpen"
        [class.rotate-[270deg]]="isOpen"
      />
    </button>
  </div>

  <div
    *ngIf="isOpen"
  >
    <app-receipt
      [sections]="order.receiptSections"
      [resultLocal]="translateAmount"
    />

    <div
      *ngIf="order.status === InvoiceStatus.PENDING"
      class="mt-[16px] w-full flex justify-end gap-[16px]"
    >
      <app-core-button
        variant="soft"
        (onClick)="onCancel.emit()"
      >
        {{ t('cancel-order') }}
      </app-core-button>
      <app-core-button
        (onClick)="onPay.emit()"
      >
        {{ t('proceed-to-payment') }}
      </app-core-button>
    </div>
  </div>
</div>
