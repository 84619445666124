import { useEffect } from "react";
import YooptaEditor from "@yoopta/editor";
import { useInjected } from "@bubblydoo/angular-react";
import { EditorChangesService } from "../../../data/editor-changes-service";

function ReactYooptaEditor(props: any) {
  const changesService = useInjected(EditorChangesService)

  function handleChange() {
    if(changesService)
    {
      changesService.onChanges(true)
    }
  }

  useEffect(() => {
    props.editor.on('change', handleChange);
    return () => {
      props.editor.off('change', handleChange);
    };
  }, [props.editor]);

  return (
    <div>
      <YooptaEditor
        {...props}
      />
    </div>
  );
}
export default ReactYooptaEditor
