<div class="wrapper">
  <main>
    <router-outlet></router-outlet>
    <app-core-toast-alert />
    <app-core-button
      class="fixed bottom-[24px] right-[24px] z-[1000]"
      (click)="changeLocalisation()"
    >
      {{ localisation }}
    </app-core-button>
  </main>
</div>
