<div
  [ngClass]="{
    'block': isPopupVisible,
    'hidden group-hover/triple-dot:block': !isPopupVisible,
  }"
>
  <app-core-button-icon
    size="m"
    (onClick)="isPopupVisible = true"
  >
    <svg-triple-dot class="size-[24px] block"/>
  </app-core-button-icon>

  <app-core-action-menu
    *ngIf="isPopupVisible"
    [translocoRead]="translocoRead"
    [sections]="sections"
    (elementClicked)="elementClicked($event)"
    appPopup
    (popupClose)="isPopupVisible = false"
  />
</div>

