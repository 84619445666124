import { Injectable } from '@angular/core';
import { SpaceNavItems } from '../../domain/nav-items';
import { DocumentSummaryEntity } from '../../domain/document-summery-entity';
import { SpaceDtoPermissions } from '../../../../data/dto/space-dto';
import { NavTab } from 'src/app/core/components/nav-tabs/nav-tabs.component';
import { SpaceConstants } from "../../common/space-constants";

@Injectable()
export class SpaceState {
  readonly id: string = ''
  readonly name: string = ''
  readonly currentNavItem: SpaceNavItems = SpaceNavItems.MAIN
  readonly navTabs: NavTab[] = []

  readonly isSpaceLoading: boolean = false
  readonly isContentFirstLoading: boolean = false
  readonly isContentLoading: boolean = false
  readonly isPageLoading: boolean = false

  readonly isDocumentListVisible: boolean = true

  readonly alertDocumentId?: string
  readonly alertDocumentName?: string
  readonly alertIsLoading: boolean = false
  readonly isDeleteModalOpen: boolean = false
  readonly isRenameModalOpen: boolean = false

  readonly selectedDocument: DocumentSummaryEntity = SpaceConstants.SELECTED_DOCUMENT_INIT
  readonly selectedDocumentIsLoading: boolean = true
  readonly selectedDocumentUpdatedAt: string = ''

  readonly permissions: SpaceDtoPermissions = {
    isCreateAvailable: false,
    settings: false
  }

  readonly documents: DocumentSummaryEntity[] = []

  readonly searchFieldValue: string = ''
  readonly filteredDocuments: DocumentSummaryEntity[] = []
}
