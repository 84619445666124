import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../../domain/auth.service';
import { MainRoutesPaths } from "../../../../routes/main-routes";

export const alreadyLoginGuardFunction: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.isAuthenticated()) {
    return true;
  }

  return router.navigate([MainRoutesPaths.KNOWLEDGE_BASE]);
};
