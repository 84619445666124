import { Routes } from "@angular/router";
import {
  AgilesDeskSettingsComponent
} from "../features/agiles/agile-desk/modules/agiles-desk-settings/presentation/agiles-desk-settings.component";
import {
  AgilesDeskMainComponent
} from "../features/agiles/agile-desk/modules/agiles-desk-main/presentation/agiles-desk-main.component";

export enum AgileDeskRoutesPath { DESK = '', DESK_SETTINGS = 'settings',}

export const AGILE_DESK_ROUTES: Routes = [
  {
    path: AgileDeskRoutesPath.DESK_SETTINGS,
    component: AgilesDeskSettingsComponent,
  },
  {
    path: AgileDeskRoutesPath.DESK,
    component: AgilesDeskMainComponent,
  },
];
